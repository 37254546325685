import { Box, Icon } from '@palmetto/palmetto-components';
import { Cancellation } from 'types';
import { cancelReasonLabels, cancelActivityRequesterTypeLabels } from './CancelReactivateAccountModal';
import DateTimeDisplay from '../DateTime';
import ReactivateAccount from './ReactivateAccount';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../auth/requirePermissions';
import { HiddenLightReachInfoForEpcUser } from '../auth/HiddenLightReachInfoToEpcUser';

interface CancelledBannerProps {
  cancellation: Cancellation;
  className?: string;
}

const CancelledBanner = ({ cancellation, className }: CancelledBannerProps) => {
  const { notes, reason, requestedBy, requestedByDisplayName, requestedDate, requesterType } = cancellation || {};
  return (
    <Box
      borderWidth="xs"
      borderColor="danger-200"
      radius="md"
      direction={{ base: 'column', tablet: 'row' }}
      alignItems="stretch"
      fontSize="sm"
      overflow="hidden"
      background="primary"
      shadow="xs"
      className={className}
    >
      <Box
        background="danger"
        padding="lg"
        justifyContent="center"
        direction="row"
        gap="sm"
        color="danger"
        alignItems="center"
      >
        <Box>
          <Icon name="c-remove" size="xl" />
        </Box>
        <Box>
          <Box
            fontSize="lg"
            fontWeight="bold"
            style={{ lineHeight: 'var(--size-line-height-base)' }}
            display="block"
          >
            Account Cancelled
          </Box>
        </Box>
      </Box>
      <Box flex="auto" gap="xs" padding="lg" fontSize="sm" fontWeight="medium" justifyContent="center">
        <Box direction="row" gap="2xs">
          <Box fontWeight="bold">{cancelActivityRequesterTypeLabels[requesterType]} Requested</Box>
          <Box as="em"> - {notes ? notes : cancelReasonLabels[reason]}</Box>
        </Box>
        <Box direction="row" gap="2xs" fontSize="xs">
          <DateTimeDisplay value={requestedDate.toString()} fontSize="xs" dateFormat="DD h:mm a" inline /> by{' '}
          <HiddenLightReachInfoForEpcUser fallbackChildren="LightReach Support">{requestedByDisplayName || requestedBy}</HiddenLightReachInfoForEpcUser>
        </Box>
      </Box>
      <Box padding={{ base: '0 lg lg lg', tablet: 'lg' }} justifyContent="center">
        <RequirePalmettoFinanceUser>
          <RequirePermissions permissions={['admin']}>
            <ReactivateAccount />
          </RequirePermissions>
        </RequirePalmettoFinanceUser>
      </Box>
    </Box>
  );
};

export default CancelledBanner;
