import { ObjectId } from 'mongodb';
import { MilestoneRequirement } from './';

/*
 *  An account requirement stores the state of a requirement intended to satisfy a milestone. The account requirement may change
 *  due to a variety of reasons, such as a task being completed, or a contract being signed.
 */

// const example = {
//   _id: new ObjectId(),
//   accountId: new ObjectId(),
//   accountMilestoneId: new ObjectId(),
//   completedAt: new Date(),
//   isRequired: true,
//   name: 'Title Verified',
//   order: 3,
//   requirementId: new ObjectId(),
//   status: 'completed',
//   statusTimeline: [
//     {
//       oldStatus: 'pending',
//       newStatus: 'completed',
//       updatedAt: '2023-09-20',
//     },
//   ],
//   type: 'titleApproved',
// };

export interface AccountRequirementDocument {
  id: ObjectId;
  accountId: ObjectId;
  accountMilestoneId: ObjectId;
  completedAt?: Date;
  completed: boolean;
  isRequired: boolean; // copied from organizationMilestones template
  name: string; // copied from organizationMilestones template
  order: number; // copied from organizationMilestones template
  parentRequirementId?: ObjectId;
  requirementId: ObjectId; // copied from organizationMilestones template
  status: AccountRequirementStatus;
  statusTimeline?: RequirementStatusUpdates[];
  submittedAt?: Date;
  type: MilestoneRequirement;
  wasAutoCompleted?: boolean;
}

export interface RequirementStatusUpdates {
  oldStatus: AccountRequirementStatus;
  newStatus: AccountRequirementStatus;
  modifiedAt: Date;
  modifiedBy: string;
}

export enum AccountRequirementStatus {
  completed = 'completed',
  error = 'error',
  inProgress = 'inProgress',
  pending = 'pending',
  rejected = 'rejected',
  resubmitted = 'resubmitted',
  submitted = 'submitted',
}
