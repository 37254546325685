import { ApplicationDocumentWithAdditionalApplicantData } from 'types';
import { Application, ApplicationSuccessResponse } from '../types/Applications';
import { api } from './api';

interface GetPropertyReportsForApplicationQueryParams {
  accountId: string;
  applicationId: string;
  includeReportDetails?: boolean;
}

interface GetApplicationsQueryParams {
  accountId: string;
  inviteToken?: string | null;
}

export const applicationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApplications: build.query<ApplicationDocumentWithAdditionalApplicantData[], GetApplicationsQueryParams>({
      query: ({ accountId, inviteToken }) => ({
        url: `accounts/${accountId}/applications`,
        params: inviteToken ? { inviteToken } : undefined,
      }),
      providesTags: () => [{ type: 'Applications', id: 'LIST' }],
    }),
    getExternalApplicationUrl: build.query<{ url: string }, string>({
      query: (id) => ({
        url: `accounts/${id}/applications/external`,
      }),
    }),
    getPropertyReportsForApplication: build.query<any, GetPropertyReportsForApplicationQueryParams>({
      query: ({ accountId, applicationId, includeReportDetails }) => ({
        url: `accounts/${accountId}/applications/${applicationId}/property-reports${
          includeReportDetails ? '?includeReportDetails=true' : ''
        }`,
      }),
    }),
    createApplication: build.mutation<ApplicationSuccessResponse, any>({
      query: ({ application, accountId, inviteToken }) => ({
        url: `accounts/${accountId}/applications`,
        method: 'POST',
        body: application,
        params: inviteToken ? { inviteToken } : undefined,
      }),
      invalidatesTags: (_result, _error, { accountId }) => [
        { type: 'Accounts', id: accountId },
        { type: 'Applications', id: 'LIST' },
        { type: 'CreditReportSummaries', id: 'LIST' },
        { type: 'AccountMilestones', id: accountId },
      ],
    }),
    overrideApplication: build.mutation<Application, any>({
      query: ({ accountId, applicationId }) => ({
        url: `accounts/${accountId}/applications/${applicationId}/credit-override`,
        method: 'PUT',
      }),
      invalidatesTags: (_result, _error, { accountId }) => [
        { type: 'Applications', id: 'LIST' },
        { type: 'AccountMilestones', id: accountId },
        { type: 'Accounts', id: accountId },
      ],
    }),
    sendExternalApplicationUrl: build.mutation<void, { accountId: string; email: string; firstName: string }>({
      query: ({ accountId, email, firstName }) => ({
        url: `accounts/${accountId}/applications/external/send`,
        method: 'POST',
        body: { email, firstName },
      }),
    }),
    verifyOwnershipForApplication: build.mutation<Application, any>({
      query: ({ applicationId, accountId }) => ({
        url: `accounts/${accountId}/applications/${applicationId}/verify-ownership`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, { accountId }) => [
        { type: 'Applications', id: 'LIST' },
        { type: 'AccountMilestones', id: accountId },
        { type: 'Accounts', id: accountId },
      ],
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useLazyGetExternalApplicationUrlQuery,
  useLazyGetPropertyReportsForApplicationQuery,
  useCreateApplicationMutation,
  useOverrideApplicationMutation,
  useSendExternalApplicationUrlMutation,
  useVerifyOwnershipForApplicationMutation,
} = applicationsApi;
