import { NewCreditApplication, RawApplicationApplicant } from 'types';
import { ApplicationFormSchema } from '../components/CreditCheck/ApplicationForm';

export const createApplicationRequest = (
  accountData: any,
  disclosures: any,
  application: ApplicationFormSchema,
): NewCreditApplication => {
  let applicants: Array<RawApplicationApplicant> = [];
  applicants.push({
    firstName: application.primary.firstName.trim(),
    middleName: application.primary?.middleName?.trim() || undefined,
    lastName: application.primary.lastName?.trim(),
    phoneNumber: application.primary.phoneNumber?.trim(),
    email: application.primary.email?.trim(),
    type: 'primary',
    address: {
      address1: application.address1,
      address2: application?.address2 || undefined,
      city: application.city,
      state: application.state,
      zip: application.zip,
    },
    disclosures: [
      {
        id: disclosures[0].id,
        type: disclosures[0].type,
        accepted: application.creditApplicationDisclosure,
        version: disclosures[0].version,
      },
    ],
    ssn: application.primary?.ssn?.trim() || '',
  });

  if (application.addAdditionalApplicant) {
    applicants.push({
      firstName: application.secondary?.firstName.trim(),
      middleName: application.secondary?.middleName?.trim() || undefined,
      lastName: application.secondary?.lastName?.trim(),
      phoneNumber: application.secondary?.phoneNumber?.trim(),
      email: application.secondary?.email?.trim(),
      type: 'secondary',
      address: {
        address1: application.address1,
        address2: application?.address2 || undefined,
        city: application.city,
        state: application.state,
        zip: application.zip,
      },
      disclosures: [
        {
          id: disclosures[0].id,
          type: disclosures[0].type,
          accepted: application.creditApplicationDisclosure,
          version: disclosures[0].version,
        },
      ],
      ssn: application.secondary?.ssn?.trim() || '',
    });
  }

  return {
    externalReference: accountData.externalReference,
    applicants,
  };
};
