import { Box, CheckboxInput } from '@palmetto/palmetto-components';
import {
  HvacParentReason,
  ParentReason,
  ProgramType,
  activationPackageRejectionReasons,
  activationPackageSubRejectionReasons,
  activationPackageSubRejectionReasonsForBatteryMonitoringId,
  hvacInstallPackageRejectionReasons
} from 'types';
interface RejectionReasonInputsProps {
  handleChange: (field: string, value: any) => any;
  isSubmitting: boolean;
  parentReasons: (ParentReason | HvacParentReason)[];
  packageRejection: typeof activationPackageRejectionReasons[] | typeof hvacInstallPackageRejectionReasons[];
  packageSubRejection?: typeof activationPackageRejectionReasons[];
  programType: ProgramType;
}

export const MultiOptionsQueueRejectionReasonCheckboxGroup = ({
  handleChange,
  isSubmitting,
  parentReasons,
  packageRejection,
  packageSubRejection,
  programType,
}: RejectionReasonInputsProps) => {

  const packageParentReason = programType === ProgramType.hvac ? Object.values(HvacParentReason) : Object.values(ParentReason);
  const rejectionReasons = programType === ProgramType.hvac ? hvacInstallPackageRejectionReasons : activationPackageRejectionReasons;

  const handleParentReasonSelection = ({
    isSelected,
    value,
    selectedOptions,
  }: {
    isSelected: boolean;
    value: ParentReason | HvacParentReason;
    selectedOptions: ParentReason[] | HvacParentReason[];
  }) => {
    const newSelectedOptions = [...selectedOptions];

    if (isSelected) {
      newSelectedOptions.push(value);
    } else {
      const index = selectedOptions.findIndex((selectedOption: any) => selectedOption === value);
      if (index > -1) {
        newSelectedOptions.splice(index, 1);
        handleChange('rejection', packageRejection.filter((rejection: any) => rejection.parentReason !== value));
        handleChange('subRejection', packageSubRejection ? packageSubRejection.filter((rejection: any) => rejection.parentReason !== value) : []);
      };
    }
    handleChange('parentReason', newSelectedOptions);
  };

  const handleRejectionOptionSelection = async ({
    isSelected,
    value,
    selectedOptions,
    parentReason
  }: {
    isSelected: boolean;
    value: typeof activationPackageSubRejectionReasons;
    selectedOptions: any;
    parentReason: ParentReason
  }) => {
    const newSelectedOptions = [...selectedOptions];
    if (isSelected) {
      newSelectedOptions.push({ parentReason, rejectedReason: value });
    } else {
      const index = selectedOptions.findIndex((selectedOption: any) => selectedOption.parentReason === parentReason &&
        selectedOption.rejectedReason === value);
      if (index > -1) {
        newSelectedOptions.splice(index, 1)
        const releasedSubRejection = packageSubRejection?.filter((rejection: any) => rejection.parentReason !== parentReason ||
          rejection.rejectedReason !== value);
        handleChange('subRejection', releasedSubRejection);
      };
    }
    await handleChange('rejection', newSelectedOptions);
  };

  const handleRejectionSubOptionSelection = async ({
    isSelected,
    value,
    parentReason,
    selectedSubOptions,
    option
  }: {
    isSelected: boolean;
    value: typeof activationPackageRejectionReasons | typeof hvacInstallPackageRejectionReasons;
    parentReason: ParentReason
    selectedSubOptions: any;
    option: any;
  }) => {
    const newSelectedSubOptions = [...selectedSubOptions];

    if (isSelected) {
      newSelectedSubOptions.push({ parentReason, rejectedReason: option, subRejectedReason: value });
    } else {
      const index = selectedSubOptions.findIndex((selectedSubOption: any) => selectedSubOption.parentReason === parentReason && selectedSubOption.rejectedReason === option && selectedSubOption.subRejectedReason === value);
      if (index > -1) newSelectedSubOptions.splice(index, 1);
    }

    await handleChange('subRejection', newSelectedSubOptions);
  };

  return (
    <Box gap="sm">
      {packageParentReason.map((parentReason) => (
        <Box key={parentReason} gap="sm">
          <CheckboxInput
            id={parentReason}
            label={parentReason}
            name={parentReason}
            isChecked={parentReasons?.includes(parentReason) || false}
            isDisabled={isSubmitting}
            onChange={(event) =>
              handleParentReasonSelection({
                isSelected: event.target.checked,
                value: parentReason,
                selectedOptions: parentReasons as ParentReason[] | HvacParentReason[],
              })
            }
          />
          {parentReasons.includes(parentReason) &&
            rejectionReasons[parentReason as keyof typeof rejectionReasons] && (
              <Box gap="sm" margin="0 0 0 lg">
                {Object.values(rejectionReasons[parentReason as keyof typeof rejectionReasons]).map(
                  (option: any) => (
                    <Box key={`${parentReason}-${option}`} gap="sm">
                      <CheckboxInput
                        id={`${parentReason}-${option}`}
                        label={option}
                        name={option}
                        isChecked={
                          packageRejection.some(
                            (selectedOption: any) =>
                              selectedOption.parentReason === parentReason &&
                              selectedOption.rejectedReason === option
                          ) || false
                        }
                        isDisabled={isSubmitting}
                        onChange={(event) =>
                          handleRejectionOptionSelection({
                            isSelected: event.target.checked,
                            value: option,
                            selectedOptions: packageRejection,
                            parentReason: parentReason as ParentReason,
                          })
                        }
                      />
                      {packageRejection.some(
                        (selectedOption: any) =>
                          selectedOption.parentReason === parentReason &&
                          selectedOption.rejectedReason === option
                      ) && (
                          <Box gap="sm" margin="0 0 0 lg">
                            {(activationPackageSubRejectionReasons[option as keyof typeof activationPackageSubRejectionReasons] ||
                              activationPackageSubRejectionReasonsForBatteryMonitoringId[option as keyof typeof activationPackageSubRejectionReasonsForBatteryMonitoringId]) &&
                              Object.values(
                                parentReason === ParentReason.batteryMonitoringId ? activationPackageSubRejectionReasonsForBatteryMonitoringId[option as keyof typeof activationPackageSubRejectionReasonsForBatteryMonitoringId] :
                                  activationPackageSubRejectionReasons[option as keyof typeof activationPackageSubRejectionReasons]
                              ).map((subOption, subIndex) => (
                                <CheckboxInput
                                  id={`${option}-${subOption}`}
                                  label={subOption}
                                  name={subOption}
                                  key={`${option}-${subIndex}`}
                                  isChecked={
                                    packageSubRejection?.some(
                                      (selectedOption: any) =>
                                        selectedOption.parentReason === parentReason &&
                                        selectedOption.rejectedReason === option &&
                                        selectedOption.subRejectedReason == subOption
                                    ) || false
                                  }
                                  isDisabled={isSubmitting}
                                  onChange={(event) =>
                                    handleRejectionSubOptionSelection({
                                      isSelected: event.target.checked,
                                      parentReason: parentReason as ParentReason,
                                      value: subOption,
                                      selectedSubOptions: packageSubRejection,
                                      option: option,
                                    })
                                  }
                                />
                              ))}
                          </Box>
                        )}
                    </Box>
                  )
                )}
              </Box>
            )}
        </Box>
      ))}
    </Box>
  );
};