import { Alert, Box } from '@palmetto/palmetto-components';

export const AlertProductionDifference = ({ productionDifference }: { productionDifference: number }) => {
  return (
    <Alert
      variant="danger"
      title={`${productionDifference * 100.0}% difference between the new system year-1 production estimate and the contracted estimate exceeds allowable limit.`}
      message={
        <Box>
          To achieve install approval:
          <ol style={{ paddingLeft: '15px' }}>
            <li>Void the existing quote and contract</li>
            <li>Update annual system production with the installed system production estimate</li>
            <li>Create a new quote and contract</li>
            <li>Customer e-sign new contract</li>
          </ol>
        </Box>
      }
      hasIcon
    />
  );
};
