import { ActivityList } from './ActivityList';
import { Box, Button, Spinner } from '@palmetto/palmetto-components';
import { useGetAccountActivityQuery } from '../../services/accounts';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

export const AccountActivity = () => {
  const { id } = useParams<{ id: any }>();
  const { data: activity, isLoading: isActivityLoading, refetch, isFetching } = useGetAccountActivityQuery({ id });
  const handleRefresh = useCallback(() => {
    console.log('refreshing activity');
    refetch();
  }, [refetch]);

  return (
    <Box display="block" childGap="sm">
      {!isActivityLoading && (
        <Box alignItems={{ base: 'stretch', tablet: 'flex-start' }} padding={{ base: '0 lg', desktop: '0' }}>
          <Button
            iconPrefix="refresh"
            variant="secondary"
            tone="neutral"
            size="sm"
            isLoading={isFetching || isActivityLoading}
            onClick={handleRefresh}
          >
            refresh
          </Button>
        </Box>
      )}
      <Box>{isActivityLoading ? <Spinner size="lg" /> : <ActivityList activities={activity?.data} />}</Box>
    </Box>
  );
};
