import { api } from './api';
import { ProductClassification, ProductDTO } from 'types';

export const productCatalogApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query<ProductDTO[], any>({
      query: ({ classification, ...rest }) => ({
        url: `product-catalog/products/${classification}`,
        params: { ...rest },
      }),
    }),
  }),
});

export const { useGetProductsQuery } = productCatalogApi;
