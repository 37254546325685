import { datadogRum } from '@datadog/browser-rum';
import getConfigValue from '../config';

export const setDataDogUser = ({ id, username, email, organization }: Record<string, any>) => {
  datadogRum.setUser({
    id,
    username,
    email,
    organization,
  });
};

export const setDataDogUserProperty = (key: string, value: string) => {
  datadogRum.setUserProperty(key, value);
};

export const initDataDog = (enableSessionRecording = false) => {
  const env = getConfigValue('NODE_ENV');
  if (env !== 'development') {
    if (!(window as any)?.DD_RUM?.getInternalContext()) {
      const datadogConfig = {
        appId: getConfigValue('VITE_APP_DD_APP_ID'),
        clientToken: getConfigValue('VITE_APP_DD_CLIENT_TOKEN'),
        envName: getConfigValue('VITE_APP_DD_ENV_NAME'),
        appVersion: getConfigValue('VITE_APP_VERSION'),
      };
      datadogRum.init({
        applicationId: datadogConfig.appId,
        clientToken: datadogConfig.clientToken,
        site: 'datadoghq.com',
        service: 'sabal-app',
        env: datadogConfig.envName,
        version: datadogConfig.appVersion,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [/https:\/\/(?!auth).+\.palmetto\.(com|finance)/],
      });
    }

    if (enableSessionRecording && (window as any)?.DD_RUM?.getInternalContext()) {
      datadogRum.startSessionReplayRecording();
    } else {
      console.log('DD RUM session recording is disabled');
    }
  } else {
    console.log('DD RUM is disabled in local development mode');
  }
};
