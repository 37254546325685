import { ObjectId } from 'mongodb';
import { MilestoneTemplateType } from './Milestones';
import { Meta, MilestoneRequirement, MilestoneType } from '.';

/*
 *  The account milestones is generated from the organization milestone template and is where the computed
 *  milestone state is stored for an account. The state is computed whenever the referenced requirements state changes.
 */

// const example = {
//   _id: new ObjectId(),
//   accountId: new ObjectId(),
//   milestones: [
//     {
//       accountMilestoneId: new ObjectId(), // generated for this sub-document
//       completedAt: new Date(),
//       milestoneId: new ObjectId(), // copied from organizationMilestones template
//       milestoneKey: 'noticeToProceed',
//       name: 'Notice to Proceed',
//       order: 0,
//       requirementRefs: [new ObjectId(), new ObjectId(), new ObjectId()],
//       status: 'approved',
//       statusTimeline: [
//         {
//           oldStatus: 'pending',
//           newStatus: 'approved',
//           updatedAt: '2023-09-20',
//         },
//       ],
//     },
//     {
//       accountMilestoneId: new ObjectId(), // generated for this sub-document
//       milestoneId: new ObjectId(), // copied from organizationMilestones template
//       milestoneKey: 'install',
//       name: 'Install',
//       order: 1,
//       requirementRefs: [new ObjectId(), new ObjectId()],
//       status: 'pending',
//       statusTimeline: [],
//     },
//   ],
//   organzationId: 'beeblebrox',
// };

export interface AccountMilestonesDocument {
  id: ObjectId;
  accountId: ObjectId;
  milestones: AccountMilestone[];
  organizationId: string; // copied from organizationMilestones template
  completedAt?: Date;
}

export interface AccountMilestone {
  accountMilestoneId: ObjectId;
  completed: boolean;
  completedAt?: Date;
  milestoneId: ObjectId;
  name: string; // copied from organizationMilestones template
  order: number; // copied from organizationMilestones template
  requirementsRefs: ObjectId[];
  status: AccountMilestoneStatus;
  statusTimeline: MilestoneStatusUpdates[];
  type: MilestoneTemplateType;
  exception?: {
    note: string;
    exceptionGivenBy: string;
  } | null;
}

export interface MilestoneStatusUpdates {
  oldStatus: AccountMilestoneStatus;
  newStatus: AccountMilestoneStatus;
  modifiedAt: Date;
}

export enum AccountMilestoneStatus {
  approved = 'approved',
  conditionallyApproved = 'conditionallyApproved',
  paused = 'paused',
  pending = 'pending',
  rejected = 'rejected',
  restarted = 'restarted',
  resubmitted = 'resubmitted',
  submitted = 'submitted',
}

export interface AccountMilestoneProgress {
  id: ObjectId;
  accountId: ObjectId;
  latestCompletedMilestone: MilestoneType;
  latestSatisfiedRequirement: MilestoneRequirement;
  milestones: AccountMilestonesDocument[];
  meta?: Meta;
}
