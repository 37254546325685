import { useCallback, useState } from 'react';
import { Box, Button, ButtonSize, Modal, Popover } from '@palmetto/palmetto-components';
import { TaskQueueSortField } from '../../types/Queues';

interface SortByButtonPopoverProps {
  className?: string;
  label?: string;
  selectedSort: TaskQueueSortField;
  sortOptions: Record<TaskQueueSortField, string>;
  size?: ButtonSize;
  onApplySort: (sort: TaskQueueSortField) => void;
  isMobile: boolean;
}

export const SortByButtonPopover = ({
  label = 'Sort',
  selectedSort,
  sortOptions,
  className,
  size = 'sm',
  onApplySort,
  isMobile,
}: SortByButtonPopoverProps) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleOpenPopover = useCallback(() => {
    setShowMenu(!showMenu);
  }, [setShowMenu, showMenu]);

  const handleSortSelection = useCallback(
    (sort: TaskQueueSortField) => () => {
      onApplySort(sort);
      setShowMenu(!showMenu);
    },
    [onApplySort, setShowMenu, showMenu],
  );

  const closeMenu = useCallback(async () => {
    setShowMenu(false);
  }, [setShowMenu]);

  const content = (
    <Box padding="sm" gap="xs">
      {Object.keys(sortOptions).map((sort: any) => {
        const queueSort = sort as TaskQueueSortField;

        return (
          <Button
            key={sort}
            size="sm"
            variant={queueSort === selectedSort ? 'primary' : 'tertiary'}
            tone="neutral"
            onClick={handleSortSelection(queueSort)}
          >
            {sortOptions[queueSort]}
          </Button>
        );
      })}
    </Box>
  );

  // open a modal on a phone instead of dropdown popover
  if (isMobile) {
    return (
      <div className={className}>
        <Modal ariaLabelledBy="titleFooterBody" isOpen={showMenu} onDismiss={closeMenu}>
          <Modal.Header id="titleFooterBody" title="Sort By" onDismiss={closeMenu} />
          <Modal.Body padding="0 sm">{content}</Modal.Body>
        </Modal>
        <Button
          onClick={handleOpenPopover}
          variant="secondary"
          tone="neutral"
          size={size}
          iconSuffix={isMobile ? undefined : 'caret-down'}
          style={{ whiteSpace: 'nowrap' }}
        >
          {label}
        </Button>
      </div>
    );
  }

  return (
    <div className={className}>
      <Popover
        content={content}
        isOpen={showMenu}
        placement="bottom-end"
        offsetFromTarget={8}
        contentContainerProps={{
          padding: '0',
          width: '2xl',
          overflow: 'hidden',
        }}
        onClickOutside={closeMenu}
      >
        <Button
          onClick={handleOpenPopover}
          variant="secondary"
          tone="neutral"
          size={size}
          iconSuffix={isMobile ? undefined : 'caret-down'}
          style={{ whiteSpace: 'nowrap' }}
        >
          <span className="display-none display-inline-hd">{`${label}:`}</span> {sortOptions[selectedSort]}
        </Button>
      </Popover>
    </div>
  );
};
