import states from 'states-us';

export const getStateOptions = (includePr = false): [{ label: string; value: string }] => {
  return states.reduce(
    (states, currentState) => {
      if (currentState.territory === false || (includePr && currentState.abbreviation === 'PR')) {
        states.push({ label: currentState.name, value: currentState.abbreviation });
      }
      return states;
    },
    [] as unknown as [{ label: string; value: string }],
  );
};
