import { Duration } from '@palmetto/palmetto-components';

interface QueueItemAgeProps {
  createdAt: string;
}

export const QueueItemAge = ({ createdAt }: QueueItemAgeProps): JSX.Element => {
  const ageInSeconds = Math.floor((Date.now() - new Date(createdAt).getTime()) / 1000);
  return createdAt ? <Duration seconds={ageInSeconds} displayHours={7200000} /> : <div>--</div>;
};
