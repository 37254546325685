import { FinancingDecision } from 'types';

export enum StipulationType {
  employmentVerification = 'employmentVerification',
  identityVerification = 'identityVerification',
  incomeVerification = 'incomeVerification',
  titleVerification = 'titleVerification',
  achRequirement = 'achRequirement',
}

export interface Stipulation {
  description: string;
  isSatisfied: boolean;
  stipulationType: StipulationType;
  requiresReview?: boolean;
}

export interface Address {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}

export interface Applicant {
  address: Address;
  firstName: string;
  lastName: string;
  type: 'primary' | 'secondary';
  status?: FinancingDecision;
  underwritingNotes?: string[];
}

export enum PropertyOwnershipStatus {
  manualVerificationRequired = 'manualVerificationRequired',
  unverified = 'unverified',
  verified = 'verified',
}

export interface Application {
  accountId: string;
  applicants: Applicant[];
  id?: string;
  archived: boolean;
  creditExpiryDate: string;
  status: keyof typeof FinancingDecision;
  externalReference?: string | number;
  stipulations: Stipulation[];
  propertyOwnershipStatus: PropertyOwnershipStatus;
  statusDecisionDate: string;
  underwritingNotes?: string[];
}

export interface ApplicationSuccessResponse extends Application {
  redirectUrl: string;
}
