import { ObjectId } from 'mongodb';
import { BankAccountType } from './OrganizationSettings';
import { ExternalReference } from './index';

export enum FinancialAccountStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum FinancialAccountReferenceType {
  account = 'account',
  organization = 'organization',
}

export enum FinancialAccountPaymentMethod {
  card = 'card',
  bankAccount = 'bankAccount',
}

export enum FinancialAccountPaymentMethodType {
  card = 'Credit card',
  bankAccount = 'ACH',
}

export interface FinancialAccountBankRegister {
  accountNumber: string;
  routingNumber: string;
  bankAccountType: BankAccountType;
  accountHolderName?: string;
  isAccountValidated?: boolean;
}

export interface FinancialAccountBankResponse {
  last4: string;
  bankAccountType: string;
  accountHolderName: string;
}

export interface FinancialAccountBankAdminUserResponse {
  accountNumber: string;
  routingNumber: string;
  bankAccountType: string;
}

export type FinancialAccountBank = FinancialAccountBankRegister & {
  last4: string;
  accountHolderName: string;
  bankName?: string;
};

export interface FinancialAccountCardRegister {
  number: string;
  expirationMonth: string;
  expirationYear: string;
  cvc: string;
  cardHolderName: string;
  zipCode: string;
}

export interface FinancialAccountCardRegisterResponse extends Omit<FinancialAccountCardRegister, 'zipCode'> {
  zipCode?: string;
}

export interface FinancialAccountCardResponse {
  cardHolderName: string;
  last4: string;
  cardType?: string;
}

export type FinancialAccountCard = FinancialAccountCardRegister & FinancialAccountCardResponse;

export interface FinancialAccountReference {
  id: ObjectId;
  type: FinancialAccountReferenceType;
}

export interface FinancialAccountDocument {
  id: ObjectId;
  references: FinancialAccountReference[];
  card?: FinancialAccountCard;
  bankAccount?: FinancialAccountBank;
  status: FinancialAccountStatus;
  externalReferenceIds: ExternalReference[];
  isSavedInConcord: boolean;
}

export interface FinancialAccountResponse {
  id: string;
  references: FinancialAccountReference[];
  card?: FinancialAccountCardResponse;
  bankAccount?: FinancialAccountBankResponse;
  status: FinancialAccountStatus;
  externalReferenceIds: ExternalReference[];
}

export interface FinancialAccountPaymentMethodResponse {
  id: string;
  accountId?: string;
  organizationId?: string;
  card?: FinancialAccountCardResponse;
  bankAccount?: FinancialAccountBankResponse;
}

export interface FinancialAccountPaymentMethodAdminUserResponse {
  id: string;
  accountId?: string;
  organizationId?: string;
  card?: FinancialAccountCardResponse;
  bankAccount?: FinancialAccountBankAdminUserResponse;
}

export interface FinancialAccountRegister {
  card?: FinancialAccountCardRegister;
  bankAccount?: FinancialAccountBankRegister;
}

export interface FinancialAccountRegisterPayload {
  card?: FinancialAccountCardRegisterResponse;
  bankAccount?: FinancialAccountBankRegister;
}

export interface FinancialAccountUpsertParams {
  entityType: string;
  entityData: FinancialAccountCard | FinancialAccountBank;
  existingFinancialAccounts: FinancialAccountDocument[];
  references: FinancialAccountReference[];
}

export interface CreditCardProcessorProviderDict {
  [key: string]: CardProcessorType;
}

export enum CardProcessorType {
  visa = 'visa',
  mastercard = 'mastercard',
  americanExpress = 'americanexpress',
  discover = 'discover',
}
