import { RangeCheckProps } from '../types/ProductionValueCheck';

/**
 * @description Checks whether the value is on range or not. Should follow the same comparison logic as BE,
 * just returning additional data.
 */
export function isProductionWithinChangeToleranceFE({
  originalProductionKwh,
  newProductionKwh,
  settings,
}: RangeCheckProps): {
  valid: boolean;
  percentDifference: number;
  difference: number;
} {
  if (originalProductionKwh === newProductionKwh) {
    return {
      valid: true,
      percentDifference: 0,
      difference: 0,
    };
  }

  let difference = 0;
  let percentDifference = 0;

  if (originalProductionKwh > newProductionKwh) {
    difference = originalProductionKwh - newProductionKwh;
    percentDifference = difference / originalProductionKwh;
    if (settings && percentDifference > settings.maxProductionDecrease) {
      return {
        valid: false,
        percentDifference,
        difference,
      };
    }
  } else {
    difference = newProductionKwh - originalProductionKwh;
    percentDifference = difference / originalProductionKwh;
    if (settings && percentDifference > settings.maxProductionIncrease) {
      return {
        valid: false,
        percentDifference,
        difference,
      };
    }
  }

  return {
    valid: true,
    percentDifference,
    difference,
  };
}
