import { Box, Button, Heading, useBreakpoint } from '@palmetto/palmetto-components';
import { Link, useRouteError } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  const { isPhone } = useBreakpoint();
  const error = useRouteError() as any;
  return (
    <>
      <Helmet>
        <title>Palmetto Finance - Page Not Found</title>
      </Helmet>
      <Box background="secondary" color="body-primary">
        <Box
          as="header"
          borderWidth="0 0 xs 0"
          borderColor="separator"
          direction="row"
          justifyContent="space-between"
          fontSize={{ base: 'sm', desktop: 'md' }}
          background="primary"
        >
          <Box
            direction="row"
            childGap="md"
            alignItems="center"
            justifyContent="center"
            padding={{ base: 'sm md', desktop: 'md lg' }}
          >
            <Link to="/">
              <img src="/palmetto-brand-logo.svg" alt="Palmetto" height={isPhone ? '24px' : '32px'} />
            </Link>
          </Box>
        </Box>
        <Box margin={{ base: 'lg auto 5xl', desktop: '2xl auto 5xl' }} width={{ base: '100', desktop: '80' }}>
          <Box
            padding={{ base: 'lg', desktop: '0' }}
            childGap={{
              base: 'lg',
            }}
            height="100"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Box fontSize={{ base: '2xl', desktop: '3xl' }} color="body-tertiary" aria-hidden="true">
              ¯\_(ツ)_/¯
            </Box>
            <Heading as="h1" size={{ base: 'lg', desktop: 'xl' }}>
              We couldn't find the page you're looking for.
            </Heading>
            <Box as="p"> {error?.error?.message || 'Page Not Found'}</Box>
            <Button as="a" href="/" variant="secondary" size="lg">
              Return Home
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const OtherError = () => {
  const error = useRouteError() as any;
  return (
    <>
      <Helmet>
        <title>Palmetto Finance - Error</title>
      </Helmet>
      <Box background="secondary" color="body-primary">
        <Box margin={{ base: 'lg auto 5xl', desktop: '2xl auto 5xl' }} width={{ base: '100', desktop: '80' }}>
          <Box
            padding={{ base: 'lg', desktop: '0' }}
            childGap={{
              base: 'lg',
            }}
            height="100"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Box fontSize={{ base: '2xl', desktop: '3xl' }} color="body-tertiary" aria-hidden="true">
              ¯\_(ツ)_/¯
            </Box>
            <Heading as="h1" size={{ base: 'lg', desktop: 'xl' }}>
              Oops. Something went wrong.
            </Heading>
            <Box as="p">
              {error?.status} - {error?.error?.message || 'Page Not Found'}
            </Box>
            <Button as="a" href="/" variant="secondary" size="lg">
              Return Home
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const Error = () => {
  let error = useRouteError() as any;
  console.error(error);
  if (error?.status === 404) return <NotFound />;
  return <OtherError />;
};

export default Error;
