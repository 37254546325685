import { Box, Duration, Popover, useOpenClose, FontSize } from '@palmetto/palmetto-components';
import { DateTime } from 'luxon';

interface DateTimeProps {
  /**
   * Additional classNames to add
   */
  className?: string;
  /**
   * Display local date time as the primary
   */
  displayLocalDateTime?: boolean;
  /**
   * Format used to display text in the input box
   * default is default YYYY-MM-DD or YYYY-MM-DD{timeFormat}
   * @see https://momentjs.com/docs/#/parsing/string-format/
   */
  dateFormat?: string;
  /**
   * show date only but no time
   */
  excludeTime?: boolean;
  /**
   * hide the time zone text
   */
  hideTimeZone?: boolean;
  /**
   * A label for the time that is displayed
   */
  label?: React.ReactNode;
  /**
   * Format used to display time in the time column in the popover
   */
  timeFormat?: string;
  /**
   * Show local time zone beneath the UTC time.
   * UTC time is displayed beneath the local time if `displayLocalDateTime` is true.
   *
   * When `false` a [Tooltip](/#/Components/Tooltip) will display the alternative date time on hover.
   */
  showAlternativeTimeZone?: boolean;
  /**
   * Changes the font size of the date time display value
   */
  fontSize?: FontSize;
  /**
   * The value a UTC ISO 8601 string (https://en.wikipedia.org/wiki/ISO_8601)
   */
  value: string;
  /**
   * Display the date time as an inline block
   */
  inline?: boolean;
  /**
   * Show the time since current date
   */
  showDuration?: boolean;
}

const DateTimeDisplay = (props: DateTimeProps) => {
  const { className, dateFormat, excludeTime, timeFormat = 'HH:mm a', value, showDuration, fontSize = 'sm' } = props;
  const { isOpen: isPopoverOpen, handleClose: closePopover, handleOpen: openPopover } = useOpenClose();

  if (!value) {
    return;
  }

  const format = dateFormat || (excludeTime ? 'yyyy-MM-dd' : `yyyy-MM-dd ${timeFormat}`);

  const UTC = DateTime.fromISO(value).toUTC().toFormat(format);
  const Local = DateTime.fromISO(value).toLocal().toFormat(format);
  const ageInSeconds = Math.floor((Date.now() - new Date(value).getTime()) / 1000);

  const popoverContent = (
    <Box display="block" childGap="xs">
      <Box display="block" fontWeight="bold">
        <Box display="inline-block" width="lg">
          Local
        </Box>{' '}
        {Local}
      </Box>
      <Box display="block">
        <Box display="inline-block" width="lg">
          UTC
        </Box>{' '}
        {UTC}
      </Box>
    </Box>
  );

  return (
    <Popover content={popoverContent} isOpen={isPopoverOpen} placement="top">
      <Box
        onMouseOver={openPopover}
        onMouseOut={closePopover}
        className={className}
        display={props.inline ? 'inline-block' : undefined}
        fontSize={fontSize}
      >
        {showDuration ? <Duration seconds={ageInSeconds} /> : Local}
      </Box>
    </Popover>
  );
};

export default DateTimeDisplay;
