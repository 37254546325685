import { Field, useFormikContext } from 'formik';
import { Box, FormikSelectInputNative, FormLabel, FormikTextInput, Button } from '@palmetto/palmetto-components';
import { ChangeEvent, useCallback } from 'react';
import { TwelveMonthsNumberInput } from './TwelveMonthsNumberInput';
import { Option } from './AvlFormUtils';
import { PanelOrientation } from 'types';

interface SolarArrayProps {
  isLoading: boolean;
  arrayNumber: number;
  fieldName: string;
  inverterOptions: Option[];
}

const orientationOptions: Option[] = Object.keys(PanelOrientation).map((orientation: string) => ({
  value: orientation,
  label: `${orientation[0].toUpperCase()}${orientation.slice(1)}`,
}));

export const SolarArray = ({ isLoading, arrayNumber, fieldName, inverterOptions }: SolarArrayProps) => {
  const { isSubmitting, values, setFieldValue } = useFormikContext<any>();
  const isAnythingLoadingOrSubmitting = isLoading || isSubmitting;

  const removeArray = useCallback(() => {
    setFieldValue(
      'arrays',
      values.arrays.filter((_: any, index: number) => index !== arrayNumber),
    );
  }, [arrayNumber, setFieldValue, values.arrays]);

  const setNumericValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.endsWith('.')) {
        setFieldValue(event.target.name, event.target.value);
      } else {
        const numericValue = Number(event.target.value);
        if (!isNaN(numericValue)) {
          setFieldValue(event.target.name, numericValue);
        }
      }
    },
    [setFieldValue],
  );
  return (
    <Box childGap="lg" borderColor="separator" borderWidth="xs" radius="sm" padding="lg">
      <Box direction="row" gap="xs" alignItems="center">
        <Box fontWeight="medium">{`Array ${arrayNumber + 1}`}</Box>
        {arrayNumber > 0 ? (
          <Button
            variant="tertiary"
            tone="neutral"
            style={{ textDecoration: 'underline' }}
            size="xs"
            onClick={removeArray}
          >
            remove
          </Button>
        ) : (
          ''
        )}
      </Box>
      <Box direction={{ base: 'column', tablet: 'row' }} childGap={{ base: 'lg', desktop: 'xl' }}>
        <Field
          label="Panel Count"
          name={`${fieldName}[${arrayNumber}].panelCount`}
          id={`panelCount-${arrayNumber}`}
          component={FormikTextInput}
          isLoading={isAnythingLoadingOrSubmitting}
          maxWidth="25"
          onChange={setNumericValue}
        />
        <Field
          label="Annual TSRF"
          name={`${fieldName}[${arrayNumber}].annualTSRF`}
          id={`panelCount-${arrayNumber}`}
          component={FormikTextInput}
          isLoading={isAnythingLoadingOrSubmitting}
          suffix="%"
          maxWidth="25"
          onChange={setNumericValue}
        />
        <Field
          label="Inverter"
          name={`${fieldName}[${arrayNumber}].inverter`}
          id={`panelCount-${arrayNumber}`}
          component={FormikSelectInputNative}
          options={inverterOptions}
          isLoading={isAnythingLoadingOrSubmitting}
          maxWidth="40"
        />
      </Box>
      <Box direction={{ base: 'column', tablet: 'row' }} childGap={{ base: 'lg', desktop: 'xl' }}>
        <Field
          label="Tilt (Degrees)"
          name={`${fieldName}[${arrayNumber}].tilt`}
          id={`tilt-${arrayNumber}`}
          component={FormikTextInput}
          isLoading={isAnythingLoadingOrSubmitting}
          suffix="°"
          maxWidth="25"
          onChange={setNumericValue}
        />
        <Field
          label="Azimuth (Degrees)"
          name={`${fieldName}[${arrayNumber}].azimuth`}
          id={`azimuth-${arrayNumber}`}
          component={FormikTextInput}
          isLoading={isAnythingLoadingOrSubmitting}
          suffix="°"
          maxWidth="25"
          onChange={setNumericValue}
        />
        <Field
          label="Orientation"
          name={`${fieldName}[${arrayNumber}].orientation`}
          id={`orientation-${arrayNumber}`}
          component={FormikSelectInputNative}
          options={orientationOptions}
          isLoading={isAnythingLoadingOrSubmitting}
          maxWidth="40"
        />
      </Box>
      <Box childGap={{ base: 'lg', desktop: 'xl' }}>
        <FormLabel inputId={''}>Monthly Solar Access (%)</FormLabel>
        <TwelveMonthsNumberInput
          fieldName={`${fieldName}[${arrayNumber}].monthlySolarAccess`}
          isLoading={isAnythingLoadingOrSubmitting}
          min={0}
          max={100}
        />
      </Box>
    </Box>
  );
};
