import { ProgramType, BatchReviewStatus } from 'types';
import { BatchesList } from '../BatchesList';
import { useGetBatchesByReviewStatusQuery } from '@/services/payouts';
import { BatchComponent } from '@/types/Payouts';

export const Queued = ({ programType }: { programType: ProgramType }) => {
  const { data: batches, isLoading: isLoadingBatches } = useGetBatchesByReviewStatusQuery({
    batchReviewStatus: BatchReviewStatus.rejected,
  });

  return (
    <>
      {!isLoadingBatches && batches && (
        <BatchesList programType={programType} batches={batches || []} originComponent={BatchComponent.queued} />
      )}
    </>
  );
};
