import { Box, Spinner, toast } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MainContainer } from '../../MainContainer';
import { useCreatePriceSheetMappingMutation, useGetPriceSheetMappingByIdQuery } from '../../../services/pricing';
import { isErrorWithErrors } from '../../../services/helpers';
import PageHeader from '../../PageHeader';
import { percentageFormatter } from '../../../helpers/percentageFormatter';
import { ProgramType } from 'types';
import { MappingFormFactory } from './MappingFormFactory';

export const EditMapping = () => {
  const { id, programType = ProgramType.solar } = useParams<{ id: any; programType: ProgramType }>();
  const navigate = useNavigate();
  const { data: priceSheetMapping, isLoading: isPriceSheetMappingLoading }: any = useGetPriceSheetMappingByIdQuery({
    id,
  });

  const [createPriceSheetMapping] = useCreatePriceSheetMappingMutation();

  const initialValues = useMemo(() => {
    if (!priceSheetMapping) return;
    const adderMap = Object.fromEntries(
      priceSheetMapping?.constructionAdders?.map((adder: any) => [adder.type, adder]) ?? [],
    );
    switch (programType) {
      case ProgramType.doePr:
        return {
          organizationIds: [{ label: priceSheetMapping.organizationName, value: priceSheetMapping.organizationId }],
          state: { value: priceSheetMapping.state, label: priceSheetMapping.state },
          utilities: [{ label: priceSheetMapping.utility.name, value: priceSheetMapping.utility.lseId }],
          priceSheet: { value: priceSheetMapping.priceSheetId, label: priceSheetMapping.priceSheetName },
          escMin: {
            label: percentageFormatter.format(Number(priceSheetMapping.escalationRateMin)),
            value: priceSheetMapping.escalationRateMin,
          },
          escMax: {
            label: percentageFormatter.format(Number(priceSheetMapping.escalationRateMax)),
            value: priceSheetMapping.escalationRateMax,
          },
          minSystemSize: { label: priceSheetMapping.minSystemSize, value: priceSheetMapping.minSystemSize },
          maxSystemSize: { label: priceSheetMapping.maxSystemSize, value: priceSheetMapping.maxSystemSize },
          minBatteryCapacity: {
            label: priceSheetMapping.minBatteryCapacity,
            value: priceSheetMapping.minBatteryCapacity,
          },
          maxBatteryCapacity: {
            label: priceSheetMapping.maxBatteryCapacity,
            value: priceSheetMapping.maxBatteryCapacity,
          },
          solarReadiness: adderMap?.solarReadiness
            ? { label: adderMap.solarReadiness.name, value: adderMap.solarReadiness.id }
            : '',
        };
      case ProgramType.hvac:
        return {
          organizationIds: [{ label: priceSheetMapping.organizationName, value: priceSheetMapping.organizationId }],
          state: { value: priceSheetMapping.state, label: priceSheetMapping.state },
          utilities: priceSheetMapping?.utility
            ? [{ label: priceSheetMapping?.utility?.name, value: priceSheetMapping?.utility?.lseId }]
            : [],
          priceSheet: { value: priceSheetMapping.priceSheetId, label: priceSheetMapping.priceSheetName },
          escMin: {
            label: percentageFormatter.format(Number(priceSheetMapping.escalationRateMin)),
            value: priceSheetMapping.escalationRateMin,
          },
          escMax: {
            label: percentageFormatter.format(Number(priceSheetMapping.escalationRateMax)),
            value: priceSheetMapping.escalationRateMax,
          },
          minTerm: { label: priceSheetMapping.minTerm, value: priceSheetMapping.minTerm },
          maxTerm: { label: priceSheetMapping.maxTerm, value: priceSheetMapping.maxTerm },
          minSystemCost: { label: priceSheetMapping.minSystemCost, value: priceSheetMapping.minSystemCost },
          maxSystemCost: { label: priceSheetMapping.maxSystemCost, value: priceSheetMapping.maxSystemCost },
        };
      default:
        return {
          organizationIds: [{ label: priceSheetMapping.organizationName, value: priceSheetMapping.organizationId }],
          state: { value: priceSheetMapping.state, label: priceSheetMapping.state },
          utilities: [{ label: priceSheetMapping.utility.name, value: priceSheetMapping.utility.lseId }],
          priceSheet: { value: priceSheetMapping.priceSheetId, label: priceSheetMapping.priceSheetName },
          escMin: {
            label: percentageFormatter.format(Number(priceSheetMapping.escalationRateMin)),
            value: priceSheetMapping.escalationRateMin,
          },
          escMax: {
            label: percentageFormatter.format(Number(priceSheetMapping.escalationRateMax)),
            value: priceSheetMapping.escalationRateMax,
          },
          pvOnlyMin: { label: priceSheetMapping.pvOnlyMin, value: priceSheetMapping.pvOnlyMin },
          pvOnlyMax: { label: priceSheetMapping.pvOnlyCeiling, value: priceSheetMapping.pvOnlyCeiling },
          absoluteCeiling: { label: priceSheetMapping.absoluteCeiling, value: priceSheetMapping.absoluteCeiling },
          electricalUpgrade: adderMap?.electricalUpgrade
            ? { label: adderMap.electricalUpgrade.name, value: adderMap.electricalUpgrade.id }
            : '',
          arbitrageBattery: adderMap?.arbitrageBattery
            ? { label: adderMap.arbitrageBattery.name, value: adderMap.arbitrageBattery.id }
            : '',
          backupBattery: adderMap?.backupBattery
            ? { label: adderMap.backupBattery.name, value: adderMap.backupBattery.id }
            : '',
          backupBatteryPriceCaps: adderMap?.backupBatteryPriceCap
            ? { label: adderMap.backupBatteryPriceCap.name, value: adderMap.backupBatteryPriceCap.id }
            : '',
          holdback: priceSheetMapping.holdback
            ? { label: priceSheetMapping.holdback.name, value: priceSheetMapping.holdback.id }
            : '',
          zeroEscalatorCeiling: priceSheetMapping.zeroEscalatorCeiling
            ? { label: priceSheetMapping.zeroEscalatorCeiling, value: priceSheetMapping.zeroEscalatorCeiling }
            : '',
          energyCommunity: priceSheetMapping.energyCommunity
            ? { label: priceSheetMapping.energyCommunity.name, value: priceSheetMapping.energyCommunity.id }
            : '',
          backupBatteryKwh: adderMap.backupBatteryKwh
            ? { label: adderMap.backupBatteryKwh.name, value: adderMap.backupBatteryKwh.id }
            : '',
          ppwModifier: priceSheetMapping.ppwModifier
            ? { label: priceSheetMapping.ppwModifier.name, value: priceSheetMapping.ppwModifier.id }
            : '',
          domesticContent: priceSheetMapping.domesticContentModifier
            ? { label: priceSheetMapping.domesticContentModifier.name, value: priceSheetMapping.domesticContentModifier.id }
            : '',
        };
    }
  }, [priceSheetMapping]);

  const handleSubmit = async (data: any) => {
    try {
      await createPriceSheetMapping({ data, programType }).unwrap();
      toast.success('Mapping updated successfully');
      navigate(`/admin/pricing/${programType}`);
    } catch (e) {
      if (isErrorWithErrors(e)) {
        const errorMessage = e.data.message;
        toast.error(`${errorMessage}: ${e.data.errors.map((e) => e).join(',')}`);
      } else {
        console.error(e);
        toast.error('Error updating mapping');
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit Price Mapping</title>
      </Helmet>
      <MainContainer maxWidth="100">
        <PageHeader eyebrow={<Link to={`/admin/pricing/${programType}`}>Pricing</Link>} title="Edit Price Mapping" />
        <Box padding={{ base: '0 lg', tablet: '0' }}>
          {!isPriceSheetMappingLoading && priceSheetMapping ? (
            <MappingFormFactory handleSubmit={handleSubmit} programType={programType} data={initialValues} />
          ) : (
            <Spinner size="lg" />
          )}
        </Box>
      </MainContainer>
    </>
  );
};
