import React, { useCallback, useState } from 'react';
import { useAuth } from '../auth/authProvider';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import { AddFlagModal } from './AddFlagModal';
import { useAddFlagToQueueMutation, useGetQueueByIdQuery, useResolveFlagMutation } from '../../services/queues';
import { Flag, FlagPayload } from 'types';
import { skipToken } from '@reduxjs/toolkit/query';

export interface QueueFlagsContextValue {
  isModalShowing: boolean;
  flags: Flag[];
  shouldHide: boolean;
  showModal: (dataKey: string, fileName?: string, baseCategory?: string, type?: string) => void;
  hideModal: () => void;
  addFlag: ({ dataKey, fileName, note, reason, fileUpload, type }: FlagPayload) => Promise<void>;
  resolveFlag: (dataKey: string, fileName?: string) => Promise<void>;
  getFlag: (dataKey: string | string[], fileName?: string, includeResolved?: boolean) => Flag | undefined;
  getAllFlags: (includeResolved?: boolean) => Flag[];
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
}

export interface QueueFlagsProviderProps {
  children?: React.ReactNode;
  queueId: string;
}

const QueueFlagsContext = React.createContext<QueueFlagsContextValue>({} as QueueFlagsContextValue);
const { Provider } = QueueFlagsContext;

const QueueFlagsProvider = ({ children, queueId }: QueueFlagsProviderProps) => {
  const [isModalShowing, setIsModalShowing] = React.useState(false);
  const [saveFlag] = useAddFlagToQueueMutation();
  const [resolveCurrentFlag] = useResolveFlagMutation();
  const { data: queue } = useGetQueueByIdQuery(queueId ? { id: queueId } : skipToken);
  const { claims } = useAuth();
  const isFinCoUser = isPalmettoFinanceUser(claims);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [flagTarget, setFlagTarget] = React.useState<{
    dataKey: string;
    fileName: string;
    baseCategory?: string;
    type?: string;
  }>({
    dataKey: '',
    fileName: '',
  });
  const addFlag = useCallback(
    async ({ dataKey, fileName, note, reason, baseCategory, fileUpload, type }: FlagPayload) => {
      setLoading(true);
      const result = await saveFlag({
        id: queueId,
        flag: { dataKey, fileName, reason, note, category: baseCategory, fileUpload, type },
      }).unwrap();
      setLoading(false);
      return result;
    },
    [queueId, saveFlag],
  );
  const resolveFlag = useCallback(
    async (dataKey: string, fileName?: string) => {
      setLoading(true);
      try {
        await resolveCurrentFlag({ id: queueId, dataKey, fileName }).unwrap();
      } catch (e) {
        setLoading(false);
        throw e;
      }
      setLoading(false);
    },
    [queueId, resolveCurrentFlag],
  );
  const showModal = useCallback((dataKey: string, fileName?: string, baseCategory?: string, type?: string) => {
    setFlagTarget({ dataKey, fileName: fileName || '', baseCategory, type });
    setIsModalShowing(true);
  }, []);
  const hideModal = useCallback(() => {
    setIsModalShowing(false);
  }, []);

  const getFlag = useCallback(
    (dataKeys: string | string[], fileName?: string, includeResolved?: boolean) => {
      const keys = Array.isArray(dataKeys) ? dataKeys : [dataKeys];
      return queue?.flags?.find(
        (flag: Flag) =>
          (includeResolved || !flag.resolved) &&
          keys.includes(flag.dataKey) &&
          (!fileName || flag.fileName === fileName),
      );
    },
    [queue?.flags],
  );

  const getAllFlags = useCallback(
    (includeResolved?: boolean) => {
      if (!queue?.flags) {
        return [];
      }
      if (includeResolved) {
        return queue?.flags || [];
      } else {
        return queue?.flags.filter((flag: Flag) => !flag.resolved);
      }
    },
    [queue?.flags],
  );

  return (
    <Provider
      value={{
        isModalShowing,
        addFlag,
        resolveFlag,
        shouldHide: !Boolean(queue?.id) || !isFinCoUser,
        showModal,
        hideModal,
        flags: queue?.flags || [],
        getFlag,
        getAllFlags,
        isLoading,
        setLoading,
      }}
    >
      <AddFlagModal
        accountId={queue?.account?.id}
        isOpen={isModalShowing}
        onDismiss={hideModal}
        dataKey={flagTarget.dataKey}
        fileName={flagTarget.fileName}
        baseCategory={flagTarget.baseCategory}
        type={flagTarget.type}
        showUploadButton={isFinCoUser}
      />
      {children}
    </Provider>
  );
};

export { QueueFlagsProvider, QueueFlagsContext };
