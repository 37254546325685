import { Box } from '@palmetto/palmetto-components';
import { DiffErrorAlert } from './DiffErrorAlert';
import { HeaderElementProps, Origin } from '../../types/ProductionValueCheck';
import PageHeader from '../PageHeader';
import AdminStipulationBannerList from '../Stipulations/AdminStipulationBannerList';
import { Link } from 'react-router-dom';

export function HeaderElement({
  requestVoid,
  origin,
  accountName,
  errorObject,
  accountId,
  loadingState,
}: HeaderElementProps) {
  const comesFromInstallMilestone = origin === Origin.INSTALL_PACKAGE;

  return (
    <Box
      as="main"
      gap="md"
      maxWidth="6xl"
      width="100"
      margin="0 auto"
      padding={{
        base: 'md md',
        tablet: '0 0',
      }}
    >
      <AdminStipulationBannerList />
      <PageHeader
        eyebrow={<Link to={`/accounts/${accountId}`}>{accountName}</Link>}
        title={
          comesFromInstallMilestone
            ? 'Confirm Year-1 Total Production'
            : 'Year-1 Production Value Change Order Check'
        }
      />
      {errorObject && requestVoid && !comesFromInstallMilestone ? (
        <DiffErrorAlert
          requestVoid={requestVoid}
          percentErrorDiff={errorObject.percentErrorDiff}
          loadingState={loadingState}
        />
      ) : null}
    </Box>
  );
}
