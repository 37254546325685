import { Alert } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useGetAccountQuery } from '../../services/accounts';
import { MilestoneType } from '../../types/Milestone';
import { ActivationPackageActions } from '../../types/ActivationPackage';
import { MainContainer } from '../MainContainer';
import {
  AccountMilestoneWithRequirements,
  AccountRequirementDocument,
  AccountRequirementStatus,
  MilestoneRequirement,
  QueueType,
} from 'types';
import { useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import AdminStipulationBannerList from '../Stipulations/AdminStipulationBannerList';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ActivationPackage } from './ActivationPackage';
import { LegacyActivationPackage } from './LegacyActivationPackage';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';

export const ActivationMilestonePackage = () => {
  const { activationQueue = false } = useFlags();
  const { id, action } = useParams<{ id: any; action: ActivationPackageActions }>();
  const { data: account, isLoading: accountIsLoading }: any = useGetAccountQuery(id);
  const { data: queue, isLoading: queueIsLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.activationPackage,
  });

  const isActivationPending = useMemo(() => {
    if (!account) return false;
    return (
      account?.milestones
        ?.find((milestone: AccountMilestoneWithRequirements) => milestone.type === MilestoneType.activation)
        ?.requirements?.find(
          (requirement: AccountRequirementDocument) => requirement?.type === MilestoneRequirement.activationPackage,
        ).status === AccountRequirementStatus.pending
    );
  }, [account]);

  const navigate = useNavigate();

  const canSubmitActivationPackage = useMemo(() => {
    if (!account) return false;
    return account?.currentMilestone?.type === MilestoneType.activation || false;
  }, [account]);

  useEffect(() => {
    if (!action || !ActivationPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
  }, [id, navigate, action]);

  return (
    <>
      <Helmet>
        <title>{`${account?.primaryApplicantName} Activation Package`}</title>
      </Helmet>
      <MainContainer>
        <AdminStipulationBannerList />
        <PageHeader
          eyebrow={
            <>
              <Link to="/accounts">Accounts</Link> \{' '}
              <Link to={`/accounts/${id}`}>{`${account?.primaryApplicantName}`}</Link>
            </>
          }
          title="Activation Package"
          description="Provide the required documents and monitoring information."
        />
        {account && action !== ActivationPackageActions.review && !canSubmitActivationPackage && (
          <Alert
            variant="warning"
            hasIcon
            title="Install Package is not complete"
            message="The Install Package must be completed and approved before submitting the Activation Package."
          />
        )}
        {accountIsLoading || queueIsLoading ? (
          <FormSection title="Loading...">
            <SkeletonBox height="25px" width="100" />
          </FormSection>
        ) : activationQueue && (isActivationPending || Boolean(queue)) ? (
          <ActivationPackage canSubmitActivationPackage={canSubmitActivationPackage} />
        ) : (
          <LegacyActivationPackage />
        )}
      </MainContainer>
    </>
  );
};
