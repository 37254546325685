import { Box, Card, FormLabel, toast, Toggle } from '@palmetto/palmetto-components';
import { OrganizationNode } from '@/types/Organizations';
import { useCallback, useState } from 'react';
import { PaymentHoldModal } from '@/components/settings/organizations/Payments/PaymentHoldModal';
import {
  useGetOrganizationPaymentSettingsQuery,
  usePutOrganizationPaymentSettingMutation,
} from '@/services/organizations';

export interface PaymentsOrgSettingProps {
  organization: OrganizationNode;
}
export const PaymentsOrgSetting = ({ organization }: PaymentsOrgSettingProps) => {
  const { data: paymentSettings } = useGetOrganizationPaymentSettingsQuery({ alias: organization.alias });
  const [updatePaymentSetting] = usePutOrganizationPaymentSettingMutation();
  const [isModalShowing, setIsModalShowing] = useState(false);
  const hideModal = useCallback(() => setIsModalShowing(false), [setIsModalShowing]);
  const handlePaymentHoldToggle = useCallback(() => {
    if (paymentSettings?.payoutHold?.active) {
      const confirmed = window.confirm(
        'Are you sure you want to remove this payment hold? All payouts will resume.',
      );
      if (confirmed) {
        try {
          updatePaymentSetting({
            alias: organization.alias,
            paymentSettings: {
              payoutHold: {
                active: false,
              },
            },
          }).unwrap();
          toast.success('Payment hold has been successfully removed');
        } catch (error) {
          toast.error('Failed to remove payment hold');
          console.error(error);
        }
      }
    } else {
      setIsModalShowing(true);
    }
  }, [paymentSettings?.payoutHold?.active, updatePaymentSetting, organization.alias, setIsModalShowing]);

  const createPaymentHold = useCallback(
    async (reason: string) => {
      const payoutHold = {
        active: true,
        reason,
      };

      try {
        await updatePaymentSetting({
          alias: organization.alias,
          paymentSettings: {
            payoutHold,
          },
        }).unwrap();
        toast.success('Payment hold has been successfully created');
      } catch (error) {
        toast.error('Failed to create payment hold');
        console.error(error);
      }
    },
    [updatePaymentSetting],
  );

  return (
    <Card>
      <Card.Header>
        <Box as="h3" fontWeight="medium" fontSize="md">
          Organization Payment Settings
        </Box>
      </Card.Header>
      <PaymentHoldModal isOpen={isModalShowing} onDismiss={hideModal} onConfirm={createPaymentHold} />
      <Box margin="lg">
        <Toggle
          id="paymentHold"
          isChecked={paymentSettings?.payoutHold?.active ?? false}
          label="Payment Hold"
          onChange={handlePaymentHoldToggle}
        />
        {paymentSettings?.payoutHold?.active && (
          <Box padding="md xl" direction="row" gap="sm">
            <FormLabel inputId="">Reason: </FormLabel>
            <Box>{paymentSettings?.payoutHold?.reason}</Box>
          </Box>
        )}
      </Box>
    </Card>
  );
};
