import { Box, Table } from '@palmetto/palmetto-components';
import { OrganizationNode } from '../../../types/Organizations';

interface OrganizationAuthorizedSignersProps {
  organization: OrganizationNode | undefined;
}

export const OrganizationAuthorizedSigners = ({ organization }: OrganizationAuthorizedSignersProps) => {
  const authorizedSignerColumns = [
    {
      heading: 'Name',
      dataKey: 'name',
    },
    {
      heading: 'Title',
      dataKey: 'title',
    },
    {
      heading: 'Email',
      dataKey: 'email',
    },
  ];

  return (
    <Box margin="lg 0 0 0" childGap="md">
      <Box as="h4" fontSize="md" fontWeight="medium">
        Authorized Signers
      </Box>
      {organization?.authorizedSigners?.length ? (
        <Table
          isCompact
          rowKey="email"
          columns={authorizedSignerColumns}
          rows={organization?.authorizedSigners || []}
          isScrollable={{
            x: true,
          }}
        />
      ) : (
        'No authorized signers on file'
      )}
    </Box>
  );
};
