import DateTimeDisplay from '@/components/DateTime';
import { kwhPriceFormatter } from '@/helpers/currencyFormatter';
import { percentageFormatter } from '@/helpers/percentageFormatter';
import { Badge, Box, Column, Icon } from '@palmetto/palmetto-components';
import { Link } from 'react-router-dom';
import { PriceSheetStatus, ProgramType } from 'types';

export const mappingsColumnConfig = {
  baseStart: [
    {
      heading: 'Status',
      dataKey: 'status',
      width: 125,
      render: (_cell: any, row: any) => (
        <Badge
          variant={
            row.status === PriceSheetStatus.active
              ? 'success'
              : row.status === PriceSheetStatus.archived
                ? 'warning'
                : 'danger'
          }
          message={row.status}
        />
      ),
    },
    { heading: 'org', dataKey: 'organizationName' },
    { heading: 'state', dataKey: 'state' },
    {
      heading: 'utility',
      dataKey: 'utility',
      render: (cell: any) => <Box>{cell?.name}</Box>,
    },
    {
      heading: 'Price Sheet',
      dataKey: 'priceSheetName',
      render: (cell: any, row: any) => (
        <Link
          to={`/admin/pricing/${row.programType}/price-sheet/${row.priceSheetId}`}
          style={{ textDecoration: 'underline' }}
        >
          {cell}
        </Link>
      ),
    },
    {
      heading: 'esc min',
      dataKey: 'escalationRateMin',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <div>{percentageFormatter.format(cell)}</div>;
      },
    },
    {
      heading: 'esc max',
      dataKey: 'escalationRateMax',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <div>{percentageFormatter.format(cell)}</div>;
      },
    },
  ],
  baseEnd: [
    {
      heading: 'Created',
      dataKey: 'createdDate',
      width: 150,
      render: (_cell: any, row: any) => <DateTimeDisplay value={row?.createdDate} dateFormat={'DD'} excludeTime />,
    },
    {
      heading: 'Archived',
      dataKey: 'archivedDate',
      width: 150,
      render: (_cell: any, row: any) => <DateTimeDisplay value={row?.archivedDate} dateFormat={'DD'} excludeTime />,
    },
    {
      render: (_cell: any, row: any) =>
        row.status !== 'archived' && (
          <Link
            to={`/admin/pricing/${row.programType}/mappings/edit/${row.id}`}
            style={{ textDecoration: 'underline' }}
          >
            <Icon color="body-primary" name="settings" size="sm" />
          </Link>
        ),
    },
  ],
  [ProgramType.solar]: [
    {
      heading: 'pv-only min',
      dataKey: 'pvOnlyMin',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <div>{kwhPriceFormatter.format(cell)}</div>;
      },
    },
    {
      heading: 'pv-only max',
      dataKey: 'pvOnlyCeiling',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <div>{kwhPriceFormatter.format(cell)}</div>;
      },
    },
    {
      heading: 'zero escalator max',
      dataKey: 'zeroEscalatorCeiling',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return cell && <div>{kwhPriceFormatter.format(cell)}</div>;
      },
    },
    {
      heading: 'absolute max',
      dataKey: 'absoluteCeiling',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <div>{kwhPriceFormatter.format(cell)}</div>;
      },
    },
    {
      heading: 'construction adders',
      dataKey: 'constructionAdders',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <Box childGap="xs">{cell?.map((adder: any) => <Box key={adder.name}>{adder.name}</Box>)}</Box>;
      },
    },
    {
      heading: 'modifiers',
      render: (_cell: any, row: any) => (
        <Box childGap="xs">
          {row.holdback && <Box>{row.holdback.name}</Box>}
          {row.energyCommunity && <Box>{row.energyCommunity.name}</Box>}
          {row.ppwModifier && <Box>{row.ppwModifier.name}</Box>}
          {row.domesticContentModifier && <Box>{row.domesticContentModifier.name}</Box>}
        </Box>
      ),
    },
  ],
  [ProgramType.newHomes]: [
    {
      heading: 'pv-only min',
      dataKey: 'pvOnlyMin',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <div>{kwhPriceFormatter.format(cell)}</div>;
      },
    },
    {
      heading: 'pv-only max',
      dataKey: 'pvOnlyCeiling',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <div>{kwhPriceFormatter.format(cell)}</div>;
      },
    },
    {
      heading: 'zero escalator max',
      dataKey: 'zeroEscalatorCeiling',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return cell && <div>{kwhPriceFormatter.format(cell)}</div>;
      },
    },
    {
      heading: 'absolute max',
      dataKey: 'absoluteCeiling',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <div>{kwhPriceFormatter.format(cell)}</div>;
      },
    },
    {
      heading: 'construction adders',
      dataKey: 'constructionAdders',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <Box childGap="xs">{cell?.map((adder: any) => <Box key={adder.name}>{adder.name}</Box>)}</Box>;
      },
    },
    {
      heading: 'modifiers',
      render: (_cell: any, row: any) => (
        <Box childGap="xs">
          {row.holdback && <Box>{row.holdback.name}</Box>}
          {row.energyCommunity && <Box>{row.energyCommunity.name}</Box>}
          {row.ppwModifier && <Box>{row.ppwModifier.name}</Box>}
          {row.domesticContentModifier && <Box>{row.domesticContentModifier.name}</Box>}
        </Box>
      ),
    },
  ],
  [ProgramType.hvac]: [
    {
      heading: 'min system cost',
      dataKey: 'minSystemCost',
      headerClassName: 'wrap',
    },
    {
      heading: 'max system cost',
      dataKey: 'maxSystemCost',
      headerClassName: 'wrap',
    },
    { heading: 'Min Term', dataKey: 'minTerm', headerClassName: 'wrap' },
    { heading: 'Max Term', dataKey: 'maxTerm', headerClassName: 'wrap' },
  ],
  [ProgramType.doePr]: [
    {
      heading: 'system size min',
      dataKey: 'minSystemSize',
      headerClassName: 'wrap',
    },
    {
      heading: 'system size max',
      dataKey: 'maxSystemSize',
      headerClassName: 'wrap',
    },
    {
      heading: 'battery capacity min',
      dataKey: 'minBatteryCapacity',
      headerClassName: 'wrap',
    },
    {
      heading: 'battery capacity max',
      dataKey: 'maxBatteryCapacity',
      headerClassName: 'wrap',
    },
    {
      heading: 'construction adders',
      dataKey: 'constructionAdders',
      headerClassName: 'wrap',
      render: (cell: any) => {
        return <Box childGap="xs">{cell?.map((adder: any) => <Box key={adder.name}>{adder.name}</Box>)}</Box>;
      },
    },
  ],
};
