import { Box, Button, Modal, SelectInput, TextInput, toast } from '@palmetto/palmetto-components';
import { FormikValues, useFormik } from 'formik';
import { SyntheticEvent } from 'react';
import * as yup from 'yup';
import {
  useCreateOrganizationPricingHoldbacksMutation,
  useGetHoldbacksQuery,
} from '../../../../../../services/pricing';
import { useParams } from 'react-router-dom';
import { useGetUtilitiesQuery } from '../../../../../../services/utilities';
import { ProgramType } from 'types';

export const CreateHoldback = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
}) => {
  const [createOrganizationPricingHoldbackMutation] = useCreateOrganizationPricingHoldbacksMutation();
  const { alias = '' } = useParams<{ alias: string }>();
  const { data: holdbacks } = useGetHoldbacksQuery({ programType: ProgramType.solar });
  const filter = [
    { id: 'programType', operation: 'Equal', selectedValues: [ProgramType.solar, ProgramType.newHomes] },
  ];
  const { data: utilities = [] } = useGetUtilitiesQuery({
    advancedFilters: JSON.stringify(filter),
  });
  const formik = useFormik({
    initialValues: {
      holdback: {},
      amount: undefined,
      state: [],
    },
    validationSchema: yup.object({
      holdback: yup.object().required('Holdback is required'),
    }),
    onSubmit: async (values: FormikValues, { setSubmitting, resetForm }) => {
      try {
        const states = values.state.map((state: { label: string; value: string }) => state.value);
        await createOrganizationPricingHoldbackMutation({
          alias,
          data: { holdbackId: values.holdback.value, states },
        }).unwrap();
        toast.success('Holdback created successfully');
        resetForm();
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Error creating holdback');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const stateUtilityMapping = utilities.reduce(
    (acc, utility) => {
      acc[utility.state] = acc[utility.state] ?? [];
      acc[utility.state].push(utility);
      return acc;
    },
    {} as Record<string, any[]>,
  );

  const stateOptions = Object.keys(stateUtilityMapping)
    .sort()
    .map((state) => ({ label: state, value: state }));

  return (
    <Modal ariaLabelledBy="createHoldback" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header id="createHoldback" title="Add Holdback" onDismiss={closeModal} />
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body background="secondary">
          <Box direction="column" childGap="xl">
            {holdbacks && (
              <SelectInput
                label="Holdback"
                name="holdback"
                id="holdback"
                value={formik.values.holdback}
                onChange={(event) => formik.setFieldValue('holdback', event.target.value)}
                options={holdbacks.map((holdback: any) => ({
                  label: holdback.name,
                  value: holdback.id,
                  amount: holdback.amount,
                }))}
                isRequired
                onBlur={formik.handleBlur}
                error={formik.touched.holdback && (formik.errors.holdback as string)}
                menuPortalTarget={document.body}
              />
            )}
            <Box direction={{ base: 'column', tablet: 'row' }} childGap={{ base: 'md' }}>
              <SelectInput
                id="state"
                name="state"
                label="State"
                value={formik.values.state}
                onChange={(event) => {
                  formik.setFieldValue('state', event.target.value);
                }}
                options={stateOptions}
                onBlur={formik.handleBlur}
                isDisabled={formik.isSubmitting}
                error={formik.touched.state && (formik.errors.state as string)}
                menuPortalTarget={document.body}
                isMulti
              />
              <TextInput
                type="text"
                label="Amount"
                name="amount"
                id="amount"
                value={formik.values.holdback.amount}
                onChange={() => formik.setFieldValue('amount', formik.values.holdback.amount)}
                prefix="$"
                suffix="/Watt"
                isDisabled
              />
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            as="button"
            onClick={() => {
              formik.resetForm();
              closeModal();
            }}
            variant="secondary"
            tone="neutral"
            size="md"
            isLoading={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button variant="primary" size="md" type="submit" isLoading={formik.isSubmitting}>
            Add Holdback
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
