export enum ActivityHistoryEventType {
  all = '',
  sabalDocumentsUploaded = 'sabal.documents.uploaded',
  sabalDocumentsArchived = 'sabal.documents.archived',
  sabalDocumentsSubmitted = 'sabal.documents.submitted',
  sabalDocumentsRejected = 'sabal.documents.rejected',
  sabalDocumentsReSubmitted = 'sabal.documents.resubmitted',
  sabalDocumentsApproved = 'sabal.documents.approved',
  sabalAccountUpdated = 'sabal.account.updated',
  sabalAccountCreated = 'sabal.account.created',
  sabalAccountCancelled = 'sabal.account.cancelled',
  sabalAccountReactivated = 'sabal.account.reactivated',
  cedInvoiceReceived = 'sabal.cedInvoice.received',
  cedShipmentConfirmationReceived = 'sabal.cedShipment.confirmation.received',
  contractCreated = 'sabal-contractCreated',
  contractSent = 'sabal-contractSent',
  contractSigned = 'sabal-contractSigned',
  contractVoided = 'sabal-contractVoided',
  illinoisDisclosureSent = 'sabal-illinoisDisclosureSent',
  illinoisDisclosureSigned = 'sabal-illinoisDisclosureSigned',
  emailNotificationSent = 'EmailNotificationSent',
  heliosUserInvited = 'helios.user.invited',
  heliosUserCreated = 'helios.user.created',
  solarFlareEmailNotificationSent = 'Solarflare.EmailNotification.Sent',
  solarFlareSmsNotificationSent = 'Solarflare.SmsNotification.Sent',
  sabalQuoteCreated = 'sabal.quote.created',
  sabalQuoteVoided = 'sabal.quote.voided',
  sabalPaymentMethodAdded = 'sabal.paymentMethod.added',
  sabalPaymentFailed = 'sabal.payment.failed',
  sabalAccountMilestoneUpdated = 'sabal.accountMilestone.updated',
  sabalCreditDecisionCompleted = 'sabal.creditDecision.completed',
  sabalCreditDecisionError = 'sabal.creditDecision.error',
  sabalCreditDecisionExpired = 'sabal.creditDecision.expired',
  sabalDesignStipulationFlagged = 'sabal.designStipulation.flagged',
  sabalDesignStipulationCleared = 'sabal.designStipulation.cleared',
  sabalSystemDesignCreated = 'sabal.systemDesign.created',
  sabalAddersUpdated = 'sabal.adders.updated',
  sabalSystemDesignAttested = 'sabal.systemDesign.attested',
  sabalNTPPackageSubmitted = 'sabal.ntpPackage.submitted',
  sabalNTPPackageSaved = 'sabal.ntpPackage.saved',
  sabalNTPPackageResubmitted = 'sabal.ntpPackage.resubmitted',
  sabalInstallPackageSubmitted = 'sabal.installPackage.submitted',
  sabalInstallPackageSaved = 'sabal.installPackage.saved',
  sabalInstallPackageResubmitted = 'sabal.installPackage.resubmitted',
  sabalActivationPackageSaved = 'sabal.activationPackage.saved',
  sabalActivationPackageSubmitted = 'sabal.activationPackage.submitted',
  sabalActivationPackageResubmitted = 'sabal.activationPackage.resubmitted',
  sabalPermissionToOperateSaved = 'sabal.permissionToOperate.saved',
  sabalPermissionToOperateSubmitted = 'sabal.permissionToOperate.submitted',
  sabalPermissionToOperateResubmitted = 'sabal.permissionToOperate.resubmitted',
  sabalPermissionToOperateCompleted = 'sabal.permissionToOperate.completed',
  sabalElectricianSignOffSaved = 'sabal.electricianSignOff.saved',
  sabalElectricianSignOffResubmitted = 'sabal.electricianSignOff.resubmitted',
  sabalElectricianSignOffSubmitted = 'sabal.electricianSignOff.submitted',
  sabalElectricianSignOffCompleted = 'sabal.electricianSignOff.completed',
  sabalPostActivationPackageSubmitted = 'sabal.PostActivationPackage.submitted',
  sabalPostActivationPackageResubmitted = 'sabal.postActivationPackage.resubmitted',
}

export enum SabalEventType {
  sabalHoldbackCreated = 'holdback.created',
  sabalHoldbackUpdated = 'holdback.updated',
  sabalModifierUpdated = 'modifier.updated',
  sabalModifierCreated = 'modifier.created',
  sabalPriceSheetsMappingsArchived = 'modifier.pricesheet.mappings.archived',
  sabalPriceSheetsMappingsCreated = 'modifier.pricesheet.mappings.created',
  sabalConstructionAdderUpdated = 'construction.adder.updated',
  sabalConstructionAdderCreated = 'construction.adder.created',
  sabalPriceSheetCreated = 'pricesheet.created',
  sabalPriceSheetUpdated = 'pricesheet.updated',
}

export enum ActivityEventAssociatedEntityType {
  SabalAccountId = 'sabalAccountId',
  SabalSystemDesign = 'sabalSystemDesign',
  SabalApplicationId = 'sabalApplicationId',
  SabalDocumentId = 'sabalDocumentId',
  SabalContractId = 'sabalContractId',
  SabalQuoteId = 'sabalQuoteId',
}
