import { useCallback, useEffect } from 'react';
import { Field, Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { Box, Button, FormikRadioGroup, FormikTextInput } from '@palmetto/palmetto-components';
import { object, string, Schema } from 'yup';
import { ProgramType } from 'types';
import { PaymentPlanTable } from '../../../Payouts/PaymentPlanTable';
import { Link, useNavigate } from 'react-router-dom';
import { getOrgNameFromAlias } from '@/helpers/getOrgNameFromAlias';
import { useGetOrganizationPaymentScheduleQuery } from '@/services/organizations';
import { useGetPaymentPlansQuery } from '@/services/paymentPlans';

export interface PaymentFormEditData {
  shouldHavePaymentSchedule: string;
  paymentPlanId?: string | undefined;
  legalEntity: string;
  setUpBankAccount: string;
  netSuiteVendorId?: string | undefined;
}

const paymentSchema = {
  shouldHavePaymentSchedule: string().required().oneOf(['yes', 'no']),
  paymentPlanId: string().when('shouldHavePaymentSchedule', {
    is: 'yes',
    then: (schema) => schema.required('A payment plan is required'),
    otherwise: (schema) => schema.optional(),
  }),
  legalEntity: string().required().oneOf(['yes', 'no']),
  setUpBankAccount: string().required().oneOf(['yes', 'no']),
  netSuiteVendorId: string().optional(),
};

export const PaymentFormSchema: Schema<PaymentFormEditData> = object().shape(paymentSchema).noUnknown(true);

type SubmitHandler = (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;

const shouldHavePaymentScheduleOptions = [
  {
    id: 'shouldHavePaymentScheduleTrue',
    value: 'yes',
    label: 'Yes',
  },
  {
    id: 'shouldHavePaymentScheduleFalse',
    value: 'no',
    label: 'No, payments schedule should be inherited from parent organization.',
  },
];

const legalEntityOptions = [
  {
    id: 'legalEntityTrue',
    value: 'yes',
    label: 'Yes',
  },
  {
    id: 'legalEntityFalse',
    value: 'no',
    label: 'No',
  },
];

const setUpBankAccountOptions = [
  {
    id: 'setUpBankAccountTrue',
    value: 'yes',
    label: `Yes`,
  },
  {
    id: 'setUpBankAccountFalse',
    value: 'no',
    label: 'No, payments should be made to parent organization',
  },
];

const InheritScheduleInput = ({
  organizationName,
  isSubmitting,
}: {
  organizationName: string;
  isSubmitting: boolean;
}) => {
  const { setFieldValue, values }: any = useFormikContext();
  useEffect(() => {
    if (values.shouldHavePaymentSchedule === 'no') {
      setFieldValue('paymentPlanId', undefined);
    }
  }, [setFieldValue, values.shouldHavePaymentSchedule]);

  return (
    <Field
      title={`Should ${organizationName} have its own payment schedule?`}
      name="shouldHavePaymentSchedule"
      id="shouldHavePaymentSchedule"
      options={shouldHavePaymentScheduleOptions}
      component={FormikRadioGroup}
      isRequired
      isDisabled={isSubmitting}
    />
  );
};

const IsLegalEntityInput = ({
  organizationName,
  isSubmitting,
}: {
  organizationName: string;
  isSubmitting: boolean;
}) => {
  const { setFieldValue, values }: any = useFormikContext();
  useEffect(() => {
    if (values.legalEntity === 'no') {
      setFieldValue('netSuiteVendorId', undefined);
    }
  }, [setFieldValue, values.legalEntity, values.shouldHavePaymentSchedule]);

  return (
    <Field
      title={`Does ${organizationName} represent its own legal entity?`}
      name="legalEntity"
      id="legalEntity"
      options={legalEntityOptions}
      component={FormikRadioGroup}
      isRequired
      isDisabled={isSubmitting}
    />
  );
};

interface PaymentFormProps {
  initialValues: any;
  handleSubmit: SubmitHandler;
  isLoading: boolean;
  disabledFields?: string[];
  organizationAlias: string;
  programType: ProgramType;
}

export const PaymentForm = ({
  initialValues,
  handleSubmit,
  isLoading,
  organizationAlias,
  programType,
}: PaymentFormProps) => {
  const navigate = useNavigate();

  const { data: paymentPlan, isLoading: isLoadingPaymentPlan } = useGetOrganizationPaymentScheduleQuery({
    alias: organizationAlias,
  });
  const { data: paymentPlans, isLoading: isLoadingPaymentPlans } = useGetPaymentPlansQuery({
    programType,
  });
  const isLoadingData = isLoading || isLoadingPaymentPlan || isLoadingPaymentPlans;

  const onCancel = useCallback(() => {
    navigate(`/settings/organizations/${organizationAlias}/payments`);
  }, [navigate, organizationAlias]);

  return (
    <>
      <Formik
        validationSchema={PaymentFormSchema}
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={handleSubmit}
        enableReinitialize={false}
      >
        {({ isSubmitting, values, errors, setFieldValue, validateForm }) => (
          <Form noValidate>
            <Box childGap="xl">
              <Box childGap="xl">
                <Box>
                  <InheritScheduleInput isSubmitting={isSubmitting} organizationName={organizationAlias} />
                </Box>
                {values.shouldHavePaymentSchedule === 'yes' && (
                  <Box padding="0 lg lg lg" childGap="xs">
                    <Box
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderWidth="0 0 xs 0"
                      borderColor="separator"
                      padding="0 0 xs 0"
                    >
                      {paymentPlan?.inherited && (
                        <>
                          <Box fontWeight="medium">Inherited From</Box>
                          <Box direction="row" childGap="xs" alignItems="center">
                            <Link
                              style={{ color: 'green' }}
                              to={`/settings/organizations/${paymentPlan.scheduleOwnerAlias}/payments`}
                            >
                              {paymentPlan.scheduleOwnerAlias && getOrgNameFromAlias(paymentPlan.scheduleOwnerAlias)}
                            </Link>
                          </Box>
                        </>
                      )}
                    </Box>
                    {paymentPlans && (
                      <PaymentPlanTable
                        paymentPlans={paymentPlans}
                        programType={programType}
                        selectedPaymentPlanId={values.paymentPlanId}
                        selectPaymentPlan={(id) => {
                          setFieldValue('paymentPlanId', id);
                          setTimeout(() => validateForm(), 0);
                        }}
                      />
                    )}
                    {errors?.paymentPlanId && <Box color="danger">{errors.paymentPlanId as string}</Box>}
                  </Box>
                )}
              </Box>
              <Box>
                <Field
                  title={`Should ${organizationAlias} set up their own bank account to receive project payments?`}
                  name="setUpBankAccount"
                  id="setUpBankAccount"
                  options={setUpBankAccountOptions}
                  component={FormikRadioGroup}
                  isRequired
                  isDisabled={isSubmitting}
                />
              </Box>
              <Box>
                <Box>
                  <IsLegalEntityInput isSubmitting={isSubmitting} organizationName={organizationAlias} />
                </Box>
                {errors?.legalEntity && <Box color="danger">{errors.legalEntity as string}</Box>}
              </Box>
              {errors?.setUpBankAccount && <Box color="danger">{errors.setUpBankAccount as string}</Box>}
              {values.legalEntity === 'yes' && (
                <Box width="300px">
                  <Field
                    type="text"
                    label="NetSuite Vendor Id"
                    name="netSuiteVendorId"
                    id="netSuiteVendorId"
                    component={FormikTextInput}
                    autoComplete="off"
                    isDisabled={isSubmitting}
                  />
                </Box>
              )}
            </Box>

            <Box
              direction={{
                base: 'column',
                tablet: 'row',
              }}
              alignItems={{
                base: 'stretch',
                tablet: 'flex-end',
              }}
              justifyContent={{
                tablet: 'flex-end',
              }}
              childGap="sm"
              style={{ flexShrink: 0 }}
              padding="lg 0 0 0"
            >
              <Button
                variant="secondary"
                tone="neutral"
                size="md"
                isDisabled={isLoadingData}
                isLoading={isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button variant="primary" size="md" type="submit" isDisabled={isLoading} isLoading={isSubmitting}>
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
