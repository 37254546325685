import { Box } from '@palmetto/palmetto-components';
import { FC } from 'react';

export const DiffCell: FC<{ oldValue: string; newValue: string }> = ({ oldValue, newValue }) => (
  <Box>
    <Box style={{
      backgroundColor: 'rgba(220 ,38, 22, 0.25)',
    }}>
      <Box>{oldValue ?? '--'}</Box>
    </Box>
    <Box style={{ backgroundColor: 'rgba(0, 132, 51, 0.25)' }}>
      <Box>{newValue}</Box>
    </Box>
  </Box>
);
