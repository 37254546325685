import { Box, BoxProps } from '@palmetto/palmetto-components';

interface MainContainerProps extends BoxProps {
  children: React.ReactNode;
}

export function MainContainer({ children, ...restProps }: MainContainerProps) {
  return (
    <Box as="main" gap="lg" maxWidth="6xl" width="100" margin="0 auto" {...restProps}>
      {children}
    </Box>
  );
}
