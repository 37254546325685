import { useState } from 'react';
import { Box, Button, CheckboxInput, Modal, ModalProps } from '@palmetto/palmetto-components';
import { AttestmentData } from 'types';

export interface AttestmentModalProps extends ModalProps {
  onAttestmentConfirmed: (data: AttestmentData) => Promise<void>;
  label: string;
  title: string;
  isLoading: boolean;
}

/**
 * @description This is the confirmation modal shown to attest certain data
 */
export function AttestmentModal({
  isOpen,
  onDismiss,
  onAttestmentConfirmed,
  label,
  title,
  isLoading,
}: AttestmentModalProps) {
  const [checked, setChecked] = useState(false);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth="4xl" ariaLabelledBy="confirmContractVoiding">
      <Modal.Header id="confirmContractVoiding" title={title} onDismiss={onDismiss} />
      <Modal.Body background="secondary" childGap="lg">
        <Box
          fontSize={'sm'}
          color="body-primary"
          data-testid="void-confirmation-modal-body"
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          direction={'row'}
        >
          <CheckboxInput
            id="attestmendModalCheckbox"
            label=""
            onChange={() => {
              setChecked(!checked);
            }}
            isChecked={checked}
            isDisabled={isLoading}
          />
          <Box>{label}</Box>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          tone="neutral"
          onClick={onDismiss}
          id="attestmendModalCancelCTA"
          disabled={isLoading}
        >
          <Box>Cancel</Box>
        </Button>
        <Button
          variant="primary"
          tone="primary"
          onClick={() => {
            onAttestmentConfirmed({
              attestmentText: label,
            });
          }}
          disabled={!checked}
          id="attestmendModalProceedCTA"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          <Box>Proceed</Box>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
