import { ProgramType } from './';
import { FinanceType } from './ProductPricing';

export enum UtilityStatus {
  active = 'active',
  inactive = 'inactive',
}
export interface UtilityDocument {
  lseId: number;
  name: string;
  state: string;
  status: UtilityStatus;
  contractType: FinanceType;
  programTypes?: ProgramType[];
  incentives?: IncentivesObject[];
}

export enum Incentives {
  illinoisShines = 'Illinois-Shines',
}

interface IncentivesObject {
  incentive: Incentives;
  rates?: {
    rate: number;
    systemSizekWACMax?: number;
    systemSizekWACMin?: number;
  }[];
}
