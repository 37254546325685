import { ObjectId } from 'mongodb';
import { Meta, ProgramType } from '.';

export enum PricingSettingsType {
  constructionAdders = 'constructionAdders',
  energyCommunity = 'energyCommunity',
  holdbacks = 'holdbacks',
  lowAndMiddleIncome = 'lowAndMiddleIncome',
  pricingRanges = 'pricingRanges',
  taxCreditModifiers = 'taxCreditModifiers',
  ppwAdjustment = 'ppwAdjustment',
  domesticContent = 'domesticContent',
}

export enum ConstructionAdderType {
  arbitrageBattery = 'arbitrageBattery',
  backupBattery = 'backupBattery',
  backupBatteryKwh = 'backupBatteryKwh',
  electricalUpgrade = 'electricalUpgrade',
  backupBatteryPriceCap = 'backupBatteryPriceCap',
  solarReadiness = 'solarReadiness',
}

export enum EnergyCommunityType {
  coalTract = 'coalTract',
  combined = 'combined',
  fossilFuel = 'fossilFuel',
}

export enum EnergyCommunityFormatType {
  percent = 'percent',
  perWatt = 'perWatt',
}

export enum DomesticContentType {
  domesticContent = 'domesticContent',
}

export enum DomesticContentFormatType {
  percent = 'percent',
  perWatt = 'perWatt',
}

export enum AdderValueType {
  additionalKwhRateBand = 'additionalKwhRateBand',
  rawCost = 'rawCost',
}

export interface AdderOption {
  adderType: ConstructionAdderType;
  enabled: boolean;
  valueType: AdderValueType;
  value?: number;
}

export type PaymentFactorAdder = {
  escalationRate: number;
  value: number;
}[];

export type BackupBatteryPriceCap = {
  batteryId: ObjectId;
  maxPrice: number;
};

export interface PricingSettingsDocument {
  id: ObjectId;
  type: PricingSettingsType;
  name: string;
  value: Record<string, any>;
  meta?: Meta;
  programType: ProgramType;
}

export interface PriceSheetRangesSettingsDocument extends PricingSettingsDocument {
  value: {
    pvOnlyMin: number;
    pvOnlyCeiling: number;
    zeroEscalatorCeiling?: number;
    absoluteCeiling: number;
    escalationRateMin: number;
    escalationRateMax: number;
    state: string;
    organizationName: string;
  };
}

export interface ConstructionAdderSettingsDocument extends PricingSettingsDocument {
  value: {
    adderType: ConstructionAdderType;
    kwhAdderBand?: number;
    paymentFactors?: PaymentFactorAdder;
    backupBatteryPriceCaps?: BackupBatteryPriceCap[];
    maxAmount?: number;
  };
}

export interface EnergyCommunitySetting {
  type: EnergyCommunityType;
  value: number;
  format: EnergyCommunityFormatType;
}

export interface PpwAdjustmentSetting {
  amount: number;
}

export interface DomesticContentSetting {
  modifier: {
    format: DomesticContentFormatType;
    value: number;
  };
  holdback: {
    format: DomesticContentFormatType;
    value: number;
  };
}

export type ModifiersPricingSettings = EnergyCommunitySetting[] | PpwAdjustmentSetting | DomesticContentSetting;

export interface ModifierSettingsDocument extends PricingSettingsDocument {
  value: ModifiersPricingSettings;
}

export interface HoldbackSettingsDocument extends PricingSettingsDocument {
  value: {
    amount: number;
  };
}
