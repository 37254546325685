import { AdderOption, ConstructionAdderType } from 'types';

export const isAdderAvailable = (adderType: ConstructionAdderType, availableAdders: AdderOption[] = []): boolean => {
  return !!availableAdders.find((adder) => adder.adderType === adderType && adder.enabled);
};

export const getAdderValue = (
  adderType: ConstructionAdderType,
  availableAdders: AdderOption[] = [],
): number | undefined => {
  const adder = availableAdders.find((adder) => adder.adderType === adderType && adder.enabled);
  return adder?.value;
};
