import { Box, Details, Icon, useOpenClose } from '@palmetto/palmetto-components';
import DOMPurify from 'dompurify';
import EventBy from './EventBy';

const SolarFlareEmailNotificationSent = ({ event }: { event: any }) => {
  const { isOpen, handleToggle } = useOpenClose();
  return (
    <Box display="block" childGap="sm">
        { event.data.request.data && (
          <Box display="block">
            <EventBy event={event} /> sent an email to <strong>{event?.data?.request?.recipients?.[0].email}</strong>
          </Box>
        )}
        { !event.data.request.data && (
          <Box display="block">
            The{' '}
            <Box as="span" display="inline-block" fontWeight="bold" margin="0 2xs 0 0" title={event?.data?.request.templateDescription}>
              {event?.data?.renderedMessage?.input?.template?.name}
            </Box>
            email was sent to{' '}
            <Box as="span" display="inline-block" fontWeight="bold" margin="0 2xs 0 0">
              {event?.data?.request?.recipients?.[0].email}
            </Box>
          </Box>
        )}

      { event.data.renderedMessage.output && (
        <Details
          isOpen={isOpen}
          margin="xs 0 0 0"
          display="block"
          background="primary"
          radius="md"
          borderWidth="xs"
          borderColor="separator"
          width="100"
        >
          <Details.Summary
            isDetailsOpen={isOpen}
            onToggle={handleToggle}
            justifyContent="space-between"
            display="flex"
            direction="row"
            alignItems="center"
            padding="md"
          >
            <Box>{event?.data?.renderedMessage?.output?.subject}</Box>
            <Box fontSize="xs" direction="row" alignItems="center" childGap="xs" hover={{ color: 'body-primary' }}>
              <Icon name={isOpen ? 'caret-up' : 'caret-down'} />
            </Box>
          </Details.Summary>
          <Box padding="md" childGap="sm" borderWidth="xs 0 0 0" borderColor="separator">
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event?.data?.renderedMessage?.output?.body) }}
            />
          </Box>
        </Details>
      )}
    </Box>
  );
};

export default SolarFlareEmailNotificationSent;
