import { BadgeVariant } from '@palmetto/palmetto-components';
import { CodeViolationRejectionReasons } from './ReviewPackage';
import { BaseRejectionReasons } from './BasePackageTypes';
import {
  ActivationDocumentTypes,
  AllDocumentTypes,
  DocumentRejectionReasons,
  DocumentStatus,
  DocumentTypes,
  InstallDocumentTypes,
} from 'types';

export const documentStatus: {
  [key in DocumentStatus]: {
    label: string;
    variant: BadgeVariant;
  };
} = {
  [DocumentStatus.approved]: {
    label: 'Approved',
    variant: 'success',
  },
  [DocumentStatus.rejected]: {
    label: 'Rejected',
    variant: 'danger',
  },

  [DocumentStatus.pending]: {
    label: 'Pending',
    variant: 'warning',
  },
  [DocumentStatus.voided]: {
    label: 'Voided',
    variant: 'default',
  },
} as const;

export const documentRejectionReasons: {
  [Key in AllDocumentTypes]?: string[];
} = {
  [InstallDocumentTypes.dxf]: Object.keys(BaseRejectionReasons),
  [InstallDocumentTypes.shadeReport]: Object.keys(BaseRejectionReasons),
  [InstallDocumentTypes.planSet]: [
    ...Object.keys(BaseRejectionReasons),
    ...Object.keys(CodeViolationRejectionReasons),
  ],
  [InstallDocumentTypes.permit]: [
    ...Object.keys(BaseRejectionReasons),
    ...Object.keys(CodeViolationRejectionReasons),
  ],
  [DocumentTypes.voidedCheck]: [...Object.keys(DocumentRejectionReasons)],
  [DocumentTypes.disclosure]: [...Object.keys(DocumentRejectionReasons)],
  [DocumentTypes.supporting]: [...Object.keys(DocumentRejectionReasons)],
  [InstallDocumentTypes.productionModel]: Object.keys(BaseRejectionReasons),
  [ActivationDocumentTypes.ptoConfirmation]: Object.keys(BaseRejectionReasons),
  [ActivationDocumentTypes.extendedWarranty]: Object.keys(BaseRejectionReasons),
  [ActivationDocumentTypes.incentives]: Object.keys(BaseRejectionReasons),
};
