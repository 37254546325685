import { Box } from '@palmetto/palmetto-components';
import { QueueItemDisplayStatus } from '../../types/Queues';

export function QueueItemStatus({ status }: { status: keyof typeof QueueItemDisplayStatus }): JSX.Element {
  return (
    <Box
      style={{
        height: '100%',
        justifyContent: 'center',
      }}
      height="100"
      justifyContent="center"
      alignItems="center"
    >
      <Box style={{ fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '.5px' }}>
        {QueueItemDisplayStatus[status]}
      </Box>
    </Box>
  );
}
