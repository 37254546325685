import { FormikErrors, FormikValues } from 'formik';
import { geocodeByPlaceId } from 'react-places-autocomplete';
export type AddressFieldNames = {
  address1FieldName: string;
  address2FieldName: string;
  cityFieldName: string;
  stateFieldName: string;
  zipFieldName: string;
  latFieldName: string;
  lonFieldName: string;
};

const getAddressComponentsByType = (addressComponents: google.maps.GeocoderAddressComponent[], type: string) =>
  addressComponents.filter((component) => component.types.includes(type));

export const setAddressDetails = async (
  placeId: string,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>,
  addressFieldNames: AddressFieldNames,
) => {
  if (!placeId) {
    return;
  }
  /**
   * Init Google PlacesService
   * Read more: https://developers.google.com/maps/documentation/javascript/reference/places-service
   */
  const service = new window.google.maps.places.PlacesService(document.createElement('div'));

  // Promisifying a Google Places `getDetails` Method so we can use it with async/await syntax.
  const getDetailsByPlaceId = (): Promise<
    google.maps.places.PlaceResult | { status: google.maps.places.PlacesServiceStatus } | null
  > =>
    new Promise((resolve, reject) => {
      service.getDetails({ placeId, fields: ['address_components'] }, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        }
        reject(status);
      });
      return { status: google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR };
    });

  const detailsByPlaceId = await getDetailsByPlaceId();
  if (detailsByPlaceId && 'address_components' in detailsByPlaceId) {
    const components = detailsByPlaceId.address_components;
    // Generate lat/lng based on the selected address (to update the map).
    const [
      {
        geometry: { location },
      },
    ] = await geocodeByPlaceId(placeId);

    // TODO: Update map position
    // setCurrentMapPosition({ lat: location.lat(), lng: location.lng() });
    setFieldValue(addressFieldNames.latFieldName, location.lat());
    setFieldValue(addressFieldNames.lonFieldName, location.lng());

    // Extract address components
    if (components) {
      const countryCode = getAddressComponentsByType(components, 'country')[0]?.short_name;
      const streetNumber = getAddressComponentsByType(components, 'street_number')[0]?.long_name;
      const streetName = getAddressComponentsByType(components, 'route')[0]?.long_name;
      const streetAddress = streetNumber && streetName ? `${streetNumber} ${streetName}` : '';
      const subpremise = getAddressComponentsByType(components, 'subpremise')[0]?.long_name;
      const city = getAddressComponentsByType(components, 'locality')[0]?.long_name;
      const stateAbbreviation = countryCode === 'PR' ? countryCode : getAddressComponentsByType(components, 'administrative_area_level_1')[0]?.short_name;
      const zipCode = getAddressComponentsByType(components, 'postal_code')[0]?.long_name;
      // Setting relevant form state.
      setFieldValue(addressFieldNames.address1FieldName, streetAddress);
      setFieldValue(addressFieldNames.address2FieldName, subpremise || '');
      setFieldValue(addressFieldNames.cityFieldName, city);
      setFieldValue(addressFieldNames.stateFieldName, stateAbbreviation);
      setFieldValue(addressFieldNames.zipFieldName, zipCode);
    }
  }
};
