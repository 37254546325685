import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';

const QuoteCreated = ({ event }: { event: any }) => {
  return (
    <Box display="block">
      <Box as="span" display="inline-block" margin="0 2xs 0 0">
        <EventBy event={event} /> created a quote
      </Box>
    </Box>
  );
};

export default QuoteCreated;
