import { AllDocumentTypes, InstallDocumentTypes, InstallationPackagePhotoCategory } from 'types';
import { TaskType } from '../../types/Tasks';

export const taskTypeToInstallDocumentType: Record<string, AllDocumentTypes> = {
  [TaskType.dxfFile]: InstallDocumentTypes.dxf,
  [TaskType.shadeReport]: InstallDocumentTypes.shadeReport,
  [TaskType.planSet]: InstallDocumentTypes.planSet,
  [TaskType.permit]: InstallDocumentTypes.permit,
};

export const taskTypeToInstallPhotoSection: Record<string, InstallationPackagePhotoCategory> = {
  [TaskType.projectSite]: InstallationPackagePhotoCategory.projectSite,
  [TaskType.roof]: InstallationPackagePhotoCategory.roof,
  [TaskType.electrical]: InstallationPackagePhotoCategory.electrical,
  [TaskType.storage]: InstallationPackagePhotoCategory.storage,
  [TaskType.systemCommissioning]: InstallationPackagePhotoCategory.systemCommissioning,
};
