import { useAuth } from './authProvider';
import { ReactNode } from 'react';
import { isPalmettoFinanceUser } from '@/components/auth/RequirePricingAdminUser';
import { UserPermissions } from 'types';

export const RequirePayoutsAdminUser = ({ children }: { children: ReactNode }) => {
  const { claims } = useAuth();
  const isPalmettoFinance = isPalmettoFinanceUser(claims);
  const isPricingAdmin = claims?.permissions?.includes(UserPermissions.lightReachPaymentAdmin);
  return isPalmettoFinance && isPricingAdmin ? <>{children}</> : null;
};
