import React from 'react';
import { Box, Card } from '@palmetto/palmetto-components';
import PaymentCardHeader from './PaymentCardHeader';
import { OrganizationNode } from '../../../../types/Organizations';

interface PaymentCardProps {
  href?: string;
  isPhone?: boolean;
  organization?: OrganizationNode | undefined;
  onClick?: () => void;
  cardTitle: string;
  accountingEmail?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
}

const PaymentCard = ({
  href,
  cardTitle,
  isPhone,
  organization,
  isLoading,
  accountingEmail,
  children,
  onClick,
}: PaymentCardProps) => {
  return (
    <Card height="400px">
      <PaymentCardHeader
        organization={organization}
        isPhone={isPhone}
        cardTitle={cardTitle}
        href={href}
        onClick={onClick}
      />
      {isLoading && <Box padding="0 lg lg lg">loading...</Box>}
      {!isLoading && !organization && <Box padding="0 lg lg lg">Organization not found</Box>}
      {!isLoading && organization && children}
    </Card>
  );
};

export default PaymentCard;
