import { Box } from '@palmetto/palmetto-components';
import React, { ReactNode } from 'react';

const FormSectionContainer = ({ children }: { children: ReactNode }) => (
  <Box margin={{ base: '0', desktop: 'md 0 0 0' }} padding={{ base: 'lg lg 0 lg', desktop: '0' }}>
    {children}
  </Box>
);

export default FormSectionContainer;
