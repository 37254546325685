import { useAuth } from './authProvider';
import { ReactNode } from 'react';

const palmettoFinanceOrgAlias = 'sabal';

export const isPalmettoFinanceUser = (claims: any) =>
  claims?.org === palmettoFinanceOrgAlias || claims?.rootOrg === palmettoFinanceOrgAlias;

const RequirePalmettoFinanceUser = ({ children }: { children: ReactNode }) => {
  const { claims } = useAuth();
  const show = isPalmettoFinanceUser(claims);
  return show ? <>{children}</> : null;
};

export default RequirePalmettoFinanceUser;
