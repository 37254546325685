import { Box, Button, FormikRadioGroup, FormikSelectInput, TextInput } from '@palmetto/palmetto-components';
import FormSection from '../Forms/FormSection';
import { SyntheticEvent } from 'react';
import { Field } from 'formik';

export interface IDataResponse {
  productId: string;
  type: string;
  name: string;
  escalationRate: number;
  monthlyPayments: {
    year: number;
    monthlyPayment: number;
    totalMonthlyPayment: number;
  }[];
  term: number;
  lseId: number;
  state: string;
  programType: string;
}

export interface ISelectionOption {
  label: string;
  value: string;
  id: string;
}

export function FormActionsSection({
  isLoading,
  selectedElement,
  onCancel,
}: {
  isLoading: boolean;
  selectedElement: any;
  onCancel: () => void;
}) {
  return (
    <Box
      direction={{
        base: 'column',
        tablet: 'row',
      }}
      alignItems={{
        base: 'stretch',
        tablet: 'flex-end',
      }}
      justifyContent={{
        tablet: 'flex-end',
      }}
      childGap="sm"
      style={{ flexShrink: 0 }}
      padding={{ base: 'lg', desktop: 'lg 0 0 0' }}
    >
      <Button variant="secondary" tone="neutral" size="md" onClick={onCancel} isDisabled={isLoading}>
        Cancel
      </Button>
      <Button size="md" variant="primary" type="submit" isDisabled={isLoading || !selectedElement}>
        Create Quote
      </Button>
    </Box>
  );
}

export function EscalatorRateSection({
  rates,
  onChange,
  disabled,
}: {
  rates: ISelectionOption[];
  onChange: (result: string) => void;
  disabled?: boolean;
}) {
  return (
    <FormSection
      title="Select Escalator Rate"
      baseStyle={{
        alignItems: {
          base: 'flex-start',
          tablet: 'flex-start',
          desktop: 'center',
        },
        borderColor: 'transparent',
      }}
    >
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
        style={{
          maxWidth: '10em',
        }}
      >
        <Field
          component={FormikRadioGroup}
          id="rate"
          name="rate"
          options={rates}
          isDisabled={disabled}
          onChange={(data: SyntheticEvent<HTMLInputElement>) => {
            if (typeof (data.target as HTMLInputElement).value === 'undefined') {
              return;
            }
            onChange((data.target as HTMLInputElement).value);
          }}
        />
      </Box>
    </FormSection>
  );
}

export function FormResultSection({ data }: { data: IDataResponse }) {
  return (
    <Box
      style={{
        margin: '1em 0',
      }}
    >
      <Box>
        <Box
          style={{
            fontSize: '12pt',
          }}
        >
          Year 1 Monthly Payment
        </Box>
        <Box
          style={{
            fontSize: '24pt',
          }}
        >
          ${data.monthlyPayments[0].totalMonthlyPayment}/mo
        </Box>
        <Box
          style={{
            fontSize: '12pt',
            fontWeight: 'medium',
          }}
        >
          Customer Payment
        </Box>
      </Box>
    </Box>
  );
}

export function TermFormSection({
  terms,
  onChange,
  isDisabled,
}: {
  terms: ISelectionOption[];
  onChange: (data: ISelectionOption) => void;
  isDisabled?: boolean;
}) {
  return (
    <FormSection
      title="Term (Contract Length)"
      baseStyle={{
        alignItems: {
          base: 'flex-start',
          tablet: 'flex-start',
          desktop: 'center',
        },
        borderColor: 'transparent',
      }}
    >
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
        style={{
          maxWidth: '9.8em',
        }}
      >
        <Field
          component={FormikRadioGroup}
          id="term"
          name="term"
          options={terms}
          isDisabled={isDisabled}
          onChange={(data: SyntheticEvent<HTMLInputElement>) => {
            const selectedValue = (data.target as HTMLInputElement).value;
            if (typeof selectedValue === 'undefined') {
              return;
            }
            const selected = terms.find((element) => {
              return element.value === selectedValue;
            });
            if (selected) {
              onChange(selected);
            }
          }}
        />
      </Box>
    </FormSection>
  );
}

export function TotalFinancedAmountFormSection({
  onChange,
  value,
  disabled,
}: {
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
}) {
  return (
    <FormSection
      title="Total Financed Amount"
      baseStyle={{
        alignItems: {
          base: 'flex-start',
          tablet: 'flex-start',
          desktop: 'center',
        },
        borderColor: 'transparent',
        maxWidth: {
          base: '100',
          desktop: '100',
        },
      }}
    >
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        style={{
          maxWidth: '9.8em',
        }}
      >
        <Box>
          <TextInput
            name="totalFinancedAmount"
            id="totalFinancedAmount"
            label=""
            onChange={async ({ target }: any) => {
              onChange(target.value as string);
            }}
            value={value}
            isDisabled={disabled}
          />
        </Box>
      </Box>
    </FormSection>
  );
}
