import { useParams } from 'react-router-dom';
import {
  useDeleteOrganizationEnergyCommunityMutation,
  useLazyGetOrganizationEnergyCommunityQuery,
} from '../../../../../services/pricing';
import { useEffect } from 'react';
import { Box, Button, Card, Column, Table, toast, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { TableLoading } from '../../../../TableLoading';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { CreateEnergyCommunity } from './modals/CreateEnergyCommunity';
import { EnergyCommunityFormatType, EnergyCommunitySetting, EnergyCommunityType } from 'types';
import RequirePricingAdminUser from '@/components/auth/RequirePricingAdminUser';

type FuelTypes = {
  [EnergyCommunityType.coalTract]: EnergyCommunityType.coalTract;
  [EnergyCommunityType.fossilFuel]: EnergyCommunityType.fossilFuel;
  [EnergyCommunityType.combined]: EnergyCommunityType.combined;
};

type EnergyCommunity = {
  id: string;
  name: string;
  organizationId: string;
  organizationName: string;
  state: string;
  values: EnergyCommunitySetting[];
};

const evaluateValues = (values: EnergyCommunitySetting) => {
  return (
    (values.format === EnergyCommunityFormatType.perWatt
      ? currencyFormatter.format(values.value) + '/W'
      : values.value + '%') || (values.format === EnergyCommunityFormatType.perWatt ? '0.00' : '0%')
  );
};

const transformArray = (energyCommunity: EnergyCommunity[]) => {
  let energyCommunityTable: Partial<EnergyCommunity & FuelTypes>[] = [];

  energyCommunity.forEach((item: EnergyCommunity) => {
    energyCommunityTable.push({
      id: item.id,
      name: item.name,
      organizationId: item.organizationId,
      organizationName: item.organizationName,
      state: item.state,
      coalTract: item.values.map(
        (values: EnergyCommunitySetting) => values.type === EnergyCommunityType.coalTract && evaluateValues(values),
      ) as unknown as EnergyCommunityType.coalTract,
      fossilFuel: item.values.map(
        (values: EnergyCommunitySetting) => values.type === EnergyCommunityType.fossilFuel && evaluateValues(values),
      ) as unknown as EnergyCommunityType.fossilFuel,
      combined: item.values.map(
        (values: EnergyCommunitySetting) => values.type === EnergyCommunityType.combined && evaluateValues(values),
      ) as unknown as EnergyCommunityType.combined,
    });
  });
  return energyCommunityTable;
};

export const EnergyCommunity = () => {
  const { alias = '' } = useParams<{ alias: string }>();
  const { isPhone } = useBreakpoint();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [trigger, { data, isLoading, isFetching }] = useLazyGetOrganizationEnergyCommunityQuery();
  const [deleteOrganizationEnergyCommunityMutation] = useDeleteOrganizationEnergyCommunityMutation();

  useEffect(() => {
    if (alias) {
      trigger({ alias });
    }
  }, [alias, trigger]);

  const handleArchive = async (id: string) => {
    try {
      await deleteOrganizationEnergyCommunityMutation({ alias, id }).unwrap();
      toast.success('Energy Community Archived successfully');
    } catch (e) {
      console.log(e);
      toast.error('Error archiving Energy Community');
    }
  };

  const columnConfig: Column[] = [
    { heading: 'Name', dataKey: 'name' },
    { heading: 'org', dataKey: 'organizationName' },
    { heading: 'State', dataKey: 'state' },
    { heading: 'Coal Closure', dataKey: 'coalTract' },
    { heading: 'Fossil Fuel', dataKey: 'fossilFuel' },
    { heading: 'Combined', dataKey: 'combined' },
    {
      heading: '',
      render: (_cell: any, row: any) => (
        <RequirePricingAdminUser>
          <Button
            size="sm"
            variant="secondary"
            tone="neutral"
            onClick={() => {
              handleArchive(row.id);
            }}
          >
            Archive
          </Button>
        </RequirePricingAdminUser>
      ),
    },
  ];
  return (
    <>
      <CreateEnergyCommunity isModalOpen={isModalOpen} closeModal={closeModal} />
      <Card.Header>
        <RequirePricingAdminUser>
          <Box direction="row" justifyContent="space-between">
            <Box />
            <Button
              as="button"
              variant="primary"
              iconPrefix="add"
              size={{ base: 'sm', desktop: 'md' }}
              aria-label="create adder"
              onClick={() => openModal()}
            >
              {!isPhone && 'Energy Community'}
            </Button>
          </Box>
        </RequirePricingAdminUser>
      </Card.Header>
      <Box margin="0 lg 0 lg">
        {isFetching || isLoading ? (
          <TableLoading rows={12} />
        ) : (
          <Table
            rowKey="id"
            columns={columnConfig as Column[]}
            rows={(data && transformArray(data.energyCommunities)) ?? []}
            isLoading={isLoading || isFetching}
            isScrollable={{ y: true }}
            hasStickyHeader
          />
        )}
      </Box>
    </>
  );
};
