import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';
import { getSettingsTypeLabel } from '../../../helpers/getSettingsTypeLabel';

const SabalPricingSettingCreated = ({ event }: { event: any }) => {
  const label = getSettingsTypeLabel(event.data, event.eventType);
  return (
    <Box display="block">
      <EventBy event={event} /> created a {label}: {event.data.name}
    </Box>
  );
};

export default SabalPricingSettingCreated;
