import { HVACDiscountDetails, HVACEquipmentDetails, HVACInstallKitDetails, HVACServiceDetails } from 'types';

export const calculateTotalCost = (values: {
  equipment: HVACEquipmentDetails[];
  installKits?: HVACInstallKitDetails[];
  services?: HVACServiceDetails[];
  discounts?: HVACDiscountDetails[];
}) => {
  const equipmentCost = values.equipment.reduce((sum, item) => sum + Number(item.cost ?? 0), 0);
  const installKitsCost = values?.installKits?.reduce((sum, item) => sum + Number(item.cost ?? 0), 0) || 0;
  const servicesCost = values?.services?.reduce((sum, item) => sum + Number(item.cost ?? 0), 0) || 0;
  const discountsCost = values?.discounts?.reduce((sum, item) => sum + Number(item.cost ?? 0), 0) || 0;

  return equipmentCost + installKitsCost + servicesCost - discountsCost;
};
