import { ReactNode, useState } from 'react';
import { Box, Heading, Icon } from '@palmetto/palmetto-components';
import { useSearchParams } from 'react-router-dom';

type MobileFilterContainerProps = {
  className?: string;
  children: ReactNode;
  title: string;
  isMobile: boolean;
  type: string;
};

export const MobileFilterContainer = ({
  className,
  children,
  title,
  type,
  isMobile = false,
}: MobileFilterContainerProps) => {
  const [searchParams] = useSearchParams();
  const filters = searchParams.get(type)?.split(',') || [];
  const startOpen = filters.length || !isMobile;

  const [isOpen, setIsOpen] = useState(startOpen);

  const formattedTitle = `${title}${filters?.length > 0 ? ` (${filters.length})` : ''}`;
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      className={className}
      maxHeight={{ base: '3xl', desktop: 'inherit' }}
      borderWidth="0 0 xs 0"
      borderColor="separator"
    >
      <Box padding="md lg">
        <button onClick={handleToggle} className="text-align-left w-100 naked">
          <Box direction="row" justifyContent="space-between" color="body-primary">
            <Heading as="h4" size="lg">
              {formattedTitle}
            </Heading>
            <Icon name={isOpen ? 'caret-up' : 'caret-down'} />
          </Box>
        </button>
      </Box>

      {isOpen && (
        <Box overflow="auto" childGap="sm" padding="md lg" background="secondary">
          {children}
        </Box>
      )}
    </Box>
  );
};
