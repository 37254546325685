import { Box } from '@palmetto/palmetto-components';
import { FC, useMemo } from 'react';

export const DiffMapListCell: FC<{
  oldValue: Record<string, { id: string; name: string }>;
  newValue: Record<string, { id: string; name: string }>
}> = ({
        oldValue,
        newValue,
      }) => {
  const { unchanged, removed, added } = useMemo(
    () =>
      Object.keys(newValue ?? {}).reduce(
        (acc, key) => {
          const newVal = newValue[key];
          if (oldValue[key] && (oldValue?.[key]?.id === newVal?.id || !newVal || newVal?.id === '')) {
            acc.unchanged.push(oldValue[key]);
          } else if (newValue[key]?.id === 'remove' && oldValue?.[key]?.id) {
            acc.removed.push(oldValue[key]);
          } else if (newValue[key]?.id && !oldValue?.[key] && newValue[key]?.id !== 'remove') {
            acc.added.push(newValue[key]);
          } else {
            if (newValue[key]) {
              acc.added.push(newValue[key]);
            }
            if (oldValue[key]) {
              acc.removed.push(oldValue[key]);
            }
          }

          return acc;
        },
        { unchanged: [] as any[], removed: [] as any[], added: [] as any[] },
      ),
    [oldValue, newValue],
  );

  return (
    <Box>
      {unchanged.map((value) => (value ? <Box key={value.id}>{value.name}</Box> : null))}
      {removed.map((value, index) =>
        value ? (
          <Box
            key={`${value.id}-${value.name}-${index}`}
            style={{
              textDecoration: 'line-through',
              backgroundColor: 'rgba(220 ,38, 22, 0.25)',
              borderColor: 'rgba(220 ,38, 22, 0.25)',
            }}
            borderWidth="0 0 xs 0"
          >
            {value.name}
          </Box>
        ) : null,
      )}
      <Box>
        {added.map((value) =>
          value ? (
            <Box
              key={value.id}
              style={{ backgroundColor: 'rgba(0, 132, 51, 0.25)', borderColor: 'rgba(0, 132, 51, 0.25)' }}
              borderWidth="0 0 xs 0"
            >
              <Box>{value.name}</Box>
            </Box>
          ) : null,
        )}
      </Box>
    </Box>
  );
};
