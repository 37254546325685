import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';
import { MilestoneLabels, MilestoneType } from 'types';

const SabalAccountMilestoneUpdated = ({ event }: { event: any }) => {
  return (
    <Box display="block">
      <Box as="span" display="inline-block" margin="0 2xs 0 0">
        <EventBy event={event} />{' '}
        <strong>
          {event?.data?.milestone &&
            (MilestoneLabels[event.data.milestone as MilestoneType] ?? event?.data?.milestone)}
        </strong>
        <span> status changed to </span>
        <strong>{event?.data?.status}</strong>
      </Box>
    </Box>
  );
};

export default SabalAccountMilestoneUpdated;
