import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AccountMilestoneStipulation, AccountStipulationType } from '../../types/Accounts';
import { useGetAccountStipulationsQuery } from '../../services/accounts';
import AdminStipulationBanner from '../Stipulations/AdminStipulationBanner';

const AdminStipulationBannerList = () => {
  const { id } = useParams<{ id: any }>();
  const { data: stipulations } = useGetAccountStipulationsQuery(id);
  const adminStipulations = useMemo(() => {
    if (!stipulations?.length) {
      return;
    }

    return stipulations.filter(
      (stipulation) =>
        stipulation.stipulationType === AccountStipulationType.administrative && !stipulation.isSatisfied,
    );
  }, [stipulations]);

  const banners =
    adminStipulations &&
    adminStipulations?.length > 0 &&
    adminStipulations?.map((stipulation: AccountMilestoneStipulation) => (
      <AdminStipulationBanner key={stipulation?.id} stipulation={stipulation} />
    ));

  return banners && banners?.length > 0 ? banners : null;
};

export default AdminStipulationBannerList;
