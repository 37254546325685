import { Alert } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import {
  AccountMilestoneWithRequirements,
  AccountRequirementDocument,
  AccountRequirementStatus,
  MilestoneRequirement,
} from 'types';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { MilestoneType } from '../../types/Milestone';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useGetAccountQuery } from '../../services/accounts';
import { useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import { MainContainer } from '../MainContainer';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import { LegacyMilestonePackage } from './LegacyMilestonePackage';
import { InstallPackage } from './InstallPackage';
import AdminStipulationBannerList from '../Stipulations/AdminStipulationBannerList';
import { NeedsAttestmentGuard } from '../NeedsAttestmentGuard/NeedsAttestmentGuard';

export const InstallMilestonePackage = () => {
  const { id, action } = useParams<{ id: any; action: ReviewPackageActions }>();
  const { data: account, isLoading: accountIsLoading }: any = useGetAccountQuery(id);
  const { data: queue, isLoading: queueIsLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: 'installPackage',
  });

  const isInstallPending = useMemo(() => {
    if (!account) return false;
    return (
      account?.milestones
        ?.find((milestone: AccountMilestoneWithRequirements) => milestone.type === MilestoneType.install)
        ?.requirements?.find(
          (requirement: AccountRequirementDocument) => requirement?.type === MilestoneRequirement.installPackage,
        ).status === AccountRequirementStatus.pending
    );
  }, [account]);

  const canSubmitInstallPackage = useMemo(() => {
    if (!account) return false;
    return account?.currentMilestone?.type !== MilestoneType.noticeToProceed || false;
  }, [account]);

  return (
    <>
      <Helmet>
        <title>{`${account?.primaryApplicantName} Install Package`}</title>
      </Helmet>
      <NeedsAttestmentGuard>
        <MainContainer>
          <AdminStipulationBannerList />
          <PageHeader
            eyebrow={
              <>
                <Link to="/accounts">Accounts</Link> \{' '}
                <Link to={`/accounts/${id}`}>{`${account?.primaryApplicantName}`}</Link>
              </>
            }
            title={`Install Package${accountIsLoading ? '' : ': ' + account?.primaryApplicantName}`}
            description="Post installation, provide the required documents and install documentation photos for review."
          />
          {account && action !== ReviewPackageActions.review && !canSubmitInstallPackage && (
            <Alert
              variant="warning"
              hasIcon
              title="Notice to Proceed has not been issued"
              message="Notice to Proceed has to be issued before submitting the Install Package."
            />
          )}
          {accountIsLoading || queueIsLoading ? (
            <FormSection title="Loading...">
              <SkeletonBox height="25px" width="100" />
            </FormSection>
          ) : isInstallPending || Boolean(queue) ? (
            <InstallPackage canSubmitInstallPackage={canSubmitInstallPackage} />
          ) : (
            <LegacyMilestonePackage canSubmitInstallPackage={canSubmitInstallPackage} />
          )}
        </MainContainer>
      </NeedsAttestmentGuard>
    </>
  );
};
