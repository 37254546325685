import { ActivationPackagePhotoCategory } from 'types';

export enum ActivationPackageActions {
  create = 'create',
  edit = 'edit',
  review = 'review',
}

export enum MonitoringSiteIdRejectionReasons {
  ownershipAccessRequired = 'Ownership Access Required',
  layoutNotMapped = 'Layout Not Mapped',
  noCommunication = 'No Communication',
  systemSettingsIncorrect = 'System Settings Incorrect',
}

export type ActivationPhotoSectionType = {
  id: ActivationPackagePhotoCategory;
  title: string;
  description?: string;
  documentationUrl?: string;
};

export const activationPhotoSections: Record<ActivationPackagePhotoCategory, ActivationPhotoSectionType> = {
  [ActivationPackagePhotoCategory.siteImprovements]: {
    id: ActivationPackagePhotoCategory.siteImprovements,
    title: 'Site Improvement Photos',
  },
} as const;
