import { Box, DateInput, FormikTextInput } from '@palmetto/palmetto-components';
import { useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { DocumentList } from './Documents/DocumentList';
import { InstallDocumentTypes } from 'types';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { DATE_FORMAT } from '@/helpers/dates';
import { ElectricianSignOffReview } from '@/components/InstallMilestonePackage/ElectricianSignOffReview';

export const ElectricianSignOff = ({ hideQueueStatus = false }: { hideQueueStatus?: boolean }) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<any>();
  const minDate = DateTime.fromISO('2022-01-01').toJSDate();
  const maxDate = DateTime.local().endOf('day').toJSDate();
  const electricianSignoffDate = useMemo(() => {
    return values.electricianSignoffDate
      ? DateTime.fromFormat(values.electricianSignoffDate, DATE_FORMAT).toJSDate()
      : null;
  }, [values.electricianSignoffDate]);
  const handleDateChange = useCallback(
    (date: Date | [Date, Date] | null) => {
      if (!date || !(date instanceof Date)) return;
      setFieldValue('electricianSignoffDate', DateTime.fromJSDate(date).toUTC().toFormat(DATE_FORMAT));
    },
    [setFieldValue],
  );
  return (
    <FormSection title="Electrician Sign-off">
      {!hideQueueStatus && <ElectricianSignOffReview />}
      <DocumentList
        documentTypeFilter={[InstallDocumentTypes.electricianSignOff]}
        title="Electrician Sign-off"
        showTypeLabelOnItems={false}
        baseCategory="Electrician Sign-off"
      />
      <Box childGap="lg">
        <DateInput
          label="Electrician Signed Date"
          name="electricianSignoffDate"
          id="electricianSignoffDate"
          placeholder={'Pick a date'}
          dateFormat={DATE_FORMAT}
          isDisabled={isSubmitting}
          width="2xl"
          value={values.electricianSignoffDate}
          datePickerProps={{
            maxDate,
            minDate,
            onChange: handleDateChange,
            openToDate: electricianSignoffDate ?? undefined,
          }}
          textInputProps={{
            onClear: () => setFieldValue('electricianSignoffDate', ''),
          }}
        />
      </Box>
    </FormSection>
  );
};
