import { Box, Button, Modal, SelectInput, toast } from '@palmetto/palmetto-components';
import { FormikValues, useFormik } from 'formik';
import { SyntheticEvent } from 'react';
import * as yup from 'yup';
import {
  useCreateOrganizationEnergyCommunityMutation,
  useGetModifiersQuery,
} from '../../../../../../services/pricing';
import { useParams } from 'react-router-dom';
import { useGetUtilitiesQuery } from '../../../../../../services/utilities';
import { ProgramType } from 'types';

export const CreateEnergyCommunity = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
}) => {
  const [createOrganizationPricingEnergyCommunityMutation] = useCreateOrganizationEnergyCommunityMutation();
  const { alias = '' } = useParams<{ alias: string }>();
  const { data: energyCommunityAdders = [] } = useGetModifiersQuery({
    type: 'energyCommunity',
    programType: ProgramType.solar,
  });
  const filter = [
    { id: 'programType', operation: 'Equal', selectedValues: [ProgramType.solar, ProgramType.newHomes] },
  ];
  const { data: utilities = [] } = useGetUtilitiesQuery({
    advancedFilters: JSON.stringify(filter),
  });
  const formik = useFormik({
    initialValues: {
      energyCommunity: {},
      state: [],
    },
    validationSchema: yup.object({
      energyCommunity: yup.object().required('Energy Community is required'),
    }),
    onSubmit: async (values: FormikValues, { setSubmitting, resetForm }) => {
      try {
        const states = values.state.map((state: { label: string; value: string }) => state.value);
        await createOrganizationPricingEnergyCommunityMutation({
          alias,
          data: { energyCommunityId: values.energyCommunity.value, states },
        }).unwrap();
        toast.success('Energy Community added successfully');
        resetForm();
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Error adding Energy Community');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const stateUtilityMapping = utilities.reduce(
    (acc, utility) => {
      acc[utility.state] = acc[utility.state] ?? [];
      acc[utility.state].push(utility);
      return acc;
    },
    {} as Record<string, any[]>,
  );

  const stateOptions = Object.keys(stateUtilityMapping)
    .sort()
    .map((state) => ({ label: state, value: state }));

  return (
    <Modal ariaLabelledBy="crateEnergyCommunity" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header id="createEnergyCommunity" title="Add Energy Community" onDismiss={closeModal} />
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body background="secondary">
          <Box direction="column" childGap="xl">
            {energyCommunityAdders && (
              <SelectInput
                label="Energy Community"
                name="energyCommunity"
                id="energyCommunity"
                value={formik.values.energyCommunity}
                onChange={(event) => formik.setFieldValue('energyCommunity', event.target.value)}
                options={energyCommunityAdders.map((energyCommunity: any) => ({
                  label: energyCommunity.name,
                  value: energyCommunity.id,
                }))}
                isRequired
                onBlur={formik.handleBlur}
                error={formik.touched.energyCommunity && (formik.errors.energyCommunity as string)}
                menuPortalTarget={document.body}
              />
            )}
            <Box>
              <SelectInput
                id="state"
                name="state"
                label="State"
                value={formik.values.state}
                onChange={(event) => {
                  formik.setFieldValue('state', event.target.value);
                }}
                options={stateOptions}
                onBlur={formik.handleBlur}
                isDisabled={formik.isSubmitting}
                error={formik.touched.state && (formik.errors.state as string)}
                menuPortalTarget={document.body}
                isMulti
              />
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            as="button"
            onClick={() => {
              formik.resetForm();
              closeModal();
            }}
            variant="secondary"
            tone="neutral"
            size="md"
            isLoading={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button variant="primary" size="md" type="submit" isLoading={formik.isSubmitting}>
            Add Energy Community
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
