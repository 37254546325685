import { Box, Button, Drawer, TabsSlider, useBreakpoint } from '@palmetto/palmetto-components';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddNoteModal } from './AddNoteModal';
import { useAddNoteToQueueMutation, useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import { NoteHistoryItem, NoteHistoryItemProps } from './NoteHistoryItem';
import { mapQueueHistoryOrNote } from './mapQueueHistoryAndNotes';
import { DateHeader } from './DateHeader';
import { QueueType } from 'types';

export const NotesDrawer = ({
  isOpen,
  setIsOpen,
  queueType,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  queueType: QueueType;
}) => {
  const { id: accountId } = useParams<{ id: any }>();
  const { data: queue, isLoading: queueIsLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId,
    type: queueType,
  });
  const [addNote] = useAddNoteToQueueMutation();
  const [isAddNoteOpen, setIsAddNoteOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { isPhone, isTablet } = useBreakpoint();
  const width = isPhone || isTablet ? '100%' : undefined;

  const combinedData = useMemo(() => {
    const history = activeTab !== 1 ? queue?.history : [];
    const notes = activeTab !== 2 ? queue?.notes : [];
    return [...(notes ?? []), ...(history ?? [])]
      .sort((a, b) => (b.date === a.date ? 0 : b.date < a.date ? -1 : 1))
      .map((item) => mapQueueHistoryOrNote(item, queue))
      .reduce((acc, item) => {
        if (!acc[item.date]) acc[item.date] = [];
        acc[item.date].push(item);
        return acc;
      }, {} as any);
  }, [activeTab, queue]);

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const tabsSliderOnChange = useCallback((_event: MouseEvent, index: number) => {
    setActiveTab(index);
  }, []);

  const showAddNote = useCallback(() => {
    setIsAddNoteOpen(true);
  }, []);

  const saveNote = useCallback(
    async (note: string) => {
      if (!queue) return;
      await addNote({
        id: queue.id,
        data: { note },
      }).unwrap();
    },
    [addNote, queue],
  );

  const hideAddNote = useCallback(() => {
    setIsAddNoteOpen(false);
  }, []);

  return (
    !queueIsLoading && (
      <Drawer
        isOpen={isOpen}
        title="Review Notes"
        hideOverlay={!isTablet && !isPhone}
        width={width}
        onDismiss={closeDrawer}
      >
        <AddNoteModal isOpen={isAddNoteOpen} onDismiss={hideAddNote} onSave={saveNote} />
        <Box padding="lg" childGap="2xl">
          <Box direction="row" childGap="lg">
            <TabsSlider value={activeTab} onChange={tabsSliderOnChange} size="sm" flex="auto">
              <TabsSlider.Item key={0}>All</TabsSlider.Item>
              <TabsSlider.Item key={1}>Notes</TabsSlider.Item>
              <TabsSlider.Item key={2}>History</TabsSlider.Item>
            </TabsSlider>
            <Button variant="secondary" iconPrefix="pencil" size="sm" onClick={showAddNote}>
              Add Note
            </Button>
          </Box>
          <Box childGap="3xl">
            {Object.keys(combinedData).map((key) => (
              <Box key={key}>
                <DateHeader key={key} date={key} />
                {combinedData[key].map((item: NoteHistoryItemProps, index: number) => (
                  <NoteHistoryItem key={index} {...item} />
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Drawer>
    )
  );
};
