import { useGetAccountQuery } from '@/services/accounts';
import { ReviewPackageActions } from '@/types/ReviewPackage';
import { useParams } from 'react-router-dom';
import { ProgramType } from 'types';
import { InstallHVACMilestonePackage } from './InstallHVACMilestonePackage';
import { InstallMilestonePackage } from './InstallMilestonePackage';

export const InstallMilestonePackageFactory = () => {
  const { id } = useParams<{ id: any; action: ReviewPackageActions }>();
  const { data: account }: any = useGetAccountQuery(id);

  switch (account?.programType) {
    case ProgramType.hvac:
      return <InstallHVACMilestonePackage />;
    case ProgramType.solar:
    case ProgramType.doePr:
    case ProgramType.newHomes:
      return <InstallMilestonePackage />;
    default:
      return null;
  }
};
