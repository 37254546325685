import { Box } from '@palmetto/palmetto-components';
import { AllDocumentTypes, documentTitles } from 'types';
import EventBy from './EventBy';

const SabalDocumentApproved = ({ event }: { event: any }) => {
  const files = event.data?.document?.files?.map((file: any) => file.originalName).join(', ');
  const typeOfDocument: AllDocumentTypes = event.data?.document?.type;

  return (
    <Box display="block">
      <EventBy event={event} /> approved <strong>{documentTitles[typeOfDocument]}</strong> document{' '}
      <strong>{files}</strong>
    </Box>
  );
};

export default SabalDocumentApproved;
