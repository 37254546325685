import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useGetAccountPayoutEventsQuery } from '@/services/accountPayoutEvents';
import { Column, Table } from '@palmetto/palmetto-components';

export const AccountPayoutEventErrors = () => {
  const { data: transactions, isLoading: isTransactionsLoading } = useGetAccountPayoutEventsQuery();
  const columns: Column[] = [
    {
      heading: 'Timestamp',
      dataKey: 'timestamp',
    },
    {
      heading: 'Account Id',
      dataKey: 'accountId',
      render: (cell: any) => (
        <Link to={`/accounts/${cell}`} target="_blank">
          {cell}
        </Link>
      ),
    },
    {
      heading: 'Event',
      dataKey: 'event',
    },
    {
      heading: 'Event Type',
      dataKey: 'eventType',
    },
    { heading: 'Amount', dataKey: 'amount', render: (cell: any) => (cell ? `$${cell}` : '--') },
    {
      heading: 'Latest History',
      dataKey: 'history',
      render: (historyData: any) =>
        historyData && historyData.length ? historyData[historyData.length - 1]?.summary : '--',
    },
  ];
  return (
    <>
      <Helmet>
        <title>Account Payout Event Errors</title>
      </Helmet>
      {transactions && transactions?.length > 0 ? (
        <Table rowKey="id" isLoading={isTransactionsLoading} columns={columns as Column[]} rows={transactions} />
      ) : (
        'nothing to see here!'
      )}
    </>
  );
};
