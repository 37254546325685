import { Box } from '@palmetto/palmetto-components';

export const PropertyValue = ({ label, value }: { label: string; value: string | undefined }) => (
  <Box
    direction="row"
    alignItems="baseline"
    justifyContent="space-between"
    borderWidth="0 0 xs 0"
    borderColor="separator"
    padding="0 0 xs 0"
    gap="md"
  >
    <Box minWidth={{ desktop: '2xl' }} fontWeight="medium">
      {label}
    </Box>
    <Box direction="row" childGap="xs" alignItems="center">
      {value}
    </Box>
  </Box>
);
