import { PaymentPlan, PaymentPlanOrgs, ProgramType, WithObjectIdsAsString } from 'types';
import { api } from './api';

export const paymentPlansApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaymentPlans: build.query<WithObjectIdsAsString<PaymentPlan>[], { programType: ProgramType }>({
      query: ({ programType }) => ({ url: `payment-plans?programType=${programType}` }),
      providesTags: (_result, _error, args) => {
        return [{ type: 'PaymentPlans', id: `LIST` }];
      },
    }),
    getPaymentPlan: build.query<WithObjectIdsAsString<PaymentPlan>, { id: string }>({
      query: ({ id }) => ({ url: `payment-plans/${id}` }),
      providesTags: (_result, _error, args) => {
        return [{ type: 'PaymentPlans', id: `LIST` }];
      },
    }),
    getPaymentPlanOrgs: build.query<WithObjectIdsAsString<PaymentPlanOrgs>[], { id: string }>({
      query: ({ id }) => ({ url: `admin/payment-plan/${id}/orgs` }),
      providesTags: (_result, _error, args) => {
        return [{ type: 'PaymentPlansOrgs', id: `LIST` }];
      },
    }),
    createPaymentPlan: build.mutation<WithObjectIdsAsString<PaymentPlan>, PaymentPlan>({
      query: (body) => ({ url: `payment-plans`, method: 'POST', body }),
      invalidatesTags: [{ type: 'PaymentPlans', id: 'LIST' }],
    }),
    updatePaymentPlan: build.mutation<WithObjectIdsAsString<PaymentPlan>, { id: string; body: PaymentPlan }>({
      query: ({ id, body }) => ({
        url: `payment-plans/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'PaymentPlans', id: 'LIST' },
        { type: 'PaymentPlans', id: _result?.id },
      ],
    }),
  }),
});

export const {
  useGetPaymentPlansQuery,
  useGetPaymentPlanQuery,
  useGetPaymentPlanOrgsQuery,
  useCreatePaymentPlanMutation,
  useUpdatePaymentPlanMutation,
} = paymentPlansApi;
