import FormSection from '@/components/Forms/FormSection'
import { PhotosList } from '../Documents/PhotosList'
import { InstallationPackagePhotoCategory } from 'types'
import { Box } from '@palmetto/palmetto-components'
import { Link } from 'react-router-dom'

export const SystemPhotos = () => {
	return (
		<FormSection title="New System Photos" description={<><Box>Upload photos to all applicable categories.</Box><Box>Reference <Link to={''}>Install Photo Requirements documentation</Link> for specifics on submission requirements </Box></>}>
			<PhotosList
				title="Project Site"
				description={<Box>Home, with home number visible</Box> as unknown as string}
				category={InstallationPackagePhotoCategory.projectSite}
				baseCategory="Installation Photo Documentation" />
			<PhotosList
				title="HVAC Installation"
				description={<><Box>Equipment, interior and exterior, any duct work</Box><Box>Includes photos of electrical upgrades</Box></> as unknown as string}
				category={InstallationPackagePhotoCategory.hvacInstallation}
				baseCategory="Installation Photo Documentation" />
			<PhotosList
				title="Voltage Readings"
				description='Photo of the voltage reading of the units'
				category={InstallationPackagePhotoCategory.voltage}
				baseCategory="Installation Photo Documentation" />
			<PhotosList
				title="Static Pressure Readings"
				description='Photo of the static pressure test, if applicable'
				category={InstallationPackagePhotoCategory.staticPressure}
				baseCategory="Installation Photo Documentation" />
			<PhotosList
				title="Laser Therm Reading"
				description='Photo of the thermometer showing that the interior unit(s) are producing air of a certain necessary temp'
				category={InstallationPackagePhotoCategory.laserTherm}
				baseCategory="Installation Photo Documentation" />
		</FormSection>
	)
}
