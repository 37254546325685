import { HiddenLightReachInfoForEpcUser } from "@/components/auth/HiddenLightReachInfoToEpcUser";

const palmettoFinanceOrgAlias = 'sabal';

const EventBy = ({ event }: { event: any }) => {
  const {
    performedBy: { displayName, organizationId },
  } = event;

  const isEpcUser = organizationId && organizationId !== palmettoFinanceOrgAlias || false;

  return <HiddenLightReachInfoForEpcUser children={<strong>{displayName}</strong>} fallbackChildren={isEpcUser ?
    <strong>{displayName}</strong> :
    <strong>LightReach Support</strong>} />;
};

export default EventBy;
