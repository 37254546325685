export const HTTP_STATUS_CODES = {
  SUCCESS_OK: 200,
  RESOURCE_CREATED: 201,
  NO_CONTENT_SUCCESS: 204,
  CLIENT_ERROR_BAD_REQUEST: 400,
  CLIENT_ERROR_UNAUTHORIZED: 401,
  CLIENT_ERROR_FORBIDDEN: 403,
  CLIENT_ERROR_NOT_FOUND: 404,
  CLIENT_ERROR_CONFLICT: 409,
  CLIENT_ERROR_ENTITY_TOO_LARGE: 413,
  SERVER_ERROR_INTERNAL: 500,
  SERVER_ERROR_SERVICE_UNAVAILABLE: 503,
};
