import { api } from '@/services/api';
import { AccountPayoutEventDocument } from 'types';

export const accountPayoutEvents = api.injectEndpoints({
  endpoints: (build) => ({
    getAccountPayoutEvents: build.query<AccountPayoutEventDocument[], void>({
      query: () => ({ url: `payouts/account-events/errors` }),
      providesTags: (_result, _error, _args) => [{ type: 'AccountPayoutEvents', id: 'LIST' }],
    }),
  }),
});

export const { useGetAccountPayoutEventsQuery } = accountPayoutEvents;
