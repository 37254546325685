import { PaymentPlanTable } from '../PaymentPlanTable';
import { ProgramType } from 'types';
import { useCreatePaymentPlanMutation, useGetPaymentPlansQuery } from '@/services/paymentPlans';
import { Box, Button, Card, toast } from '@palmetto/palmetto-components';
import { useCallback, useState } from 'react';
import { PaymentPlanEditModal } from './PaymentPlanEditModal';
import { isErrorWithData } from '@/services/helpers';

export const PaymentPlans = ({ programType }: { programType: ProgramType }) => {
  const { data: paymentPlans, isLoading: isLoadingPaymentPlans } = useGetPaymentPlansQuery({
    programType,
  });
  const [createPaymentPlanMutation] = useCreatePaymentPlanMutation();
  const [isModalShowing, setIsModalShowing] = useState(false);
  const showAddPaymentPlanModal = useCallback(() => {
    setIsModalShowing(true);
  }, [setIsModalShowing]);
  const hideAddPaymentPlanModal = useCallback(() => {
    setIsModalShowing(false);
  }, [setIsModalShowing]);
  const handleSubmit = useCallback(async (values: any) => {
    try {
      await createPaymentPlanMutation(values).unwrap();
      hideAddPaymentPlanModal();
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error creating payment plan: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error getting payment plan');
      }
    }
  }, []);
  return (
    !isLoadingPaymentPlans &&
    paymentPlans && (
      <Card>
        <PaymentPlanEditModal
          isOpen={isModalShowing}
          handleClose={hideAddPaymentPlanModal}
          handleSubmit={handleSubmit}
          programType={programType}
          modalId="addPaymentPlan"
          modalTitle="Add Payment Plan"
          submitButtonText="Add Payment Plan"
        />
        <Card.Header>
          <Box direction="row" justifyContent="space-between" fontSize="md">
            <Box>Payment Plans</Box>
            <Box>
              <Button iconPrefix="add" variant="secondary" tone="primary" onClick={showAddPaymentPlanModal}>
                Payment Plan
              </Button>
            </Box>
          </Box>
        </Card.Header>
        <PaymentPlanTable programType={programType} paymentPlans={paymentPlans} paymentPlanClickable />
      </Card>
    )
  );
};
