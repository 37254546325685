import { Box, Button, Modal, ModalProps } from '@palmetto/palmetto-components';

export interface VoidConfirmationModalProps extends ModalProps {
  /**
   * @description The confirmation call for voiding request.
   */
  onVoidRequest: () => void;
  isVoiding: boolean;
  cta2Text?: string;
}

/**
 * @description This is the confirmation modal shown before confirming the
 * voidance of the contract on an account.
 */
export function VoidConfirmationModal({
  isOpen,
  isVoiding,
  onDismiss,
  onVoidRequest,
  cta2Text,
}: VoidConfirmationModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth="4xl" ariaLabelledBy="confirmContractVoiding">
      <Modal.Header
        id="confirmContractVoiding"
        title="Void Contract and Update Production Value?"
        onDismiss={onDismiss}
      />
      <Modal.Body background="secondary" childGap="lg">
        <Box fontSize={'sm'} color="body-primary" data-testid="void-confirmation-modal-body">
          Are you sure you want to void the contract and update the year-1 estimated production value?
          <br />
          <br />
          This can not be undone.
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Box justifyContent={'space-between'} display={'flex'} direction={'row'} width={'100'}>
          <Button
            variant="secondary"
            tone="neutral"
            disabled={isVoiding}
            onClick={onDismiss}
            data-testid="void-confirmation-modal-cancel-cta"
          >
            <Box fontWeight={'bold'}>Cancel</Box>
          </Button>
          <Button
            variant="primary"
            tone="danger"
            isLoading={isVoiding}
            onClick={onVoidRequest}
            data-testid="void-confirmation-modal-confirm-cta"
          >
            <Box>{cta2Text || 'Void Contract and Update Year-1 Production'}</Box>
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
}
