import { Box } from '@palmetto/palmetto-components';
import { useContext, useMemo } from 'react';
import { QueueFlagsContext } from './QueueFlagsContext';
import { DocumentFile } from 'types';

interface FlagBoxProps {
  displayWithNoQueue?: boolean;
  isLast?: boolean;
  files: DocumentFile[];
  itemId: string;
}

export const FlaggedListItems = ({ displayWithNoQueue = false, files, itemId }: FlagBoxProps) => {
  const { getFlag, shouldHide } = useContext(QueueFlagsContext);
  const flaggedFiles = useMemo(() => {
    return files.filter((file: DocumentFile, index: number) => {
      const dataKeyWithSuffix = `${itemId}-${index}`;
      return getFlag([dataKeyWithSuffix, itemId], file.originalName, false);
    });
  }, [files, getFlag, itemId]);

  return flaggedFiles.length > 0 || !shouldHide || displayWithNoQueue ? (
    <Box>
      <Box fontWeight="medium">Flagged items</Box>
      {flaggedFiles.map((file, index) => (
        <Box key={`${file.md5Hash}-${index}`} fontWeight="light">
          {file.originalName}
        </Box>
      ))}
    </Box>
  ) : null;
};
