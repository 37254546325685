import { Box, Button, Modal, toast } from '@palmetto/palmetto-components';
import { useUpdateQueueMutation } from '../../services/queues';
import { useCallback, useState } from 'react';

interface RemoveClaimModalProps {
  isModalOpen: boolean;
  closeModal: any;
  data?: Record<string, string>;
}

export const RemoveClaimModal = ({ isModalOpen, closeModal, data }: RemoveClaimModalProps) => {
  const [updateQueue] = useUpdateQueueMutation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const removeClaim = useCallback(async () => {
    setIsSubmitting(true);
    await updateQueue({
      data: { assigneeId: null },
      id: data?.id as string,
    }).unwrap();
    setIsSubmitting(false);
    closeModal();
    toast.success('Queue unclaimed');
  }, [closeModal, updateQueue, data]);

  return (
    <Modal ariaLabelledBy="titleBasic" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="4xl">
      <Modal.Header id="customPortalModal" title="Remove Claim" onDismiss={closeModal} />
      <Modal.Body background="secondary" childGap="lg" fontSize="sm">
        <Box>Are you sure you wish to remove {data?.assigneeName}?</Box>
      </Modal.Body>
      <Modal.Footer>
        <Box direction="row" alignItems="flex-end" childGap="sm">
          <Button
            variant="secondary"
            tone="neutral"
            onClick={() => {
              closeModal();
            }}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={removeClaim}
          >
            Remove Claim
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};
