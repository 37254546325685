import { Box, Card, FormikCheckboxInput, Icon } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { DisclosureDefinition } from 'types/dist/Disclosures';

export interface DisclosureCardProps {
  disclosure: DisclosureDefinition;
  isLoading?: boolean;
  disclosureProperty: string;
}

export const DisclosureCard = ({ disclosure, isLoading, disclosureProperty }: DisclosureCardProps) => {
  const paragraphs = disclosure.text.split('/n/n');
  const { values, setFieldValue, isSubmitting } = useFormikContext<any>();

  const setConsentChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(disclosureProperty, event.target.checked);
    },
    [setFieldValue],
  );
  return (
    <Card key={disclosure.id?.toString()}>
      <Card.Header title={disclosure.name} />
      <Card.Section background="secondary">
        {paragraphs.map((paragraph, index) => (
          <Box key={`${disclosure.id}-${index}`} as="p" margin="0 0 sm 0">
            {paragraph}
          </Box>
        ))}
      </Card.Section>
      <Card.Section>
        {disclosure.requireConsent ? (
          <Field
            type="number"
            label={disclosure.acceptanceLabel}
            name={disclosureProperty}
            id={disclosureProperty}
            component={FormikCheckboxInput}
            isChecked={values?.creditApplicationDisclosure}
            isRequired
            onChange={setConsentChecked}
            isDisabled={isSubmitting || isLoading}
          />
        ) : (
          disclosure.acceptanceLabel && (
            <Box direction="row" color="primary" fontSize="md" fontWeight="medium" childGap="md">
              <Icon name="arrow-down" size="lg" />
              <Box>{disclosure.acceptanceLabel}</Box>
            </Box>
          )
        )}
      </Card.Section>
    </Card>
  );
};
