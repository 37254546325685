import { useSearchParams } from 'react-router-dom';

interface InviteTokenHook {
  isInviteToken: boolean;
  inviteToken: string | null;
}

export const useInviteToken = (): InviteTokenHook => {
  const [ queryParams ] = useSearchParams();
  const inviteToken = queryParams.get('inviteToken');

  const isInviteToken = !!inviteToken;

  return { isInviteToken, inviteToken };
};
