import { Box } from '@palmetto/palmetto-components';

const Email = ({ event }: { event: any }) => {
  const toEmail = event.data?.source?.input?.channel?.address;
  const templateName = event.data?.source?.template?.name;
  const templateDescription = event.data?.source?.template?.description;
  return (
    <Box display="block">
      The{' '}
      <Box as="span" display="inline-block" fontWeight="bold" margin="0 2xs 0 0" title={templateDescription}>
        {templateName}
      </Box>
      email was sent to{' '}
      <Box as="span" display="inline-block" fontWeight="bold" margin="0 2xs 0 0">
        {toEmail}
      </Box>
    </Box>
  );
};

export default Email;
