import { AccountRequirementStatus, TaskStatus } from 'types';

export const getRemainingRequirements = (currentMilestone: any) => {
  const outstandingRequirements: any[] = [];
  const incompleteRequirements =
    currentMilestone?.requirements?.filter(
      (requirement: any) => requirement.status !== AccountRequirementStatus.completed,
    ) || [];

  for (const req of incompleteRequirements) {
    if (req?.requirements && req?.requirements?.length > 0) {
      for (const subReq of req.requirements) {
        if (subReq.status !== TaskStatus.completed) {
          if (subReq.isRequired) {
            outstandingRequirements.push(subReq);
          } else {
            if (subReq.status !== TaskStatus.pending) {
              outstandingRequirements.push(subReq);
            }
          }
        }
      }
    } else {
      if (req.status !== TaskStatus.completed) {
        if (req.isRequired) {
          outstandingRequirements.push(req);
        } else {
          if (req.status !== TaskStatus.pending) {
            outstandingRequirements.push(req);
          }
        }
      }
    }
  }

  return outstandingRequirements;
};
