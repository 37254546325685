import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuth } from '../auth/authProvider';

/**
 * @description Checks whether required flags are found and active or not
 * @param required The flags required
 */
export function useHasEnabledFlag(required: string[]) {
  const flags = useFlags();
  return () => {
    const hasAllRequiredFlags = !required.find((element) => !flags[element]);
    return hasAllRequiredFlags;
  };
}


/**
 * @description Checks whether required flags are found and active or not
 * when a user is loaded (otherwise returns undefined).
 * @param required The flags required
 */
export const useHasEnabledNonAnonFlag = (required: string[]) => {
  const flags = useFlags();
  const { authenticatedLDContext } = useAuth();
  const checkFlags = () => {
    if (authenticatedLDContext && !authenticatedLDContext?.anonymous) {
      const hasAllRequiredFlags = !required.find((element) => !flags[element]);
      return hasAllRequiredFlags;
    }
    return undefined;
  }

  return { checkFlags, context: authenticatedLDContext }
}