import { Box } from '@palmetto/palmetto-components';
import { AdderDetails, SystemComponent } from 'types';

const capitalizeFirstLetter = (title: string) => {
  return title.charAt(0).toUpperCase() + title.slice(1);
};

const transformCamelCase = (value: any): string | number => {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value !== 'string' || value === '' || value === null || value === undefined) {
    return '-';
  }
  if (value === value.toUpperCase()) {
    return value;
  }
  const transformedValue = value.replace(/([A-Z])/g, ' $1');
  return capitalizeFirstLetter(transformedValue.trim());
};

type InverterAndAddersListProps = {
  inverter?: SystemComponent;
  position: number;
  adders?: AdderDetails;
};

export const InverterAndAddersList = ({ inverter, position, adders }: InverterAndAddersListProps) => {
  const dataToDisplay = inverter || adders || [];
  return (
    <Box columnGap="2xl" margin="0 0 md 0" direction={{ base: 'column', tablet: 'row' }} wrap>
      {Object.entries(dataToDisplay).map(([key, value], index) => {
        const isManufacturer = key === 'manufacturer';
        const shouldDisplay =
          adders ||
          (position === 0 && isManufacturer) ||
          (position === 0 && !isManufacturer) ||
          (position > 0 && !isManufacturer);
        if (shouldDisplay) {
          return (
            <Box
              display="flex"
              direction="column"
              gap="sm"
              key={index + value}
              width={adders ? '2xl' : position === 0 && isManufacturer ? '5xl' : '3xl'}
              margin="0 0 md 0"
            >
              <Box fontSize={adders ? 'sm' : undefined} fontWeight="light">
                {capitalizeFirstLetter(key)}
              </Box>
              <Box fontSize="lg">{transformCamelCase(value)}</Box>
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};
