import { Button, Modal } from '@palmetto/palmetto-components';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { ReactNode, SyntheticEvent, useCallback, useMemo } from 'react';
import { BulkEditPricingFormSection } from './BulkEditPricingFormSection';
import { BulkEditModifiersFormSection } from './BulkEditModifiersFormSection';
import { BulkEditAddersFormSection } from './BulkEditAddersFormSection';

const dataKeyToFormMap: Record<string, ReactNode> = {
  priceSheetName: <BulkEditPricingFormSection />,
  escalationRateMin: <BulkEditPricingFormSection />,
  escalationRateMax: <BulkEditPricingFormSection />,
  pvOnlyMin: <BulkEditPricingFormSection />,
  pvOnlyCeiling: <BulkEditPricingFormSection />,
  zeroEscalatorCeiling: <BulkEditPricingFormSection />,
  absoluteCeiling: <BulkEditPricingFormSection />,
  constructionAdders: <BulkEditAddersFormSection />,
  modifiers: <BulkEditModifiersFormSection />,
};

interface BulkEditModalProps {
  isModalOpen: boolean;
  dataKey: string;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
  setEditValues: (values: any) => void;
  initialValues: Record<string, string>;
}

export const BulkEditModal = ({
  isModalOpen,
  dataKey,
  closeModal,
  setEditValues,
  initialValues,
}: BulkEditModalProps) => {
  const onSubmit = useCallback(
    async (values: FormikValues, { setSubmitting, resetForm }: FormikHelpers<FormikValues>) => {
      try {
        setEditValues(values);
        resetForm();
        closeModal();
        
      } catch (e) {
        console.log(e);
      } finally {
        setSubmitting(false);
      }
    },
    [closeModal, setEditValues],
  );
  const title = useMemo(
    () =>
      dataKey === 'modifiers' ? 'Modifiers' : dataKey === 'constructionAdders' ? 'Construction Adders' : 'Pricing',
    [dataKey],
  );

  const formSection = useMemo(() => dataKeyToFormMap[dataKey], [dataKey]);
  
  return (
    <Modal ariaLabelledBy="bulkEdit" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header id="bulkEdit" title={`Bulk Edit ${title}`} onDismiss={closeModal} />
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        {({ resetForm, isSubmitting }) => {
          return (
            <Form>
              <Modal.Body background="secondary">{formSection}</Modal.Body>
              <Modal.Footer>
                <Button
                  as="button"
                  onClick={() => {
                    resetForm();
                    closeModal();
                  }}
                  variant="secondary"
                  tone="neutral"
                  size="md"
                  isLoading={isSubmitting}
                >
                  Cancel
                </Button>
                <Button variant="primary" size="md" type="submit" isLoading={isSubmitting}>
                  Done
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
