import React, { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, toast, Spinner } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../PageHeader';
import { OrganizationForm, OrganizationFormSchema } from './OrganizationForm';

import {
  useEditOrganizationMutation,
  useGetOrganizationDetailsQuery,
  useGetOrganizationTreeQuery,
} from '../../../services/organizations';
import { flattenToOptions } from '../../../helpers/flattenOrgs';
import { MainContainer } from '../../MainContainer';

export const filterOutDisabledFields = (account: any, disabledFields: string[]): any => {
  const filtered = Object.keys(account);
  const filteredObject = filtered
    .filter((key) => !disabledFields.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: account[key as keyof OrganizationFormSchema],
      };
    }, {});
  return filteredObject;
};

const disabledFields = ['alias', 'parent', 'organizationTypes'];

export const EditOrganization = () => {
  const { alias } = useParams<{ alias: any }>();
  const navigate = useNavigate();
  const { data: org, isLoading: isLoadingOrg } = useGetOrganizationDetailsQuery({ alias });
  const { data: { tree = [] } = {}, isLoading: isLoadingOrgs } = useGetOrganizationTreeQuery();
  const [editOrganization] = useEditOrganizationMutation();

  const isLoading = isLoadingOrg || isLoadingOrgs;
  // TODO: fix this
  const parentOptions = useMemo(() => {
    return flattenToOptions(tree || []);
  }, [tree]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const confirmed = window.confirm(
      'By clicking submit, I acknowledge that the information I am submitting is true and accurate and that any misrepresentation shall constitute a breach of the partnership agreement.',
    );

    if (!confirmed) return;

    try {
      setSubmitting(true);

      const { address1: address, stateLicense: licenses, ...rest } = values;

      const result = await editOrganization({
        organization: filterOutDisabledFields(
          {
            licenses,
            id: org?.id,
            address,
            ...rest,
          },
          disabledFields,
        ),
        alias,
      });
      if ('error' in result === false) {
        toast.success('Organization modified successfully');
        navigate(`/settings/organizations/${alias}`);
      } else {
        if ((result as any).error?.data?.errors) {
          toast.error((result as any).error?.data?.errors.join(', '));
        } else {
          toast.error('Error updating organization');
        }
      }
    } catch (_e) {
      toast.error('Error updating organization');
    }
    setSubmitting(false);
  };

  const initialValues = useMemo(
    () => ({
      name: org?.name,
      alias: org?.alias,
      parent: parentOptions.find((p) => p.value === org?.parentAlias) || {
        label: `${org?.parentAlias})`,
        value: org?.parentAlias,
      },
      email: org?.email,
      phoneNumber: org?.phoneNumber,
      federalEmployerId: org?.federalEmployerId,
      insuranceCompanyName: org?.insuranceCompanyName,
      insuranceCompanyPhoneNumber: org?.insuranceCompanyPhoneNumber,
      address1: org?.address,
      city: org?.city,
      state: org?.state,
      zip: org?.zip,
      authorizedSigners: org?.authorizedSigners,
      stateLicense: org?.stateLicenseNumbers,
      programTypes: org?.programTypes,
      organizationTypes: org?.organizationTypes,
      extendedWarrantyDealerInformation: org?.extendedWarrantyDealerInformation,
    }),
    [org, parentOptions],
  );

  return (
    <>
      <Helmet>
        <title>Edit Organization</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          title="Edit Organization"
          eyebrow={<Link to={`/settings/organizations/${alias}`}>{org?.name || '...'}</Link>}
        />
        <Box padding={{ base: '0 lg', tablet: '0' }}>
          {!isLoading ? (
            <OrganizationForm
              initialValues={initialValues}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              parentOptions={parentOptions}
              disabledFields={disabledFields}
              cancelButtonUrl={`/settings/organizations/${alias}`}
            />
          ) : (
            <Spinner size="lg" />
          )}
        </Box>
      </MainContainer>
    </>
  );
};
