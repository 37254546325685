import { ObjectId } from 'mongodb';
import { Cents, Decimal, IsoDateString } from './Helpers';
import { FinanceType } from './ProductPricing';
import { AdditionalCostAdderType } from './Adders';

export enum LineItemTypes {
  product = 'product',
  // These are items that add to the total but are not products, (i.e. a late payment fee)
  lateFee = 'lateFee',
  backupBattery = 'backupBattery',
  solarReadiness = 'solarReadiness',
}

export enum LineItemCategories {
  lease = 'lease',
  ppa = 'ppa',
  backupBattery = 'backupBattery',
}

export const productFinanceTypeToLineItemCategoryMap = {
  [FinanceType.lease]: LineItemCategories.lease,
  [FinanceType.ppa]: LineItemCategories.ppa,
  [AdditionalCostAdderType.backupBattery]: LineItemCategories.backupBattery,
};

export const adderTypeToLineItemCategoryMap = {
  [AdditionalCostAdderType.backupBattery]: LineItemTypes.backupBattery,
  [AdditionalCostAdderType.solarReadiness]: LineItemTypes.solarReadiness,
};

export const adderLineItemTypeToNameMap = {
  [AdditionalCostAdderType.backupBattery]: 'Backup Battery Service',
  [AdditionalCostAdderType.solarReadiness]: 'Solar Readiness',
};

export enum InboundPaymentEvent {
  create = 'create',
  update = 'update',
  recalculate = 'recalculate',
  error = 'error',
}

export enum PaymentStatus {
  /**
   * The payment object was backfilled but no existing payments or invoices were found
   * recommend manual
   */
  backfilled = 'backfilled',

  /**
   * The payment object is waiting to be sent to the customer
   * at the appropriate date.
   */
  pending = 'pending',

  /**
   * The payment object has been sent to the customer
   */
  invoiced = 'invoiced',

  /**
   * The payment has been requested from the customer
   */
  paymentRequested = 'paymentRequested',

  completed = 'completed',
  failed = 'failed',
}

export enum ScheduleStatus {
  active = 'active',
  cancelled = 'cancelled',
  completed = 'completed',
}

export enum PaymentMethod {
  creditCard = 'creditCard',
  ach = 'ach',
  check = 'check',
  cash = 'cash',
  other = 'other',
  none = 'none',
}

export enum ScheduleInterval {
  month = 'month',
}

export enum SyncStatus {
  pending = 'pending',
  completed = 'completed',
  failed = 'failed',
}

export interface InboundPaymentEventLog {
  date: IsoDateString | null;
  event: InboundPaymentEvent;
  details?: string;
  data?: Record<string, string>;
  initiatedBy?: string;
}

export enum InboundPaymentOperation {
  sendInvoice = 'sendInvoice',
  providerFailedInvoicePayment = 'providerFailedInvoicePayment',
  collectPayment = 'collectPayment',
  inboundPaymentScheduleCreation = 'inboundPaymentScheduleCreation',
  reviseInvoice = 'reviseInvoice',
}

// Need to add some concept of order of operations (i.e. the credit card fee is applied to the total after all other line items)
export interface LineItem {
  id: ObjectId;
  name: string;
  // This is the total amount of the line item, including taxes and quantities.
  amount: Cents;
  // This is the number of units of the line item.
  quantity: number;
  // If a line item is a fee that is percentage based, this represents the percentage.
  percentage?: Decimal;
  // This is the amount pre-tax
  subTotal: Cents;
  type: LineItemTypes;
  category: LineItemCategories;
  references: {
    paymentProviderLineItemId?: string;
    paymentProviderTaxCode: string;
    paymentProviderProductId?: string;
    productId?: ObjectId;
  };
}

export type PreTaxLineItem = Omit<LineItem, 'amount' | 'references'> & {
  references: Omit<LineItem['references'], 'paymentProviderTaxCode'>;
};

export interface InboundPayment {
  id: ObjectId;
  instanceCount: number;
  // This is the total amount of the payment, including taxes.
  amount: Cents;
  // This is the amount pre-tax
  subTotal: Cents;
  lineItems: LineItem[];
  state: {
    paymentStatus: PaymentStatus;
    paymentStatusReason?: string;
    isPastDue?: boolean;
    netSuiteSyncStatus: SyncStatus;
    salesforceSyncStatus: SyncStatus;
    paymentCollectionAttempts: number;
  };
  completedDate?: IsoDateString;
  invoiceSentDate?: IsoDateString;
  invoiceDate: IsoDateString;
  dueDate: IsoDateString;
  gracePeriodEndDate: IsoDateString;
  paymentModifiers: PaymentModifiers;
  references: {
    paymentProvider: {
      paymentId?: string;
      customerId: string;
      invoiceId?: string;
      paymentMethodId?: string;
    };
    accountingProvider?: {
      paymentId?: string;
      invoiceId?: string;
    };
    accountId: ObjectId;
    paymentScheduleId: ObjectId;
  };
  eventLog: InboundPaymentEventLog[];
}

export interface PaymentModifiers {
  paymentMethod?: PaymentMethod;
  isAutopayEnabled?: boolean;
  escalationRate?: number;
  kwhRate?: number;
  panelDegredationRate?: number;
  monthlyProductionKwH?: number;
  region?: string;
}

export interface ScheduleAttributes {
  interval: ScheduleInterval;
  duration: number;
  escalationInterval: number;
  customBillingDay?: number;
}

export interface InboundPaymentSchedule {
  id: ObjectId;
  scheduleAttributes: ScheduleAttributes;
  paymentModifiers: Omit<PaymentModifiers, 'paymentMethod' | 'isAutopayEnabled'>;
  schedule: {
    amount: Cents;
    startInterval: number;
    endInterval: number;
  }[];
  adderSchedule?: {
    amount: Cents;
    type: AdditionalCostAdderType;
    startInterval: number;
    endInterval: number;
  }[];
  solarSystemDetails: {
    systemSizeKw: number;
    systemFirstYearProductionKwh: number;
  };
  state: {
    currentInterval: number;
    status: ScheduleStatus;
  };
  references: {
    paymentProvider?: {
      customerId?: string;
      productIds?: string[];
    };
    accountingProvider?: {
      customerAccountId?: string;
    };
    accountId: ObjectId;
    productIds: ObjectId[];
    previousScheduleId?: ObjectId;
  };
  eventLog: InboundPaymentEventLog[];
}
