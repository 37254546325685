import { Box, Details, useOpenClose } from '@palmetto/palmetto-components';
import EventBy from './EventBy';

const ActivationPackageDefaultEvent = ({ event, action }: { event: any, action: string}) => {
  const { isOpen, handleToggle } = useOpenClose();

  return (
    <Box flex="auto">
      <Box display="block">
        <Details isOpen={isOpen}>
          <Details.Summary isDetailsOpen={isOpen} onToggle={handleToggle} display="inline">
            <Box as="span" display="inline-block" fontWeight="bold" margin="0 2xs 0 0">
              {action}
            </Box>
          </Details.Summary>
          <Box margin="md 0" padding="md 0" childGap="sm" overflow="scroll" maxHeight="400px" background="white">
            <pre>
              <code>{JSON.stringify(event.data, null, 2)}</code>
            </pre>
          </Box>
        </Details>
      </Box>
      <EventBy event={event} />
    </Box>
  );
};

export default ActivationPackageDefaultEvent;
