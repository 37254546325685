import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Card, Icon, TextLink } from '@palmetto/palmetto-components';
import { DocumentDocument, DocumentFile } from 'types';
import { FileItem } from './documents/FileItem';
import { useGetTasksQuery } from '../services/tasks';
import { useGetDocumentsQuery } from '../services/documents';
import RequirePalmettoFinanceUser from '../components/auth/RequirePalmettoFinanceUser';
import { InstallationTask, NTPTask, SystemActivationTask, Task, TaskAssigneeType, TaskStatus } from '../types/Tasks';

const StatusIcon: FC<{ status: TaskStatus }> = ({ status }) => {
  if (status === TaskStatus.ready) {
    return (
      <Box alignItems="center" width="lg" childGap="xs">
        <Icon name="circle" color="disabled" size="xl" />
        <Box fontSize="xs" color="body-secondary">
          {status}
        </Box>
      </Box>
    );
  }
  if (status === TaskStatus.completed) {
    return (
      <Box alignItems="center" width="lg" childGap="xs">
        <Icon name="c-check" color="primary" size="xl" />
        <Box fontSize="xs" color="body-secondary">
          {status}
        </Box>
      </Box>
    );
  }
  if (status === TaskStatus.voided || status === TaskStatus.rejected) {
    return (
      <Box alignItems="center" width="lg" childGap="xs">
        <Icon name="c-remove" color="danger" size="xl" />
        <Box fontSize="xs" color="body-secondary">
          {status}
        </Box>
      </Box>
    );
  }
  return (
    <Box alignItems="center" width="lg" childGap="xs">
      <Icon name="circle" color="disabled" size="xl" />
      <Box fontSize="xs" color="body-secondary">
        {status}
      </Box>
    </Box>
  );
};

const sortTasks = (tasks: Task[]) => {
  return tasks?.sort((a, b) => {
    if (a.status === TaskStatus.pending && b.status !== TaskStatus.pending) {
      return 1;
    } else {
      return -1;
    }
  });
};

export function Tasks({ className }: { className?: string }) {
  const navigate = useNavigate();
  const { id } = useParams<{ id: any }>();
  const { data: tasks, isLoading: tasksIsLoading } = useGetTasksQuery({ id });
  const { data: documents } = useGetDocumentsQuery({ accountId: id });

  const [consumerTasks, setConsumerTasks] = useState<Task[]>([]);
  const [partnerTasks, setPartnerTasks] = useState<Task[]>([]);
  const [internalTasks, setInternalTasks] = useState<Task[]>([]);
  const installPackageUrl = `/accounts/${id}/installation-package/review`;

  useEffect(() => {
    if (tasks?.length && !tasksIsLoading) {
      const sortedTasks = tasks.reduce(
        (acc, task) => {
          acc[task.assigneeType] = acc[task.assigneeType] || [];
          acc[task.assigneeType].push(task);
          return acc;
        },
        {} as Record<TaskAssigneeType, Task[]>,
      );
      setConsumerTasks(sortTasks(sortedTasks[TaskAssigneeType.consumer]));
      setInternalTasks(sortTasks(sortedTasks[TaskAssigneeType.internal]));
      setPartnerTasks(sortTasks(sortedTasks[TaskAssigneeType.partner]));
    }
  }, [tasks, tasksIsLoading]);
  return (
    <>
      {!tasksIsLoading && tasks && tasks?.length > 0 && (
        <Box className={className} gap="lg">
          <RequirePalmettoFinanceUser>
            {consumerTasks?.length > 0 && (
              <Card>
                <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
                  <Box as="h3" fontWeight="medium" fontSize="md">
                    [
                    {`${consumerTasks.reduce((count, task) => (task.status === 'completed' ? count + 1 : count), 0)}/${
                      consumerTasks.length
                    }`}
                    ] Applicant Tasks
                  </Box>
                </Box>
                {consumerTasks?.map((task) => (
                  <Box padding="md lg" borderWidth="xs 0 0 0" borderColor="separator" key={task.id}>
                    <Box childGap="md" direction="row" alignItems="center">
                      <StatusIcon status={task.status} />
                      <Box margin="0 0 0 md" childGap="xs">
                        <Box fontWeight="medium">{task.description}</Box>
                        <Box fontSize="xs">{task.name}</Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Card>
            )}
          </RequirePalmettoFinanceUser>
          {partnerTasks?.length > 0 && (
            <Card>
              <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
                <Box as="h3" fontWeight="medium" fontSize="md">
                  [
                  {`${partnerTasks.reduce((count, task) => (task.status === 'completed' ? count + 1 : count), 0)}/${
                    partnerTasks.length
                  }`}
                  ] Partner Tasks
                </Box>
              </Box>
              {partnerTasks?.map((task) => (
                <Box padding="md lg" borderWidth="xs 0 0 0" borderColor="separator" key={task.id}>
                  <Box childGap="md" direction="row" alignItems="center">
                    <StatusIcon status={task.status} />
                    <Box flex="auto" margin="0 0 0 md" childGap="xs">
                      <Box fontWeight="medium">{task.description}</Box>
                      <Box fontSize="xs">{task.name}</Box>
                    </Box>
                    {NTPTask[task.type as unknown as NTPTask] && (
                      <TextLink navigate={() => navigate(`/accounts/${id}/ntp-package/edit`)}>
                        Update Notice to Proceed Package
                      </TextLink>
                    )}
                    {InstallationTask[task.type as unknown as InstallationTask] && (
                      <TextLink navigate={() => navigate(`/accounts/${id}/installation-package/edit`)}>
                        Update Installation Package
                      </TextLink>
                    )}
                    {task.type in SystemActivationTask && (
                      <TextLink navigate={() => navigate(`/accounts/${id}/activation-package/edit`)}>
                        Update Activation Package
                      </TextLink>
                    )}
                  </Box>
                </Box>
              ))}
            </Card>
          )}
          <RequirePalmettoFinanceUser>
            {internalTasks?.length > 0 && (
              <Card>
                <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
                  <Box as="h3" fontWeight="medium" fontSize="md">
                    [
                    {`${internalTasks.reduce(
                      (count, task) => (task.status === 'completed' ? count + 1 : count),
                      0,
                    )}/${internalTasks.length}`}
                    ] Palmetto Finance Tasks
                  </Box>
                </Box>
                {internalTasks?.map((task) => (
                  <Box
                    width="100"
                    padding="md lg"
                    borderWidth="xs 0 0 0"
                    borderColor="separator"
                    childGap="md"
                    direction="row"
                    alignItems="center"
                    key={task.id}
                  >
                    <StatusIcon status={task.status} />
                    <Box flex="auto" margin="0 0 0 md" childGap="xs">
                      <Box childGap="sm">
                        <Box fontWeight="medium">{task.description}</Box>
                        {task?.referenceIds
                          ?.filter((reference) => reference?.type === 'documents')
                          .map((reference) => {
                            const documentId = reference?.id;
                            const document = documents?.find(
                              (doc: DocumentDocument) => doc?.id?.toString() === documentId,
                            );
                            return (
                              document &&
                              document.files?.map((f: DocumentFile) => <FileItem file={f} key={f?.md5Hash} />)
                            );
                          })}
                      </Box>
                      <Box fontSize="xs">{task.name}</Box>
                    </Box>
                    {NTPTask[task.type as unknown as NTPTask] && (
                      <Link
                        to={`/accounts/${id}/ntp-package/review`}
                        state={{ shouldRedirect: false, action: 'review' }}
                      >
                        Review Notice to Proceed Package
                      </Link>
                    )}
                    {InstallationTask[task.type as unknown as InstallationTask] && (
                      <Link to={installPackageUrl} state={{ shouldRedirect: false, action: 'review' }}>
                        Review Installation Package
                      </Link>
                    )}
                    {SystemActivationTask[task.type as unknown as SystemActivationTask] && (
                      <Link
                        to={`/accounts/${id}/activation-package/review`}
                        state={{ shouldRedirect: false, action: 'review' }}
                      >
                        Review Activation Package
                      </Link>
                    )}
                  </Box>
                ))}
              </Card>
            )}
          </RequirePalmettoFinanceUser>
        </Box>
      )}
    </>
  );
}
