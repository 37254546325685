// @ts-nocheck
import React from 'react';
import AddressInput from './AddressInput';

const FormikTextInput = ({ field: { name, onBlur, onChange, value }, form: { touched, errors }, ...props }) => (
  <AddressInput
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    error={touched[name] && errors[name]}
    {...props}
  />
);

export default FormikTextInput;
