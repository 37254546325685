import { Box } from '@palmetto/palmetto-components';
interface SidebarSectionProps {
  children?: React.ReactNode;
}

export const SidebarSection: React.FC<SidebarSectionProps> = ({ children }) => {
  return (
    <Box childGap="xs" borderWidth="xs" fontSize="xs" radius="md" borderColor="separator" padding="lg">
      {children}
    </Box>
  );
};
