import { api } from './api';

export const availableAddersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAvailableAdders: build.query<any, string>({
      query: (id) => ({ url: `v2/accounts/${id}/available-adders` }),
    }),
  }),
});

export const { useGetAvailableAddersQuery } = availableAddersApi;
