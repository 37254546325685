const getFriendlyNameForReportType = (type = '') => {
  switch (type) {
    case 'preQual':
      return 'Soft / Pre-Qual Reports';
    case 'hard':
      return 'Full / Hard Reports';
    default:
      return type;
  }
};

export default getFriendlyNameForReportType;
