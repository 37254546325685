
export const sortByLabel = (a: any, b: any) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
  
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  };