import { api } from './api';

interface AddUserParams {
  organizationAlias: string;
  email: string;
  permissions: string[];
  machine?: boolean;
}

interface AddUserResult {
  organization: string;
  error: { status: number; data: { message: string } } | null;
}

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    addUser: build.mutation<AddUserResult, AddUserParams>({
      query: ({ organizationAlias, email, permissions, machine = false }) => ({
        url: `users/invite`,
        method: 'POST',
        body: { organizationAlias, email, permissions, machine },
      }),
      invalidatesTags: (_result, _error, options) => {
        return [
          { type: 'Users', id: 'LIST' },
          { type: 'Organizations', id: options.organizationAlias },
        ];
      },
    }),
    editUser: build.mutation<void, { organizationAlias: string; userId: string; permissions: string[] }>({
      query: ({ userId, permissions }) => ({
        url: `users/${userId}`,
        method: 'POST',
        body: { permissions },
      }),
      invalidatesTags: (_result, _error, options) => {
        return [
          { type: 'Users', id: 'LIST' },
          { type: 'Organizations', id: options.organizationAlias },
        ];
      },
    }),
    removeUser: build.mutation<void, { organizationAlias: string; userId: string }>({
      query: ({ organizationAlias, userId }) => ({
        url: `users/${userId}`,
        method: 'DELETE',
        body: { organizationAlias },
      }),
      invalidatesTags: (_result, _error, options) => {
        return [
          { type: 'Users', id: 'LIST' },
          { type: 'Organizations', id: options.organizationAlias },
        ];
      },
    }),
  }),
});

export const { useAddUserMutation, useRemoveUserMutation, useEditUserMutation } = userApi;
