import { Box, Icon } from '@palmetto/palmetto-components';
import { PayoutEvent, ProjectDocument } from 'types/dist';
import { flattenedPaymentEventLabelInfo, ProjectTransactionClientData } from '@/types/Payouts';
import { useGetPaymentPlanQuery } from '@/services/paymentPlans';
import { useMemo } from 'react';
import { percentageFormatter } from '@/helpers/percentageFormatter';
import { currencyFormatter } from '@/helpers/currencyFormatter';

interface AccountFundingSummaryProps {
  project: ProjectDocument;
  transactions: ProjectTransactionClientData[];
}

export const AccountFundingSummary = ({ project, transactions }: AccountFundingSummaryProps) => {
  const { data: paymentPlan } = useGetPaymentPlanQuery({
    id: project.planId as string,
  });
  const totalPayoutAmount = useMemo(
    () => transactions.reduce((acc, transaction) => acc + transaction.amount, 0),
    [transactions],
  );

  const labelInfoMap = useMemo(() => {
    return flattenedPaymentEventLabelInfo.reduce(
      (acc, label) => {
        acc[label.event] = label;
        return acc;
      },
      {} as Record<PayoutEvent, (typeof flattenedPaymentEventLabelInfo)[0]>,
    );
  }, []);

  const summaryInfoItems = useMemo(
    () => [
      {
        label: 'Transactions',
        value: transactions.length,
      },
      { label: 'Total Payout Amount', value: currencyFormatter.format(totalPayoutAmount) },
      { label: 'Project Cost', value: currencyFormatter.format(project.totalCost) },
      { label: 'Balance', value: currencyFormatter.format(project.totalCost - totalPayoutAmount) },
    ],
    [transactions, project, totalPayoutAmount],
  );

  return (
    <Box gap="md" background="primary" radius="md">
      {project && transactions && (
        <Box direction="row" childGap="lg">
          <Box borderWidth="0 sm 0 0" borderColor="grey-100" childGap="lg">
            <Box as="h3" padding="md 0 0 md">
              PAYMENT PLAN
            </Box>
            <Box direction="row" childGap="lg" padding="0 md md">
              {paymentPlan?.paymentSchedule.map((payment) => (
                <Box key={payment.event}>
                  <Box width="180px" display="block">
                    <Box direction="row" childGap="sm" borderWidth="0 0 sm 0" borderColor="grey-100">
                      <Box fontWeight="bold">{labelInfoMap[payment.event].name}</Box>
                      {transactions.some(
                        (transaction) => transaction.event === payment.event && transaction.amount > 0,
                      ) ? (
                        <Icon name="check" color="success" />
                      ) : (
                        ''
                      )}
                    </Box>
                    <Box>{percentageFormatter.format(payment.paymentPercent)}</Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box direction="row" childGap="md">
            {summaryInfoItems.map(({ label, value }) => (
              <Box childGap="lg" padding="md md 0 md" width={`${summaryInfoItems.length / 100}`}>
                <Box direction="row">{label}</Box>
                <Box alignSelf="flex-end" fontWeight="bold">
                  {value}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
