import { Box } from '@palmetto/palmetto-components';
import { NavLink, Route, Routes, useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { AdderType, ProgramType, adderMap } from 'types';
import { Adder } from './Adder';

export const Adders = () => {
  const navigate = useNavigate();
  const { programType = ProgramType.solar, adderType } = useParams<{ programType: string; adderType: string }>();
  useEffect(() => {
    if (!adderType) {
      const adderKey = Object.keys(adderMap[programType as ProgramType])[0] as AdderType;
      navigate(`${adderKey}`);
    }
  }, [adderType, navigate]);

  return (
    <Box direction="row" childGap="3xl">
      <Box>
        {Object.keys(adderMap[programType as ProgramType]).map((type) => (
          <NavLink
            to={`/admin/pricing/${programType}/adders/${type}`}
            style={{ textDecoration: 'none', fontWeight: '500' }}
            key={type}
            end
          >
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s', borderBottom: 0 }}
              >
                {adderMap[programType as ProgramType][type as AdderType]}
              </Box>
            )}
          </NavLink>
        ))}
      </Box>
      <Box flex="auto">
        <Routes>
          <Route path=":adderType?" element={<Adder />} />
        </Routes>
      </Box>
    </Box>
  );
};
