export const trimFileNames = (fileName?: string, dataKey?: string) => {
  const name = fileName || '';

  if (!fileName) {
    return dataKey;
  } else if (name.length > 50) {
    return `${name.slice(0, 10)}...${name.slice(-10)}`;
  }

  return fileName;
};
