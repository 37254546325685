import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { ActivationPackageActions } from '../../types/ActivationPackage';
import { ActivationDocumentTypes } from 'types';
import { useGetTasksQuery } from '../../services/tasks';
import { Badge, Box, TextInput } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { sortDescending } from '../../helpers/sort';
import { useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { Documents } from '../Documents';
import { DATE_FORMAT } from '../../helpers/dates';

interface PTOConfirmationFormValues {
  ptoGrantedDate: string;
}

export const PTOConfirmationSection = ({ isActivationPendingReview }: { isActivationPendingReview: boolean }) => {
  const { id, action } = useParams<{ id: any; action: ActivationPackageActions }>();
  const { data: items = [] } = useGetTasksQuery({ id });
  const { errors, isSubmitting, setFieldValue, values } = useFormikContext<PTOConfirmationFormValues>();

  const ptoConformationTask = useMemo(() => {
    const task = items.filter((item) => {
      return item.type === 'ptoConfirmation' && item.assigneeType === 'internal';
    });
    const mostRecentTask = sortDescending(task, 'createdAt')[0];
    return mostRecentTask;
  }, [items]);

  return (
    <>
      {/* @ts-ignore pass component to description */}
      <FormSection
        title="Permission to Operate"
        description="Utility PTO proof such as letter/email copy, or screenshot of utility portal status."
      >
        {ptoConformationTask && ptoConformationTask?.status === 'completed' && (
          <Badge message="Approved" style={{ width: 'fit-content', marginBottom: '15px' }} variant="success" />
        )}
        <Documents
          documentTypeFilter={[ActivationDocumentTypes.ptoConfirmation]}
          showDocumentActions={action === ActivationPackageActions.review}
          allowArchive={false}
          title="PTO"
          showUploadButton={action !== ActivationPackageActions.review && !isActivationPendingReview}
        />
        <Box width={{ base: '100', desktop: '33' }}>
          <TextInput
            label="PTO Granted Date"
            name="ptoGrantedDate"
            id="ptoGrantedDate"
            value={values?.ptoGrantedDate && DateTime.fromISO(values.ptoGrantedDate).toUTC().toFormat(DATE_FORMAT)}
            isRequired
            onChange={(event) => {
              setFieldValue('ptoGrantedDate', event.target.value);
            }}
            inputMask="date"
            placeholder="MM-DD-YYYY"
            isDisabled={isSubmitting}
            error={errors?.ptoGrantedDate}
          />
        </Box>
      </FormSection>
    </>
  );
};
