import { AccountMilestoneStipulationDocument } from 'types';

export enum AccountStatus {
  created = '1 - Created',
  creditApproved = '2 - Credit Approved',
  creditApprovedWithStipulations = '3 - Credit Approved',
  creditDenied = '4 - Credit Denied',
  contractCreated = '5 - Contract Created',
  contractSent = '6 - Contract Sent',
  contractSigned = '7 - Contract Signed',
  contractApproved = '8 - Contract Approved',
  noticeToProceedEligible = '9 - Notice to Proceed eligible',
  noticeToProceedIssued = '10 - M0 Notice to Proceed issued',
  installMilestoneSubmitted = 'Install Milestone Submitted',
  installMilestoneRejected = 'Install Milestone Rejected',
  installMilestoneResubmitted = 'Install Milestone Resubmitted',
  installMilestoneApproved = 'Install Milestone Approved',
  jcoApproved = 'Job Close Out Approved',
  creditExpired = '99 - Credit Expired',
}

export interface AccountMilestoneStipulation extends Omit<AccountMilestoneStipulationDocument, 'accountId' | 'id'> {
  accountId: string;
  id: string;
}

export enum AccountStipulationType {
  administrative = 'administrative',
  designVerification = 'designVerification',
}
