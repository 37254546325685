import { DeepPartial, PostActivationPackage } from 'types';
import { api } from './api';

export const postActivationPackageApi = api.injectEndpoints({
  endpoints: (build) => ({
    savePostActivationPackage: build.mutation<
      any,
      { accountId: string; postActivationPackage: DeepPartial<PostActivationPackage> }
    >({
      query: ({ accountId, postActivationPackage }) => ({
        url: `v2/accounts/${accountId}/post-activation-package/save`,
        method: 'POST',
        body: { ...postActivationPackage },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Accounts', id: arg?.accountId }],
    }),
    submitPostActivationPackage: build.mutation<
      any,
      { accountId: string; postActivationPackage: PostActivationPackage }
    >({
      query: ({ accountId, postActivationPackage }) => ({
        url: `v2/accounts/${accountId}/post-activation-package/submit`,
        method: 'POST',
        body: { ...postActivationPackage },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Accounts', id: arg?.accountId },
        { type: 'Queues', accountId: arg?.accountId },
      ],
    }),
  }),
});

export const { useSavePostActivationPackageMutation, useSubmitPostActivationPackageMutation } =
  postActivationPackageApi;
