import { Box, Details, useOpenClose } from '@palmetto/palmetto-components';
import EventBy from './EventBy';
import { ActivityHistoryEventType } from 'types';

const MilestonePackageSavedSubmitted = ({ event }: { event: any }) => {
  const { isOpen, handleToggle } = useOpenClose();

  let action;
  let type;
  switch (event.eventType) {
    case ActivityHistoryEventType.sabalNTPPackageResubmitted:
      action = 'Resubmitted';
      type = 'Notice to Proceed';
      break;
    case ActivityHistoryEventType.sabalNTPPackageSaved:
      action = 'Saved';
      type = 'Notice to Proceed';
      break;
    case ActivityHistoryEventType.sabalNTPPackageSubmitted:
      action = 'Submitted';
      type = 'Notice to Proceed';
      break;
    case ActivityHistoryEventType.sabalInstallPackageResubmitted:
      action = 'Resubmitted';
      type = 'Install';
      break;
    case ActivityHistoryEventType.sabalInstallPackageSaved:
      action = 'Saved';
      type = 'Install';
      break;
    case ActivityHistoryEventType.sabalInstallPackageSubmitted:
      action = 'Submitted';
      type = 'Install';
      break;
    case ActivityHistoryEventType.sabalPermissionToOperateSubmitted:
      action = 'Submitted';
      type = 'Permission to Operate';
      break;
    case ActivityHistoryEventType.sabalPermissionToOperateCompleted:
      action = 'Approved';
      type = 'Permission to Operate';
      break;
    case ActivityHistoryEventType.sabalElectricianSignOffSubmitted:
      action = 'Submitted';
      type = 'Electrician Sign Off';
      break;
    case ActivityHistoryEventType.sabalElectricianSignOffCompleted:
      action = 'Approved';
      type = 'Electrician Sign Off';
      break;
  }
  return (
    <Box flex="auto">
      <Box display="block">
        <Details isOpen={isOpen}>
          <Details.Summary isDetailsOpen={isOpen} onToggle={handleToggle} display="inline">
            <Box as="span" display="inline-block" margin="0 2xs 0 0">
              <EventBy event={event} /> {action} {type} Package
            </Box>
          </Details.Summary>
          <Box margin="md 0" padding="md 0" childGap="sm" overflow="scroll" maxHeight="400px" background="primary">
            <pre>
              <code>{JSON.stringify(event.data, null, 2)}</code>
            </pre>
          </Box>
        </Details>
      </Box>
    </Box>
  );
};

export default MilestonePackageSavedSubmitted;
