import { DocumentFile, DocumentDocument, documentTitles } from 'types';
import { Box, Button, Icon, Popover, toast, useOpenClose } from '@palmetto/palmetto-components';
import { useArchiveDocumentMutation } from '../../../services/documents';
import RequirePermissions from '../../auth/requirePermissions';
import DateTimeDisplay from '../../DateTime';
import styles from './DocumentListItem.module.scss';
import { Flag } from '../../QueueFlags/Flag';
import { useContext } from 'react';
import { QueueFlagsContext } from '../../QueueFlags/QueueFlagsContext';
import { FlagInfo } from '../../QueueFlags/FlagInfo';
import { trimFileNames } from '@/helpers/trimFileNames';
import { FlaggedListItems } from '@/components/QueueFlags/FlaggedListItems';

interface DocumentListItemProps {
  allowArchive?: boolean;
  item: DocumentDocument;
  showDocumentActions?: boolean;
  showTypeLabel?: boolean;
  baseCategory?: string;
  onClick: (fileIndex: number) => void;
}

export function DocumentListItem({
  allowArchive = true,
  item,
  showDocumentActions = true,
  showTypeLabel = true,
  baseCategory,
  onClick,
}: DocumentListItemProps) {
  const { type, files, meta } = item || {};
  const { isOpen, handleToggle, handleClose } = useOpenClose();
  const [archiveDocument, { isLoading: isArchiving }] = useArchiveDocumentMutation();
  const documentTitle = documentTitles[type] || (type as string);
  const originalViewUrl = files[0]?.viewUrls?.[0]?.url;
  const fileName = files[0]?.originalName;
  const isImage = files[0]?.contentType?.includes('image');
  const { getFlag, shouldHide } = useContext(QueueFlagsContext);
  const itemId = item?.id?.toString() || '';
  const currentFlag = files.some((file: DocumentFile, index: number) => {
    const dataKeyWithSuffix = `${itemId}-${index}`;
    return getFlag([dataKeyWithSuffix, itemId], file.originalName, false);
  });

  const handleArchive = async () => {
    if (currentFlag) {
      toast.error('Please upload a new document to fix the flagged issue(s). You cannot archive this document.');
      return;
    }
    const confirmed = window.confirm(
      'Are you sure you want to Archive this Document? This action cannot be undone.',
    );

    if (!confirmed) return;

    try {
      await archiveDocument({
        id: item?.id?.toString() || '',
        accountId: item?.accountId?.toString() || '',
        invalidateAllTags: false,
      }).unwrap();
      toast.success('Document archived successfully');
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error archiving uploaded document');
    } finally {
      handleClose();
    }
  };

  return (
    <Box borderWidth="xs 0 0 0" borderColor="separator" background={currentFlag ? 'danger' : 'primary'}>
      <Box padding={{ base: 'md 0', tablet: '0' }} direction="row" width="100" alignItems="center">
        <Box width="100" direction="row" alignItems="center">
          {currentFlag && (
            <>
              <Box display="block" childGap="xs" padding={{ base: 'md lg', tablet: 'sm md md lg' }}>
                <Icon name="flag" size="lg" color="danger" />
              </Box>
              <Box
                display="block"
                childGap="2xs"
                padding={{ base: '0 lg', tablet: 'md md md 0' }}
                margin="0 2xl 0 0"
                width={{ base: '2xl' }}
              >
                {files.length > 1 ? (
                  <FlaggedListItems files={files} itemId={itemId} />
                ) : (
                  files.map((file, index) => {
                    const dataKey = getFlag([itemId], file.originalName, false) ? itemId : `${itemId}-${index}`;
                    return <FlagInfo key={dataKey} dataKey={dataKey} fileName={file.originalName} />;
                  })
                )}
              </Box>
            </>
          )}
          <Box onClick={() => onClick(0)} style={{ cursor: 'pointer' }}>
            {isImage ? (
              <Box margin="sm lg sm lg" borderWidth="xs" borderColor="default">
                <img className={styles.image} src={originalViewUrl} alt={fileName} height="100%" loading="lazy" />
              </Box>
            ) : (
              <Box
                alignItems="flex-start"
                width={{ base: 'lg', tablet: 'lg' }}
                padding={{ base: 'md lg', tablet: 'sm md md lg' }}
                margin={
                  currentFlag
                    ? { base: '0 xl 0 0', tablet: '0 2xl 0 0' }
                    : { base: '0 2xl 0 0', tablet: '0 4xl 0 0' }
                }
              >
                <Icon name="document" size="3xl" color="body-tertiary" />
              </Box>
            )}
          </Box>
          <Box
            width={
              currentFlag
                ? { base: 'xl', tablet: isImage ? '2xl' : '25' }
                : { base: '2xl', tablet: isImage ? '4xl' : '50' }
            }
            display="block"
            childGap="xs"
            padding={{ base: '0 lg', tablet: 'md md md 0' }}
            onClick={() => onClick}
          >
            <Box display="block">
              {files.map((file: DocumentFile, index: number) => {
                const dataKeyWithSuffix = `${itemId}-${index}`;
                const fileFlag = getFlag([dataKeyWithSuffix, itemId], file.originalName, false);

                return (
                  <Box
                    key={`${file.md5Hash}-${index}`}
                    title={file.originalName}
                    display="flex"
                    direction="row"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => onClick(index)}
                  >
                    {trimFileNames(file.originalName)}
                    {fileFlag && files.length > 1 && (
                      <Box margin="2xs 0 0 xs">
                        <Icon name="flag" size="xs" color="danger" />
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
            {documentTitle && showTypeLabel && <Box fontSize="xs">{documentTitle}</Box>}
          </Box>
          <Box
            width={{ base: '2xl', tablet: '3xl' }}
            childGap="xs"
            padding={{ base: '0 lg', tablet: 'md md md 0' }}
            margin={{ base: '0 0 xs 0', tablet: '0 0 0 4xl' }}
            direction={{ base: 'column', tablet: 'row' }}
            justifyContent={{ base: 'flex-start', tablet: 'flex-end' }}
          >
            <DateTimeDisplay
              displayLocalDateTime={true}
              value={meta?.createdAt || ''}
              excludeTime={true}
              dateFormat="DD h:mm:ss a"
              fontSize="md"
            />
          </Box>
          {showDocumentActions && allowArchive && (
            <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
              <Popover
                content={
                  <Box childGap="xs">
                    <Button
                      size="sm"
                      variant="secondary"
                      tone="neutral"
                      isLoading={isArchiving}
                      onClick={handleArchive}
                    >
                      Archive
                    </Button>
                  </Box>
                }
                isOpen={isOpen}
                onClickOutside={() => handleClose()}
                placement="bottom-start"
                offsetFromTarget={-20}
                contentContainerProps={{
                  padding: 'sm',
                  background: 'grey-800',
                }}
              >
                <Box
                  width={{ base: 'lg', tablet: 'xl' }}
                  childGap="xs"
                  padding={{ base: '0 lg', tablet: 'md lg md 0' }}
                  direction={{ base: 'column', tablet: 'row' }}
                  justifyContent={{ base: 'flex-start', tablet: 'flex-end' }}
                  alignItems={{ base: 'center' }}
                >
                  {!shouldHide && item.id && (
                    <Flag baseCategory={baseCategory} dataKey={`${itemId}-0`} fileName={fileName} type={type} />
                  )}
                  <Button
                    iconPrefix="dots"
                    variant="tertiary"
                    size="md"
                    tone="neutral"
                    aria-label="documentActions"
                    onClick={handleToggle}
                  />
                </Box>
              </Popover>
            </RequirePermissions>
          )}
        </Box>
      </Box>
    </Box>
  );
}
