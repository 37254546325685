import { TextInput } from '@palmetto/palmetto-components';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

type NameFilterProps = {
  setNameValue: Dispatch<SetStateAction<string>>;
  value: string;
};

export const NameFilter = ({ value, setNameValue }: NameFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // debounces the name input so it doesn't try to filter every keystroke
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (value.length) {
        searchParams.set('name', value);
        setSearchParams(searchParams);
      } else {
        searchParams.delete('name');
        setSearchParams(searchParams);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [value, searchParams, setSearchParams]);

  const handleNameChange = (event: any) => {
    setNameValue(event.target.value);
  };

  const clearName = () => {
    setNameValue('');
  };
  return (
    <TextInput
      id="searchInput"
      label="Search Name"
      placeholder="Search by name"
      hideLabel
      value={value}
      size="sm"
      onChange={handleNameChange}
      onClear={clearName}
    />
  );
};
