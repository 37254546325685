import { OrganizationNode } from '../types/Organizations';

export const flattenToOptions = (orgs: OrganizationNode[], level = 0): { label: string; value: string }[] => {
  return orgs.reduce((acc: { label: string; value: string }[], item: OrganizationNode) => {
    return acc.concat(
      [
        {
          label: `${Array(level + 1).join('-')} ${item.name} (${item.alias})`,
          value: item.alias,
        },
      ],
      flattenToOptions(item.children, level + 1),
    );
  }, []);
};
