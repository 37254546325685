import { Alert } from '@palmetto/palmetto-components';
import { useMemo } from 'react';

interface RejectionAlertProps {
  task: any;
  className?: string;
  rejectionReasons: Record<string, any>;
}

const RejectionAlert = ({ className, task, rejectionReasons }: RejectionAlertProps) => {
  const rejectionMessageContent = useMemo(() => {
    const rejection = task?.rejectionReasons?.[0];
    const rejectionReasonLabel = rejection?.reasons?.length > 1 ? 'Rejection Reasons' : 'Rejection Reason';
    const mappedRejectionReasons = rejection?.reasons?.map((reason: string) => rejectionReasons[reason]);
    const rejectionReasonsString = mappedRejectionReasons?.join(', ');
    const rejectionNote = rejection?.note;

    return (
      <>
        <p>
          <b>{rejectionReasonLabel}:</b> {rejectionReasonsString}
        </p>
        {rejectionNote && (
          <p>
            <b>Rejection Note:</b> {rejectionNote}
          </p>
        )}
      </>
    );
  }, [task, rejectionReasons]);

  // Do not display alert for documents on account page
  const isRejectionReasons = task?.rejectionReasons?.[0]?.reasons?.some(
    (reason: string) => reason !== 'documentRejected',
  );
  if (!isRejectionReasons) return;

  return <Alert variant="danger" message={rejectionMessageContent} isCompact className={className} />;
};

export default RejectionAlert;
