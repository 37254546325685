import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@palmetto/palmetto-components';
import { Documents } from '../Documents';
import { Tasks } from '../Tasks';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import { Quotes } from '../Quotes/Quotes';
import { AccountMilestones } from './AccountMilestones';
import { Contracts } from '../Contracts/ContractList';
import {
  DocumentStatus,
  DocumentTypesArr,
  InstallDocumentTypesArr,
  DeprecatedDocumentTypesArr,
  BillingDocumentTypes,
  QueueType,
  ProgramType,
  HVACDocumentTypesArr,
  NTPPackageDocumentTypes,
} from 'types';
import { InstallMilestonePackageContainer } from '../InstallMilestonePackage/InstallMilestonePackageContainer';
import RejectionBanner from '../Reject/RejectionBanner';
import { MilestoneType } from '../../types/Milestone';
import { ActivationMilestonePackageContainer } from '../ActivationMilestonePackage/ActivationMilestonePackageContainer';
import { Underwriting } from '../Underwriting/Underwriting';
import { AccountDetails } from './AccountDetails';
import CancelAccount from '../Cancel/CancelAccount';
import AdminStipulation from '../Stipulations/AdminStipulation';
import { useGetAccountQuery } from '../../services/accounts';
import {
  AccountMilestoneWithRequirements,
  AccountMilestoneStatus,
  AccountRequirementDocument,
  AccountRequirementStatus,
  MilestoneRequirement,
} from 'types';
import { useAuth } from '../auth/authProvider';
import { useGetQueueByAccountIdAndTypeQuery } from '@/services/queues';
import { NTPMilestonePackageContainer } from '../NTPMilestonePackage/NTPMilestonePackageContainer';
import { PostActivationMilestonePackageContainer } from '../PostActivationMilestonePackage/PostActivationMilestonePackagerContainer';

const getMostRecentObj = (array: any[]) => {
  return array?.reduce((prev: any, current: any) => {
    return prev.date > current.date ? prev : current;
  });
};

const Overview = () => {
  const { id } = useParams<{ id: any }>();
  const { adminStipulations, enableCancelAccountButton, viewActivationMilestonePackage } = useFlags();
  const { data: account } = useGetAccountQuery(id);
  const { data: activationQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.activationPackage,
  });
  const noticeToProceedMilestone: AccountMilestoneWithRequirements = account?.milestones?.find(
    (milestone: AccountMilestoneWithRequirements) => milestone.type === MilestoneType.noticeToProceed,
  );
  const installMilestone: AccountMilestoneWithRequirements = account?.milestones?.find(
    (milestone: AccountMilestoneWithRequirements) => milestone.type === MilestoneType.install,
  );
  const activationMilestone: AccountMilestoneWithRequirements = account?.milestones?.find(
    (milestone: AccountMilestoneWithRequirements) => milestone.type === MilestoneType.activation,
  );

  const noticeToProceedRejections: string[] | undefined = useMemo(() => {
    if (!noticeToProceedMilestone || noticeToProceedMilestone.status !== AccountMilestoneStatus.rejected) {
      return;
    }
    const noticeToProceedPackage = noticeToProceedMilestone.requirements.find(
      (req: AccountRequirementDocument) => req.type === MilestoneRequirement.ntpPackage,
    );
    const rejectedRequirements = noticeToProceedPackage?.requirements
      .filter((req: AccountRequirementDocument) => req.status === AccountRequirementStatus.rejected)
      .map((rejectedRequirement: AccountRequirementDocument) => rejectedRequirement.name);
    return rejectedRequirements;
  }, [noticeToProceedMilestone]);

  const installRejections: string[] | undefined = useMemo(() => {
    if (!installMilestone || installMilestone.status !== AccountMilestoneStatus.rejected) {
      return;
    }
    const installPackage = installMilestone.requirements.find(
      (req: AccountRequirementDocument) => req.type === MilestoneRequirement.installPackage,
    );
    const rejectedRequirements = installPackage?.requirements
      .filter((req: AccountRequirementDocument) => req.status === AccountRequirementStatus.rejected)
      .map((rejectedRequirement: AccountRequirementDocument) => rejectedRequirement.name);
    return rejectedRequirements;
  }, [installMilestone]);

  const activationRejections: string[] | undefined = useMemo(() => {
    if (!activationMilestone || activationMilestone.status !== AccountMilestoneStatus.rejected) {
      return;
    }
    const activationPackage = activationMilestone.requirements.find(
      (req: AccountRequirementDocument) => req.type === MilestoneRequirement.activationPackage,
    );
    const rejectedRequirements = activationPackage?.requirements
      .filter((req: AccountRequirementDocument) => req.status === AccountRequirementStatus.rejected)
      .map((rejectedRequirement: AccountRequirementDocument) => rejectedRequirement.name);
    return rejectedRequirements;
  }, [activationMilestone]);

  const { claims } = useAuth();
  let documentFilters: any[] = isPalmettoFinanceUser(claims)
    ? [...DocumentTypesArr, BillingDocumentTypes.incomingCedInvoice]
    : DocumentTypesArr;

  const mostRecentPackageRejectionInfo = activationQueue?.rejectionReasons
    ? getMostRecentObj(activationQueue?.rejectionReasons).rejectionInfo
    : [];
  const isNonHvac = account?.programType !== ProgramType.hvac;

  if (account?.programType === ProgramType.hvac) {
    documentFilters = [...documentFilters, ...HVACDocumentTypesArr];
  }

  return (
    <Box childGap="lg">
      {noticeToProceedRejections && noticeToProceedRejections.length ? (
        <RejectionBanner
          key="noticeToProceed-rejected-banner"
          milestone={MilestoneType.noticeToProceed}
          rejections={noticeToProceedRejections}
        />
      ) : null}
      {installRejections && installRejections.length ? (
        <RejectionBanner
          key="install-rejected-banner"
          milestone={MilestoneType.install}
          rejections={installRejections}
        />
      ) : null}
      {activationRejections && activationRejections.length ? (
        <RejectionBanner
          key="activation-rejected-banner"
          milestone={MilestoneType.activation}
          rejections={activationRejections}
          rejectionInfo={mostRecentPackageRejectionInfo}
        />
      ) : null}
      <AccountMilestones />
      <Box
        direction={{ base: 'column', desktop: 'row' }}
        alignItems="flex-start"
        childGap={{ base: 'lg', desktop: '3xl' }}
        width="100"
      >
        <Box width={{ base: '100', desktop: '30' }}>
          <AccountDetails />
          <Box direction="row" gap="sm">
            {enableCancelAccountButton && <CancelAccount />}
            {adminStipulations && <AdminStipulation />}
          </Box>
        </Box>
        <Box width={{ base: '100', desktop: '70' }} childGap="lg">
          <Tasks />
          <Box>
            <Underwriting />
          </Box>
          <Quotes />
          <Contracts />
          <Documents
            documentTypeFilter={documentFilters}
            additionalDocumentsToFetch={[
              {
                types: InstallDocumentTypesArr,
                status: [DocumentStatus.approved],
              },
              {
                types: NTPPackageDocumentTypes,
                status: [DocumentStatus.approved],
              },
              { types: DeprecatedDocumentTypesArr },
            ]}
          />
          {account?.programType === ProgramType.hvac && <NTPMilestonePackageContainer />}
          <InstallMilestonePackageContainer />
          {viewActivationMilestonePackage && isNonHvac && <ActivationMilestonePackageContainer />}
          {account?.programType === ProgramType.doePr && <PostActivationMilestonePackageContainer />}
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
