import { useMemo } from 'react';
import { RejectionModal } from '../Reject/RejectionModal';
import { ActivationPackageActions, MonitoringSiteIdRejectionReasons } from '../../types/ActivationPackage';
import { useGetTasksQuery, useUpdateTaskStatusMutation } from '../../services/tasks';
import {
  Alert,
  Badge,
  Box,
  Button,
  FormikTextInput,
  Heading,
  toast,
  TextLink,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { isErrorWithData } from '../../services/helpers';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../auth/requirePermissions';
import { sortDescending } from '../../helpers/sort';
import { Field, useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';

interface MonitoringSiteIdFormValues {
  monitoringSiteId: string;
}

export const MonitoringSiteIdSection = () => {
  const { id, action } = useParams<{ id: any; action: ActivationPackageActions }>();
  const { data: items = [], isLoading } = useGetTasksQuery({ id });
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const { initialValues, isSubmitting, getFieldProps, submitForm } = useFormikContext<MonitoringSiteIdFormValues>();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const rejectionReasons = useMemo(() => {
    const baseReasons = Object.keys(MonitoringSiteIdRejectionReasons).map((reason) => ({
      label: MonitoringSiteIdRejectionReasons[reason as keyof typeof MonitoringSiteIdRejectionReasons],
      value: reason,
    }));
    return baseReasons;
  }, []);

  const monitoringSiteIdTask = useMemo(() => {
    const task = items.filter((item) => {
      return item.type === 'monitoringSiteId' && item.assigneeType === 'internal';
    });
    const mostRecentTask = sortDescending(task, 'createdAt')[0];
    if (mostRecentTask?.status === 'rejected') {
      const rejectedTaskReasons = mostRecentTask?.rejectionReasons[0];
      const mappedRejectionReasons = rejectedTaskReasons.reasons?.map(
        (reason: keyof typeof MonitoringSiteIdRejectionReasons) => MonitoringSiteIdRejectionReasons[reason],
      );
      const rejectionReasonsString = mappedRejectionReasons?.join(', ');
      const rejectionHtml = () => (
        <Box childGap="2xs">
          <Heading as="h5">Rejected</Heading>
          <Box direction="row">
            <strong>Rejection Reason:</strong>&nbsp;{rejectionReasonsString}
          </Box>
          {rejectedTaskReasons.note && (
            <Box direction="row">
              <strong>Rejection Note:</strong>&nbsp;{rejectedTaskReasons.note}
            </Box>
          )}
        </Box>
      );

      return { rejectionHtml: rejectionHtml, ...mostRecentTask };
    }
    return mostRecentTask;
  }, [items]);

  const updateAdminTask = async (
    value: {
      status: 'completed' | 'rejected';
      rejectionNote?: string;
      rejectionReasons?: string[];
    },
    monitoringSiteId: string | undefined,
  ) => {
    try {
      if (monitoringSiteId !== initialValues.monitoringSiteId) {
        await submitForm();
      }
      await updateTaskStatus({
        accountId: id,
        taskId: monitoringSiteIdTask.id,
        status: value,
      }).unwrap();

      toast.success('Task updated');
    } catch (e: any) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error updating task: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error updating task');
      }
    }
  };
  const handleRejectModal = async (value: {
    rejectionReason: { value: string; label: string }[];
    rejectionNote: string;
  }) => {
    const rejectionReasons = value.rejectionReason.map((reason) => reason.value);
    await updateAdminTask(
      {
        status: 'rejected',
        rejectionNote: value.rejectionNote,
        rejectionReasons,
      },
      undefined,
    );
    closeModal();
  };

  const handleApproval = async (monitoringSiteId: string) => {
    updateAdminTask({ status: 'completed' }, monitoringSiteId);
  };
  return (
    <>
      <RejectionModal
        title={`Reject Monitoring Site Id`}
        rejectionReasons={rejectionReasons}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        onSubmit={handleRejectModal}
      />
      <FormSection
        title="Monitoring Active"
        description={
          <Box childGap="xs" color="body-secondary">
            <p>Site ID for the inverter and battery if applicable. Typically 6-7 numbers.</p>
            <p>
              <TextLink
                href="https://help.palmetto.finance/en/articles/8306383-monitoring-portal-access"
                target="_blank"
                rel="noreferrer"
              >
                See monitoring portal access requirements
              </TextLink>
            </p>
          </Box>
        }
      >
        {monitoringSiteIdTask && monitoringSiteIdTask?.status === 'completed' && (
          <Badge message="Approved" style={{ width: 'fit-content', marginBottom: '15px' }} variant="success" />
        )}
        <Field
          label="Monitoring Site Id"
          name="monitoringSiteId"
          id="monitoringSiteId"
          component={FormikTextInput}
          isRequired
          autoComplete="off"
          isDisabled={isSubmitting}
        />
        {!isLoading && monitoringSiteIdTask && monitoringSiteIdTask?.status === 'rejected' && (
          <Alert variant="danger" title="Rejected" render={monitoringSiteIdTask.rejectionHtml} />
        )}
        {initialValues.monitoringSiteId !== '' && action === ActivationPackageActions.review ? (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
              <Box direction="row" justifyContent="flex-end" childGap="xs">
                {monitoringSiteIdTask && monitoringSiteIdTask?.status !== 'completed' && (
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleApproval(getFieldProps('monitoringSiteId').value)}
                  >
                    Approve
                  </Button>
                )}
                <Button onClick={openModal} size="sm" variant="primary" tone="danger">
                  Reject
                </Button>
              </Box>
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ) : null}
      </FormSection>
    </>
  );
};
