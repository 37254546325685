import { ReactNode } from 'react';
import usePermissions from '../../hooks/usePermissions';
import { UserPermissions } from 'types';
import { useInviteToken } from './useInviteToken';

const RequirePermissions = ({
  permissions = [],
  checkAllPermissions = true,
  allowInviteToken = false,
  children,
}: {
  permissions: string[]; // why not UserPermissions[]?
  checkAllPermissions?: boolean;
  allowInviteToken?: boolean;
  children: ReactNode;
}) => {
  const userPermissions = usePermissions();
  const { isInviteToken } = useInviteToken();

  if (allowInviteToken && isInviteToken) {
    return children;
  }

  let show = false;
  if (checkAllPermissions) {
    show = permissions.every((permission) => userPermissions.includes(permission as UserPermissions));
  } else {
    show = permissions.some((permission) => userPermissions.includes(permission as UserPermissions));
  }

  return show ? children : null;
};

export default RequirePermissions;
