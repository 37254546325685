import { createRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useUploadDocumentMutation, useGetDocumentsQuery } from '../../../services/documents';
import {
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  FileUpload,
  TextLink,
  toast,
  useBreakpoint,
} from '@palmetto/palmetto-components';
import { useLocation, useParams } from 'react-router-dom';
import { AllDocumentTypes, DocumentStatus } from 'types';
import { ReviewPackagePhotoCategory } from '../../../types/ReviewPackage';
import { DocumentSortField } from 'types';
import { Flag } from '../../QueueFlags/Flag';
import { QueueFlagsContext } from '../../QueueFlags/QueueFlagsContext';
import RequirePermissions from '../../auth/requirePermissions';
import { DocumentListItem } from './DocumentListItem';
import { DocumentPreview, DocumentPreviewModal } from '../../documents/DocumentPreviewModal';
import { FlagInfo } from '../../QueueFlags/FlagInfo';
import { isErrorWithData } from '@/services/helpers';

type PhotoListProps = {
  additionalDocumentsToFetch?: Array<{
    types: Array<AllDocumentTypes> | undefined;
    status?: Array<DocumentStatus>;
  }>;
  showDocumentActions?: boolean;
  allowArchive?: boolean;
  showUploadButton?: boolean;
  category: ReviewPackagePhotoCategory;
  className?: string;
  description?: string;
  documentationUrl?: string;
  title?: string;
  baseCategory?: string;
};

export function PhotosList({
  showDocumentActions = true,
  allowArchive = true,
  showUploadButton = true,
  category,
  className,
  description,
  documentationUrl,
  title = '',
  baseCategory,
}: PhotoListProps) {
  const { id } = useParams<{ id: any }>();
  const queryParams = new URLSearchParams(useLocation().search);
  const scrollTo = queryParams.get('scrollTo');
  const { data: documents = [], isLoading } = useGetDocumentsQuery({
    accountId: id,
    documentTypeFilter: [category],
    sort: DocumentSortField.ID_DESC,
  });
  const { isPhone } = useBreakpoint();
  const { getFlag, shouldHide } = useContext(QueueFlagsContext);
  const currentFlag = getFlag(title, undefined, false);
  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadDocumentMutation();

  const sectionRefs = useMemo(
    () => ({
      documents: createRef(),
    }),
    [],
  ) as any;

  useEffect(() => {
    if (scrollTo) {
      sectionRefs[scrollTo]?.current?.scrollIntoView();
    }
  }, [scrollTo, sectionRefs]);

  const handleFileUpload = async (event: any) => {
    const files = event.target?.files;
    if (!!files) {
      const data = new FormData();
      data.append('type', category);

      for (let i = 0; i < files.length; i++) {
        data.append(`files[${i}]`, files[i]);
      }

      try {
        await uploadDocument({ accountId: id, formData: data, invalidateAllTags: false }).unwrap();
        toast.success(`${title} photos uploaded`);
      } catch (e: any) {
        if (e.status === 413 || e.originalStatus === 413) {
          toast.error('File size too large. Please upload files smaller than 125MB.');
        } else if (isErrorWithData(e)) {
          toast.error(e.data.message);
        } else {
          toast.error('An error occurred while uploading photos');
        }
      }
    }
  };

  return (
    <Card className={className} background={currentFlag ? 'danger' : 'primary'}>
      <Box
        childGap="lg"
        padding="lg"
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        ref={sectionRefs.documents}
      >
        {currentFlag && (
          <Box direction="row" alignItems="center">
            <Box display="block" childGap="xs" padding={{ base: 'md lg', tablet: 'sm md md 0' }}>
              <Icon name="flag" size="lg" color="danger" />
            </Box>
            <Box
              display="block"
              childGap="2xs"
              padding={{ base: '0 lg', tablet: 'md md md 0' }}
              margin="0 2xl 0 0"
              width={{ base: '2xl' }}
            >
              <FlagInfo dataKey={title} />
            </Box>
          </Box>
        )}
        <Box gap="sm">
          <Box as="h3" fontWeight="medium" fontSize="md" direction="row" gap="md" alignItems="center">
            <Box>{title ? `${title}` : 'Documents'}</Box>
            {documents?.length > 1 && <Box fontSize="md" fontWeight="light">{`${documents?.length} files`}</Box>}
          </Box>
          <Box as="p">
            {description && description}
            {documentationUrl && (
              <TextLink href={documentationUrl} rel="noreferrer" target="_blank">
                See full requirements
              </TextLink>
            )}
          </Box>
        </Box>
        <Box direction="row" gap="md" alignItems="center">
          {!shouldHide && title && <Flag dataKey={title} />}

          {showUploadButton && (
            <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
              {isUploadingDocument ? (
                <Button
                  variant="secondary"
                  tone="neutral"
                  iconPrefix="upload"
                  isDisabled={true}
                  isLoading={isUploadingDocument}
                  size="sm"
                >
                  Upload
                </Button>
              ) : (
                <FileUpload
                  labelText="Upload"
                  id={`upload-${category}-photos`}
                  name={`${category}-files`}
                  accept="image/*,.pdf"
                  size="sm"
                  onChange={handleFileUpload}
                  multiple
                  buttonText={!isPhone ? 'Upload' : null}
                  onClick={(event: any) => event.stopPropagation()}
                  isDisabled={isUploadingDocument}
                />
              )}
            </RequirePermissions>
          )}
        </Box>
      </Box>
      <List
        isLoading={isLoading}
        items={documents as any}
        showDocumentActions={showDocumentActions}
        allowArchive={allowArchive}
        baseCategory={baseCategory}
      />
    </Card>
  );
}

interface ListProps {
  isLoading: boolean;
  items: any[];
  showDocumentActions?: boolean;
  allowArchive?: boolean;
  baseCategory?: string;
  title?: string;
}

function List({
  isLoading,
  items = [],
  showDocumentActions = true,
  allowArchive = true,
  baseCategory,
  title,
}: ListProps) {
  const totalCount = items?.length || 0;
  const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState(false);
  const documents: DocumentPreview[] = useMemo(
    () =>
      items?.map((item) => ({
        id: item.id,
        url: item.files[0]?.viewUrls?.[0]?.url,
        contentType: item.files[0]?.contentType,
        fileName: item.files[0]?.originalName,
        createdAt: item.meta.createdAt,
        type: item.type,
      })),
    [items],
  );
  const [targetDoc, setTargetDoc] = useState<DocumentPreview>(documents[0]);

  const togglePreviewModal = useCallback(
    (id: string) => {
      const targetDocument = documents.find((doc) => doc.id === id);
      setTargetDoc(targetDocument ?? documents[0]);
      setIsFilePreviewModalOpen(true);
    },
    [documents],
  );

  return (
    <>
      <Card.Section padding="0">
        {totalCount > 0 ? (
          <>
            <DocumentPreviewModal
              targetDoc={targetDoc}
              setTargetDoc={setTargetDoc}
              isFilePreviewModalOpen={isFilePreviewModalOpen}
              documentList={documents}
              closeFilePreviewModal={() => setIsFilePreviewModalOpen(false)}
              title={title}
              baseCategory={baseCategory}
            />
            {items.map((item) => {
              return (
                <DocumentListItem
                  item={item}
                  key={item.id}
                  showDocumentActions={showDocumentActions}
                  allowArchive={allowArchive}
                  showTypeLabel={false}
                  baseCategory={baseCategory}
                  onClick={() => togglePreviewModal(item.id)}
                />
              );
            })}
          </>
        ) : (
          <Box
            display="block"
            textAlign="center"
            padding="lg"
            childGap="xl"
            borderColor="separator"
            borderWidth="xs 0 0 0"
          >
            {isLoading ? (
              <Spinner size="lg" />
            ) : (
              <>
                <Box fontSize="sm" color="body-secondary">
                  No documents exist for this account
                </Box>
              </>
            )}
          </Box>
        )}
      </Card.Section>
    </>
  );
}
