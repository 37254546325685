import { useMemo } from 'react';
import { Box, FormikSelectInputNative } from '@palmetto/palmetto-components';
import { IDesignTools, InstallDocumentTypes } from 'types';
import { Field, useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { DesignDocsDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';
import { useGetDesignToolsQuery } from '../../services/designTools';
import { DocumentList } from '../MilestonePackagePartials/Documents/DocumentList';

export const Design = ({ allowArchive }: { allowArchive?: boolean }) => {
  const { data: designTools, isLoading: isAppSettingsLoading } = useGetDesignToolsQuery();

  const designToolsOptions = useMemo(() => {
    if (designTools === undefined) return [];

    const mappedDesignTools = Object.keys(designTools).map((key) => {
      return { value: key, label: designTools[key as keyof IDesignTools['value']] };
    });

    return mappedDesignTools;
  }, [designTools]);

  const { isSubmitting } = useFormikContext<any>();

  return (
    <FormSection description={DesignDocsDescription} title="Design Package Documents">
      <DocumentList
        documentTypeFilter={[
          InstallDocumentTypes.planSet,
          InstallDocumentTypes.productionModel,
          InstallDocumentTypes.shadeReport,
        ]}
        title="Design Package"
        baseCategory={"Design Package Documents"}
        allowArchive={allowArchive}
      />
      <Box width="50">
        <Field
          type="text"
          id="designTool"
          name="designTool"
          label="Design Tool"
          options={designToolsOptions}
          component={FormikSelectInputNative}
          isDisabled={isAppSettingsLoading || isSubmitting}
        />
      </Box>
    </FormSection>
  );
};
