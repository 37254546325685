import * as yup from 'yup';
import { Box, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { useCallback } from 'react';

export const createDomesticContentModifierValidationSchema = yup.object().shape({
  name: yup.string().required('Option Name is required'),
  domesticContentModifierPercent: yup
    .number()
    .min(0, 'Must be a positive number')
    .max(100, 'Must be less than 100')
    .test({
      name: 'exclusive-modifier',
      test: function (value) {
        const { domesticContentModifierPerWatt } = this.parent;
        if (!value) return true;
        if (domesticContentModifierPerWatt) {
          return this.createError({ message: 'Only a % or per watt can be submitted' });
        }
        return true;
      },
    })
    .test({
      name: 'greater-than-holdback',
      test: function (value) {
        const { domesticContentHoldbackPercent } = this.parent;
        if (!value || !domesticContentHoldbackPercent) return true;
        return (
          value > domesticContentHoldbackPercent ||
          this.createError({ message: 'Modifier must be greater than holdback' })
        );
      },
    }),
  domesticContentModifierPerWatt: yup
    .number()
    .min(0, 'Must be a positive number')
    .test({
      name: 'exclusive-modifier',
      test: function (value) {
        const { domesticContentModifierPercent } = this.parent;
        if (!value) return true;
        if (domesticContentModifierPercent) {
          return this.createError({ message: 'Only a % or per watt can be submitted' });
        }
        return true;
      },
    })
    .test({
      name: 'greater-than-holdback',
      test: function (value) {
        const { domesticContentHoldbackPerWatt } = this.parent;
        if (!value || !domesticContentHoldbackPerWatt) return true;
        return (
          value > domesticContentHoldbackPerWatt ||
          this.createError({ message: 'Modifier must be greater than holdback' })
        );
      },
    }),
  domesticContentHoldbackPercent: yup
    .number()
    .min(0, 'Must be a positive number')
    .max(100, 'Must be less than 100')
    .test({
      name: 'matching-type',
      test: function (value) {
        const { domesticContentModifierPerWatt, domesticContentModifierPercent } = this.parent;
        if (!value) return true;
        if (domesticContentModifierPerWatt) {
          return this.createError({ message: 'Holdback requires a $/watt' });
        }
        return (
          value < domesticContentModifierPercent ||
          this.createError({ message: 'Holdback must be less than modifier' })
        );
      },
    }),
  domesticContentHoldbackPerWatt: yup
    .number()
    .min(0, 'Must be a positive number')
    .test({
      name: 'matching-type',
      test: function (value) {
        const { domesticContentModifierPerWatt, domesticContentModifierPercent } = this.parent;
        if (!value) return true;
        if (domesticContentModifierPercent) {
          return this.createError({ message: 'Holdback requires a percent' });
        }
        return (
          value < domesticContentModifierPerWatt ||
          this.createError({ message: 'Holdback must be less than modifier' })
        );
      },
    }),
});

export const CreateDomesticContentModifierForm = () => {
  const { setFieldTouched, setFieldValue, values } = useFormikContext<{
    domesticContentModifierPercent: number;
    domesticContentModifierPerWatt: number;
    domesticContentHoldbackPercent: number;
    domesticContentHoldbackPerWatt: number;
  }>();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, exclusive } = event.target as any;
      setFieldValue(name, value);
      if (exclusive) {
        setFieldValue(exclusive, null);
        setFieldTouched(exclusive, true, true);
      }
    },
    [setFieldTouched, setFieldValue],
  );

  const calculateNetEffect = () => {
    const {
      domesticContentModifierPercent,
      domesticContentModifierPerWatt,
      domesticContentHoldbackPercent,
      domesticContentHoldbackPerWatt,
    } = values;

    if (domesticContentModifierPercent) {
      if (domesticContentHoldbackPercent) {
        return `${(domesticContentModifierPercent - domesticContentHoldbackPercent).toFixed(2)}%`;
      }
      return `${domesticContentModifierPercent}%`;
    }
    if (domesticContentModifierPerWatt) {
      if (domesticContentHoldbackPerWatt) {
        return `$${(domesticContentModifierPerWatt - domesticContentHoldbackPerWatt).toFixed(2)}/W`;
      }
      return `$${domesticContentModifierPerWatt}/W`;
    }
    return '$0.0/W';
  };

  return (
    <Box direction="column" childGap="md">
      <Field component={FormikTextInput} label="Option Name" name="name" id="name" isRequired />
      <Box childGap="md">
        <Box fontWeight="medium" color="body-primary">
          <Box color="body-primary">Enter a % or $/watt modifier</Box>
        </Box>
        <Box fontWeight="bold" color="body-primary">
          Modifier
        </Box>
        <Box direction={{ base: 'column', desktop: 'row' }} childGap="xl" width="auto" flex="auto">
          <Field
            component={FormikTextInput}
            label=""
            name="domesticContentModifierPercent"
            id="domesticContentModifierPercent"
            type="number"
            suffix="%"
            onChange={handleChange}
            exclusive="domesticContentModifierPerWatt"
          />
          <Field
            component={FormikTextInput}
            label=""
            name="domesticContentModifierPerWatt"
            id="domesticContentModifierPerWatt"
            type="number"
            prefix="$"
            suffix="/W"
            onChange={handleChange}
            exclusive="domesticContentModifierPercent"
          />
        </Box>
        <Box fontWeight="medium" color="body-primary">
          <Box color="body-primary">Enter a % or $/watt holdback</Box>
        </Box>
        <Box fontWeight="bold" color="body-primary">
          Holdback
        </Box>
        <Box direction={{ base: 'column', desktop: 'row' }} childGap="xl" width="auto" flex="auto">
          <Field
            component={FormikTextInput}
            label=""
            name="domesticContentHoldbackPercent"
            id="domesticContentHoldbackPercent"
            type="number"
            suffix="%"
          />
          <Field
            component={FormikTextInput}
            label=""
            name="domesticContentHoldbackPerWatt"
            id="domesticContentHoldbackPerWatt"
            type="number"
            prefix="$"
            suffix="/W"
          />
        </Box>
        <Box fontWeight="medium" color="body-primary">
          <Box color="body-primary">The net value will be the pricing shown to the sales rep</Box>
        </Box>
        <Box direction={'row'} childGap="xl" width="auto" flex="auto">
          <Box fontWeight="bold" color="body-primary">
            Net Pricing Effect
          </Box>
          <Box color="body-primary">{calculateNetEffect()}</Box>
        </Box>
      </Box>
    </Box>
  );
};
