export enum ParentReason {
  conditionalApproval = 'Conditional Approval',
  inverterMonitoringId = 'Inverter Monitoring ID',
  batteryMonitoringId = 'Battery Monitoring ID',
  incentives = 'Incentives',
  extendedWarranty = 'Extended Warranty',
}

export enum HvacParentReason {
  systemInstallDate = 'System Install Date',
  ahriNumber = 'AHRI Number',
  installationDocument = 'Installation Document',
  projectSite = 'Project Site',
  HVACInstallation = 'HVAC Installation',
  staticPressureReadings = 'Static Pressure Readings',
  voltageReadings = 'Voltage Readings',
  laserTherm = 'Laser Therm Reading',
  installedEquipment = 'Installed Equipment',
}

export enum HvacSystemInstallDateRejectionOptions {
  installDateMissMatch = 'Install Date Mismatch',
  other = 'Other',
}

export enum HvacStaticPressureReadingsRejectionOptions {
  notCompleted = 'Not Completed',
}

export enum InverterMonitoringIdRejectionOptions {
  systemSettings = 'System Settings',
  ownershipAccess = 'Ownership Access',
  ctIssues = 'CT Issues',
  physicalLayout = 'Physical Layout',
  productionConsumptionData = 'Production/Consumption Data',
  systemEventError = 'System Event/Error',
}

export enum BatteryMonitoringIdRejectionOptions {
  systemSettings = 'System Settings',
  ownershipAccess = 'Ownership Access',
  ctIssues = 'CT Issues',
  productionConsumptionData = 'Production/Consumption Data',
  systemEventError = 'System Event/Error',
}

export enum IncentivesRejectionOptions {
  notComplete = 'Not Complete',
  missingDocumentation = 'Missing Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  illegibleDocumentation = 'Illegible Documentation',
}

export enum HvacNtpRejectionReasonOptions {
  systemCost = 'System Cost',
  workOrder = 'Work Order',
  systemDetails = 'System Details',
}

export enum ExtendedWarrantyRejectionOptions {
  notComplete = 'Not Complete',
  missingDocumentation = 'Missing Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  illegibleDocumentation = 'Illegible Documentation',
}

export enum ConditionalApprovalOptions {
  mOneApprovalStip = 'M1 Approval Stip - Missing/Incorrect',
  CommControlCable = 'Comm/Control Cable - Missing/Incorrect',
  inlineFilter = 'Inline Filter - Missing/Incorrect',
  asBuilt = 'As Built - Missing/Incorrect',
  electricalRedFlag = 'Electrical Red Flag',
  missingDocumentation = 'Missing Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  illegibleDocumentation = 'Illegible Documentation',
}

export enum MediumToHighPriorityRejectionOptions {
  reducesCircleTimesWithCommonIssues = 'Reduces Circle Times with Common Issues',
  mTwoFirstTouchApprovalStip = 'M2 First Touch Approval',
  reduceReviewDuration = 'Reduce Review Duration',
  auditDiligenceCoverage = 'Audit Diligence Coverage',
}

export enum SystemSettingsRejectionSubOptions {
  pcs = 'PCS - Missing/Incorrect',
  systemDetails = 'System Details - Incorrect',
  arbitrageSettings = 'Arbitrage Settings - Missing/Incorrect',
  backupSettings = 'Backup Settings - Missing/Incorrect',
  cellularModem = 'Cellular Modem - Incorrect',
}

export enum SystemSettingsRejectionSubOptionsForBatteryMonitoringId {
  pcs = 'PCS - Missing/Incorrect',
  systemDetails = 'System Details - Incorrect',
  arbitrageSettings = 'Arbitrage Settings - Missing/Incorrect',
  backupSettings = 'Backup Settings - Missing/Incorrect',
}

export enum OwnershipAccessRejectionSubOptions {
  missingThirdPartyAssignment = 'Missing 3rd Party Assignment',
  ownershipToLightReach = 'Ownership to LightReach - Incomplete',
  noAccessOrIncorrectSiteId = 'No Access/Incorrect Site ID',
}

export enum CtIssuesRejectionSubOptions {
  productionConsumptionCts = 'Production/Consumption CTs - Missing/Disabled',
  polarityIncorrect = 'Polarity - Incorrect',
  locationConfiguration = 'Location/Configuration - Incorrect',
  terminationsSplices = 'Terminations/Splices - Missing/Incorrect',
  lOneLTwoPhases = 'L1/L2 Phases - Incorrect',
  secondSystem = '2nd System - Missing/Incorrect',
  additionalCts = 'Additional CTs - Missing/Incorrect',
}

export enum CtIssuesRejectionSubOptionsForBatteryMonitoringId {
  polarityIncorrect = 'Polarity - Incorrect',
  locationConfiguration = 'Location/Configuration - Incorrect',
  terminationsSplices = 'Terminations/Splices - Missing/Incorrect',
  storageCt = 'Storage CT - Missing/Incorrect',
}

export enum PhysicalLayoutRejectionSubOptions {
  layoutMissMatch = 'Layout Mismatch',
  moduleAssignment = 'Module Assignment - Missing/Incorrect',
  tiltAzimuth = 'Tilt/Azimuth - Incorrect',
}

export enum ProductionConsumptionDataRejectionSubOptions {
  productionData = 'Production Data - Missing/Incorrect',
  consumptionData = 'Consumption Data - Missing/Correlating/Incorrect',
  consumptionDataNegative = 'Consumption Data - Negative',
}

export enum ProductionConsumptionDataRejectionSubOptionsForBatteryMonitoringId {
  storageChargeDischargeData = 'Storage Charge/Discharge Data - Missing/Incorrect',
}

export enum SystemEventErrorRejectionSubOptions {
  microInverterGatewayMeterNotCommunicating = 'Microinverter/Gateway/Meter Not Communicating',
  pcsProductionErrorPowerGenerationOffByCommand = 'PCS Production Error/Power Generation Off by Command',
  miscellaneous = 'Miscellaneous',
}

export enum SystemEventErrorRejectionSubOptionsForBatteryMonitoringId {
  microInverterGatewayMeterNotCommunicating = 'Microinverter/Gateway/Meter Not Communicating',
  batteryNotCommunicating = 'Battery Not Communicating',
  miscellaneous = 'Miscellaneous',
}

export const hvacInstallPackageRejectionReasons = {
  [HvacParentReason.systemInstallDate]: HvacSystemInstallDateRejectionOptions,
  [HvacParentReason.staticPressureReadings]: HvacStaticPressureReadingsRejectionOptions,
};

export const activationPackageRejectionReasons = {
  [ParentReason.conditionalApproval]: ConditionalApprovalOptions,
  [ParentReason.inverterMonitoringId]: InverterMonitoringIdRejectionOptions,
  [ParentReason.batteryMonitoringId]: BatteryMonitoringIdRejectionOptions,
  [ParentReason.incentives]: IncentivesRejectionOptions,
  [ParentReason.extendedWarranty]: ExtendedWarrantyRejectionOptions,
};

export const activationPackageSubRejectionReasons = {
  [InverterMonitoringIdRejectionOptions.systemSettings]: SystemSettingsRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.ownershipAccess]: OwnershipAccessRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.ctIssues]: CtIssuesRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.physicalLayout]: PhysicalLayoutRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.productionConsumptionData]: ProductionConsumptionDataRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.systemEventError]: SystemEventErrorRejectionSubOptions,
};

export const activationPackageSubRejectionReasonsForBatteryMonitoringId = {
  [InverterMonitoringIdRejectionOptions.systemSettings]: SystemSettingsRejectionSubOptionsForBatteryMonitoringId,
  [InverterMonitoringIdRejectionOptions.ownershipAccess]: OwnershipAccessRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.ctIssues]: CtIssuesRejectionSubOptionsForBatteryMonitoringId,
  [InverterMonitoringIdRejectionOptions.productionConsumptionData]:
    ProductionConsumptionDataRejectionSubOptionsForBatteryMonitoringId,
  [InverterMonitoringIdRejectionOptions.systemEventError]: SystemEventErrorRejectionSubOptionsForBatteryMonitoringId,
};

export type PackageRejectionInfo = {
  option: ParentReason;
  rejectionReasons: [
    {
      reason: (typeof activationPackageRejectionReasons)[];
      subReasons: (typeof activationPackageSubRejectionReasons)[];
    },
  ];
};
