import { Box, FormikSelectInputNative } from '@palmetto/palmetto-components';
import { Field } from 'formik';
import { useGetModifiersQuery, useGetHoldbacksQuery } from '../../../../services/pricing';
import { sortByLabel } from '../util';
import { useMemo } from 'react';
import { ProgramType } from 'types';
import { useParams } from 'react-router-dom';

const removeModifierOption = { label: 'x--Remove Modifier--x', value: 'remove' };
export const BulkEditModifiersFormSection = () => {
  const { programType } = useParams<{ programType: string }>();

  const { data: energyCommunityAdders = [] } = useGetModifiersQuery({
    type: 'energyCommunity',
    programType: programType as ProgramType,
  });
  const { data: ppwModifiers = [] } = useGetModifiersQuery({
    type: 'ppwAdjustment',
    programType: programType as ProgramType,
  });
  const { data: holdbacks = [] } = useGetHoldbacksQuery({ programType: programType as ProgramType });

  const holdbackOptions = useMemo(
    () => [
      removeModifierOption,
      ...holdbacks.map((hb: any) => ({ label: hb.name, value: hb.id })).sort(sortByLabel),
    ],
    [holdbacks],
  );
  const ppwModifierOptions = useMemo(
    () => [
      removeModifierOption,
      ...ppwModifiers.map((ppw: any) => ({ label: ppw.name, value: ppw.id })).sort(sortByLabel),
    ],
    [ppwModifiers],
  );
  const energyCommunityOptions = useMemo(
    () => [
      removeModifierOption,
      ...energyCommunityAdders.map((ec: any) => ({ label: ec.name, value: ec.id })).sort(sortByLabel),
    ],
    [energyCommunityAdders],
  );
  return (
    <Box childGap="lg">
      <Field
        name="holdback"
        id="holdback"
        label="Holdback"
        component={FormikSelectInputNative}
        options={holdbackOptions}
      />
      <Field
        name="energyCommunity"
        id="energyCommunity"
        label="Energy Community"
        component={FormikSelectInputNative}
        options={energyCommunityOptions}
      />
      <Field
        name="ppwModifier"
        id="ppwModifier"
        label="PPW Modifier"
        component={FormikSelectInputNative}
        options={ppwModifierOptions}
      />
    </Box>
  );
};
