import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';
import { Diffs } from './Diffs';

const hiddenProperties = ['meta', '_id', 'id', 'createdDate'];

const SabalPriceSheetActivity = ({ event, actionLabel }: { event: any, actionLabel: string }) => {
  return (
    <Box display="block">
      <EventBy event={event} /> {actionLabel}
      <br />
      <Diffs hideInitialNulls={true} diff={event?.data?.diff} hiddenProperties={hiddenProperties} />
    </Box>
  );
};

export default SabalPriceSheetActivity;
