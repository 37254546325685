import { Alert } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import { MilestoneType } from 'types';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useGetAccountQuery } from '../../services/accounts';
import { MainContainer } from '../MainContainer';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import { NTPPackage } from './NTPPackage';
import AdminStipulationBannerList from '../Stipulations/AdminStipulationBannerList';
import { NeedsAttestmentGuard } from '../NeedsAttestmentGuard/NeedsAttestmentGuard';

export const NTPMilestonePackage = () => {
  const { id, action } = useParams<{ id: any; action: ReviewPackageActions }>();
  const { data: account, isLoading: accountIsLoading }: any = useGetAccountQuery(id);

  const canSubmitNTPPackage = useMemo(() => {
    if (!account) return false;
    return (
      account?.currentMilestone?.type !== MilestoneType.customerAgreement ||
      account?.currentMilestone?.type !== MilestoneType.initialAccountDetails ||
      false
    );
  }, [account]);

  return (
    <>
      <Helmet>
        <title>{`${account?.primaryApplicantName} Notice to Proceed Package`}</title>
      </Helmet>
      <NeedsAttestmentGuard>
        <MainContainer>
          <AdminStipulationBannerList />
          <PageHeader
            eyebrow={
              <>
                <Link to="/accounts">Accounts</Link> \{' '}
                <Link to={`/accounts/${id}`}>{`${account?.primaryApplicantName}`}</Link>
              </>
            }
            title={`Notice to Proceed Package${accountIsLoading ? '' : ': ' + account?.primaryApplicantName}`}
            description="Please provide the required documentation and confirm equipment to be installed below."
          />
          {account && action !== ReviewPackageActions.review && !canSubmitNTPPackage && (
            <Alert
              variant="warning"
              hasIcon
              title="Customer Agreement has not been issued"
              message="Customer Agreement must be completed before submitting the Notice to Proceed Package."
            />
          )}
          {accountIsLoading ? (
            <FormSection title="Loading...">
              <SkeletonBox height="25px" width="100" />
            </FormSection>
          ) : (
            <NTPPackage canSubmitPackage={canSubmitNTPPackage} />
          )}
        </MainContainer>
      </NeedsAttestmentGuard>
    </>
  );
};
