import { MilestoneRequirement, MilestoneStatus } from 'types';
import { MilestoneType } from '../../../types/Milestone';
import { Badge, Box } from '@palmetto/palmetto-components';

export const ProgressBadge = ({ milestones = [] }: { milestones?: any[] }) => {
  const milestoneRequirements = milestones.find(
    (milestone: any) => milestone.type === MilestoneType.noticeToProceed,
  )?.requirements;
  let progressBadge;
  if (
    [MilestoneStatus.approved, MilestoneStatus.submitted].includes(
      milestoneRequirements?.find((requirement: any) => requirement.type === MilestoneRequirement.contractSigned)
        ?.status,
    )
  ) {
    progressBadge = 'active contract';
  } else if (
    milestoneRequirements?.find((requirement: any) => requirement.type === MilestoneRequirement.quoteActive)
      ?.status === 'completed'
  ) {
    progressBadge = 'active quote';
  }

  return progressBadge ? (
    <Box alignItems="flex-start">
      <Badge variant="info" message={progressBadge} />
    </Box>
  ) : null;
};
