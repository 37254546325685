import { AllDocumentTypes, ActivationPackagePhotoCategory, ActivationDocumentTypes } from 'types';
import { TaskType } from '../../types/Tasks';

export const taskTypeToActivationDocumentType: Record<string, AllDocumentTypes> = {
  [TaskType.ptoConfirmation]: ActivationDocumentTypes.ptoConfirmation,
  [TaskType.extendedWarranty]: ActivationDocumentTypes.extendedWarranty,
  [TaskType.incentives]: ActivationDocumentTypes.incentives,
};

export const taskTypeToActivationPhotoSection: Record<string, ActivationPackagePhotoCategory> = {
  [TaskType.siteImprovements]: ActivationPackagePhotoCategory.siteImprovements,
};
