import { Box, Button, Modal, TextareaInput } from '@palmetto/palmetto-components';
import { ChangeEvent, useCallback, useState } from 'react';

interface PaymentHoldModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: (reason: string) => void;
}

export const PaymentHoldModal = ({ isOpen, onDismiss, onConfirm }: PaymentHoldModalProps) => {
  const [reason, setReason] = useState('');
  const setReasonText = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event.target.value);
  }, []);

  const hide = useCallback(() => {
    setReason('');
    onDismiss();
  }, [onDismiss]);

  const confirm = useCallback(() => {
    onConfirm(reason);
    hide();
  }, [reason, onConfirm, hide]);

  return (
    <Modal isOpen={isOpen} maxWidth="4xl" ariaLabelledBy="paymentHoldHeader" onDismiss={hide}>
      <Modal.Header id="paymentHoldHeader" title="Set Payment Hold" onDismiss={hide} />
      <Modal.Body background="secondary" childGap="lg">
        <Box>
          <TextareaInput
            id="reason"
            label="Reason for Payment Hold"
            value={reason}
            onChange={setReasonText}
            rows={3}
          />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button as="button" onClick={hide} variant="secondary" tone="neutral" size="md">
          Cancel
        </Button>
        <Button size="md" type="submit" onClick={confirm} disabled={!reason}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
