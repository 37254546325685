import { useEffect, useState } from 'react';
import { Box, Button, CheckboxInput, Modal, ModalProps } from '@palmetto/palmetto-components';
import { FormikValues } from 'formik';

export interface AttestationModalProps extends ModalProps {
  onAttestationConfirm: (attestation: boolean) => void;
  label: string;
  title: string;
  isLoading: boolean;
  values: FormikValues;
  handleSubmit: (values: FormikValues, { setSubmitting }: any) => Promise<void>;
  setSubmitting: (isSubmitting: boolean) => void;
}
/**
 * @description This is the confirmation modal shown to attest certain data
 */
export function HVACAttestationModal({
  isOpen,
  onDismiss,
  label,
  title,
  isLoading,
  onAttestationConfirm,
  handleSubmit,
  values,
  setSubmitting,
}: AttestationModalProps) {

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth="4xl" ariaLabelledBy="confirmContractVoiding">
      <Modal.Header id="confirmContractVoiding" title={title} onDismiss={onDismiss} />
      <Modal.Body background="secondary" childGap="lg">
        <Box
          fontSize={'sm'}
          color="body-primary"
          data-testid="void-confirmation-modal-body"
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          direction={'row'}
        >
          <CheckboxInput
            id="attestationModalCheckbox"
            label=""
            onChange={() => {
              setChecked(!checked);
              onAttestationConfirm(!checked);
            }}
            isChecked={checked}
            isDisabled={isLoading}
          />
          <Box>{label}</Box>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          tone="neutral"
          onClick={() => {
            onDismiss();
            setChecked(false);
          }}
          id="attestationModalCancelCTA"
          disabled={isLoading}
        >
          <Box>Cancel</Box>
        </Button>
        <Button
          variant="primary"
          tone="primary"
          onClick={() => checked && handleSubmit(values, { setSubmitting })}
          disabled={!checked}
          id="attestationModalProceedCTA"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          <Box>Proceed</Box>
        </Button>
      </Modal.Footer>
    </Modal >
  );
}
