import { useParams } from 'react-router-dom';
import {
  useDeleteOrganizationPpwModifierMutation,
  useLazyGetOrganizationPpwModifiersQuery,
} from '../../../../../services/pricing';
import { useEffect } from 'react';
import { Box, Button, Card, Column, Table, toast, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { TableLoading } from '../../../../TableLoading';
import { CreatePpwModifier } from './modals/CreatePpwModifier';
import RequirePricingAdminUser from '@/components/auth/RequirePricingAdminUser';

export const PpwModifiers = () => {
  const { alias = '' } = useParams<{ alias: string }>();
  const { isPhone } = useBreakpoint();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [trigger, { data, isLoading, isFetching }] = useLazyGetOrganizationPpwModifiersQuery();
  const [deleteOrganizationPpwModifierMutation] = useDeleteOrganizationPpwModifierMutation();

  useEffect(() => {
    if (alias) {
      trigger({ alias });
    }
  }, [alias, trigger]);

  const handleArchive = async (id: string) => {
    try {
      await deleteOrganizationPpwModifierMutation({ alias, id }).unwrap();
      toast.success('PPW Modifier Archived successfully');
    } catch (e) {
      console.log(e);
      toast.error('Error archiving PPW Modifier');
    }
  };

  const columnConfig: Column[] = [
    { heading: 'Name', dataKey: 'name' },
    { heading: 'org', dataKey: 'organizationName' },
    { heading: 'State', dataKey: 'state' },
    { heading: 'Modifier Amount', dataKey: 'amount' },
    {
      heading: '',
      render: (_cell: any, row: any) => (
        <RequirePricingAdminUser>
          <Button
            size="sm"
            variant="secondary"
            tone="neutral"
            onClick={() => {
              handleArchive(row.id);
            }}
          >
            Archive
          </Button>
        </RequirePricingAdminUser>
      ),
    },
  ];
  return (
    <>
      <CreatePpwModifier isModalOpen={isModalOpen} closeModal={closeModal} />
      <Card.Header>
        <RequirePricingAdminUser>
          <Box direction="row" justifyContent="space-between">
            <Box />
            <Button
              as="button"
              variant="primary"
              iconPrefix="add"
              size={{ base: 'sm', desktop: 'md' }}
              aria-label="create adder"
              onClick={() => openModal()}
            >
              {!isPhone && 'PPW Modifier'}
            </Button>
          </Box>
        </RequirePricingAdminUser>
      </Card.Header>
      <Box margin="0 lg 0 lg">
        {isFetching || isLoading ? (
          <TableLoading rows={12} />
        ) : (
          <Table
            rowKey="id"
            columns={columnConfig as Column[]}
            rows={(data && data.ppwModifiers) ?? []}
            isLoading={isLoading || isFetching}
            isScrollable={{ y: true }}
            hasStickyHeader
          />
        )}
      </Box>
    </>
  );
};
