import React, { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { filterOutDisabledFields, createEditAccountRequestBody } from '../../helpers/accountRequestHelpers';
import { Box, toast } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import { useEditAccountMutation, useGetAccountQuery } from '../../services/accounts';
import { AccountForm } from './AccountForm';
import { getDisabledFieldsBasedOnStatus } from './getDisabledFieldsBasedOnStatus';
import { isErrorWithData } from '../../services/helpers';
import { useAuth } from '../auth/authProvider';
import { MainContainer } from '../MainContainer';
import { ApplicantType, ExternalReferenceType, Locale } from 'types';

export const EditAccount = () => {
  const { id } = useParams<{ id: any }>();
  const { data: accountData, isLoading }: any = useGetAccountQuery(id);
  const [editAccount] = useEditAccountMutation();
  const navigate = useNavigate();
  const { claims } = useAuth();
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const filteredFields = filterOutDisabledFields({ ...accountData, ...values }, disabledFields as string[]);
      const request = createEditAccountRequestBody({ ...filteredFields, hasAdditionalApplicant });
      await editAccount({
        account: request,
        id,
      }).unwrap();
      toast.success('Account updated successfully');
      navigate(`/accounts/${id}`);
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error updating account: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error updating account');
      }
    }
    setSubmitting(false);
  };

  const disabledFields = useMemo(() => {
    if (isLoading || accountData?.status === undefined) {
      return;
    }
    return getDisabledFieldsBasedOnStatus(accountData?.status, claims);
  }, [accountData, isLoading, claims]);

  const hasAdditionalApplicant = useMemo(() => {
    if (isLoading || !accountData) return false;
    return accountData?.applicants.some((a: any) => a.type === ApplicantType.secondary);
  }, [accountData, isLoading]);

  const initialValues = useMemo(() => {
    if (isLoading || !accountData) return;
    const primaryAccountApplicant = accountData?.applicants.find((applicant: any) => applicant.type === 'primary');
    const secondaryAccountApplicant = accountData?.applicants.find(
      (applicant: any) => applicant.type === ApplicantType.secondary,
    );
    const programReferenceId = accountData?.externalReferenceIds?.find(
      (ref: any) => ref.type === ExternalReferenceType.doe,
    )?.id;
    getDisabledFieldsBasedOnStatus(accountData?.status, claims);
    return {
      address1: accountData.address.address1,
      address2: accountData.address?.address2,
      city: accountData.address.city,
      state: accountData.address.state,
      zip: accountData.address.zip,
      lat: accountData?.coordinates?.lat,
      lon: accountData?.coordinates?.lon,
      // applicant info
      primary: {
        firstName: primaryAccountApplicant?.firstName,
        lastName: primaryAccountApplicant.lastName,
        middleName: primaryAccountApplicant?.middleName,
        phoneNumber: primaryAccountApplicant?.phoneNumber,
        email: primaryAccountApplicant.email,
      },
      addAdditionalApplicant: secondaryAccountApplicant ? true : false,
      ...(secondaryAccountApplicant && {
        secondary: {
          firstName: secondaryAccountApplicant?.firstName,
          lastName: secondaryAccountApplicant.lastName,
          middleName: secondaryAccountApplicant?.middleName,
          phoneNumber: secondaryAccountApplicant?.phoneNumber,
          email: secondaryAccountApplicant.email,
        },
      }),
      applicantAddress1: primaryAccountApplicant.address.address1,
      applicantAddress2: primaryAccountApplicant.address?.address2,
      applicantCity: primaryAccountApplicant.address.city,
      applicantState: primaryAccountApplicant.address.state,
      applicantZip: primaryAccountApplicant.address.zip,
      // sales rep info
      salesRepName: accountData.salesRepName,
      salesRepLicenseNumber: accountData.salesRepLicenseNumber,
      salesRepEmail: accountData.salesRepEmail,
      salesRepPhoneNumber: accountData.salesRepPhoneNumber,
      reference: accountData.externalReference,
      programReferenceId,
      programType: accountData.programType,
      language: accountData.language,
      // utility info
      lseId: accountData?.utility?.lseId,
      tariffId: accountData?.utility?.tariffId,
      rate: accountData?.utility?.rate,
    };
  }, [accountData, isLoading, claims]);
  return (
    <>
      <Helmet>
        <title>Edit Account</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          title="Edit Account"
          eyebrow={<Link to={`/accounts/${accountData?.id}`}>{accountData?.primaryApplicantName}</Link>}
        />
        <Box
          padding={{
            base: '0 lg',
            tablet: '0',
          }}
        >
          {initialValues && (
            <AccountForm
              initialValues={initialValues}
              handleSubmit={handleSubmit}
              disabledFields={disabledFields}
              hasAdditionalApplicantData={hasAdditionalApplicant}
              programTypes={accountData.programType ? [accountData.programType] : []}
            />
          )}
        </Box>
      </MainContainer>
    </>
  );
};
