import { Helmet } from 'react-helmet';
import { Button, useBreakpoint } from '@palmetto/palmetto-components';
import PageHeader from './PageHeader';
import RequirePermissions from './auth/requirePermissions';
import { AccountsSummary } from './AccountsSummary';
import { MainContainer } from './MainContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function Accounts() {
  const navigate = useNavigate();
  const { isPhone } = useBreakpoint();
  const [searchParams] = useSearchParams();
  const cancelled = searchParams.get('cancelled');
  const showCancelled = cancelled !== null;
  const postActivation = searchParams.get('postActivation');
  const showPostActivation = postActivation !== null;

  const titlePrefix = showCancelled ? 'Cancelled' : showPostActivation ? 'Post-Activation' : '';

  return (
    <>
      <Helmet>
        <title>{titlePrefix} Accounts</title>
      </Helmet>
      <MainContainer>
        <PageHeader title={`${titlePrefix} Accounts`}>
          <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
            <Button
              as="a"
              variant="primary"
              iconPrefix="add"
              size={{ base: 'sm', desktop: 'md' }}
              aria-label="create account"
              navigate={() => navigate(`/account/create`)}
            >
              {!isPhone && 'Account'}
            </Button>
          </RequirePermissions>
        </PageHeader>
        <AccountsSummary />
      </MainContainer>
    </>
  );
}
