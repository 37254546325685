import { useMemo } from 'react';
import { isEqual } from 'lodash';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { Box, Button, toast } from '@palmetto/palmetto-components';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { useGetDisclosuresByTypeQuery } from '@/services/disclosures';
import { MainContainer } from '@/components';
import { isErrorWithData } from '@/services/helpers';
import { useInviteToken } from '@/components/auth/useInviteToken';
import { ApplicantType, DisclosureType, Locale, ProgramType } from 'types';
import { DisclosureCard } from '@/components/Disclosures/DisclosureCard';
import FormSection from '@/components/Forms/FormSection';
import { AddressFormFields } from '@/components/AddressFormFields/AddressFormFields';
import { useAcceptTermsAndConditionsMutation } from '@/services/termsAndConditions';
import { useEditAccountMutation, useGetAccountWithTokenQuery } from '@/services/accounts';

const accountSchema = yup.object().shape({
  address1: yup.string().required('Address 1 is a required field.'),
  address2: yup.string(),
  city: yup.string().required('City is a required field.'),
  state: yup.string().required('State is a required field.'),
  zip: yup.string().required('Zip Code is a required field.'),
  lat: yup.number(),
  lon: yup.number(),
  applicantAddress1: yup.string().required('Address 1 is a required field.'),
  applicantAddress2: yup.string(),
  applicantCity: yup.string().required('City is a required field.'),
  applicantState: yup.string().required('State is a required field.'),
  applicantZip: yup.string().required('Zip Code is a required field.'),
});

const emptySchema = yup.object().shape({});

export function DisclosureForm() {
  const { id } = useParams<{ id: any }>();
  const { inviteToken } = useInviteToken();
  const navigate = useNavigate();

  const { data: account, isLoading: isAccountLoading }: any = useGetAccountWithTokenQuery({
    id,
    inviteToken,
  });

  const primaryApplicant = account?.applicants?.find((applicant: any) => applicant.type === ApplicantType.primary);
  const provideSpanish = account?.language === Locale.es;
  const disclosureLanguages = provideSpanish ? [Locale.en, Locale.es] : [Locale.en];
  const programIsDoePr = account?.programType === ProgramType.doePr;
  const collectAddresses = programIsDoePr;

  // these can be parameterized to handle further disclosures in the future
  const disclosureType = programIsDoePr ? DisclosureType.termsAndConditions : DisclosureType.creditApplication;
  const initialValues = {
    [disclosureType]: false,
    ...(collectAddresses
      ? {
          address1: account?.address?.address1,
          address2: account?.address?.address2,
          city: account?.address?.city,
          state: account?.address?.state,
          zip: account?.address?.zip,
          lat: account?.coordinates?.lat,
          lon: account?.coordinates?.lon,
          applicantAddress1: primaryApplicant?.address?.address1,
          applicantAddress2: primaryApplicant?.address?.address2,
          applicantCity: primaryApplicant?.address?.city,
          applicantState: primaryApplicant?.address?.state,
          applicantZip: primaryApplicant?.address?.zip,
        }
      : {}),
  };
  const [acceptTermsAndConditions] = useAcceptTermsAndConditionsMutation();
  const [editAccount] = useEditAccountMutation();
  // we may eventually be able to get what disclosures are required from milestone requirements
  const { data: disclosures, isLoading: isDisclosuresLoading } = useGetDisclosuresByTypeQuery({
    type: disclosureType,
    inviteToken,
    language: disclosureLanguages,
  });

  const sortedDisclosures = useMemo(() => {
    if (!disclosures) {
      return [];
    }
    const sorted = [...disclosures].sort((a: any, b: any) => {
      if (a.language === Locale.es) return -1;
      if (b.language === Locale.es) return 1;
      return 0;
    });
    return sorted;
  }, [disclosures]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (!values[disclosureType]) {
      toast.error('Please acknowledge the disclosure');
      setSubmitting(false);
      return;
    }
    try {
      if (collectAddresses) {
        const address = {
          address1: values?.address1?.trim(),
          ...(values?.address2 && { address2: values.address2?.trim() }),
          city: values?.city?.trim(),
          state: values?.state,
          zip: values?.zip?.trim(),
        };
        const applicantAddress = {
          address1: values?.applicantAddress1?.trim(),
          ...(values?.applicantAddress2 && { address2: values.applicantAddress2?.trim() }),
          city: values?.applicantCity?.trim(),
          state: values?.applicantState,
          zip: values?.applicantZip?.trim(),
        };
        const hasAddressChanged =
          !isEqual(address, account?.address) || !isEqual(applicantAddress, primaryApplicant?.address);

        if (hasAddressChanged) {
          // For Puerto Rico, per requirements, we are always reusing the original US Dept of Energy-supplied lat/lon coordinates.
          // https://palmetto.atlassian.net/browse/FIN-1723
          await editAccount({
            account: {
              address,
              ...(account?.programType !== ProgramType.doePr
                ? { coordinates: { lat: values?.lat, lon: values?.lon } }
                : { coordinates: { lat: account?.coordinates?.lat, lon: account?.coordinates?.lon } }),
              applicants: [
                {
                  type: ApplicantType.primary,
                  phoneNumber: primaryApplicant.phoneNumber,
                  email: primaryApplicant.email?.trim()?.toLowerCase(),
                  firstName: primaryApplicant.firstName?.trim(),
                  lastName: primaryApplicant.lastName?.trim(),
                  middleName: primaryApplicant.middleName?.trim(),
                  address: applicantAddress,
                },
              ],
            },
            id,
            inviteToken,
          }).unwrap();
        }
      }
      await acceptTermsAndConditions({
        accountId: id,
        inviteToken,
      }).unwrap();
      toast.success('Disclosure acknowledged successfully');
      navigate(`/accounts/${id}/disclosure-confirmation?userState=Authenticated&inviteToken=${inviteToken}`);
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error saving disclosures');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <MainContainer>
        <PageHeader title={`${provideSpanish ? 'Términos y Condiciones / ' : ''}Terms and Conditions`} />
        <Box>
          {provideSpanish && (
            <>
              <Box
                fontSize={{
                  base: 'sm',
                  tablet: 'md',
                  desktop: 'lg',
                }}
                color="body-secondary"
              >
                Revise los siguientes términos y condiciones para su acuerdo de compra de energía
              </Box>
              –
            </>
          )}
          <Box
            fontSize={{
              base: 'sm',
              tablet: 'md',
              desktop: 'lg',
            }}
            color="body-secondary"
          >
            Please review the following terms and conditions for your power purchase agreement
          </Box>
        </Box>
        <Box padding={{ base: '0 lg', tablet: '0' }}>
          <Formik
            initialValues={initialValues}
            validationSchema={collectAddresses ? accountSchema : emptySchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting }) => (
              <Form noValidate>
                <FormSection title={`${provideSpanish ? 'Divulgaciones / ' : ''}Disclosures`}>
                  <Box childGap="lg">
                    {sortedDisclosures?.length > 0 &&
                      sortedDisclosures.map((disclosure: any) => (
                        <Box key={disclosure.id}>
                          <DisclosureCard
                            disclosure={disclosure}
                            disclosureProperty={disclosureType}
                            isLoading={isDisclosuresLoading}
                          />
                        </Box>
                      ))}
                  </Box>
                </FormSection>
                {account && collectAddresses && !isDisclosuresLoading && (
                  <Box>
                    <FormSection title="Confirmar Dirección de Propiedad / Confirm Property Address">
                      <Box gap="lg">
                        <AddressFormFields
                          isSubmitting={isSubmitting}
                          placeHolderId="propertyAddress"
                          disabledFields={['lat', 'lon']}
                          showAddress2
                          showLatLon
                          includePR
                        />
                      </Box>
                    </FormSection>
                    <FormSection title="Confirmar Dirección Postal / Confirm Mailing Address">
                      <Box gap="lg">
                        <AddressFormFields
                          isSubmitting={isSubmitting}
                          prefix="applicant"
                          placeHolderId="primaryApplicantAddress"
                          showAddress2
                          includePR
                        />
                      </Box>
                    </FormSection>
                  </Box>
                )}
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  alignItems={{
                    base: 'stretch',
                    tablet: 'flex-end',
                  }}
                  justifyContent={{
                    tablet: 'flex-end',
                  }}
                  childGap="sm"
                  style={{ flexShrink: 0 }}
                  padding="lg 0 0 0"
                >
                  <Button
                    as="a"
                    navigate={() => navigate(`/accounts/${id}`)}
                    variant="secondary"
                    tone="neutral"
                    size="md"
                    isDisabled={isDisclosuresLoading}
                    isLoading={isSubmitting || isAccountLoading}
                  >
                    {`${provideSpanish ? 'Cancelar / ' : ''}Cancel`}
                  </Button>
                  <Button
                    size="md"
                    variant="primary"
                    type="submit"
                    isDisabled={isDisclosuresLoading}
                    isLoading={isSubmitting || isAccountLoading}
                  >
                    {`${provideSpanish ? 'Entregar / ' : ''}Submit`}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </MainContainer>
    </>
  );
}
