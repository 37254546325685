export enum ProgramType {
  doePr = 'doePr',
  hvac = 'hvac',
  newHomes = 'newHomes',
  solar = 'solar',
}
export const ProgramTypes: ProgramType[] = Object.values(ProgramType);
export const ProgramTypeLabels: Record<ProgramType, string> = {
  doePr: 'DOE Puerto Rico',
  hvac: 'HVAC',
  newHomes: 'New Homes',
  solar: 'Solar',
};
