import { Alert } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useGetAccountQuery } from '../../services/accounts';
import { MilestoneType } from '../../types/Milestone';
import { MainContainer } from '../MainContainer';
import { PostActivationPackageActions } from 'types';
import { PostActivationPackage } from './PostActivationPackage';
import AdminStipulationBannerList from '../Stipulations/AdminStipulationBannerList';

import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';

export const PostActivationMilestonePackage = () => {
  const { id, action } = useParams<{ id: any; action: PostActivationPackageActions }>();
  const { data: account, isLoading: accountIsLoading }: any = useGetAccountQuery(id);

  const navigate = useNavigate();

  const canSubmitPostActivationPackage = useMemo(() => {
    if (!account) return false;
    return account?.currentMilestone?.type === MilestoneType.postActivation || false;
  }, [account]);

  useEffect(() => {
    if (!action || !PostActivationPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
  }, [id, navigate, action]);

  return (
    <>
      <Helmet>
        <title>{`${account?.primaryApplicantName} Activation Package`}</title>
      </Helmet>
      <MainContainer>
        <AdminStipulationBannerList />
        <PageHeader
          eyebrow={
            <>
              <Link to="/accounts">Accounts</Link> \{' '}
              <Link to={`/accounts/${id}`}>{`${account?.primaryApplicantName}`}</Link>
            </>
          }
          title="Post Activation Package"
          description="Provide the required documents."
        />
        {account && action !== PostActivationPackageActions.review && !canSubmitPostActivationPackage && (
          <Alert
            variant="warning"
            hasIcon
            title="Post Activation Package is not complete"
            message="The Post Activation Package must be completed and approved before submitting the Post Activation Package."
          />
        )}
        {accountIsLoading ? (
          <FormSection title="Loading...">
            <SkeletonBox height="25px" width="100" />
          </FormSection>
        ) : (
          <PostActivationPackage canSubmitPostActivationPackage={canSubmitPostActivationPackage} />
        )}
      </MainContainer>
    </>
  );
};
