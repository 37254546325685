export enum ReviewTaskType {
  reviewContract = 'reviewContract',
  reviewIdentity = 'reviewIdentity',
  reviewTitle = 'reviewTitle',
  reviewUploadedDocument = 'reviewUploadedDocument',
  reviewInstall = 'reviewInstall',
  reviewPermissionToOperate = 'reviewPermissionToOperate',
  reviewSystemActivation = 'reviewSystemActivation',
  reviewVoidedCheck = 'voidedCheck',
}

export enum QueueItemDisplayStatus {
  ready = 'Ready',
  pending = 'Pending',
  completed = 'Completed',
  rejected = 'Rejected',
  voided = 'Voided',
}

export enum TaskQueueSortField {
  CREATED_DESC = 'createdAtDescending',
  CREATED_ASC = 'createdAtAscending',
}
