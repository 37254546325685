import { AccountDocument, Milestone, MilestoneStatus } from "types";

export const getMilestoneLabel = function (milestone: Milestone, account: AccountDocument) {
  switch (milestone.status) {
    case MilestoneStatus.rejected:
      return 'Rejected';
    case MilestoneStatus.paused:
      return 'Paused';
    case MilestoneStatus.submitted:
      return 'Under Review';
    case MilestoneStatus.approved:
      return "Approved";
    case MilestoneStatus.resubmitted:
      return 'Resubmitted';
    case MilestoneStatus.pending:
      return 'Pending';
  }
}