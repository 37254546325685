import { useParams } from 'react-router-dom';
import {
  useDeleteOrganizationDomesticContentMutation,
  useGetOrganizationDomesticContentQuery,
} from '../../../../../services/pricing';
import { Box, Button, Card, Column, Table, toast, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { TableLoading } from '../../../../TableLoading';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { DomesticContentFormatType, DomesticContentSetting } from 'types';
import RequirePricingAdminUser from '@/components/auth/RequirePricingAdminUser';
import { CreateDomesticContent } from './modals/CreateDomesticContent';

type DomesticContent = {
  id: string;
  name: string;
  organizationId: string;
  organizationName: string;
  state: string;
  values: DomesticContentSetting;
};

type DomesticContentValues = {
  format: string;
  value: number;
};

const evaluateValues = (values: DomesticContentValues) => {
  return (
    (values.format === DomesticContentFormatType.perWatt
      ? currencyFormatter.format(values.value) + '/W'
      : values.value + '%') || (values.format === DomesticContentFormatType.perWatt ? '0.00' : '0%')
  );
};

const calculateNetPricingEffect = (modifier: DomesticContentValues, holdback: DomesticContentValues) => {
  const difference = (modifier.value - holdback.value).toFixed(2);
  return evaluateValues({ format: modifier.format, value: +difference });
};

const transformArray = (domesticContent: DomesticContent[]) => {
  let domesticContentTable: Partial<
    DomesticContent & { modifier: string; holdback: string; netPricingEffect: string }
  >[] = [];

  domesticContent.forEach((item: DomesticContent) => {
    domesticContentTable.push({
      id: item.id,
      name: item.name,
      organizationId: item.organizationId,
      organizationName: item.organizationName,
      state: item.state,
      modifier: evaluateValues(item.values.modifier),
      holdback: evaluateValues(item.values.holdback),
      netPricingEffect: calculateNetPricingEffect(item.values.modifier, item.values.holdback),
    });
  });
  return domesticContentTable;
};

export const DomesticContent = () => {
  const { alias = '' } = useParams<{ alias: string }>();
  const { isPhone } = useBreakpoint();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [deleteOrganizationDomesticContentMutation] = useDeleteOrganizationDomesticContentMutation();

  const { data, isLoading, isFetching } = useGetOrganizationDomesticContentQuery(
    { alias },
    {
      skip: !alias,
    },
  );

  const handleArchive = async (id: string) => {
    try {
      await deleteOrganizationDomesticContentMutation({ alias, id }).unwrap();
      toast.success('Domestic Content Archived successfully');
    } catch (e) {
      console.log(e);
      toast.error('Error archiving Domestic Content');
    }
  };

  const columnConfig: Column[] = [
    { heading: 'Name', dataKey: 'name' },
    { heading: 'org', dataKey: 'organizationName' },
    { heading: 'State', dataKey: 'state' },
    { heading: 'Modifier Amount', dataKey: 'modifier' },
    { heading: 'Holdback Amount', dataKey: 'holdback' },
    { heading: 'Net Pricing Effect', dataKey: 'netPricingEffect' },
    {
      heading: '',
      render: (_cell: any, row: any) => (
        <RequirePricingAdminUser>
          <Button
            size="sm"
            variant="secondary"
            tone="neutral"
            onClick={() => {
              handleArchive(row.id);
            }}
          >
            Archive
          </Button>
        </RequirePricingAdminUser>
      ),
    },
  ];
  return (
    <>
      <CreateDomesticContent isModalOpen={isModalOpen} closeModal={closeModal} />
      <Card.Header>
        <RequirePricingAdminUser>
          <Box direction="row" justifyContent="space-between">
            <Box />
            <Button
              as="button"
              variant="primary"
              iconPrefix="add"
              size={{ base: 'sm', desktop: 'md' }}
              aria-label="create adder"
              onClick={() => openModal()}
            >
              {!isPhone && 'Domestic Content'}
            </Button>
          </Box>
        </RequirePricingAdminUser>
      </Card.Header>
      <Box margin="0 lg 0 lg">
        {isFetching || isLoading ? (
          <TableLoading rows={12} />
        ) : (
          <Table
            rowKey="id"
            columns={columnConfig as Column[]}
            rows={(data && transformArray(data.domesticContentModifiers)) ?? []}
            isLoading={isLoading || isFetching}
            isScrollable={{ y: true }}
            hasStickyHeader
          />
        )}
      </Box>
    </>
  );
};
