import { Helmet } from 'react-helmet';
import { MainContainer } from '../MainContainer';
import { Badge, Box, Button, useOpenClose } from '@palmetto/palmetto-components';
import PageHeader from '../PageHeader';
import { FundType } from 'types';
import { useGetFundQuery } from '../../services/funds';
import { useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PropertyValue } from '../PropertyListBox/PropertyValue';
import { DateTime } from 'luxon';
import { FundAccountsSummary } from './FundAccountsSummary';
import { EditTrancheModal } from './EditTrancheModal';

const convertIsoToDateMed = (isoDate: string) =>
  isoDate ? DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_MED) : null;

export function Fund() {
  const navigate = useNavigate();
  const { key } = useParams<{ key: any }>();
  const { data: fundData = {}, isLoading: isFundDataLoading, isFetching: isFundDataFetching } = useGetFundQuery(key);
  const fund = useMemo(() => {
    if (isFundDataLoading || isFundDataFetching) return undefined;
    return fundData;
  }, [fundData, isFundDataLoading, isFundDataFetching]);
  const fundTypeDisplayName = {
    [FundType.strandedAsset]: 'Stranded Asset',
    [FundType.taxEquity]: 'Tax Equity',
    [FundType.debt]: 'Debt',
    [FundType.jointStrandedAsset]: 'Joint Stranded Asset',
  };
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [selectedTranche, setSelectedTranche] = useState<any>(null);

  const getTrancheFilter = (trancheName: string) =>
    `[{"id": "tranche", "operation": "Equal", "selectedValues": ["${trancheName}"]}]`;

  const handleEdit = (tranche: any) => {
    setSelectedTranche(tranche);
    openModal();
  };

  return (
    <>
      <Helmet>
        <title>Fund</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          eyebrow={<Link to="/admin/funds">Funds</Link>}
          title={fund?.description ?? '...'}
          description={
            fund && (
              <Box direction="row">
                <Badge
                  variant="info"
                  message={fundTypeDisplayName[fund.fundType as FundType]}
                  size={{ base: 'md', desktop: 'lg' }}
                />
              </Box>
            )
          }
        />
        <Box direction="row" childGap="3xl">
          <Box>
            <Box margin="0 0 lg 0">
              <Button size="sm" navigate={() => navigate(`/admin/funds/${key}/assign`)}>
                Tranche Accounts
              </Button>
            </Box>
            {!isFundDataLoading && fund && (
              <Box childGap="xs" fontSize="xs" margin="0 0 lg 0">
                <PropertyValue label="NetSuite Subsidiary Id" value={fund.netSuiteSubsidiaryId} />
                <PropertyValue label="Created" value={convertIsoToDateMed(fund.createdDate) as string} />
              </Box>
            )}
            <Box childGap="lg" radius="md" borderWidth="xs" borderColor="separator" padding="lg" margin="0 0 md 0">
              <Box as="h3" fontWeight="medium" fontSize="md">
                Tranches
              </Box>
              <Box>
                {!isFundDataLoading &&
                  fund?.tranches &&
                  fund.tranches.map((tranche: any) => (
                    <Box padding="0 0 sm 0">
                      <Box>
                        <Box direction="row" alignItems="baseline" justifyContent="space-between" padding="0 0 xs 0">
                          <Box as="h3" fontWeight="medium" fontSize="sm">
                            <Link
                              to={`/admin/funds/${fund.fundKey}?advancedFilters=${encodeURIComponent(getTrancheFilter(tranche.name))}`}
                            >
                              {tranche.name}
                            </Link>
                          </Box>
                          <Button
                            onClick={() => handleEdit(tranche)}
                            variant="primary"
                            tone="neutral"
                            size="xs"
                            aria-label="Edit Tranche Account"
                          >
                            Edit
                          </Button>
                        </Box>
                        <Box
                          direction="row"
                          alignItems="baseline"
                          // justifyContent="space-between"
                          padding="0 0 xs 0"
                          childGap="lg"
                        >
                          <Box childGap="xs" fontSize="xs" flex="auto" padding="xs 0 0 0">
                            <Box direction="row" gap="2xs" justifyContent="space-between">
                              <Box color="body-secondary" fontWeight="medium">
                                Submitted
                              </Box>
                              <Box
                                direction="row"
                                childGap="xs"
                                alignItems="center"
                                textAlign="right"
                                style={{ wordBreak: 'break-word' }}
                              >
                                {convertIsoToDateMed(tranche.submittedDate)}
                              </Box>
                            </Box>
                            <Box direction="row" gap="2xs" justifyContent="space-between">
                              <Box color="body-secondary" fontWeight="medium">
                                Accepted
                              </Box>
                              <Box
                                direction="row"
                                childGap="xs"
                                alignItems="center"
                                textAlign="right"
                                style={{ wordBreak: 'break-word' }}
                              >
                                {tranche.trancheDate ? convertIsoToDateMed(tranche.trancheDate) : '-'}
                              </Box>
                            </Box>
                            <Box
                              direction="row"
                              alignItems="baseline"
                              justifyContent="space-between"
                              borderWidth="0 0 xs 0"
                              borderColor="separator"
                              padding="0 0 xs 0"
                              childGap="lg"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box flex="auto">
            <FundAccountsSummary fundKey={key} />
          </Box>
          <EditTrancheModal
            fundKey={key}
            selectedTranche={selectedTranche}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          />
        </Box>
      </MainContainer>
    </>
  );
}
