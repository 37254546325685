import { Box } from '@palmetto/palmetto-components';

const CreditError = ({ event }: { event: any }) => (
  <Box display="block">
    <Box as="span" display="inline-block" margin="0 2xs 0 0" childGap="md">
      There was an error seeking credit history for <strong> {event?.data?.creditApplicant}</strong>
    </Box>
  </Box>
);

export default CreditError;
