import { Box, Button, Icon, Modal, ModalProps } from '@palmetto/palmetto-components';

export function PreventSubmissionModal({ isOpen, onDismiss }: ModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth="4xl" ariaLabel="prevent submission">
      <Modal.Body background="secondary" childGap="lg">
        <Box
          fontSize={'md'}
          color="black"
          fontWeight="bold"
          data-testid="prevent-submission-modal-body"
          display={'flex'}
          direction={'column'}
        >
          <Icon name="remove" size="2xl" color="danger" onClick={onDismiss} data-testid="close-icon" />
          <br />
          <p>
            The year-1 production estimate for the installed system exceeds the allowable difference from the year-1
            production estimate in the contract.
          </p>
          <br />
          <p>Therefore this installation package cannot be submitted until this difference is resolved.</p>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" tone="neutral" onClick={onDismiss} id="preventSubmissionModalCancelCTA">
          <Box>Cancel</Box>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
