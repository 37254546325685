import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, toast } from '@palmetto/palmetto-components';
import { MainContainer } from '../MainContainer';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import { useAuth } from '../auth/authProvider';
import { useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import {
  DeepPartial,
  QueueStatus,
  QueueType,
  PostActivationPackage as IPostActivationPackage,
  ProgramType,
  PostActivationPackageActions,
} from 'types';
import { Formik, Form, FormikValues } from 'formik';
import { useGetAccountQuery } from '../../services/accounts';
import { QueueFlagsProvider } from '../QueueFlags/QueueFlagsContext';
import { NotesDrawer } from '../NotesDrawer/NotesDrawer';
import { Helmet } from 'react-helmet';
import { QueueBanner } from '../MilestonePackagePartials/QueueBanner';
import { Footer } from '../MilestonePackagePartials/Footer';
import { isErrorWithData } from '../../services/helpers';
import { DateTime } from 'luxon';
import { DATE_FORMAT, convertToISODateString } from '../../helpers/dates';
import { ProofOfPto } from '../ActivationMilestonePackage/ProofOfPto';
import {
  useSavePostActivationPackageMutation,
  useSubmitPostActivationPackageMutation,
} from '@/services/postActivationPackage';

export const formatPostActivationPackageData = (values: FormikValues): DeepPartial<IPostActivationPackage> => {
  return {
    ptoGrantedDate: values.ptoGrantedDate ? convertToISODateString(values.ptoGrantedDate) : undefined,
  };
};
const postActivationPackageSchema = yup.object().shape({
  ptoGrantedDate: yup.string().required('PTO Granted Date is required'),
});

export const PostActivationPackage = ({
  canSubmitPostActivationPackage,
}: {
  canSubmitPostActivationPackage: boolean;
}) => {
  const navigate = useNavigate();
  const { id = '', action = 'review' } = useParams<{ id: string; action: PostActivationPackageActions }>();

  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(id);

  const { data: queue, isLoading: isQueueLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.postActivationPackage,
  });

  const [triggerSave] = useSavePostActivationPackageMutation();
  const [triggerSubmit] = useSubmitPostActivationPackageMutation();
  const { claims } = useAuth();
  const isFinCoUser = isPalmettoFinanceUser(claims);
  const [isNotesOpen, setIsNotesOpen] = useState(false);

  const canSubmitReview = action === PostActivationPackageActions.review && queue;

  const isLoading = isQueueLoading || isAccountLoading;

  const initialValues = {
    ptoGrantedDate: account?.ptoGrantedDate
      ? DateTime.fromISO(account?.ptoGrantedDate).toUTC().toFormat(DATE_FORMAT)
      : undefined,
  };

  useEffect(() => {
    if (!action || !PostActivationPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
    if (action === PostActivationPackageActions.review && !isFinCoUser) {
      if (queue) {
        navigate(`/accounts/${id}/post-activation-package/edit`, { replace: true });
      } else {
        navigate(`/accounts/${id}/post-activation-package/create`, { replace: true });
      }
    }
  }, [id, navigate, action, isFinCoUser, queue]);

  const handleSave = async ({ accountId, data }: { accountId: string; data: FormikValues }) => {
    const formattedData = formatPostActivationPackageData(data);
    try {
      await triggerSave({ accountId, postActivationPackage: formattedData }).unwrap();
      toast.success('Post Activation Package Saved');
    } catch (e) {
      console.error(e);
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        toast.error('Error saving Post Activation Package');
      }
    }
  };
  const handleSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    try {
      const formattedData = formatPostActivationPackageData(values) as IPostActivationPackage;
      await triggerSubmit({ accountId: id, postActivationPackage: formattedData }).unwrap();
      toast.success('Post Activation Package Submitted');
      navigate(`/accounts/${id}`);
    } catch (e: any) {
      console.error(e);
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        toast.error('Error saving Post Activation Package');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleValidation = (values: any) => {
    const errors = {} as any;
    const dateRegex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(20[0-9]{2}|2[1-9][0-9]{3}|[3-9][0-9]{3})$/;

    if (account.programType === ProgramType.doePr) {
      if (!values.ptoGrantedDate) {
        errors.ptoGrantedDate = 'PTO Granted Date is required';
      } else if (!dateRegex.test(values.ptoGrantedDate)) {
        errors.ptoGrantedDate = 'PTO Granted Date is invalid. Must be in the format MM-DD-YYYY';
      }
    }
    return errors;
  };

  if (isLoading) {
    return (
      <MainContainer>
        <FormSection title="Loading...">
          <SkeletonBox height="25px" width="100" />
        </FormSection>
      </MainContainer>
    );
  }

  return (
    <QueueFlagsProvider queueId={queue?.id}>
      <NotesDrawer isOpen={isNotesOpen} setIsOpen={setIsNotesOpen} queueType={QueueType.postActivationPackage} />
      <Helmet>
        <title>{`${account?.primaryApplicantName} Post Activation Package`}</title>
      </Helmet>
      <MainContainer>
        {queue && (queue.status === QueueStatus.rejected || isFinCoUser) ? (
          <QueueBanner
            setIsNotesOpen={setIsNotesOpen}
            queueType={QueueType.postActivationPackage}
            isFinCoUser={isFinCoUser}
          />
        ) : null}
        {action === PostActivationPackageActions.review && !canSubmitReview ? (
          <Alert
            variant="warning"
            title="Post Activation Package Not Submitted"
            message="This post activation package is not ready to be reviewed yet. It has not been submitted."
            hasIcon
          />
        ) : null}
        <Box>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validateOnChange={false}
            validate={handleValidation}
            validationSchema={postActivationPackageSchema}
          >
            {() => (
              <Form>
                <ProofOfPto />
                <Footer
                  canSubmitPackage={canSubmitPostActivationPackage}
                  isLoading={isLoading}
                  isFirstSubmission={!queue}
                  canSubmitReview={canSubmitReview}
                  queueType={QueueType.postActivationPackage}
                  onSave={handleSave}
                />
              </Form>
            )}
          </Formik>
        </Box>
      </MainContainer>
    </QueueFlagsProvider>
  );
};
