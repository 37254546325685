import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@palmetto/palmetto-components';
import classNames from 'classnames';

import './nav.scss';

const calcClasses = ({ isActive = false, isPending = false }) => {
  return classNames(
    'display-flex p-v-sm p-sm p-md-tablet',
    isPending ? 'pending' : isActive ? 'active' : '',
  );
};

function Nav({ className, onNavLinkClick }: { className?: string; onNavLinkClick?: () => void }) {
  const cx = classNames('settings-nav', className);

  return (
    <Box as="nav" className={cx} fontSize="sm" childGap="2xs">
      <NavLink className={calcClasses} to="/settings/organizations" onClick={onNavLinkClick}>
        Organizations
      </NavLink>
    </Box>
  );
}

export default Nav;
