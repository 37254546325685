import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLDClient, LDContext, LDSingleKindContext } from 'launchdarkly-react-client-sdk';

import Cookies from 'js-cookie';
import { useGetOrganizationDetailsQuery } from '@/services/organizations';

export interface IAuthContext {
  login: (state?: string, impersonateOrg?: string) => Promise<void>;
  logout: () => Promise<void>;
  register?: (state?: string) => Promise<void>;
  user?: Record<string, any>;
  isLoading: boolean;
  isAuthenticated: boolean;
  refreshToken: () => Promise<void>;
  claims?: Record<string, any>;
  organizationData?: Record<string, any>;
  authenticatedLDContext?: LDSingleKindContext;
}

export type RedirectSuccess = (state: string) => void;
export type RedirectFail = (error: any) => void;

export interface AuthConfig extends PropsWithChildren {
  clientId?: string;
  scope?: string;
}

export const AuthContext = React.createContext<IAuthContext>({
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  user: {},
  organizationData: {},
  isLoading: false,
  isAuthenticated: false,
  refreshToken: () => Promise.resolve(),
});

export const AuthProvider: React.FC<AuthConfig> = (props) => {
  const { children, clientId, scope } = props;
  const ldClient = useLDClient();

  const [isAuthenticated] = useState(() => !!Cookies.get('access_token_expires_at'));
  const [refreshBefore, setRefreshBefore] = useState(() => {
    const expiresAt = Cookies.get('access_token_expires_at');
    if (expiresAt) {
      // refresh the token 5 minutes before it expires
      return parseInt(expiresAt, 10) - 1000 * 60 * 5;
    }
  });
  const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isLoading] = useState(false);
  const [user, setUser] = useState<Record<string, any>>({});
  const [claims, setClaims] = useState<Record<string, any>>({});
  const [authenticatedLDContext, setAuthenticatedLDContext] = useState<LDSingleKindContext>();
  const [organizationData, setOrganizationData] = useState<Record<string, any>>({});

  const { data: orgData } = useGetOrganizationDetailsQuery({ alias: claims.org }, { skip: !claims.org });

  useEffect(() => {
    if (user && user.sub && ldClient) {
      const context: LDContext = {
        kind: 'user',
        key: user.sub,
        anonymous: false,
        name: `${user.given_name} ${user.family_name}`,
        email: user.email,
        firstName: user.given_name,
        lastName: user.family_name,
      };
      ldClient.identify(context, undefined, () => {
        setAuthenticatedLDContext(context);
      });
    }
  }, [user, ldClient]);

  const login = useCallback(
    async (state = JSON.stringify({ returnTo: window.location.href }), impersonateOrg?: string) => {
      const queryParams = {
        state,
      } as Record<string, string>;
      if (!!clientId) {
        queryParams.clientId = clientId;
      }

      if (!!scope) {
        queryParams.scope = scope;
      }

      if (!!impersonateOrg) {
        queryParams.org = impersonateOrg;
      }

      const url = `/api/auth/login?${new URLSearchParams(queryParams)}`;
      window.location.assign(url);
    },
    [clientId, scope],
  );

  const logout = useCallback(async () => {
    window.location.assign('/api/auth/logout');
  }, []);

  const refreshToken = useCallback(async () => {
    await fetch('/api/auth/jwt-refresh', { method: 'POST' });
    const expiresAt = Cookies.get('access_token_expires_at');
    if (expiresAt) {
      // refresh the token 5 minutes before it expires
      setRefreshBefore(parseInt(expiresAt, 10) - 1000 * 60 * 5);
    }
  }, [setRefreshBefore]);

  useEffect(() => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }

    if (refreshBefore) {
      const waitTime = Math.abs(+new Date() - +new Date(refreshBefore));
      const timer = setTimeout(async () => {
        await refreshToken();
      }, waitTime);
      setRefreshTimeout(timer);
    }
    // I'm disabling the exhaustive-deps rule because adding refreshToken
    // to the dependency array causes an infinite loop. It's only used to make
    // sure that we do not get two timeouts running at the same time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshBefore, refreshToken]);

  useEffect(() => {
    const userCookie = Cookies.get('user');
    if (userCookie) {
      try {
        setUser(JSON.parse(userCookie));
      } catch {
        /* if JSON parse fails don't crash the app */
        console.error('Failed to parse user cookie', userCookie);
      }
    }
  }, [setUser]);

  useEffect(() => {
    const claimsCookie = Cookies.get('claims');
    if (claimsCookie) {
      try {
        setClaims(JSON.parse(claimsCookie));
      } catch {
        /* if JSON parse fails don't crash the app */
        console.error('Failed to parse claims cookie', claimsCookie);
      }
    }
  }, [setClaims]);

  useEffect(() => {
    if (orgData) {
      setOrganizationData(orgData);
    }
  }, [orgData]);

  const value = useMemo(
    () => ({
      login,
      logout,
      refreshToken,
      isLoading,
      isAuthenticated,
      user,
      claims,
      authenticatedLDContext,
      organizationData,
    }),
    [
      login,
      logout,
      refreshToken,
      isLoading,
      isAuthenticated,
      user,
      claims,
      authenticatedLDContext,
      organizationData,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
