import { AddMarketSchema } from '../components/Markets/AddMarketModal';
import { EditMarketSchema } from '../components/Markets/EditMarketModal';
import { api } from './api';

export const utilitiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUtilities: build.query<any[], Record<any, any>>({
      query: (queryParams) => ({
        url: `/utilities${
          queryParams &&
          '?' +
            new URLSearchParams({
              ...queryParams,
            })
        }`,
      }),
      providesTags: () => [{ type: 'Utilities', id: 'LIST' }],
    }),
    getUtilitiesListFilters: build.query<any, Record<any, any>>({
      query: () => {
        return {
          url: `utilities/filters`,
        };
      },
    }),
    createUtility: build.mutation<any, { marketData: AddMarketSchema & { status: 'active' } }>({
      query: ({ marketData }) => ({ url: `/utilities`, method: 'POST', body: marketData }),
      invalidatesTags: () => [{ type: 'Utilities', id: 'LIST' }],
    }),
    updateUtility: build.mutation<any, { id: string; marketData: EditMarketSchema }>({
      query: ({ id, marketData }) => ({ url: `/utilities/${id}`, method: 'PATCH', body: marketData }),
      invalidatesTags: () => [{ type: 'Utilities', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetUtilitiesQuery,
  useGetUtilitiesListFiltersQuery,
  useLazyGetUtilitiesQuery,
  useCreateUtilityMutation,
  useUpdateUtilityMutation,
} = utilitiesApi;
