import { Box, useBreakpoint, createComponent, Card, Button } from '@palmetto/palmetto-components';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TaskQueueSortField } from '../../../types/Queues';
import { DocumentTypeFilter } from './Documents/DocumentTypeFilter';
import { SortByButtonPopover } from '../SortByButtonPopover';
import { AppliedFiltersList } from './AppliedFiltersList';
import { AppliedFilterItem } from './AppliedFilterItem';
import { StatusFilter } from './StatusFilter';
import { MobileFiltersModal } from './MobileFiltersModal';
import { NameFilter } from './NameFilter';
import { LegacyReviewQueueType } from 'types';

type FiltersParams = {
  queue: LegacyReviewQueueType;
};

const ResponsiveCard = createComponent(Card)({
  radius: {
    base: '0',
    desktop: 'md',
  },
});

const TaskQueueSortDisplay: Record<TaskQueueSortField, string> = {
  [TaskQueueSortField.CREATED_ASC]: 'Oldest',
  [TaskQueueSortField.CREATED_DESC]: 'Newest',
} as const;

export const Filters = ({ queue }: FiltersParams) => {
  const { isPhone, isTablet } = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();
  const nameSearch = searchParams.get('name');

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [nameValue, setNameValue] = useState(nameSearch || '');

  const sort = (searchParams.get('sort') as TaskQueueSortField) || TaskQueueSortField.CREATED_ASC;
  const search = Array.from(searchParams.entries());
  const searchFilters = search.filter(([key]) => key !== 'sort' && key !== 'name');
  const isFiltered = !!searchFilters.length;
  const isMobile = isPhone || isTablet;

  const handleSortSelection = useCallback(
    (sortSelection: string) => {
      searchParams.set('sort', sortSelection);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleClearAllFilters = useCallback(() => {
    searchFilters.forEach(([key]) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams, searchFilters]);

  const handleClearFilter = useCallback(
    (key: string, value: string) => {
      const filters = searchParams.get(key);
      if (!filters) {
        return;
      }
      searchParams.delete(key);
      const filtersArr = filters.split(',');

      const filterIndex = filtersArr.indexOf(value);
      const updatedFilters = [...filtersArr.slice(0, filterIndex), ...filtersArr.slice(filterIndex + 1)];
      if (updatedFilters.length) {
        searchParams.set(key, updatedFilters.join(','));
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return !isMobile ? (
    <Box display="block" childGap="xs" position="sticky" zIndex="sticky" style={{ top: '0' }}>
      <Box
        padding={{ base: '0 lg', tablet: 'sm lg' }}
        direction="row"
        childGap="xs"
        alignItems="center"
        justifyContent="space-between"
        overflow="auto"
        background="secondary"
        radius="md"
      >
        <Box direction="row" childGap="xs" alignItems="center">
          <Box width={{ base: '50', tablet: '2xl' }}>
            <NameFilter value={nameValue} setNameValue={setNameValue} />
          </Box>
          <Box>
            <StatusFilter isMobile={isMobile} />
          </Box>
          <Box>
            {queue === LegacyReviewQueueType.uploadedDocument ? <DocumentTypeFilter isMobile={isMobile} /> : null}
          </Box>
        </Box>
        <SortByButtonPopover
          sortOptions={TaskQueueSortDisplay}
          selectedSort={sort}
          onApplySort={handleSortSelection}
          isMobile={isMobile}
        />
      </Box>
      {isFiltered && (
        <AppliedFiltersList onClearFilters={handleClearAllFilters}>
          {searchFilters.map((filterArr) => {
            const [key, values] = filterArr;
            return values
              .split(',')
              .map((filterItem) => (
                <AppliedFilterItem
                  key={filterItem}
                  value={filterItem}
                  onRemoveFilter={() => handleClearFilter(key, filterItem)}
                />
              ));
          })}
        </AppliedFiltersList>
      )}
    </Box>
  ) : (
    <>
      <MobileFiltersModal
        isMobile={isMobile}
        showFilterModal={showFilterModal}
        closeFilterModal={() => setShowFilterModal(false)}
        isFiltered={isFiltered}
        handleClearFilters={handleClearAllFilters}
        handleClearFilter={handleClearFilter}
        searchFilters={searchFilters}
        queue={queue}
      />

      <ResponsiveCard>
        <Box
          padding="sm lg"
          borderWidth="0 0 xs 0"
          borderColor="separator"
          alignItems="center"
          width="100"
          direction="row"
          childGap="md"
          justifyContent="space-between"
          background="tertiary"
        >
          <Box width={{ base: '50', tablet: 'xl', desktop: '30', hd: '3xl' }}>
            <NameFilter value={nameValue} setNameValue={setNameValue} />
          </Box>
          <Box direction="row" childGap="xs">
            <Button
              variant="secondary"
              tone="neutral"
              size="sm"
              style={{
                wordBreak: 'keep-all',
              }}
              onClick={() => setShowFilterModal(true)}
            >
              Filter
            </Button>
            <SortByButtonPopover
              sortOptions={TaskQueueSortDisplay}
              selectedSort={sort}
              onApplySort={handleSortSelection}
              isMobile={isMobile}
            />
          </Box>
        </Box>
      </ResponsiveCard>
    </>
  );
};
