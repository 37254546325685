import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHasEnabledNonAnonFlag } from './Hook';
import { isUndefined } from 'lodash';

export interface FlagGuardProps extends React.PropsWithChildren {
  /**
   * The flags to be required. Example: ['useProductionConfirmation']
   */
  required: string[];
  /**
   * @description The path to redirect to if failed.
   */
  redirectTo: string;
}

/**
 * @description Guards particular children from rendering depending on a list of required flags.
 * If the flags aren't found, we simlpy render null and navigate to the base route (/).
 * @param param0 The required flags and the rendered children if flag is enabled
 */
export const FlagGuard = ({ required, children, redirectTo }: FlagGuardProps) => {
  const navigate = useNavigate();
  const {
    checkFlags,
  } = useHasEnabledNonAnonFlag(required);
  const hasEnabledFlags = checkFlags();

  useEffect(() => {
    if (!isUndefined(hasEnabledFlags) && !hasEnabledFlags) {
      navigate(redirectTo);
    }
  }, [hasEnabledFlags, navigate, redirectTo]);

  if (!hasEnabledFlags) {
    return null;
  }
  return children;
};
