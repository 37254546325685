import { Badge, Box, Icon, Table } from '@palmetto/palmetto-components';
import { Link, useSearchParams } from 'react-router-dom';
import DateTimeDisplay from './DateTime';
import { getRemainingRequirements } from '../helpers/getRemainingRequirements';
import { FileItem } from './documents/FileItem';
import { CancelReason, cancelReasonLabels } from 'types';
import './AccountsSummaryTable.css';

// TODO: Add stricter typing around Account milestone on FE

enum AccountMilestoneStatus {
  approved = 'approved',
  conditionallyApproved = 'conditionallyApproved',
  paused = 'paused',
  pending = 'pending',
  rejected = 'rejected',
  resubmitted = 'resubmitted',
  submitted = 'submitted',
}

const accountMilestoneStatus: Record<AccountMilestoneStatus, string> = {
  approved: 'Approved',
  conditionallyApproved: 'Conditionally Approved',
  paused: 'Paused',
  pending: 'Pending Submittal',
  rejected: 'Rejected',
  resubmitted: 'Resubmitted',
  submitted: 'Under Review',
};

const getStatusIcon = (currentMilestone: any, milestone: any) => {
  const { status, type } = milestone;
  if (currentMilestone.type === type && status === 'pending') {
    return <Icon name="circle" size="md" color="success" />;
  } else if ([AccountMilestoneStatus.submitted, AccountMilestoneStatus.resubmitted].includes(status)) {
    return <Icon name="c-in-progress" size="md" color="success" />;
  } else if (status === 'approved') {
    return <Icon name="c-check" size="md" color="success" />;
  } else if (status === 'rejected') {
    return <Icon name="c-remove" size="md" color="danger" />;
  } else {
    return <Icon name="circle" size="md" color="disabled" />;
  }
};

const baseColumns = [
  {
    cellClassName: 'milestoneStatus',
    width: 150,
    render: (_: any, { currentMilestone, milestones, cancellation }: any) => {
      const isPausedByStipulation = currentMilestone?.status === 'paused' && !cancellation?.isCancelled;
      if (currentMilestone && milestones) {
        const sortedMilestones: any[] = [...milestones].sort((a: any, b: any) => a.order - b.order);
        return (
          <Box
            childGap="md"
            alignItems="center"
            textAlign="center"
            className={
              currentMilestone.status === 'rejected'
                ? 'column-rejected'
                : isPausedByStipulation
                  ? 'column-paused'
                  : ''
            }
          >
            <Box alignItems="center" childGap="xs">
              {isPausedByStipulation && <Badge variant="warning" message="stipulation" size="md" />}
              <Box fontSize="xs" display="inline-block">
                {currentMilestone.name}
              </Box>
              <Box textAlign="center" fontSize="sm" fontWeight="medium">
                {accountMilestoneStatus[currentMilestone.status as AccountMilestoneStatus]}
              </Box>
            </Box>
            <Box direction="row" childGap="xs">
              {sortedMilestones.map((milestone: any) => (
                <Box as="li" position="relative" display="inline-block" key={milestone.name}>
                  <Box>{getStatusIcon(currentMilestone, milestone)}</Box>
                </Box>
              ))}
            </Box>
          </Box>
        );
      } else {
        return <Box>Unknown Current Milestone</Box>;
      }
    },
  },
  {
    heading: 'Account',
    render: (_: any, row: any) => {
      return (
        <Box display="block" childGap="2xs" fontSize="xs" padding="sm 0" color="body-secondary">
          <Box display="block" fontWeight="medium" fontSize="sm">
            <Link to={`/accounts/${row.id}`}>{row.primaryApplicantName}</Link>
          </Box>
          {row.address && (
            <Box childGap="2xs">
              <Box>{row.address.address1}</Box>
              <Box>
                {row.address.city} {row.address.state} {row.address.zip}
              </Box>
            </Box>
          )}
          {row.applicants[0]?.email && <Box>{row.applicants[0].email}</Box>}
          {row.externalReference && <Box>{`Ext. Reference: ${row.externalReference}`}</Box>}
        </Box>
      );
    },
  },
];

const pipelineColumnConfig = [
  ...baseColumns,
  {
    heading: 'Sales Rep',
    render: (_: any, row: any) => (
      <Box display="block" childGap="sm" fontSize="xs">
        <Box>{row.salesRepName}</Box>
        {row.organizationName && <Box>{row.organizationName}</Box>}
      </Box>
    ),
  },
  {
    heading: 'Age',
    dataKey: 'createdAt',
    width: 112,
    render: (cell: any) => (
      <div>
        <DateTimeDisplay value={cell} showDuration={true} fontSize="xs" dateFormat="DD h:mm:ss a" inline />
      </div>
    ),
  },
  {
    heading: 'Last Updated',
    dataKey: 'updatedAt',
    width: 148,
    render: (cell: any) => (
      <div>
        <DateTimeDisplay value={cell} showDuration={true} fontSize="xs" dateFormat="DD h:mm:ss a" inline />
      </div>
    ),
  },
  {
    heading: 'Actions Needed',
    render: (_: any, { currentMilestone }: any) => {
      const requirements = getRemainingRequirements(currentMilestone);

      const sortedRequirements: any[] = requirements.sort((a: any, b: any) => a.order - b.order);
      return (
        <Box direction="row" wrap alignItems="flex-start" style={{ gap: 'var(--size-spacing-xs)' }}>
          {sortedRequirements.map((requirement: any = {}) => (
            <Badge variant="warning" key={requirement.name} message={requirement.name} size="md" />
          ))}
        </Box>
      );
    },
  },
];

const cancelledColumnConfig = [
  ...baseColumns,
  {
    heading: 'cancelled reason',
    dataKey: 'cancellation',
    render: (cell: any) => <Box>{cancelReasonLabels[cell?.reason as CancelReason]}</Box>,
  },
  {
    heading: 'cancelled by',
    dataKey: 'cancellation',
    render: (cell: any) => <Box>{cell?.requestedByDisplayName}</Box>,
  },
  {
    heading: 'cancelled date',
    dataKey: 'cancellation',
    render: (cell: any) => <DateTimeDisplay value={cell?.requestedDate} fontSize="xs" dateFormat="DD" inline />,
  },
  {
    heading: 'notes / attachments',
    dataKey: 'cancellation',
    render: (cell: any) => (
      <Box gap="sm">
        {cell?.notes.length ? <Box style={{ fontStyle: 'italic' }}>"{cell.notes}"</Box> : null}
        {cell?.supportingDocuments?.length ? (
          <Box gap="2xs" margin="0 0 0 xs">
            {cell?.supportingDocuments?.flatMap((doc: any) =>
              doc.files.map((file: any) => (
                <Box direction="row" style={{ textDecoration: 'underline' }} gap="2xs" alignItems="center">
                  <Icon name="paperclip" size="xs" />
                  <FileItem file={file} />
                </Box>
              )),
            )}
          </Box>
        ) : null}
      </Box>
    ),
  },
];

export const AccountsSummaryTable = ({ data }: any) => {
  const [searchParams] = useSearchParams();
  const cancelled = searchParams.get('cancelled');
  const showCancelled = cancelled !== null;
  return (
    <Table
      rowKey="id"
      columns={showCancelled ? cancelledColumnConfig : pipelineColumnConfig}
      className="fade-in"
      rows={data}
      align="left"
      isScrollable={{
        x: true,
        y: false,
      }}
    />
  );
};
