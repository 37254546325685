import { Box, Accordion } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { ActivationPhotoItem } from './ActivationPhotoItem';
import './ActivationPhotos.css';
import { useGetTasksQuery } from '../../services/tasks';
import { sortDescending } from '../../helpers/sort';
import { ActivationPackagePhotoCategory } from 'types';
import {
  ActivationPackageActions,
  ActivationPhotoSectionType,
  activationPhotoSections,
} from '../../types/ActivationPackage';

export const ActivationPhotos = ({
  partnerReviewTaskSections,
  isActivationPendingReview,
}: {
  partnerReviewTaskSections: ActivationPackagePhotoCategory[];
  isActivationPendingReview: boolean;
}) => {
  const { id, action } = useParams<{ id: any; action: ActivationPackageActions }>();
  const { data: items = [], isLoading } = useGetTasksQuery({ id, assignee: 'internal' });

  const findAdminTaskByActivationPackageSection = (section: ActivationPhotoSectionType, items: any[]) => {
    const adminTask = items.filter((item) => {
      return item.type === section.id;
    });
    // return the most recent
    return sortDescending(adminTask, 'createdAt')[0];
  };
  return (
    <Box position="relative">
      <Accordion className="accordion" padding={{ base: '0 2xs 0 2xs', tablet: '0' }}>
        {!isLoading &&
          Object.values(activationPhotoSections).map((section) => {
            const task = findAdminTaskByActivationPackageSection(section, items);
            return (
              <ActivationPhotoItem
                key={section.id}
                section={section}
                showUploadButton={
                  task?.status !== 'completed' &&
                  action !== ActivationPackageActions.review &&
                  (partnerReviewTaskSections.length === 0 || partnerReviewTaskSections.includes(section.id)) &&
                  !isActivationPendingReview
                }
                task={task}
              />
            );
          })}
      </Accordion>
    </Box>
  );
};
