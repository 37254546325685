import { BoxProps } from '@palmetto/palmetto-components';
import { Quote } from 'types';

type RequestContractVoidFunction = () => Promise<void>;

export interface DiffErrorAlerProps {
  loadingState: LoadingState;
  percentErrorDiff: number;
  requestVoid: RequestContractVoidFunction;
  handleToleranceExceptionModal?: (value: string) => void;
  modalCTA2Text?: string;
}

export interface ErrorObject {
  percentErrorDiff: number;
  errorKwH: number;
}

export interface ConfirmObject {
  percentDiff: number;
  diffKwH: number;
  step: number;
}

export interface LoadingState {
  isChecking?: boolean;
  isVoiding: boolean;
  isUpdating?: boolean;
  isAccountLoading?: boolean;
  isCurrentContractLoading?: boolean;
  isCurrentDesignLoading?: boolean;
}

export enum Origin {
  INSTALL_PACKAGE = 'installPackageAction',
  PV_SYSTEM_TAB = 'pvSystemTab',
  ACCOUNT_VIEW = 'accountView',
}

export interface IInputFormProps {
  /**
   * The current System First Year Production Kw/H
   */
  errorObject?: ErrorObject;
  loadingState: LoadingState;
  activeQuote: Quote;
  origin: Origin;
  confirmObject?: ConfirmObject;
  onChange: () => void;
}

export interface CommonFormSectionProps extends React.PropsWithChildren {
  baseStyle?: BoxProps;
  title: string;
  description?: string;
}

export interface HeaderElementProps {
  origin?: Origin;
  accountName: string;
  errorObject?: ErrorObject;
  accountId: string;
  loadingState: LoadingState;
  requestVoid?: RequestContractVoidFunction;
  handleToleranceExceptionModal?: (value: string) => Promise<boolean>;
}

export interface CheckRequestFormData {
  systemFirstYearProductionKwh: string;
}

export interface QuoteSettings {
  maxOveragePercentage: number; //0.001
  maxProductionDecrease: number; //0.05
  maxProductionIncrease: number; //0.1
  preConAdderLimit: number; //5000
}

export interface RangeCheckProps {
  originalProductionKwh: number;
  newProductionKwh: number;
  settings?: QuoteSettings;
}

export interface FormFooterProps {
  loadingState: LoadingState;
  origin?: Origin;
  confirmObject?: ConfirmObject;
}

export interface FormFooterActionProps {
  loadingState: LoadingState;
  origin?: Origin;
  confirmObject?: ConfirmObject;
}

export interface CheckButtonProps {
  confirmObject?: ConfirmObject;
  loadingState: LoadingState;
  origin?: Origin;
}

export interface FormSectionProps {
  errorObject?: ErrorObject;
  confirmObject?: ConfirmObject;
}
