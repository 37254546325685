import FormSection from '../Forms/FormSection';
import { InstallPhotosDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';
import { PhotosList } from '../MilestonePackagePartials/Documents/PhotosList';
import { ReviewPackageType, reviewPhotoSections } from '../../types/ReviewPackage';

export const InstallPhotos = ({ allowArchive }: { allowArchive?: boolean }) => {
  return (
    <FormSection description={InstallPhotosDescription} title="Install Photo Documentation">
      {Object.values(reviewPhotoSections).map((section) => {
        if (section.type === ReviewPackageType.installation)
          return (
            <PhotosList
              key={section.id}
              documentationUrl={section.documentationUrl}
              category={section.id}
              description={section.description}
              title={section.title}
              baseCategory={'Install Photo Documentation'}
              allowArchive={allowArchive}
            />
          );
        return null;
      })}
    </FormSection>
  );
};
