import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Box } from '@palmetto/palmetto-components';
import { useAppSelector } from './hooks';
import { useAuth } from './components/auth/authProvider';
import { initDataDog, setDataDogUser } from './services/datadog';
import { useFlags } from 'launchdarkly-react-client-sdk';
import App from './App';

function AuthenticatingWrapper() {
  const { shouldLogout } = useAppSelector((state) => state.auth);
  const { login, logout, isAuthenticated, isLoading, user, claims, organizationData } = useAuth();
  const { enableSessionRecording = false } = useFlags();
  const { update: updateIntercom } = useIntercom();

  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const userState = params.get('userState');
  const isNotAuthenticating = !isAuthenticated && !isLoading && userState !== 'Authenticated';

  useEffect(() => {
    if (isAuthenticated && location?.pathname === '/') {
      navigate('/accounts');
    } else if (isNotAuthenticating) {
      login();
    }
    if (isAuthenticated && user?.email && organizationData) {
      const name = user?.name ?? '';
      updateIntercom({
        name,
        email: user.email,
        phone: organizationData?.phoneNumber ?? '',
        company: {
          companyId: organizationData?.alias,
          name: organizationData?.name,
        },
      });
    }
    initDataDog(enableSessionRecording);
    setDataDogUser({ id: user?.sub, username: user?.name, email: user?.email, organization: claims?.org });
  }, [
    isAuthenticated,
    isNotAuthenticating,
    login,
    navigate,
    location,
    user,
    enableSessionRecording,
    claims?.org,
    updateIntercom,
    organizationData,
  ]);

  useEffect(() => {
    if (shouldLogout) {
      logout();
    }
  }, [logout, shouldLogout]);

  if (isNotAuthenticating) {
    return (
      <Box
        alignItems="center"
        background="secondary"
        color="body-primary"
        fontSize="sm"
        height="100vh"
        justifyContent="center"
        padding="lg"
      >
        Redirecting to log in page...
      </Box>
    );
  }

  return <App />;
}

export default AuthenticatingWrapper;
