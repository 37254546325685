import FormSection from '../Forms/FormSection';
import { DocumentList } from '../MilestonePackagePartials/Documents/DocumentList';
import { NTPPackageHVAC } from 'types';

export const WorkOrder = ({ allowArchive }: { allowArchive?: boolean }) => {
  return (
    <FormSection title="Work Order">
      <DocumentList
        documentTypeFilter={[NTPPackageHVAC.workOrder]}
        title="Work Order"
        showTypeLabelOnItems={false}
        baseCategory={'Work Order'}
        allowArchive={allowArchive}
      />
    </FormSection>
  );
};
