import { Box } from '@palmetto/palmetto-components';
import { ActivityHistoryEventType, AllDocumentTypes, documentTitles } from 'types';
import EventBy from './EventBy';

const SabalDocumentSubmittedOrResubmitted = ({ event }: { event: any }) => {
  const documentType: AllDocumentTypes = event.data?.documentType;

  return (
    <Box display="block">
      <EventBy event={event} />{' '}
      {event.data?.eventType === ActivityHistoryEventType.sabalDocumentsSubmitted
        ? 'submitted'
        : event.data?.eventType === ActivityHistoryEventType.sabalDocumentsReSubmitted
          ? 'resubmitted'
          : 'submitted'}{' '}
      a {documentType && <strong>{documentTitles[documentType]}</strong>} document for review
    </Box>
  );
};

export default SabalDocumentSubmittedOrResubmitted;
