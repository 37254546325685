import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetFundQuery, useTrancheAccountsMutation } from '../../services/funds';
import PageHeader from '../PageHeader';
import {
  Box,
  Button,
  FormikRadioGroup,
  FormikSelectInputNative,
  FormikTextInput,
  FormikTextareaInput,
  toast,
} from '@palmetto/palmetto-components';
import { MainContainer } from '../MainContainer';
import { Helmet } from 'react-helmet';
import { Field, Form, Formik } from 'formik';
import FormSection from '../Forms/FormSection';
import { DateTime } from 'luxon';
import csv from 'csvtojson';
import { isErrorWithData } from '../../services/helpers';
import { AssignAccountsResult } from './AssignAccountsResult';
import { useNavigate } from 'react-router-dom';
import { object, string, Schema, ValidationError } from 'yup';
import { DATE_FORMAT } from '../../helpers/dates';

export interface Option {
  value: string;
  label: string;
}

const objectIdRegexPattern = /^[0-9a-fA-F]{24}$/;

interface AssignAccountFormData {
  assignType: string;
  accountIds: string;
  tranche?: string;
  submittedDate?: string;
  trancheDate?: string;
}

const assignAccountSchema = {
  assignType: string().required('Select an option').oneOf(['existingTranche', 'newTranche']),
  accountIds: string()
    .required('Accounts are required')
    .test('is-valid-mongo-ids', 'Invalid account ids', async (value) => {
      try {
        if (!value) {
          return false;
        }
        const parseResult = await csv({ noheader: true, trim: true, output: 'csv' }).fromString(value);
        let accountIds = parseResult.flat();

        if (accountIds.length === 0) {
          return false;
        }

        accountIds = accountIds.filter((id) => id.trim().length > 0);
        const invalidIds = accountIds.filter((id) => !objectIdRegexPattern.test(id));

        if (invalidIds.length > 0) {
          return new ValidationError(`Invalid account ids: ${invalidIds.join(', ')}`, value, 'accountIds');
        }

        return true;
      } catch (_e) {
        return false;
      }
    }),
  tranche: string().when('assignType', {
    is: 'existingTranche',
    then: (schema) => schema.required('Tranche is required'),
    otherwise: (schema) => schema.optional(),
  }),
  trancheName: string().when('assignType', {
    is: 'newTranche',
    then: (schema) => schema.required('Tranche is required'),
    otherwise: (schema) => schema.optional(),
  }),
  submittedDate: string().when('assignType', {
    is: 'newTranche',
    then: (schema) => schema.required('Submitted date is required'),
    otherwise: (schema) => schema.optional(),
  }),
  trancheDate: string(),
};

export const AssignAccountFormSchema: Schema<AssignAccountFormData> = object()
  .shape(assignAccountSchema)
  .noUnknown(true);

export function AssignAccounts() {
  const [results, setResults] = useState<any>();
  const { key } = useParams<{ key: any }>();
  const { data: fundData = {}, isLoading: isFundDataLoading, isFetching: isFundDataFetching } = useGetFundQuery(key);
  const fund = useMemo(() => {
    if (isFundDataLoading || isFundDataFetching) return undefined;
    return fundData;
  }, [fundData, isFundDataLoading, isFundDataFetching]);
  const trancheOptions = useMemo(() => {
    return fund?.tranches.map((tranche: any) => ({ value: tranche.name, label: tranche.name })) ?? [];
  }, [fund?.tranches]);
  const [trancheAccounts] = useTrancheAccountsMutation();
  const [accountsForReassign, setAccountsForReassign] = useState<string[]>([]);
  const [isReassigningAccounts, setIsReassigningAccounts] = useState(false);

  const navigate = useNavigate();

  const parseAccountIdsFromString = async (value: string): Promise<Array<string>> => {
    const parser = csv({ noheader: true, trim: true, output: 'csv' });
    const parseResult = await parser.fromString(value);
    return parseResult.flat();
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const loadingToastId = toast.loading('Processing accounts...');
    try {
      let accountIds = await parseAccountIdsFromString(values.accountIds);
      accountIds = accountIds.filter((id: string) => id.trim().length > 0);

      const trancheRequest = {
        accountIds,
        trancheName: values.assignType === 'existingTranche' ? values.tranche : values.trancheName,
        ...(values.submittedDate && {
          submittedDate: DateTime.fromFormat(values.submittedDate, 'MM-dd-yyyy').toISO(),
        }),
        ...(values.trancheDate && { trancheDate: DateTime.fromFormat(values.trancheDate, 'MM-dd-yyyy').toISO() }),
      };

      const results = await trancheAccounts({ fundKey: key, trancheRequest }).unwrap();
      setResults(results);
    } catch (e) {
      if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        console.error(e);
        toast.error('Error submitting tranche request');
      }
    } finally {
      toast.dismiss(loadingToastId);
      setSubmitting(false);
    }
  };

  const initialValues = {
    assignType: '',
    tranche: '',
    submittedDate: '',
    trancheDate: '',
    trancheName: '',
  };

  const handleSelectedAccount = ({ isSelected, accountId }: any) => {
    const newSelections = [...accountsForReassign];

    if (isSelected) {
      newSelections.push(accountId);
    } else {
      const index = accountsForReassign.findIndex((id: string) => id === accountId);
      if (index > -1) newSelections.splice(index, 1);
    }
    setAccountsForReassign(newSelections);
  };

  const handleViewFund = () => {
    navigate(`/admin/funds/${key}`);
  };

  const handleReassignAccounts = async () => {
    setIsReassigningAccounts(true);
    const loadingToastId = toast.loading('Processing accounts...');
    try {
      const trancheRequest = {
        accountIds: accountsForReassign,
        trancheName: results.trancheName,
        override: true,
      };

      await trancheAccounts({ fundKey: key, trancheRequest }).unwrap();
      navigate(`/admin/funds/${key}`);
    } catch (e) {
      if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        console.error(e);
        toast.error('Error submitting tranche request');
      }
    } finally {
      toast.dismiss(loadingToastId);
      setIsReassigningAccounts(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Tranche Accounts</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          eyebrow={<Link to={`/admin/funds/${key}`}>{fund?.description}</Link>}
          title={results ? `${results.trancheName} Results` : 'Assign Accounts to a Tranche'}
        />
        {!results ? (
          <Formik validationSchema={AssignAccountFormSchema} initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, values, setFieldValue, submitForm }) => (
              <Form>
                <Box>
                  <Box>
                    <FormSection title="Tranche">
                      <Box direction="column" childGap={{ base: 'lg', desktop: 'xl' }}>
                        <Box>
                          <Field
                            name="assignType"
                            id="assignType"
                            component={FormikRadioGroup}
                            options={[
                              { value: 'existingTranche', label: 'Existing Tranche', id: 'existingTranche' },
                              { value: 'newTranche', label: 'Create New Tranche', id: 'newTranche' },
                            ]}
                            isDisabled={isSubmitting}
                          />
                        </Box>
                        {values?.assignType === 'existingTranche' && (
                          <Box>
                            <Field
                              label="Tranche"
                              name="tranche"
                              id="tranche"
                              options={trancheOptions}
                              component={FormikSelectInputNative}
                              isDisabled={isSubmitting}
                            />
                          </Box>
                        )}
                        {values?.assignType === 'newTranche' && (
                          <Box width="25" childGap="md">
                            <Field
                              name="submittedDate"
                              id="submittedDate"
                              label="Submitted Date"
                              component={FormikTextInput}
                              isDisabled={isSubmitting}
                              type="tel"
                              placeholder="MM-DD-YYYY"
                              inputMask="date"
                              onChange={(event: any) => {
                                const date = DateTime.fromFormat(event.target.value, DATE_FORMAT);
                                if (!date.invalidReason) {
                                  setFieldValue('submittedDate', event.target.value);
                                  setFieldValue('trancheName', `${key}-${date.toFormat('yyyyMMdd')}`);
                                } else {
                                  setFieldValue('trancheName', '');
                                }
                              }}
                            />
                            <Field
                              name="trancheDate"
                              id="trancheDate"
                              label="Tranche Date"
                              component={FormikTextInput}
                              isDisabled={isSubmitting}
                              type="tel"
                              placeholder="MM-DD-YYYY"
                              inputMask="date"
                            />
                            {values?.submittedDate && values.trancheName && (
                              <Box>
                                <Box margin="0 0 sm 0">Tranche Name:</Box>
                                <Box>{values.trancheName}</Box>
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    </FormSection>
                  </Box>
                  <Box>
                    <FormSection title="Accounts" description="One account ID per line, or comma separated">
                      <Field
                        name="accountIds"
                        id="accountIds"
                        component={FormikTextareaInput}
                        isDisabled={isSubmitting}
                        rows={10}
                      />
                    </FormSection>
                  </Box>
                </Box>
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  alignItems={{
                    base: 'stretch',
                    tablet: 'flex-end',
                  }}
                  justifyContent={{
                    tablet: 'flex-end',
                  }}
                  childGap="sm"
                  style={{ flexShrink: 0 }}
                  padding="lg 0 0 0"
                >
                    <Button
                      navigate={() => navigate(`/admin/funds/${key ?? ''}`)}
                      variant="secondary"
                      tone="neutral"
                      size="md"
                      isLoading={isSubmitting}
                    >
                      Cancel
                    </Button>
                  <Button size="md" variant="primary" type="submit" onClick={submitForm} isLoading={isSubmitting}>
                    Tranche Accounts
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <AssignAccountsResult
            isReassigningAccounts={isReassigningAccounts}
            results={results}
            accountsForReassign={accountsForReassign}
            handleSelectedAccount={handleSelectedAccount}
            onViewFundClick={handleViewFund}
            onReassignAccountsClick={handleReassignAccounts}
          />
        )}
      </MainContainer>
    </>
  );
}
