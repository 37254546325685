import { Box, Button, Modal, useOpenClose } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { Flag, QueueType } from 'types';
import { useGetQueueByAccountIdAndTypeQuery } from '../../../services/queues';
import { CompleteQueueReviewModal } from './CompleteQueueReviewModal';

interface CompleteReviewProps {
  disableApproval?: boolean;
  isForMilestonePackage?: boolean;
  queueType: QueueType;
  canSubmitReview: boolean;
  handleFormSave: () => Promise<void>;
}

export const CompleteQueueReview = ({
  disableApproval,
  isForMilestonePackage = true,
  queueType,
  canSubmitReview,
  handleFormSave,
}: CompleteReviewProps) => {
  const { id: accountId } = useParams<{ id: any }>();
  const { data: queue, isLoading }: any = useGetQueueByAccountIdAndTypeQuery({ accountId, type: queueType });
  const queueId = queue?.id;
  const flagCount = queue?.flags?.filter((flag: Flag) => !flag?.resolved)?.length;
  const { isOpen: isModifyOpen, handleClose, handleToggle } = useOpenClose();
  const title = 'Complete Review';
  const programType = queue?.account?.programType;

  return (
    <>
      <Modal
        isOpen={isModifyOpen}
        onDismiss={() => {
          handleClose();
        }}
        maxWidth="4xl"
        ariaLabelledBy="completeReviewHeader"
        fullScreenMobile
        overflow="scroll"
      >
        <Modal.Header
          id="completeReviewHeader"
          title={title}
          onDismiss={() => {
            handleClose();
          }}
        />
        <CompleteQueueReviewModal
          handleClose={() => {
            handleClose();
          }}
          flagCount={flagCount}
          queueId={queueId}
          queueType={queueType}
          disableApproval={disableApproval}
          isForMilestonePackage={isForMilestonePackage}
          handleFormSave={handleFormSave}
          programType={programType}
        />
      </Modal>

      {!isLoading && (
        <Box>
          <Button
            variant="primary"
            size="md"
            style={{ width: 'fit-content' }}
            onClick={handleToggle}
            disabled={!canSubmitReview}
          >
            Complete Review
          </Button>
        </Box>
      )}
    </>
  );
};
