import { ObjectId } from 'mongodb';
import { AccountDocument } from './AccountTypes';
import { Flag, FlagReasons } from './Flags';
import { WithDatesAsIsoStrings } from './Helpers';
import { ReferenceIdObject } from './ReferenceIds';
import { UserInfo, UserWithDate } from './UserTypes';
import { DocumentTypes } from './DocumentTypes';
import { PackageRejectionInfo } from './RejectionReasons';

export enum QueueStatus {
  inProgress = 'inProgress',
  rejected = 'rejected',
  resubmitted = 'resubmitted',
  submitted = 'submitted',
  onHold = 'onHold',
  approved = 'approved',
  conditionallyApproved = 'conditionallyApproved',
}

export enum QueueHistoryAction {
  status = 'status',
  claimedBy = 'claimedBy',
  flag = 'flag',
}

export enum QueueType {
  activationPackage = 'activationPackage',
  disclosureDocument = 'disclosureDocument',
  installPackage = 'installPackage',
  ntpPackage = 'ntpPackage',
  voidedCheck = 'voidedCheck',
  identityVerification = 'identityVerification',
  permissionToOperate = 'permissionToOperate',
  supportingDocument = 'supportingDocument',
  titleVerification = 'titleVerification',
  electricianSignOff = 'electricianSignOff',
  postActivationPackage = 'postActivationPackage',
}

export enum QueueTypeLabel {
  activationPackage = 'Activation Package',
  disclosureDocument = 'Disclosure Document',
  installPackage = 'Install Package',
  ntpPackage = 'Notice to Proceed Package',
  voidedCheck = 'Voided Check',
  identityVerification = 'Identity Verification',
  permissionToOperate = 'Permission to Operate',
  supportingDocument = 'Supporting Document',
  titleVerification = 'Title Verification',
  electricianSignOff = 'Electrician Sign Off',
  postActivationPackage = 'Post Activation Package',
}

export interface QueueHistory extends UserWithDate {
  action: QueueHistoryAction;
  changedFrom: any;
  changedTo: any;
  note?: string;
  dataKey?: string;
  fileName?: string;
  reasons?: (FlagReasons | QueueHoldReason | QueueRejectionReason)[];
}

export enum QueueHoldReason {
  pendingException = 'Pending Exception',
  pendingFurtherInternalReview = 'Pending Further Internal Review',
  pendingMonitoringValidation = 'Pending Monitoring Validation',
}

export interface QueueHoldReasons extends UserWithDate {
  reasons: QueueHoldReason[];
  note?: string;
}

export interface QueueNote extends UserWithDate {
  note: string;
}

export enum QueueRejectionReason {
  notComplete = 'Not Complete',
  missingDocumentation = 'Missing Documentation',
  illegibleDocumentation = 'Illegible Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  codeViolation = 'Code Violation',
}

export interface QueueRejectionReasons extends UserWithDate {
  reasons: QueueRejectionReason[];
  rejectedDocumentId?: ObjectId;
  note?: string;
}

export enum QueueAssigneeType {
  internal = 'internal',
}

export type QueueDocument = {
  id: string;
  assignee?: UserInfo;
  assigneeType?: QueueAssigneeType;
  history?: QueueHistory[];
  holdReasons?: QueueHoldReasons[];
  notes?: QueueNote[];
  approvalNotes?: QueueNote[];
  rejectionReasons?: QueueRejectionReasons[];
  flags?: Flag[];
  status: QueueStatus;
  name: string;
  description: string;
  referenceIds?: ReferenceIdObject[];
  type: QueueType;
  account: Pick<
    AccountDocument,
    | 'id'
    | 'organizationId'
    | 'licensedOrganizationId'
    | 'primaryApplicantName'
    | 'cancellation'
    | 'address'
    | 'externalReference'
    | 'salesRepName'
    | 'applicants'
    | 'programType'
  > & {
    organizationName: string;
    licensedOrganizationName: string;
  };
  createdAt: Date;
  updatedAt: Date; // will be the same as createdAt when initially submitted
  lastSubmittedAt: Date; // will be the same as createdAt when initially submitted
  reviewTeam?: {
    id: string;
    name: string;
  };
  exception?: { note: string };
};

export type QueueDocumentResponse = WithDatesAsIsoStrings<QueueDocument>;

export type UpdateQueue = {
  assigneeId?: string | null;
  status?: QueueStatus;
  rejectionReasons?: {
    reasons: QueueRejectionReason[];
    rejectedDocumentId?: ObjectId;
    note?: string;
    rejectionInfo?: PackageRejectionInfo[];
  }[];
  notes?: {
    note: string;
  }[];
  approvalNote?: {
    note: string;
  };
  holdReasons?: {
    reasons: QueueHoldReason[];
    note?: string;
  }[];
  flags?: {
    reasons: string[];
    note?: string;
    dataKey: string;
  }[];
  exception?: {
    note: string;
  };
  referenceIds?: ReferenceIdObject[];
};

export type ApprovalExceptionNote = {
  note: string;
  exceptionGivenBy: string;
};

export enum QueuesSearchField {
  primaryApplicantName = 'account.primaryApplicantName',
  address1 = 'account.address.address1',
  address2 = 'account.address.address2',
  addressCity = 'account.address.city',
  addressState = 'account.address.state',
  addressZIP = 'account.address.zip',
  applicantEmail = 'account.applicants.email',
  organizationId = 'organizationId',
  licensedOrganizationId = 'licensedOrganizationId',
  organizationName = 'organizationName',
  licensedOrganizationName = 'licensedOrganizationName',
  salesRepName = 'salesRepName',
  status = 'status',
}

export enum QueueSortField {
  UPDATED_AT_DESC = 'updatedAtDescending',
  UPDATED_AT_ASC = 'updatedAtAscending',
}

export const queueStatusMap: Record<string, string> = {
  submitted: 'Ready',
  resubmitted: 'Re-Review',
  onHold: 'On Hold',
  inProgress: 'In Progress',
  rejected: 'Rejected',
};

export enum ReviewQueueRoute {
  activationPackage = 'activation-package',
  postActivationPackage = 'post-activation-package',
  installPackage = 'install-package',
  ntpPackage = 'ntp-package',
  voidedCheck = 'voided-check',
  reviewDisclosure = 'review-disclosure',
  reviewIdentity = 'review-identity',
  reviewSupporting = 'review-supporting',
  reviewTitle = 'review-title',
  reviewUploadedDocument = 'uploaded-document',
  reviewPermissionToOperate = 'pto-package',
  electricianSignOff = 'electrician-sign-off',
}

export enum LegacyReviewQueueType {
  title = 'title',
  identity = 'identity',
  uploadedDocument = 'uploaded-document',
  install = 'install',
  systemActivation = 'system-activation',
  voidedCheck = 'voided-check',
}

export const paramToQueueTypeMap: Record<
  string,
  {
    documentType?: DocumentTypes;
    label: string;
    legacyType?: LegacyReviewQueueType;
    type: QueueType;
    getAccountReviewLink: (accountId: string) => string;
  }
> = {
  [ReviewQueueRoute.activationPackage]: {
    label: 'Activation Package',
    legacyType: LegacyReviewQueueType.systemActivation,
    type: QueueType.activationPackage,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}/activation-package/review`,
  },
  [ReviewQueueRoute.postActivationPackage]: {
    label: 'Post Activation Package',
    type: QueueType.postActivationPackage,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}/post-activation-package/review`,
  },
  [ReviewQueueRoute.reviewDisclosure]: {
    documentType: DocumentTypes.disclosure,
    label: 'Disclosure Document',
    legacyType: LegacyReviewQueueType.uploadedDocument,
    type: QueueType.disclosureDocument,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}?scrollTo=documents`,
  },
  [ReviewQueueRoute.ntpPackage]: {
    label: 'Notice to Proceed Package (HVAC)',
    type: QueueType.ntpPackage,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}/ntp-package/review`,
  },
  [ReviewQueueRoute.installPackage]: {
    label: 'Install Package',
    legacyType: LegacyReviewQueueType.install,
    type: QueueType.installPackage,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}/installation-package/review`,
  },
  [ReviewQueueRoute.reviewIdentity]: {
    label: 'Identity Verification',
    legacyType: LegacyReviewQueueType.identity,
    type: QueueType.identityVerification,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}`,
  },
  [ReviewQueueRoute.reviewTitle]: {
    label: 'Title Verification',
    legacyType: LegacyReviewQueueType.title,
    type: QueueType.titleVerification,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}`,
  },
  [ReviewQueueRoute.reviewSupporting]: {
    documentType: DocumentTypes.supporting,
    label: 'Supporting Document',
    legacyType: LegacyReviewQueueType.uploadedDocument,
    type: QueueType.supportingDocument,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}?scrollTo=documents`,
  },
  [ReviewQueueRoute.voidedCheck]: {
    label: 'Voided Check',
    type: QueueType.voidedCheck,
    legacyType: LegacyReviewQueueType.voidedCheck,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}?scrollTo=documents`,
  },
  [ReviewQueueRoute.reviewPermissionToOperate]: {
    label: 'Permission to Operate',
    type: QueueType.permissionToOperate,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}/activation-package/review`,
  },
  [ReviewQueueRoute.electricianSignOff]: {
    label: 'Electrician Sign Off',
    type: QueueType.electricianSignOff,
    getAccountReviewLink: (accountId: string) => `/accounts/${accountId}/activation-package/review`,
  },
};
