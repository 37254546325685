import { IDesignTools } from 'types';
import { api } from './api';

export const designToolsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDesignTools: build.query<IDesignTools['value'], void>({
      query: () => ({ url: `design-tools` }),
    }),
  }),
});

export const { useGetDesignToolsQuery } = designToolsApi;
