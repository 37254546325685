import { Box, useBreakpoint } from '@palmetto/palmetto-components';
import { useMemo, useState } from 'react';
import { GalleryItem } from './GalleryItem';
import Lightbox, { NextIcon, PreviousIcon } from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

type GalleryProps = {
  documents: any[];
  useLightbox?: boolean;
};

export const Gallery = ({ documents, useLightbox = false }: GalleryProps) => {
  const { isPhone, isDesktop, isHd } = useBreakpoint();
  const [index, setIndex] = useState(-1);

  const images = useMemo(() => {
    if (useLightbox) {
      return documents
        .filter((document) => document.files[0].contentType.includes('image'))
        .map((document) => ({
          id: document.id,
          src: document.files[0].viewUrls[0].url,
          original: document.files[0].viewUrls[0].url,
          thumbnailCaption: document.files[0].originalName,
        }));
    }
  }, [documents, useLightbox]);

  const handleDocumentClick = (index: number) => {
    if (useLightbox) {
      if (documents?.[index]?.files[0].contentType.includes('image')) {
        const imageIndex = images?.findIndex((image) => image.id === documents[index].id);
        if (imageIndex !== undefined && imageIndex > -1) {
          setIndex(imageIndex);
        }
      } else {
        const documentUrl = documents[index].files[0].viewUrls[0].url;
        window.open(documentUrl, '_blank');
      }
    }
  };

  return (
    <Box>
      <Box
        display="grid"
        style={{
          gridTemplateColumns: isPhone
            ? 'repeat(4,minmax(0,1fr))'
            : isDesktop
              ? 'repeat(4,minmax(0,1fr))'
              : isHd
                ? 'repeat(6,minmax(0,1fr))'
                : 'repeat(2,minmax(0,1fr))',
          columnGap: 'var(--size-spacing-sm)',
          rowGap: 'var(--size-spacing-sm)',
        }}
      >
        {documents.map((document, index) => (
          <GalleryItem
            item={document}
            key={document.id}
            useLightbox={useLightbox}
            onClick={handleDocumentClick}
            index={index}
          />
        ))}
      </Box>
      {useLightbox ? (
        <Lightbox
          slides={images}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          carousel={{
            finite: images && images.length <= 1,
            padding: 50,
          }}
          render={{
            iconNext: () => (images && images.length <= 1 ? null : <NextIcon />),
            iconPrev: () => (images && images.length <= 1 ? null : <PreviousIcon />),
          }}
        />
      ) : null}
    </Box>
  );
};
