import {
  Badge,
  Box,
  Button,
  Card,
  FileUpload,
  Icon,
  Modal,
  TextLink,
  toast,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { StatusIcon } from '../StatusIcon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useLazyGetPropertyReportsForApplicationQuery,
  useVerifyOwnershipForApplicationMutation,
} from '../../services/applications';
import { useParams } from 'react-router-dom';
import { DocumentDocument, ProgramType, UnderwritingDocumentTypes } from 'types';
import { useGetDocumentsQuery, useUploadDocumentMutation } from '../../services/documents';
import { DocumentItem } from './DocumentItem';
import { PropertyOwnershipStatus } from '../../types/Applications';
import { AccountRequirementStatus } from '../../types/Milestone';
import { ApplicationDocumentWithAdditionalApplicantData } from 'types';
import { useGetQueuesByAccountIdAndDocumentTypeQuery } from '../../services/queues';
import { isErrorWithData } from '@/services/helpers';

interface TitleVerificationProps {
  status: AccountRequirementStatus;
  application: ApplicationDocumentWithAdditionalApplicantData;
  creditStatus: AccountRequirementStatus;
  programType: ProgramType;
}
export function TitleVerification({ status, application, creditStatus, programType }: TitleVerificationProps) {
  const { id } = useParams<{ id: any }>();
  const { isOpen, handleClose, handleOpen: openUploadModal } = useOpenClose();
  const {
    isOpen: isPropertyReportOpen,
    handleClose: handlePropertyModalClose,
    handleOpen: openPropertyModal,
  } = useOpenClose();
  const [files, setFiles] = useState<FileList | undefined>();
  const [selectedReportId, setSelectedReportId] = useState<string>();
  const [selectedReport, setSelectedReport] = useState<Record<string, any> | undefined>();
  const [trigger, { data: reports = [], error, isLoading: isDocumentsLoading }] =
    useLazyGetPropertyReportsForApplicationQuery();
  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadDocumentMutation();
  const { data: documents } = useGetDocumentsQuery({
    accountId: id,
    documentTypeFilter: [UnderwritingDocumentTypes.titleVerification],
  });
  const { data: queueItems, isLoading: isGetQueueByAccountIdLoading } = useGetQueuesByAccountIdAndDocumentTypeQuery({
    accountId: id,
    type: UnderwritingDocumentTypes.titleVerification,
  });
  const isLoading = isGetQueueByAccountIdLoading || isDocumentsLoading;

  const documentIdToQueueIdMap = useMemo(() => {
    if (isGetQueueByAccountIdLoading || !queueItems || queueItems.length === 0) return {};
    const documentIdToQueueId: any = {};
    for (const item of queueItems) {
      for (const id of item.documentIds) {
        documentIdToQueueId[id] = item;
      }
    }
    return documentIdToQueueId;
  }, [queueItems, isGetQueueByAccountIdLoading]);

  const [verifyOwnershipForApplication, { isLoading: verifyOwnershipIsLoading }] =
    useVerifyOwnershipForApplicationMutation();

  useEffect(() => {
    if (id && application?.id) {
      trigger({
        accountId: id,
        applicationId: application?.id || '',
        ...(selectedReportId && { includeReportDetails: true }),
      });
    }
  }, [application, trigger, id, selectedReportId]);

  useEffect(() => {
    if (selectedReportId) {
      setSelectedReport(reports.find((report: any) => report?.id === selectedReportId));
    }
  }, [selectedReportId, setSelectedReport, reports]);

  const handleViewReport = useCallback(
    (item: string) => {
      setSelectedReportId(item);
      openPropertyModal();
    },
    [openPropertyModal, setSelectedReportId],
  );

  const handleVerifyOwnership = useCallback(() => {
    verifyOwnershipForApplication({ accountId: id, applicationId: application.id });
  }, [verifyOwnershipForApplication, id, application]);

  const onFileChange = (event: any) => {
    setFiles(event.target?.files);
  };

  const closeUploadModal = () => {
    setFiles(undefined);
    handleClose();
  };

  const handleFileUpload = async () => {
    if (!!files) {
      const data = new FormData();
      data.append('grouped', 'true');
      data.append('type', UnderwritingDocumentTypes.titleVerification);

      for (let i = 0; i < files.length; i++) {
        data.append(`files[${i}]`, files[i]);
      }

      try {
        await uploadDocument({ accountId: id, formData: data, invalidateAllTags: true }).unwrap();
        toast.success(`Title verification uploaded`);
        closeUploadModal();
      } catch (e: any) {
        if (e.status === 413 || e.originalStatus === 413) {
          toast.error('File size too large. Please upload files smaller than 125MB.');
        } else if (isErrorWithData(e)) {
          toast.error(e.data.message);
        } else {
          toast.error('An error occurred while uploading documents');
        }
      }
    }
  };

  const creditApplicationRequired = programType === ProgramType.solar || programType === ProgramType.hvac;

  return (
    <>
      <Modal ariaLabelledBy="titlePropertyReport" isOpen={isPropertyReportOpen} onDismiss={handlePropertyModalClose}>
        <Modal.Header id="titlePropertyReport" title={'Property Report'} onDismiss={handlePropertyModalClose} />
        <Modal.Body>
          <Box background="tertiary" padding="sm">
            {isLoading && <div>Loading...</div>}
            {!isLoading && !error && (
              <code>
                <pre>{JSON.stringify(selectedReport?.response?.Reports[0], null, 2)}</pre>
              </code>
            )}
          </Box>
        </Modal.Body>
      </Modal>
      <Modal
        isOpen={isOpen}
        onDismiss={closeUploadModal}
        maxWidth="4xl"
        ariaLabelledBy="uploadTitleVerificationHeader"
      >
        <Modal.Header
          id="uploadTitleVerificationHeader"
          title="Upload Proof of Title"
          onDismiss={closeUploadModal}
        />
        <Modal.Body background="secondary" childGap="md">
          <Box>Approved documents include a copy of:</Box>
          <Box as="ul" childGap="xs">
            <li>Recorded Deed</li>
            <li>Recent mortgage statement</li>
            <li>Most recent property tax bill</li>
          </Box>
          <FileUpload
            buttonText="Choose File(s)"
            name="files"
            labelText="Choose File(s)"
            id="files"
            accept="image/*,.pdf"
            variant="secondary"
            tone="neutral"
            multiple
            size="md"
            required
            requiredIndicator=""
            onChange={onFileChange}
            isDisabled={isUploadingDocument}
            files={files}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" tone="neutral" isDisabled={isUploadingDocument} onClick={closeUploadModal}>
            Cancel
          </Button>
          <Button isLoading={isUploadingDocument} onClick={handleFileUpload} isDisabled={!files || !files.length}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
      <Card.Section childGap="md">
        <Box childGap="lg" alignItems="center" direction="row" justifyContent="space-between">
          <Box direction="row" alignItems="center">
            <Box padding="0 xs 0 0">
              <StatusIcon status={status} size="md" />
            </Box>
            <Box as="h3" fontWeight="medium" fontSize="md">
              Title Verification
            </Box>
          </Box>
          {creditApplicationRequired && creditStatus !== 'completed' ? (
            <Box fontWeight="medium" fontSize="sm" color="body-secondary">
              Pending Credit Application
            </Box>
          ) : status === 'pending' ? (
            <Box>
              <Button
                size="sm"
                style={{ width: 'fit-content' }}
                onClick={() => handleVerifyOwnership()}
                isLoading={verifyOwnershipIsLoading}
                variant="secondary"
                tone="neutral"
              >
                Auto-Verify
              </Button>
            </Box>
          ) : status !== 'completed' ? (
            <Box direction="row" alignItems="center" childGap="sm">
              <Badge
                message={
                  status === AccountRequirementStatus.error
                    ? 'Error: Further action required'
                    : 'Further action required'
                }
                variant="danger"
              />
              <Button
                size="sm"
                style={{ width: 'fit-content' }}
                onClick={() => openUploadModal()}
                variant="secondary"
                tone="neutral"
              >
                Upload Proof of Title
              </Button>
            </Box>
          ) : (
            <Badge message="Verified" variant="success" />
          )}
        </Box>
        {!isLoading &&
          !error &&
          reports.map((report: any) => {
            const locationVerified = report?.result?.propertyInExpectedLocation;
            const ownerVerified = report?.result?.applicantMentionedAsOwner;
            return (
              <Box
                key={report?.id}
                padding="sm 0 0 lg"
                width="100"
                childGap="lg"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                borderWidth="xs 0 0 0"
                borderColor="separator"
              >
                <Box childGap="2xs">
                  <Box direction="row" childGap="xs">
                    <Icon
                      name={locationVerified ? 'check' : 'remove'}
                      color={locationVerified ? 'primary' : 'danger'}
                    />
                    Property {locationVerified ? '' : ' not'} location verified
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Icon name={ownerVerified ? 'check' : 'remove'} color={ownerVerified ? 'primary' : 'danger'} />
                    Primary applicant is {ownerVerified ? '' : ' not'} an owner
                  </Box>
                  {report?.result?.status === PropertyOwnershipStatus.verified && (
                    <Box direction="row" childGap="xs" color="info">
                      <Icon name="star-empty" />
                      No further action required
                    </Box>
                  )}
                  {report?.applicationId && (
                    <Box fontSize="xs" padding="md 0 0 0">
                      For Application Id: {report.applicationId}
                    </Box>
                  )}
                </Box>
                <TextLink
                  style={{
                    cursor: 'pointer',
                    color: 'var(--color-text-primary)',
                    textDecoration: 'underline',
                  }}
                  onClick={() => handleViewReport(report?.id)}
                >
                  Property Report
                </TextLink>
              </Box>
            );
          })}
        {documents?.length && !isLoading ? (
          <Box childGap="lg" display="block">
            {documents?.map((doc: DocumentDocument) => {
              const queueItem = doc.id ? documentIdToQueueIdMap[doc.id.toString()] : undefined;
              return (
                <DocumentItem key={doc.id?.toString()} item={doc} showDocumentActions={true} queueItem={queueItem} />
              );
            })}
          </Box>
        ) : null}
      </Card.Section>
    </>
  );
}
