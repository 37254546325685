import { Box, Button, Card, Column, Table, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import RequirePricingAdminUser from '../../auth/RequirePricingAdminUser';
import { currencyFormatter } from '../../../helpers/currencyFormatter';
import { CreateHoldbackModal } from './CreateHoldbackModal';
import { useGetHoldbacksQuery } from '../../../services/pricing';
import { EditHoldbackModal } from './EditHoldbackModal';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProgramType } from 'types';

export const Holdbacks = () => {
  const { isPhone } = useBreakpoint();
  const [selected, setSelected] = useState<any>(null);
  const { programType = ProgramType.solar } = useParams<{ programType: string }>();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const { isOpen: isEditModalOpen, handleOpen: openEditModal, handleClose: closeEditModal } = useOpenClose();
  const { data: holdbacks = [], isLoading } = useGetHoldbacksQuery({ programType });

  const handleClick = useCallback(
    (row: any) => {
      setSelected(row);
      openEditModal();
    },
    [openEditModal],
  );

  const columnConfig = useMemo(
    () => [
      {
        heading: 'Name',
        dataKey: 'name',
        render: (_cell: any, row: any) => (
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
            {row.name}
          </span>
        ),
      },
      { heading: 'Amount', dataKey: 'amount', render: (cell: any) => currencyFormatter.format(cell) },
    ],
    [handleClick],
  );

  return (
    <>
      <CreateHoldbackModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <EditHoldbackModal
        isModalOpen={isEditModalOpen}
        closeModal={closeEditModal}
        holdbackId={selected?.id}
        holdbackName={selected?.name}
      />
      <Box direction="row" childGap="3xl">
        <Box flex="auto">
          <Card padding="sm">
            <Card.Header>
              <Box direction="row" justifyContent="space-between">
                <Box fontSize="md" fontWeight="medium">
                  Pricing Holdbacks
                </Box>
                <RequirePricingAdminUser>
                  <Button
                    as="button"
                    variant="primary"
                    iconPrefix="add"
                    size={{ base: 'sm', desktop: 'md' }}
                    aria-label="create adder"
                    onClick={() => openModal()}
                  >
                    {!isPhone && 'Holdback'}
                  </Button>
                </RequirePricingAdminUser>
              </Box>
            </Card.Header>
            <Table rowKey="id" columns={columnConfig as Column[]} rows={holdbacks} isLoading={isLoading} />
          </Card>
        </Box>
      </Box>
    </>
  );
};
