import { CreditReportDetails, CreditReportSummary } from '../types/CreditReports';
import { api } from './api';

export const CreditReportApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCreditReportSummaries: build.query<CreditReportSummary[], void>({
      query: (accountId) => ({ url: `accounts/${accountId}/reports` }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ applicationId }) => ({ type: 'CreditReportSummaries' as const, applicationId })),
              { type: 'CreditReportSummaries', id: 'LIST' },
            ]
          : [{ type: 'CreditReportSummaries', id: 'LIST' }],
    }),
    getCreditReportDetails: build.query<
      CreditReportDetails[],
      { accountId: string; applicationId: string; applicantId?: string }
    >({
      query: ({ accountId, applicationId, applicantId }) => ({
        url: `accounts/${accountId}/reports/${applicationId}${applicantId ? '?applicantId=' + applicantId : ''}`,
      }),
      // providesTags: (result) => [{ type: 'CreditReportDetails', id: result?.id }],
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CreditReportDetails' as const, id })),
              { type: 'CreditReportDetails', id: 'LIST' },
            ]
          : [{ type: 'CreditReportDetails', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetCreditReportSummariesQuery,
  useGetCreditReportDetailsQuery,
  useLazyGetCreditReportDetailsQuery,
} = CreditReportApi;
