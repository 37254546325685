import { useEffect, useState } from 'react';
import { Box, Icon, TextInput, TextInputProps } from '@palmetto/palmetto-components';

export interface SearchInputProps extends Partial<TextInputProps> {
  searchUsers?: string;
  setSearchText(searchTerm?: string): void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

export function SearchOrganizationUser({
  searchUsers = '',
  setSearchText,
  placeholder = 'Search by name or email',
  label = 'Search',
  disabled = false,
}: SearchInputProps): JSX.Element {
  const [enteredSearchText, setEnteredSearchText] = useState<string>(searchUsers);

  useEffect(() => {
    if (searchUsers !== enteredSearchText && (enteredSearchText.length >= 3 || enteredSearchText === '')) {
      const id = setTimeout(() => {
        setSearchText(enteredSearchText);
      }, 400);

      return () => {
        clearTimeout(id);
      };
    }
  }, [enteredSearchText, searchUsers, setSearchText]);

  return (
    <Box width={{ base: '3xl', desktop: '3xl' }}>
      <TextInput
        autoComplete={false}
        autoFocus={false}
        hideLabel
        id="searchInput"
        label={label}
        onChange={(e) => setEnteredSearchText(e.target.value)}
        onClear={() => setEnteredSearchText('')}
        value={enteredSearchText}
        minLength={3}
        placeholder={placeholder}
        size="sm"
        prefix={<Icon name="search" />}
        isDisabled={disabled}
      />
    </Box>
  );
}
