import { useEffect, useMemo } from 'react';
import { useGetQuotesQuery } from '../../services/quotes';
import { QuoteStatus } from '../../types/Quotes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Origin } from '../../types/ProductionValueCheck';
import { useGetCurrentDesignQuery } from '../../services/systemDesign';
import { useHasEnabledNonAnonFlag } from '../FlagGuard/Hook';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { useGetAccountQuery } from '@/services/accounts';

// TODO: Just implement as a hook
export const NeedsAttestmentGuard = ({ children }: { children: any }) => {
  const { id, action } = useParams<{ id: any; action: ReviewPackageActions }>();
  const { data: account } = useGetAccountQuery(id);
  const { data: currentDesignQueryData, isLoading: isCurrentDesignQueryDataLoading }: any = useGetCurrentDesignQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const { data: quotes, isLoading: areQuotesLoading } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const { checkFlags } = useHasEnabledNonAnonFlag(['useProductionConfirmation']);
  const enabledProductionValueCheck = checkFlags();
  const location = useLocation();
  const navigate = useNavigate();

  const needsAttestment = useMemo(() => {
    const hasActiveQuote = quotes?.find((element: any) => element.status === QuoteStatus.active);
    return Boolean(hasActiveQuote && !currentDesignQueryData?.attestment);
  }, [currentDesignQueryData, quotes]);

  const isLoading =
    areQuotesLoading || isCurrentDesignQueryDataLoading || typeof enabledProductionValueCheck === 'undefined';
  const shouldRedirect = needsAttestment && !isLoading;

  useEffect(() => {
    if (
      shouldRedirect &&
      enabledProductionValueCheck &&
      location?.state &&
      location?.state?.shouldRedirect !== false
    ) {
      navigate(`/accounts/${id}/production-value-check`, {
        state: {
          from: Origin.INSTALL_PACKAGE,
          action,
        },
        replace: true,
      });
    }
  }, [shouldRedirect, enabledProductionValueCheck, navigate, id, location, action]);

  return children;
};
