import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';

const SabalPaymentFailed = ({ event }: { event: any }) => {
  return (
    <Box display="block" childGap="sm">
      <Box display="block">
        <EventBy event={event} /> invoice payment failed
      </Box>
      {event?.data?.paymentFailureMessage && (
        <Box
          fontWeight="medium"
          padding="sm"
          radius="md"
          background="primary"
          display="inline-block"
          borderWidth="xs"
          borderColor="separator"
        >
          {event.data.paymentFailureMessage}
        </Box>
      )}
    </Box>
  );
};

export default SabalPaymentFailed;
