export enum QuoteStatus {
  active = 'active',
  contracted = 'contracted',
  voided = 'voided',
}

export enum AdderType {
  arbitrageBattery = 'arbitrageBattery',
  backupBattery = 'backupBattery',
  backupBatteryKwh = 'backupBatteryKwh',
  backupBatteryPriceCap = 'backupBatteryPriceCap',
  electricalUpgrade = 'electricalUpgrade',
  solarReadiness = 'solarReadiness',
}

export const addersTitle = {
  arbitrageBattery: 'Arbitrage Battery',
  backupBattery: 'Backup Battery',
  electricalUpgrade: 'Electrical Upgrade',
  solarReadiness: 'Solar Readiness',
};
