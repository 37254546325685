import { ReactNode, useState } from 'react';
import { Box, Button, ButtonSize, Popover, TextInput } from '@palmetto/palmetto-components';

type FilterButtonDropdownProps = {
  label: string;
  children?: ReactNode;
  className?: string;
  size?: ButtonSize;
  searchValue: string;
  setSearchValue: (search: string) => void;
  onApplyFilters: () => void;
  onClearFilters: () => void;
};

export const FilterButtonDropdown = ({
  label,
  children,
  className,
  size = 'sm',
  searchValue,
  setSearchValue,
  onApplyFilters,
  onClearFilters,
}: FilterButtonDropdownProps) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const isSearchable = !!setSearchValue;

  const togglePopover = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  const handleDone = () => {
    if (isSearchable) setSearchValue('');
    if (onApplyFilters) onApplyFilters();
    setPopoverOpen(!isPopoverOpen);
  };

  const handleClear = () => {
    if (onClearFilters) onClearFilters();
  };

  const handleClickOutside = () => {
    setPopoverOpen(false);
  };

  const content = (
    <Box>
      {isSearchable && (
        <Box padding="md">
          <TextInput
            id="searchStatus"
            size="sm"
            label={`Search ${label}`}
            placeholder={`Search ${label}`}
            hideLabel
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            onClear={() => setSearchValue('')}
          />
        </Box>
      )}
      <Box display="block" childGap="xs" maxHeight="3xl" overflow="auto" background="secondary" padding="md">
        {children}
      </Box>
      <Box padding="md" direction="row" alignItems="center" justifyContent="space-between">
        <button
          className="naked font-size-sm font-color-grey-500 p-v-xs"
          style={{ textDecoration: 'underline' }}
          onClick={handleClear}
        >
          Clear
        </button>
        <Button variant="primary" size="sm" onClick={handleDone} className="shadow-xs">
          Done
        </Button>
      </Box>
    </Box>
  );

  return (
    <div className={className}>
      <Popover
        content={content}
        isOpen={isPopoverOpen}
        placement="bottom-start"
        offsetFromTarget={8}
        contentContainerProps={{
          padding: '0',
          width: '3xl',
          shadow: 'md',
        }}
        arrowColor={isSearchable ? 'white' : 'grey-50'}
        onClickOutside={handleClickOutside}
        portalTarget={document.body}
        withPortal
      >
        <Button
          onClick={togglePopover}
          variant="secondary"
          tone="neutral"
          size={size}
          iconSuffix="caret-down"
          style={{ whiteSpace: 'nowrap' }}
        >
          {label}
        </Button>
      </Popover>
    </div>
  );
};
