import { useMemo } from 'react';
import { AdderSection } from './AdderSection';
import { BatteryInputs } from './BatteryInputs';
import { Box } from '@palmetto/palmetto-components';
import { FlagBox } from '../QueueFlags/FlagBox';
import { AdderType, ProgramType } from 'types';

interface AddersListProps {
  selectedAdders: AdderType[];
  isLoading: boolean;
  enableFlags?: boolean;
  oldQuoteFlag?: boolean;
  programType: ProgramType;
}

export const AddersList = ({
  selectedAdders,
  isLoading,
  enableFlags = false,
  oldQuoteFlag = false,
  programType,
}: AddersListProps) => {
  const selectedAddersMap = useMemo(
    () =>
      selectedAdders?.reduce(
        (acc, adder) => {
          acc[adder] = true;
          return acc;
        },
        {} as Record<AdderType, boolean>,
      ),
    [selectedAdders],
  );
  const sectionProps = {
    [AdderType.arbitrageBattery]: {
      label: 'Arbitrage Battery',
      children: oldQuoteFlag ? (
        <Box fontWeight="bold" fontSize="sm">
          Flag no longer applicable because the adder was removed.
        </Box>
      ) : (
        <BatteryInputs fieldName="arbitrageBattery" isLoading={isLoading} programType={programType} />
      ),
    },
    [AdderType.backupBattery]: {
      label: 'Backup Battery',
      children: oldQuoteFlag ? (
        <Box fontWeight="bold" fontSize="sm">
          Flag no longer applicable because the adder was removed.
        </Box>
      ) : (
        <BatteryInputs fieldName="backupBattery" isLoading={isLoading} programType={programType} />
      ),
    },
    [AdderType.electricalUpgrade]: {
      label: 'Electrical Upgrade',
      children: oldQuoteFlag ? (
        <Box fontWeight="bold" fontSize="sm">
          Flag no longer applicable because the adder was removed.
        </Box>
      ) : (
        <Box fontWeight="bold" fontSize="sm">
          Included
        </Box>
      ),
    },
    [AdderType.solarReadiness]: {
      label: 'Solar Readiness',
      children: oldQuoteFlag ? (
        <Box fontWeight="bold" fontSize="sm">
          Flag no longer applicable because the adder was removed.
        </Box>
      ) : (
        <Box fontWeight="bold" fontSize="sm">
          Included
        </Box>
      ),
    },
  };
  return (
    <>
      {selectedAddersMap?.arbitrageBattery && (
        <>
          {enableFlags ? (
            <FlagBox
              dataKey={sectionProps[AdderType.arbitrageBattery].label}
              displayWithNoQueue
              isLast={!(selectedAddersMap?.backupBattery || selectedAddersMap?.electricalUpgrade)}
            >
              {sectionProps[AdderType.arbitrageBattery].children}
            </FlagBox>
          ) : (
            <AdderSection title={sectionProps[AdderType.arbitrageBattery].label}>
              {sectionProps[AdderType.arbitrageBattery].children}
            </AdderSection>
          )}
        </>
      )}
      {selectedAddersMap?.backupBattery && (
        <>
          {enableFlags ? (
            <FlagBox
              dataKey={sectionProps[AdderType.backupBattery].label}
              displayWithNoQueue
              isLast={!selectedAddersMap?.electricalUpgrade}
            >
              {sectionProps[AdderType.backupBattery].children}
            </FlagBox>
          ) : (
            <AdderSection title={sectionProps[AdderType.backupBattery].label}>
              {sectionProps[AdderType.backupBattery].children}
            </AdderSection>
          )}
        </>
      )}
      {selectedAddersMap?.electricalUpgrade && (
        <>
          {enableFlags ? (
            <FlagBox dataKey={sectionProps[AdderType.electricalUpgrade].label} displayWithNoQueue>
              {sectionProps[AdderType.electricalUpgrade].children}
            </FlagBox>
          ) : (
            <AdderSection title={sectionProps[AdderType.electricalUpgrade].label}>
              {sectionProps[AdderType.electricalUpgrade].children}
            </AdderSection>
          )}
        </>
      )}
      {selectedAddersMap?.solarReadiness && (
        <>
          {enableFlags ? (
            <FlagBox dataKey={sectionProps[AdderType.solarReadiness].label} displayWithNoQueue>
              {sectionProps[AdderType.solarReadiness].children}
            </FlagBox>
          ) : (
            <AdderSection title={sectionProps[AdderType.solarReadiness].label}>
              {sectionProps[AdderType.solarReadiness].children}
            </AdderSection>
          )}
        </>
      )}
    </>
  );
};
