import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';
import { HoldbackSettingsDocument } from 'types';

const SabalHoldbackCreated = ({ event }: { event: any }) => {
  const holdback: HoldbackSettingsDocument = event.data;
  return (
    <Box display="block">
      <EventBy event={event} /> created a Holdback ({holdback.name} - ${holdback.value.amount})
    </Box>
  );
};

export default SabalHoldbackCreated;
