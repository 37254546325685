import { NotesDrawer } from '../NotesDrawer/NotesDrawer';
import { toast, useOpenClose, Alert, DateInput, FormikTextInput } from '@palmetto/palmetto-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import {
  QueueStatus,
  QueueType,
  ProgramType,
  InstallationPackagePhotoCategory,
  HVACEquipmentDetails,
  UserPermissions,
  DeepPartial,
  InstallHVACPackage as IInstallHVACPackage,
  ProductDTO,
} from 'types';
import { Field, Form, Formik, FormikValues } from 'formik';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { useGetAccountQuery } from '../../services/accounts';
import { MainContainer } from '../MainContainer';
import { QueueFlagsProvider } from '../QueueFlags/QueueFlagsContext';
import { useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import { useGetCurrentDesignQuery } from '../../services/systemDesign';
import { PreventSubmissionModal } from '../ProductionValueCheck/PreventSubmissionModal';
import { QueueBanner } from '../MilestonePackagePartials/QueueBanner';
import { useAuth } from '../auth/authProvider';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import { Footer } from '../MilestonePackagePartials/Footer';
import { isErrorWithData } from '../../services/helpers';
import { convertToISODateString, DATE_FORMAT } from '../../helpers/dates';
import { PhotosList } from '../MilestonePackagePartials/Documents/PhotosList';
import EquipmentSection from '../SystemDesign/hvac/EquipmentSection';
import usePermissions from '@/hooks/usePermissions';
import { HVACAttestationModal } from '../ProductionValueCheck/HvacAttestationModal';
import {
  useSaveInstallHVACPackageMutation,
  useSubmitInstallHVACPackageMutation,
} from '@/services/installHVACPackage';
import { useGetProductsQuery } from '@/services/productCatalog';
import { SystemPhotos } from '../MilestonePackagePartials/hvac/SystemPhotos';
import { AttestationSection } from '../MilestonePackagePartials/hvac/AttestationSection';
import { FlagBox } from '../QueueFlags/FlagBox';

interface InstallPackageProps {
  canSubmitInstallPackage: boolean;
}

const InstallHVACPackageSchema = yup.object().shape({
  systemInstallDate: yup.string().required('System Install Date is a required field.'),
  ahriNumber: yup.string(),
  incentiveAttestationCheckbox: yup.boolean().oneOf([true], 'Incentive Attestation is a required field.'),
  permitAttestationCheckbox: yup.boolean().oneOf([true], 'Permit Attestation is a required field.'),
});

const incentiveAttestLabel =
  'I attest that the customer has been informed of all potential incentive eligibility, and that their system is not eligible for tax credit';

const permitAttestLabel =
  'I attest that I have or will apply for any and all necessary permits with the local authority having jurisdiction';

export const formatInstallHVACPackagesData = (values: FormikValues): DeepPartial<IInstallHVACPackage> => {
  return {
    systemDesign: {
      equipment: values.equipment,
      installKits: values.installKits,
      services: values.services,
      discounts: values.discounts,
      ahriNumber: values.ahriNumber,
    },
    systemInstallDate: values.systemInstallDate
      ? { palmetto: convertToISODateString(values.systemInstallDate) }
      : undefined,
    incentiveAttestation: values.incentiveAttestationCheckbox
      ? { attestmentText: Boolean(values.incentiveAttestationCheckbox) ? incentiveAttestLabel : '' }
      : undefined,
    permitAttestation: values.permitAttestationCheckbox
      ? { attestmentText: Boolean(values.permitAttestationCheckbox) ? permitAttestLabel : '' }
      : undefined,
  };
};

export const InstallHVACPackage = ({ canSubmitInstallPackage }: InstallPackageProps) => {
  const navigate = useNavigate();
  const { claims } = useAuth();
  const isFinCoUser = isPalmettoFinanceUser(claims);

  const { id = '', action = 'review' } = useParams<{ id: string; action: ReviewPackageActions }>();
  const { data: queue, isLoading: isQueueLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.installPackage,
  });
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(id);

  const { data: systemDesign }: any = useGetCurrentDesignQuery({
    id,
    programType: ProgramType.hvac,
  });
  const { data: products, isLoading: isProductsLoading } = useGetProductsQuery({
    classification: 'hvac',
  });

  const productsByClassification = useMemo(() => {
    if (!products) return {};
    return products?.reduce((acc: any, item: ProductDTO) => {
      if (!acc[item.productType]) {
        acc[item.productType] = [];
      }
      acc[item.productType].push(item);
      return acc;
    }, {});
  }, [products]);

  const systemEquipmentWithProductOption = useMemo(() => {
    if (!systemDesign?.equipment || !Object.keys(productsByClassification).length) return [];
    return systemDesign.equipment.map((e: HVACEquipmentDetails) => {
      // try to match items based on model and manufacturer
      // if no match but there is a productId from the product catalog then we should signify this noting changes have been made
      const selectedItem = productsByClassification?.[e.type]?.find(
        (item: ProductDTO) => item.sku === e.model && item.manufacturer === e.manufacturer,
      );
      const productOption = selectedItem
        ? { label: `${e.manufacturer} - ${e.model}`, value: selectedItem.id }
        : { label: `${e.manufacturer} - ${e.model} ${e.productId ? '*' : '**'}`, value: e.model };
      return {
        ...e,
        option: productOption,
      };
    });
  }, [systemDesign?.equipment, productsByClassification]);

  const permissions = usePermissions();
  const userOnlyHasReadPermissions = permissions?.every((permission) => permission.includes(UserPermissions.read));

  const systemInstallDate = (values: any): Date | null => {
    return values.systemInstallDate ? DateTime.fromFormat(values.systemInstallDate, DATE_FORMAT).toJSDate() : null;
  };

  const handleDateChange = useCallback((date: Date | [Date, Date] | null, setFieldValue: any) => {
    if (!date || !(date instanceof Date)) return;
    setFieldValue('systemInstallDate', DateTime.fromJSDate(date).toUTC().toFormat(DATE_FORMAT));
  }, []);

  const minDate = DateTime.fromISO('2022-01-01').toJSDate();
  const maxDate = DateTime.local().endOf('day').toJSDate();
  const [triggerSubmit] = useSubmitInstallHVACPackageMutation();
  const [triggerSave] = useSaveInstallHVACPackageMutation();

  const { isOpen: isPreventSubmissionOpen, handleClose: setPreventSubmissionClose } = useOpenClose();
  const {
    isOpen: isAttestationOpen,
    handleOpen: setAttestationSubmissionOpen,
    handleClose: setAttestationSubmissionClose,
  } = useOpenClose();
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [userAttestation, setUserAttestation] = useState(false);
  const isLoading = isAccountLoading || isQueueLoading || isProductsLoading;

  useEffect(() => {
    if (!action || !ReviewPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
    if (action === ReviewPackageActions.review && !isFinCoUser) {
      if (queue) {
        navigate(`/accounts/${id}/installation-package/edit`, { replace: true });
      } else {
        navigate(`/accounts/${id}/installation-package/create`, { replace: true });
      }
    }
  }, [id, navigate, action, isFinCoUser, queue]);

  const accountInitialValues = {
    systemInstallDate: account?.systemInstallDate?.palmetto
      ? DateTime.fromISO(account.systemInstallDate.palmetto).toUTC().toFormat(DATE_FORMAT)
      : '',
    ahriNumber: account?.systemDesign?.ahriNumber || '',
    equipment: systemEquipmentWithProductOption,
    incentiveAttestationCheckbox: Boolean(account?.incentiveAttestation?.attestmentText) || false,
    permitAttestationCheckbox: Boolean(account?.permitAttestation?.attestmentText) || false,
    installKits: account?.systemDesign?.installKits,
    services: account?.systemDesign?.services,
    discounts: account?.systemDesign?.discounts,
  };

  const canSubmitReview = action === ReviewPackageActions.review && queue;
  const initialValues = {
    ...accountInitialValues,
  };

  const handleSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    const formValues = formatInstallHVACPackagesData(values);

    if (!userAttestation) {
      setAttestationSubmissionOpen();
      return;
    }
    try {
      setSubmitting(true);
      await triggerSubmit({ accountId: id, installPackage: formValues as IInstallHVACPackage }).unwrap();
      toast.success('Install Package Submitted');
      navigate(`/accounts/${id}`);
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error submitting install package');
    } finally {
      setSubmitting(false);
      setUserAttestation(false);
      setAttestationSubmissionClose();
    }
  };

  const handleSave = async ({ accountId, data }: { accountId: string; data: FormikValues }) => {
    try {
      const formValues = formatInstallHVACPackagesData(data);
      await triggerSave({ accountId, installPackage: formValues }).unwrap();
      toast.success('Install Package Saved');
    } catch (e) {
      console.error(e);
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error saving Install Package');
      }
    }
  };

  const queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser: boolean =
    !isFinCoUser &&
    queue &&
    (queue.status === QueueStatus.conditionallyApproved || queue.status === QueueStatus.approved);

  if (isLoading) {
    return (
      <MainContainer>
        <FormSection title="Loading...">
          <SkeletonBox height="25px" width="100" />
        </FormSection>
      </MainContainer>
    );
  }

  const attestationModalText =
    'By submitting this package, I attest that all equipment listed is accurate to the equipment installed for the customer, and that all equipment is working to manufacturer specifications.';

  return (
    <>
      <QueueFlagsProvider queueId={queue?.id}>
        <NotesDrawer isOpen={isNotesOpen} setIsOpen={setIsNotesOpen} queueType={QueueType.installPackage} />
        <Helmet>
          <title>{`${account?.primaryApplicantName} Install Package`}</title>
        </Helmet>
        <MainContainer>
          {queue && (queue.status === QueueStatus.rejected || isFinCoUser) ? (
            <QueueBanner
              setIsNotesOpen={setIsNotesOpen}
              queueType={QueueType.installPackage}
              isFinCoUser={isFinCoUser}
            />
          ) : null}
          {action === ReviewPackageActions.review && !canSubmitReview ? (
            <Alert
              variant="warning"
              title="Install Package Not Submitted"
              message="This install package is not ready to be reviewed yet. It has not been submitted."
              hasIcon
            />
          ) : null}
          <Formik
            initialValues={initialValues}
            validationSchema={InstallHVACPackageSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values, setSubmitting, isSubmitting, touched, errors }) => (
              <>
                <Form>
                  <FormSection title="System Install Date">
                    <FlagBox dataKey="System Install Date" baseCategory="systemInstallDate" displayWithNoQueue>
                      <Field
                        label=""
                        name="systemInstallDate"
                        id="systemInstallDate"
                        dateFormat={DATE_FORMAT}
                        width="2xl"
                        value={values.systemInstallDate}
                        component={DateInput}
                        datePickerProps={{
                          maxDate,
                          minDate,
                          onChange: (date: Date | [Date, Date] | null) => handleDateChange(date, setFieldValue),
                          openToDate: systemInstallDate(values) ?? undefined,
                        }}
                        textInputProps={{
                          onClear: () => setFieldValue('systemInstallDate', ''),
                        }}
                        isDisabled={isSubmitting}
                        error={touched.systemInstallDate && (errors.systemInstallDate as string)}
                      />
                    </FlagBox>
                  </FormSection>
                  <FormSection title="AHRI Number">
                    <FlagBox dataKey="AHRI Number" baseCategory="AhriNumber" displayWithNoQueue>
                      <Field
                        type="text"
                        name="ahriNumber"
                        id="ahriNumber"
                        width="2xl"
                        autoComplete="off"
                        isDisabled={isSubmitting}
                        component={FormikTextInput}
                      />
                    </FlagBox>
                  </FormSection>
                  <FormSection
                    title="Installation Document"
                    description="To complete installation Milestone, you must either upload the completed Install Checklist including photo of all LightReach Installation Requirements, or upload these photos individually below"
                  >
                    <PhotosList
                      title="Proof of Installation"
                      category={InstallationPackagePhotoCategory.proofOfInstallation}
                      baseCategory="Installation Photo Documentation"
                    />
                  </FormSection>
                  <SystemPhotos />
                  <EquipmentSection
                    productsByClassification={productsByClassification}
                    userOnlyHasReadPermissions={userOnlyHasReadPermissions}
                    userIsEditing={true}
                  />
                  {action !== ReviewPackageActions.review && (
                    <>
                      <AttestationSection
                        incentiveAttestLabel={incentiveAttestLabel}
                        permitAttestLabel={permitAttestLabel}
                        setFieldValue={setFieldValue}
                        values={values}
                        isSubmitting={isSubmitting}
                        touched={touched}
                        errors={errors}
                      />
                    </>
                  )}
                  <Footer
                    canSubmitPackage={canSubmitInstallPackage}
                    isLoading={isLoading}
                    isFirstSubmission={!queue}
                    canSubmitReview={canSubmitReview}
                    onSave={handleSave}
                    queueType={QueueType.installPackage}
                    disabled={queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser}
                  />
                </Form>
                <HVACAttestationModal
                  title="Install Submission Confirmation"
                  label={attestationModalText}
                  values={values}
                  handleSubmit={handleSubmit}
                  isOpen={isAttestationOpen}
                  onDismiss={setAttestationSubmissionClose}
                  onAttestationConfirm={setUserAttestation}
                  isLoading={isSubmitting}
                  setSubmitting={setSubmitting}
                />
              </>
            )}
          </Formik>
          <PreventSubmissionModal isOpen={isPreventSubmissionOpen} onDismiss={setPreventSubmissionClose} />
        </MainContainer>
      </QueueFlagsProvider>
    </>
  );
};
