import { api } from './api';
import { DisclosureType, DisclosureDocument, Locale } from 'types';

export const disclosuresApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDisclosuresByType: build.query<
      DisclosureDocument[],
      { type: DisclosureType; inviteToken: string | null; language?: Locale[] }
    >({
      query: ({ type, inviteToken, language }) => ({
        url: `disclosures?disclosureType=${type}`,
        params: {
          ...(inviteToken ? { inviteToken } : {}),
          ...(language ? { language: language.join(',') } : {}),
        },
      }),
    }),
  }),
});

export const { useGetDisclosuresByTypeQuery } = disclosuresApi;
