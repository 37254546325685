import { Box, Button } from '@palmetto/palmetto-components';

type AppliedFilterProps = {
  onRemoveFilter: () => void;
  value: string;
};

export const AppliedFilterItem = ({ onRemoveFilter, value }: AppliedFilterProps) => {
  return (
    <Box direction="row" gap="xs" alignItems="center" padding="0 sm" radius="lg" background="info">
      <Box
        maxWidth={{ base: 'xl', desktop: '2xl' }}
        fontWeight="bold"
        overflow="hidden"
        display="block"
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Box>
      <Button
        onClick={onRemoveFilter}
        iconPrefix="c-remove"
        size="sm"
        variant="tertiary"
        tone="neutral"
        aria-label="remove"
        style={{ padding: '0' }}
      />
    </Box>
  );
};
