import { Box, Button, Modal, TextareaInput } from '@palmetto/palmetto-components';
import { ChangeEvent, useCallback, useState } from 'react';

interface AddNoteModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSave: (note: string) => void;
}

export const AddNoteModal = ({ isOpen, onDismiss, onSave }: AddNoteModalProps) => {
  const [note, setNote] = useState('');
  const setNoteText = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  }, []);

  const hide = useCallback(() => {
    setNote('');
    onDismiss();
  }, [onDismiss]);

  const save = useCallback(() => {
    onSave(note);
    hide();
  }, [note, onSave, hide]);

  return (
    <Modal isOpen={isOpen} maxWidth="4xl" ariaLabelledBy="addNoteHeader" onDismiss={hide}>
      <Modal.Header id="addNoteHeader" title="Add Note" onDismiss={hide} />
      <Modal.Body background="secondary" childGap="lg">
        <Box>
          <TextareaInput id="note" label="Note" value={note} onChange={setNoteText} rows={3} />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button as="button" onClick={hide} variant="secondary" tone="neutral" size="md">
          Cancel
        </Button>
        <Button size="md" type="submit" onClick={save}>
          Add Note
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
