import { Box, Button, Modal, SelectInput, toast } from '@palmetto/palmetto-components';
import { FormikValues, useFormik } from 'formik';
import { SyntheticEvent } from 'react';
import * as yup from 'yup';
import {
  useCreateOrganizationDomesticContentMutation,
  useGetModifiersQuery,
} from '../../../../../../services/pricing';
import { useParams } from 'react-router-dom';
import { useGetUtilitiesQuery } from '../../../../../../services/utilities';
import { ProgramType } from 'types';
import { useGetOrganizationDetailsQuery } from '@/services/organizations';

export const CreateDomesticContent = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
}) => {
  const [createOrganizationPricingDomesticContentMutation] = useCreateOrganizationDomesticContentMutation();
  const { alias = '' } = useParams<{ alias: string }>();
  const { data: organization, isLoading: isOrgLoading } = useGetOrganizationDetailsQuery(
    { alias: alias as string },
    { skip: !alias },
  );

  const programType = organization?.programTypes?.[0] ?? ProgramType.solar;

  const { data: domesticContentAdders = [] } = useGetModifiersQuery(
    {
      type: 'domesticContent',
      programType,
    },
    { skip: isOrgLoading },
  );
  const filter = [
    { id: 'programType', operation: 'Equal', selectedValues: [ProgramType.solar, ProgramType.newHomes] },
  ];
  const { data: utilities = [] } = useGetUtilitiesQuery({
    advancedFilters: JSON.stringify(filter),
  });
  const formik = useFormik({
    initialValues: {
      domesticContent: {},
      state: [],
    },
    validationSchema: yup.object({
      domesticContent: yup.object().required('Domestic Content is required'),
    }),
    onSubmit: async (values: FormikValues, { setSubmitting, resetForm }) => {
      try {
        const states = values.state.map((state: { label: string; value: string }) => state.value);
        await createOrganizationPricingDomesticContentMutation({
          alias,
          data: { domesticContentId: values.domesticContent.value, states },
        }).unwrap();
        toast.success('Domestic Content added successfully');
        resetForm();
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Error adding Domestic Content');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const stateUtilityMapping = utilities.reduce(
    (acc, utility) => {
      acc[utility.state] = acc[utility.state] ?? [];
      acc[utility.state].push(utility);
      return acc;
    },
    {} as Record<string, any[]>,
  );

  const stateOptions = Object.keys(stateUtilityMapping)
    .sort()
    .map((state) => ({ label: state, value: state }));

  return (
    <Modal ariaLabelledBy="createDomesticContent" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header id="createDomesticContent" title="Add Domestic Content" onDismiss={closeModal} />
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body background="secondary">
          <Box direction="column" childGap="xl">
            {domesticContentAdders && (
              <SelectInput
                label="Domestic Content"
                name="domesticContent"
                id="domesticContent"
                value={formik.values.domesticContent}
                onChange={(event) => formik.setFieldValue('domesticContent', event.target.value)}
                options={domesticContentAdders.map((domesticContent: any) => ({
                  label: domesticContent.name,
                  value: domesticContent.id,
                }))}
                isRequired
                onBlur={formik.handleBlur}
                error={formik.touched.domesticContent && (formik.errors.domesticContent as string)}
                menuPortalTarget={document.body}
              />
            )}
            <Box>
              <SelectInput
                id="state"
                name="state"
                label="State"
                value={formik.values.state}
                onChange={(event) => {
                  formik.setFieldValue('state', event.target.value);
                }}
                options={stateOptions}
                onBlur={formik.handleBlur}
                isDisabled={formik.isSubmitting}
                error={formik.touched.state && (formik.errors.state as string)}
                menuPortalTarget={document.body}
                isMulti
              />
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            as="button"
            onClick={() => {
              formik.resetForm();
              closeModal();
            }}
            variant="secondary"
            tone="neutral"
            size="md"
            isLoading={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button variant="primary" size="md" type="submit" isLoading={formik.isSubmitting}>
            Add Domestic Content
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
