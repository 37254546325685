import { Box } from '@palmetto/palmetto-components';

const CreditExpired = () => (
  <Box display="block">
    <Box as="span" display="inline-block" margin="0 2xs 0 0" childGap="md">
      Due to no signed contract, previously approved credit has expired.
    </Box>
  </Box>
);

export default CreditExpired;
