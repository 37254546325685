import { Box, Icon, TextLink } from '@palmetto/palmetto-components';

export const DesignDocsDescription = (
  <Box fontSize="sm" childGap="xs" color="body-secondary">
    <p>Upload all required design documents:</p>
    <Box childGap="2xs" as="ul">
      <li>Plan Set</li>
      <li>Production Model</li>
      <li>Shade Report</li>
    </Box>
  </Box>
);

export const PermitDocsDescription = (
  <Box fontSize="sm" childGap="2xs">
    <Box>Upload all required permit documents:</Box>
    <Box childGap="2xs" as="ul">
      <li>Approved Permit</li>
    </Box>
  </Box>
);

export const IncentivesDescription = ({ showUtilityBill }: { showUtilityBill: boolean }) => (
  <Box fontSize="sm" childGap="xs" color="body-secondary">
    <p>Upload all required incentive documentation:</p>
    <Box childGap="2xs" as="ul">
      <li>Applicable Incentive Documents</li>
      {showUtilityBill && <li>Recent Utility Bill</li>}
      <li>Approved Interconnection Agreement (CA only)</li>
    </Box>
    <TextLink
      href="https://help.palmetto.finance/en/articles/9083905-incentive-deliverables-m1-milestone"
      target="_blank"
      rel="noreferrer"
    >
      See incentive requirements
    </TextLink>
  </Box>
);

export const InstallPhotosDescription = (
  <Box childGap="xs">
    <p>Upload photos of the installation to all applicable categories.</p>
  </Box>
);

export const PvSystemDescription = (
  <TextLink
    href="https://help.palmetto.finance/en/articles/8305929-approved-vendor-list-avl"
    rel="noreferrer"
    target="_blank"
  >
    Confirm equipment details
  </TextLink>
);

export const InstallerDescription = <p>Required by the project location state</p>;

export const AddersDescription = <Box>Adders included in the project</Box>;

export const EquipmentDetailsDescription = (
  <Box color="body-secondary">
    <p>
      <Box
        as="a"
        direction="row"
        alignItems="center"
        childGap="xs"
        href="https://help.palmetto.finance/en/articles/8305929-approved-vendor-list-avl"
        target="_blank"
        rel="noreferrer"
      >
        <span>Approved Vendor List Reference</span>
        <Icon name="launch-app" size="xs" color="body-tertiary" />
      </Box>
    </p>
    <p>Optional for NTP</p>
  </Box>
);

export const ActivationDocumentsDescription = (
  <Box color="body-secondary" fontSize="sm" childGap="2xs">
    <Box as="p">Upload all required incentives documents:</Box>
    <Box childGap="2xs" as="ul">
      <li>Extended Warranty</li>
      <li>Incentives</li>
    </Box>
  </Box>
);

export const ContractKwhInfoDescription = (
  <Box color="body-secondary" fontSize="sm" childGap="2xs" margin="sm 0 0 0">
    <Box as="p">
      Install Year-1 production estimate must be within -5 - 10% difference from year-1 production estimate in the
      contract
    </Box>
  </Box>
);
export const PermitDescription = (
  <Box as="p" color="body-secondary" fontSize="sm">
    Copy of approved permit (photo of physical document or AHJ portal screenshot)
  </Box>
);
