import { useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { Box } from '@palmetto/palmetto-components';
import { TwelveMonthsNumberInput } from './TwelveMonthsNumberInput';

interface MonthlyProductionProps {
  isLoading: boolean;
}

export const MonthlyProductionFormSection = ({ isLoading }: MonthlyProductionProps) => {
  const { isSubmitting } = useFormikContext<any>();
  const isAnythingLoadingOrSubmitting = isLoading || isSubmitting;

  return (
    <FormSection
      title="Year-1 Monthly Production"
      description="This section is only visible for LightReach team members"
    >
      <Box direction={{ base: 'column', tablet: 'row' }} childGap={{ base: 'lg', desktop: 'xl' }}>
        <TwelveMonthsNumberInput
          fieldName="firstYearMonthlyProductionKwh"
          isLoading={isAnythingLoadingOrSubmitting}
          min={0}
        />
      </Box>
    </FormSection>
  );
};
