import { Box, Badge } from '@palmetto/palmetto-components';
import { applicationStatus } from '../../Underwriting/CreditApplications';
import EventBy from './EventBy';
import { FinancingDecision } from 'types';

const CreditDecision = ({ event }: { event: any }) => {
  const status = event?.data?.creditDecision as FinancingDecision;
  return (
    <Box display="block">
      <Box as="span" display="inline-block" margin="0 2xs 0 0" childGap="md">
        <EventBy event={event} /> inquired credit history for <strong> {event?.data?.creditApplicant}</strong>:{' '}
        <Badge
          message={applicationStatus[status]?.label}
          variant={applicationStatus[status]?.variant}
          style={{ width: 'fit-content' }}
        />
      </Box>
    </Box>
  );
};

export default CreditDecision;
