import { DocumentFile, ViewUrlType } from 'types';
import { Box } from '@palmetto/palmetto-components';
import { trimFileNames } from '@/helpers/trimFileNames';

export function FileItem({ file, className }: { file: DocumentFile; className?: string }) {
  const originalView = file?.viewUrls?.find((f) => f.type === ViewUrlType.original);
  const truncateFileName = trimFileNames(file.originalName);

  return (
    <div className={className}>
      <Box
        width={{
          base: 'xl',
          tablet: 'xl',
          desktop: 'xl',
          hd: '3xl',
        }}
      >
        <a href={originalView?.url} target="_blank" rel="noopener noreferrer" title={file.originalName}>
          {truncateFileName}
        </a>
      </Box>
    </div>
  );
}
