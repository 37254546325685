import { Box, Icon, IconName } from '@palmetto/palmetto-components';
import DOMPurify from 'dompurify';

export interface NoteHistoryItemProps {
  title: string;
  time: string;
  detail?: string | Node;
  icon?: IconName;
}

export const NoteHistoryItem = ({ title, time, detail, icon = 'question-mark' }: NoteHistoryItemProps) => {
  return (
    <Box
      display="grid"
      style={{
        gridTemplateColumns: '96px 28px 1fr',
        columnGap: 'md',
      }}
      padding="md 0 0 0"
    >
      <Box fontSize="md">{time}</Box>
      <Box>
        <Icon name={icon} />
      </Box>
      <Box>
        <Box fontSize="md" fontWeight="medium">
          {title}
        </Box>
        <Box fontWeight="light" color="body-secondary" style={{ lineHeight: 'var(--size-line-height-text)' }}>
          {detail && (
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail) }}
              style={{ whiteSpace: 'pre-wrap' }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
