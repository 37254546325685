import { Box, CheckboxInput } from '@palmetto/palmetto-components';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterButtonDropdown } from './FilterButtonDropdown';
import { TaskStatus } from '../../../types/Tasks';

const sortedStatuses = Object.values(TaskStatus).sort();

type StatusFilterProps = {
  isMobile: boolean;
  onFilterChange?: (key: string, values: string[]) => void;
};

export const StatusFilter = ({ isMobile, onFilterChange }: StatusFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [statusSearchValue, setStatusSearchValue] = useState('');
  const [checkedStatuses, setCheckedStatuses] = useState<string[]>([]);
  const statusSearch = searchParams.get('status');
  const filteredStatuses = useMemo(
    () => sortedStatuses.filter((type) => type.includes(statusSearchValue)),
    [statusSearchValue],
  );

  useEffect(() => {
    if (statusSearch) {
      setCheckedStatuses(statusSearch.split(','));
    } else {
      setCheckedStatuses([]);
    }
  }, [statusSearch]);

  const handleClearStatusFilter = () => {
    setCheckedStatuses([]);
    searchParams.delete('status');
    setSearchParams(searchParams);
  };

  const handleApplyFilters = () => {
    if (checkedStatuses.length) {
      searchParams.set('status', checkedStatuses.join(','));
      setSearchParams(searchParams);
    }
  };

  const handleFilterChange = ({ isSelected, id, updateQueryString, checkedFilters, setCheckedFilters }: any) => {
    const newFilters = [...checkedFilters];

    if (isSelected) {
      newFilters.push(id);
    } else {
      const index = checkedFilters.findIndex((ch: any) => ch === id);
      if (index > -1) newFilters.splice(index, 1);
    }

    if (updateQueryString) {
      searchParams.set(updateQueryString, newFilters.join(','));
      setSearchParams(searchParams);
    }
    setCheckedFilters([...newFilters]);

    if (onFilterChange) {
      onFilterChange('status', newFilters);
    }
  };

  return isMobile ? (
    <Box childGap="lg">
      {filteredStatuses.map((val) => {
        return (
          <Box display="block" childGap="xs" key={val}>
            <CheckboxInput
              id={val}
              label={val}
              key={val}
              onChange={(event) =>
                handleFilterChange({
                  isSelected: event.target.checked,
                  id: val,
                  checkedFilters: checkedStatuses,
                  setCheckedFilters: setCheckedStatuses,
                })
              }
              isChecked={!!checkedStatuses.find((documentType) => documentType === val)}
            />
          </Box>
        );
      })}
    </Box>
  ) : (
    <FilterButtonDropdown
      label="Status"
      searchValue={statusSearchValue || ''}
      setSearchValue={setStatusSearchValue}
      onApplyFilters={handleApplyFilters}
      onClearFilters={handleClearStatusFilter}
    >
      <Box childGap="lg">
        {filteredStatuses.map((val) => {
          return (
            <Box display="block" childGap="xs" key={val}>
              <CheckboxInput
                id={val}
                label={val}
                key={val}
                onChange={(event) =>
                  handleFilterChange({
                    isSelected: event.target.checked,
                    id: val,
                    checkedFilters: checkedStatuses,
                    setCheckedFilters: setCheckedStatuses,
                  })
                }
                isChecked={!!checkedStatuses.find((documentType) => documentType === val)}
              />
            </Box>
          );
        })}
      </Box>
    </FilterButtonDropdown>
  );
};
