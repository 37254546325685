import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';
import { AttestmentData } from 'types';

const Attestation = ({ event }: { event: any }) => {
  const attestmentData: AttestmentData = event.data?.attestation;
  return (
    <Box display="block">
      <EventBy event={event} /> Attested
      <br />
      <Box style={{
        fontStyle: "italic"
      }}>
        {
          attestmentData?.attestmentText
        }
      </Box>
    </Box>
  );
};

export default Attestation;
