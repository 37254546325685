import React from 'react';
import { Modal, Box, Button, Icon } from '@palmetto/palmetto-components';
import { currencyFormatter } from '@/helpers/currencyFormatter';

interface RemoveTransactionsConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  total: number;
  count: number;
}

export const RemoveTransactionsConfirmationModal: React.FC<RemoveTransactionsConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  total,
  count,
}) => {
  const summaryTextArray =
    `${count} transactions totalling ${currencyFormatter.format(total)} to be removed from this batch`.split(' ');
  const summaryTextGroups = [summaryTextArray.slice(0, 5).join(' '), summaryTextArray.slice(5).join(' ')];
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Confirm Payout Approval" onDismiss={onClose} maxWidth="5xl">
      <Modal.Body padding="0">
        <Box direction="row" childGap="sm" background="warning" padding="lg">
          <Box padding="sm">
            <Icon name="t-warning" size="xl" color="warning-500" />
          </Box>
          <Box>
            <Box childGap="md">
              <Box fontSize="md" fontWeight="bold">
                Confirm Transaction Removal
              </Box>
              <Box>Are you sure you want to remove these transaction{count > 0 ? 's' : ''} from this payout?</Box>
            </Box>
          </Box>
        </Box>
      </Modal.Body>
      <Modal.Footer justifyContent="space-between">
        <Box>
          <Box>{summaryTextGroups[0]}</Box>
          <Box>{summaryTextGroups[1]}</Box>
        </Box>
        <Box alignContent="flex-end">
          <Box direction="row" childGap="sm">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              Remove
            </Button>
          </Box>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};
