import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';

const ContractSent = ({ event }: { event: any }) => {
  const recipientEmails = event.data?.recipients;
  return (
    <Box display="block">
      <Box as="span" display="inline-block" margin="0 2xs 0 0">
        <EventBy event={event} /> sent a contract to
        {recipientEmails && recipientEmails.length > 0 ? ` ${recipientEmails.join(', ')}` : ''}
      </Box>
    </Box>
  );
};

export default ContractSent;
