import { Box } from '@palmetto/palmetto-components';

interface DateHeaderProps {
  date: string;
}

export const DateHeader = ({ date }: DateHeaderProps) => {
  return (
    <Box direction="row">
      <Box fontWeight="bold">{date}</Box>
    </Box>
  );
};
