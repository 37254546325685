import { Helmet } from 'react-helmet';
import { Box, createComponent } from '@palmetto/palmetto-components';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Queue } from './Queue';

const MainContent = createComponent(Box)({
  childGap: { base: 'sm', tablet: 'lg', desktop: 'lg' },
  width: '100',
});

/**
 * @deprecated This will be replaced by the /queue route
 */
export function Queues() {
  return (
    <>
      <Helmet>
        <title>Queues</title>
      </Helmet>

      <Box
        direction={{ base: 'column', desktop: 'row' }}
        childGap={{ base: 'lg', desktop: '4xl' }}
        width="100"
        margin="0 auto"
      >
        <MainContent>
          <Routes>
            <Route path="review/:queue" element={<Queue />} />
            <Route path="*" element={<Navigate to="/queues/review/identity" />} />
          </Routes>
        </MainContent>
      </Box>
    </>
  );
}
