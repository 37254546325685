import { Box, FormikTextInput, TextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { usePhoneInput } from 'react-international-phone';

export function ApplicantInformation({
  isSubmitting,
  isLoading,
  prefix,
}: {
  isSubmitting: boolean;
  isLoading: boolean;
  prefix: 'primary' | 'secondary';
}) {
  const { values, setFieldValue } = useFormikContext<any>();
  const initialPhoneNumber = values?.[prefix]?.phoneNumber?.startsWith('+1')
    ? values[prefix].phoneNumber
    : `+1 ${values?.[prefix]?.phoneNumber ?? ''}`;
  const { inputValue: phoneNumber, handlePhoneValueChange } = usePhoneInput({
    value: initialPhoneNumber,
    onChange: (data) => {
      if(values){
        setFieldValue(`${prefix}.phoneNumber`, data.phone);
      }
    },
  });
  return (
    <Box childGap={{ base: 'lg', desktop: 'xl' }}>
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
      >
        <Field
          type="text"
          label="First Name"
          name={`${prefix}.firstName`}
          id={`${prefix}.firstName`}
          component={FormikTextInput}
          isRequired
          autoComplete="off"
          isDisabled={isSubmitting || isLoading}
        />
        <Field
          type="text"
          label="Middle Name"
          name={`${prefix}.middleName`}
          id={`${prefix}.middleName`}
          component={FormikTextInput}
          autoComplete="off"
          isDisabled={isSubmitting || isLoading}
        />
        <Field
          type="text"
          label="Last Name"
          name={`${prefix}.lastName`}
          id={`${prefix}.lastName`}
          component={FormikTextInput}
          isRequired
          autoComplete="off"
          isDisabled={isSubmitting || isLoading}
        />
      </Box>
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
      >
        <TextInput
          label="Phone Number"
          name={`${prefix}.phoneNumber`}
          id={`${prefix}.phoneNumber`}
          isRequired
          autoComplete="off"
          onChange={handlePhoneValueChange}
          value={phoneNumber}
          isDisabled={isSubmitting || isLoading}
          placeholder="+1 (555) 555-5555"
        />
        <Field
          type="email"
          label="Email"
          name={`${prefix}.email`}
          id={`${prefix}.email`}
          component={FormikTextInput}
          placeholder="name@example.com"
          isRequired
          autoComplete="off"
          isDisabled={isSubmitting || isLoading}
        />
      </Box>
      <Box maxWidth="3xl">
        <Field
          type="text"
          label="Social Security Number"
          helpText="Optional, but recommended for best results (last 4 or full)"
          name={`${prefix}.ssn`}
          id={`${prefix}.ssn`}
          component={FormikTextInput}
          autoComplete="off"
          isDisabled={isSubmitting || isLoading}
          inputMask={{
            numericOnly: true,
          }}
          placeholder="e.g. 555555555 or 5555"
        />
      </Box>
    </Box>
  );
}
