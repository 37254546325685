import { Box, Button, Modal, TextInput, toast } from '@palmetto/palmetto-components';
import { FormikValues, useFormik } from 'formik';
import { SyntheticEvent } from 'react';
import * as yup from 'yup';
import { useEditConstructionAdderMutation } from '../../../services/pricing';

export const EditAdderModal = ({
  isModalOpen,
  closeModal,
  adderId,
  adderName,
}: {
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
  adderId: string;
  adderName: string;
}) => {
  const [editConstructionAdder] = useEditConstructionAdderMutation();

  const formik = useFormik({
    initialValues: {
      name: adderName,
    },
    validationSchema: yup.object({
      name: yup.string().required('Option Name is required'),
    }),
    enableReinitialize: true,
    onSubmit: async (values: FormikValues, { setSubmitting }) => {
      try {
        await editConstructionAdder({
          id: adderId,
          data: { name: values.name },
        }).unwrap();
        toast.success('Adder updated successfully');
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Error updating adder');
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Modal ariaLabelledBy="editConstructionAdder" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header id="editConstructionAdder" title="Edit Adder Name" onDismiss={closeModal} />
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body background="secondary">
          <Box direction="column" childGap="md">
            <TextInput
              type="text"
              label="Option Name"
              name="name"
              id="name"
              value={formik.values.name}
              onChange={(event) => formik.setFieldValue('name', event.target.value)}
              isRequired
              onBlur={formik.handleBlur}
              error={formik.touched.name && (formik.errors.name as string)}
            />
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            as="button"
            onClick={() => {
              formik.resetForm();
              closeModal();
            }}
            variant="secondary"
            tone="neutral"
            size="md"
            isLoading={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button variant="primary" size="md" type="submit" isLoading={formik.isSubmitting}>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
