import { Box } from '@palmetto/palmetto-components';
import { AccountMilestoneStipulation } from '../../types/Accounts';
import DateTimeDisplay from '../DateTime';
import { AccountStipulationReason } from '@/../../types/src';
import { HiddenLightReachInfoForEpcUser } from '../auth/HiddenLightReachInfoToEpcUser';

interface AdminStipulationDetailsProps {
  stipulation: AccountMilestoneStipulation;
  forBanner?: boolean;
}

const AdminStipulationDetails = ({ stipulation, forBanner }: AdminStipulationDetailsProps) => {
  const { description, reason, appliedBy, appliedByDisplayName, appliedDate } = stipulation || {};
  const reasonText = reason === AccountStipulationReason.ageRelated ? 'Customer Action Required' : reason;
  return (
    <Box
      flex="auto"
      gap="xs"
      padding={forBanner ? 'lg' : '0'}
      fontSize="sm"
      fontWeight="medium"
      justifyContent="center"
    >
      <Box direction="row" gap="2xs">
        {reason && (
          <>
            <Box fontWeight="bold">{reasonText}</Box>
            {' - '}
          </>
        )}
        <Box as="em">{description}</Box>
      </Box>
      {appliedDate && (
        <Box direction="row" gap="2xs" fontSize="xs">
          <DateTimeDisplay value={appliedDate?.toString()} fontSize="xs" dateFormat="DD h:mm a" inline /> by{' '}
          <HiddenLightReachInfoForEpcUser fallbackChildren="LightReach Support">{appliedByDisplayName || appliedBy}</HiddenLightReachInfoForEpcUser>
        </Box>
      )}
    </Box>
  );
};

export default AdminStipulationDetails;
