import { Box, Button, FormikTextareaInput, Modal, toast, useOpenClose } from '@palmetto/palmetto-components';
import { Field, Form, Formik } from 'formik';
import { useEditAccountStipulationMutation } from '../../services/accounts';
import { AccountMilestoneStipulation, AccountStipulationType } from '../../types/Accounts';
import AdminStipulationDetails from './AdminStipulationDetails';

interface ClearAdminStipulationProps {
  stipulation: AccountMilestoneStipulation;
}

const ClearAdminStipulation = ({ stipulation }: ClearAdminStipulationProps) => {
  const { handleClose, handleToggle, isOpen } = useOpenClose();
  const [editAccountStipulation] = useEditAccountStipulationMutation();

  const handleSubmit = async (
    values: {
      isSatisfied: boolean;
      isSatisfiedNote: string;
    },
    { setSubmitting }: any,
  ) => {
    const { isSatisfied, isSatisfiedNote } = values || {};
    try {
      const stipulationUpdates = {
        isSatisfied,
        isSatisfiedNote,
        stipulationType: AccountStipulationType.administrative,
      };
      await editAccountStipulation({
        stipulation: stipulationUpdates,
        accountId: stipulation?.accountId,
        stipulationId: stipulation?.id,
      }).unwrap();
      await toast.success('Administration stipulation was cleared.');
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error clearing the stipulation.');
    }
    setSubmitting(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={() => {
          handleClose();
        }}
        maxWidth="4xl"
        ariaLabelledBy="clearAdminStiplationHeader"
        fullScreenMobile
        overflow="scroll"
      >
        <Modal.Header
          id="clearAdminStiplationHeader"
          title="Clear Administrative Stipulation"
          onDismiss={() => {
            handleClose();
          }}
        />
        <Formik
          initialValues={{ isSatisfiedNote: '', isSatisfied: true }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <Form noValidate id="clearAdminStipulationForm">
              <Modal.Body background="secondary" gap="md" fontSize="sm">
                <AdminStipulationDetails stipulation={stipulation} />
                <Box gap="md">
                  <Field
                    name="isSatisfiedNote"
                    id="isSatisfiedNote"
                    component={FormikTextareaInput}
                    autoComplete="off"
                    isDisabled={isSubmitting}
                    placeholder="Explain how or why the stipulation was cleared..."
                  />
                </Box>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button size="md" tone="primary" type="submit" isLoading={isSubmitting}>
                  Clear Stipulation
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      <Button tone="neutral" variant="secondary" size="sm" style={{ width: 'fit-content' }} onClick={handleToggle}>
        Clear Stipulation
      </Button>
    </>
  );
};

export default ClearAdminStipulation;
