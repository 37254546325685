import { UserPermissions } from 'types';
import { useAuth } from '../components/auth/authProvider';

const usePermissions = (): UserPermissions[] => {
  const { claims, isAuthenticated } = useAuth();
  if (isAuthenticated && claims?.permissions) {
    return claims.permissions;
  }
  return [];
};

export default usePermissions;
