import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';
import { Diffs } from './Diffs';
import { getSettingsTypeLabel } from '../../../helpers/getSettingsTypeLabel';

const hiddenProperties = ['meta', '_id', 'id'];

const SabalPricingSettingUpdated = ({ event }: { event: any }) => {
  const label = getSettingsTypeLabel(event.data.record);
  return (
    <Box display="block">
      <EventBy event={event} /> updated a {label}: {event.data.record.name}
      <br />
      <Diffs diff={event?.data?.diff} hiddenProperties={hiddenProperties}/>
    </Box>
  );
};

export default SabalPricingSettingUpdated;
