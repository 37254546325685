export const getOrgNameFromAlias = (alias: string): string => {
  // Split the string into an array of words
  const words = alias.split('-');

  // Capitalize the first letter of each word
  const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together with spaces
  const resultString = titleCaseWords.join(' ');

  return resultString;
};
