import { AdderType, PricingSettingsType, SabalEventType, ModifierType } from "types";
import { getAdderTypeLabel } from "./addersHelpers";

const pricingSettingsLabelMapping: {
  [key: string]: string
} = {
  [PricingSettingsType.taxCreditModifiers]: "Tax Credit Modifier",
  [PricingSettingsType.holdbacks]: 'Holdback',
  [PricingSettingsType.pricingRanges]: 'Pricing Range',
  [PricingSettingsType.constructionAdders]: 'Construction Adder',
  [PricingSettingsType.ppwAdjustment]: 'PPW Adjustments',
}

const modifierTypeLabelMapping: {
  [key: string]: string
} = {
  [ModifierType.energyCommunity]: "Energy Community",
  [ModifierType.lowAndMiddleIncome]: "Low and Middle Income",
  [ModifierType.ppwAdjustment]: "PPW Adjustment",
}

export function getSettingsTypeLabel(record: any, eventType?: SabalEventType) {
  const displayPrice = (record?.type === PricingSettingsType.constructionAdders) && (eventType === SabalEventType.sabalConstructionAdderCreated);
  const label = pricingSettingsLabelMapping[record?.type];
  const subLabel = modifierTypeLabelMapping[record?.value?.modifierType] || getAdderTypeLabel(record?.value?.adderType as AdderType);
  const labels = [label, subLabel].filter(Boolean);
  return labels.length ? `${label || ''}${subLabel ? ` (${subLabel})${displayPrice ? ` ($${record.value.kwhAdderBand})` : ''}` : ''}` : "Modifier"
}