import { Box, Table } from '@palmetto/palmetto-components';
import { OrganizationNode } from '../../../types/Organizations';

interface OrganizationStateLicensesProps {
  organization: OrganizationNode | undefined;
}

export const OrganizationStateLicenses = ({ organization }: OrganizationStateLicensesProps) => {
  const stateLicenseColumns = [
    {
      heading: '',
      dataKey: 'state',
      render: (cell: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell}
        </Box>
      ),
    },
    {
      heading: 'License Number',
      dataKey: 'contractorLicenseNumber',
    },
    {
      heading: 'Monetary Limit',
      dataKey: 'monetaryLimit',
    },
    {
      heading: 'Additional License Numbers',
      dataKey: 'additionalLicenseNumbers',
    },
  ];

  return (
    <Box margin="lg 0 0 0" childGap="md">
      <Box as="h4" fontSize="md" fontWeight="medium">
        State Licenses
      </Box>
      {organization?.stateLicenseNumbers?.length ? (
        <Table
          isCompact
          rowKey="contractorLicenseNumber"
          columns={stateLicenseColumns}
          rows={organization?.stateLicenseNumbers || []}
          isScrollable={{
            x: true,
          }}
        />
      ) : (
        'No licenses on file'
      )}
    </Box>
  );
};
