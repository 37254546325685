import { Helmet } from 'react-helmet';
import { Box, Button, toast, useOpenClose } from '@palmetto/palmetto-components';
import { MainContainer } from '..';
import { FilterableProjectTransactionTable } from '@/components/Payouts/FilterableProjectTransactionTable';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import {
  useApproveBatchesMutation,
  useCreateProjectTransactionMutation,
  useGetProjectTransactionsQuery,
  useUnbatchTransactionMutation,
} from '@/services/payouts';
import { DateTime } from 'luxon';
import ApproveBatchConfirmationModal from '@/components/Payouts/admin/ApproveBatchConfirmationModal';
import { CreateProjectTransactionPayload, PayoutEventType, ProjectTransactionStatus } from 'types';
import { RequirePayoutsAdminUser } from '@/components/auth/RequirePayoutsAdminUser';
import { RemoveTransactionsConfirmationModal } from '@/components/Payouts/admin/RemoveTransactionsConfirmationModal';
import { AddProjectTransactionModal } from '@/components/Payouts/admin/AddProjectTransactionModal';
import { buildQueryString } from '@/helpers/buildQueryString';
import getConfigValue from '@/config';

const api = getConfigValue('VITE_APP_API_BASE_URL');

export const ProjectTransactionsView = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [searchParams] = useSearchParams();
  const {
    isOpen: isApproveModalOpen,
    handleOpen: handleOpenApproveModal,
    handleClose: handleCloseApproveModal,
  } = useOpenClose();
  const {
    isOpen: isConfirmRemoveTransactionModalOpen,
    handleOpen: handleOpenConfirmRemoveTransaction,
    handleClose: handleCloseConfirmRemoveTransaction,
  } = useOpenClose();
  const {
    isOpen: isAddProjectTransactionModalOpen,
    handleOpen: handleOpenAddProjectTransactionModal,
    handleClose: handleCloseAddProjectTransactionModal,
  } = useOpenClose();
  const [createTransaction] = useCreateProjectTransactionMutation();
  const eventType = searchParams.get('eventType') as PayoutEventType;
  const status = searchParams.get('status') as ProjectTransactionStatus;

  const { data: transactions, isLoading: isTransactionsLoading } = useGetProjectTransactionsQuery({
    groupId,
    eventType: eventType,
    ...(status ? { status } : {}),
  });
  // Not using RTK query to have the browser download directly
  const exportUrl = `${api}/payouts/transactions/csv?${buildQueryString({ groupId: groupId ?? '', eventType: eventType ?? '', status: status ?? '' })}`;
  const [approveBatches] = useApproveBatchesMutation();
  const [unbatchTransaction] = useUnbatchTransactionMutation();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const isLoading = isTransactionsLoading;
  const handleRowSelected = useCallback(
    (ids: string[], selected: boolean) => {
      if (selected) {
        setSelectedIds([...new Set([...selectedIds, ...ids])]);
      } else {
        setSelectedIds(selectedIds.filter((selectedId) => !ids.includes(selectedId)));
      }
    },
    [selectedIds, setSelectedIds],
  );

  const groupIdDisplay = useMemo(() => {
    return groupId ? DateTime.fromISO(groupId).toFormat('EEEE MMM d, yyyy') : '';
  }, [groupId]);

  const batchIds = useMemo(() => {
    return transactions?.map((transaction) => transaction.batchId as unknown as string) ?? ([] satisfies string[]);
  }, [transactions]);

  const transactionTotalAmount = useMemo(() => {
    return transactions?.reduce((acc, transaction) => acc + transaction.amount, 0) ?? 0;
  }, [transactions]);

  const approveBatch = useCallback(async () => {
    try {
      await approveBatches({ batchIds }).unwrap();
      handleCloseApproveModal();
      toast.success('Batch approved successfully');
    } catch (error) {
      console.log(error);
      toast.error('Could not approve batch');
    }
  }, [approveBatches, batchIds]);

  const removeSelectedTransactions = useCallback(async () => {
    try {
      for (const id of selectedIds) {
        await unbatchTransaction({ transactionId: id }).unwrap();
      }
      handleCloseConfirmRemoveTransaction();
      setSelectedIds([]);
      toast.success('Transactions removed successfully');
    } catch (error) {
      console.log(error);
      toast.error('Could not remove transactions');
    }
  }, [selectedIds, unbatchTransaction]);

  const handleAddTransaction = useCallback(async (values: CreateProjectTransactionPayload) => {
    try {
      await createTransaction(values).unwrap();
      handleCloseAddProjectTransactionModal();
      toast.success('Transaction added successfully');
    } catch (error) {
      console.log(error);
      toast.error('Could not add transaction');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Payouts</title>
      </Helmet>
      <ApproveBatchConfirmationModal
        isOpen={isApproveModalOpen}
        onConfirm={approveBatch}
        onClose={handleCloseApproveModal}
        amount={transactionTotalAmount}
        groupId={groupId}
      />
      <RemoveTransactionsConfirmationModal
        isOpen={isConfirmRemoveTransactionModalOpen}
        onClose={handleCloseConfirmRemoveTransaction}
        onConfirm={removeSelectedTransactions}
        count={selectedIds?.length || 0}
        total={selectedIds.reduce((acc, id) => {
          const transaction = transactions?.find((transaction) => transaction.id === id);
          return acc + (transaction?.amount ?? 0);
        }, 0)}
      />
      <AddProjectTransactionModal
        isOpen={isAddProjectTransactionModalOpen}
        handleClose={handleCloseAddProjectTransactionModal}
        modalTitle="Add Payout"
        submitButtonText="Add Payout"
        modalId="addPaymentModal"
        handleSubmit={handleAddTransaction}
        groupId={groupId}
      />
      <MainContainer maxWidth="100" padding="0 4xl" gap="sm">
        <Box> {eventType?.toUpperCase()} PAYOUTS</Box>

        <Box direction="row" width="100">
          <Box fontSize="2xl" fontWeight="medium" direction="row" flex="auto">
            <Box>{groupIdDisplay}</Box>
          </Box>
          <RequirePayoutsAdminUser>
            {status === ProjectTransactionStatus.pending && (
              <Box id="toolbar" direction="row" alignSelf="flex-end" childGap="sm">
                <Button
                  iconPrefix="check"
                  size="sm"
                  variant="primary"
                  onClick={handleOpenApproveModal}
                  disabled={isLoading}
                >
                  Approve
                </Button>
                <Button
                  iconPrefix="add"
                  size="sm"
                  variant="secondary"
                  tone="neutral"
                  disabled={isLoading}
                  onClick={handleOpenAddProjectTransactionModal}
                >
                  Add Payout
                </Button>
                {!isLoading && selectedIds?.length > 0 && (
                  <Box childGap="sm">
                    <Button
                      size="sm"
                      prefix="trash"
                      variant="primary"
                      tone="danger"
                      onClick={handleOpenConfirmRemoveTransaction}
                    >
                      Remove Transaction{selectedIds.length > 1 ? 's' : ''}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </RequirePayoutsAdminUser>
        </Box>
        <Box>
          {transactions && (
            <FilterableProjectTransactionTable
              transactions={transactions}
              handleRowSelected={handleRowSelected}
              selectedIds={selectedIds}
              exportUrl={exportUrl}
            />
          )}
        </Box>
      </MainContainer>
    </>
  );
};
