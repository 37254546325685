import { useCallback, useEffect, useState } from 'react';
import { Badge, Box, Card, Pagination } from '@palmetto/palmetto-components';
import { useSearchParams } from 'react-router-dom';
import { useGetAccountListFiltersQuery, useLazyGetAccountsSummaryQuery } from '../services/accounts';
import { FilterBar } from './filters';
import { AccountsSummaryTable } from './AccountsSummaryTable';
import { AccountsListLoading } from './AccountsListLoading';
import { AppliedDataFilter } from './filters/FilterButton';
import { AddFilterButtonDropdown } from './filters/AddFilterButton';
import { SkeletonBox } from './SkeletonBox';
import usePermissions from '../hooks/usePermissions';
import { UserPermissions } from 'types';

export function AccountsSummary() {
  const [trigger, { data: summaryResponse, isLoading, isFetching }] = useLazyGetAccountsSummaryQuery();
  const [appliedFilters, setAppliedFilters] = useState<Array<any>>([]);
  const userPermissions = usePermissions();
  const { accounts, milestoneSummary, total } = summaryResponse || { accounts: [], milestoneSummary: {}, total: 0 };
  const [searchParams, setSearchParams] = useSearchParams();
  const cancelled = searchParams.get('cancelled');
  const showCancelled = cancelled !== null;
  const postActivation = searchParams.get('postActivation');
  const showPostActivation = postActivation !== null;
  const { data = [], isLoading: isFiltersLoading } = useGetAccountListFiltersQuery({ cancelled: showCancelled });

  const currentMilestone = searchParams.get('currentMilestone');
  const currentPage = searchParams.get('page');
  const advancedFilters = searchParams.get('advancedFilters');
  const searchTerm = searchParams.get('search') || undefined;

  useEffect(() => {
    if (showCancelled && userPermissions.length) {
      if (!userPermissions.includes('admin' as UserPermissions)) {
        searchParams.delete('cancelled');
        setSearchParams(searchParams);
      }
    }
  }, [showCancelled, userPermissions, searchParams, setSearchParams]);

  useEffect(() => {
    const milestone = currentMilestone && currentMilestone !== 'undefined' ? currentMilestone : undefined;
    const pageNum = currentPage ? Number(currentPage) : 1;
    const filters = advancedFilters || JSON.stringify([]);

    setAppliedFilters(JSON.parse(filters));
    trigger({
      currentMilestone: milestone,
      pageNum,
      advancedFilters: filters,
      searchTerm,
      includeCompletedAccounts: showPostActivation,
      onlyCancelledAccounts: showCancelled,
      onlyPostActivationAccounts: showPostActivation,
    });
  }, [trigger, currentMilestone, currentPage, advancedFilters, searchTerm, showCancelled, showPostActivation]);

  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      searchParams.delete('page');
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );

  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      searchParams.delete('page');
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );

  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    searchParams.delete('page');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);

  const onPageChange = (newPage: number) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const onSearchChange = (searchTerm: string) => {
    if (searchTerm && searchTerm !== '') {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <Card>
      <Box direction="row" borderWidth="0 0 xs 0" borderColor="separator" margin="sm lg lg lg">
        {isLoading ? (
          <Box height="52px" alignItems="center" direction="row" childGap="xl">
            <SkeletonBox width="80px" height="14px" />
            <SkeletonBox width="170px" height="14px" />
            <SkeletonBox width="80px" height="14px" />
            <SkeletonBox width="100px" height="14px" />
          </Box>
        ) : (
          <>
            <div
              style={{ textDecoration: 'none', fontWeight: '500', cursor: 'pointer' }}
              onClick={() => {
                searchParams.delete('page');
                searchParams.delete('currentMilestone');
                setSearchParams(searchParams);
              }}
            >
              <Box
                alignItems="center"
                padding="md"
                childGap="sm"
                className={!currentMilestone ? 'tab-active' : ''}
                direction="row"
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                <Box as="span" fontWeight="bold" color={currentMilestone ? 'body-primary' : 'primary'}>
                  All
                </Box>{' '}
                <Badge
                  padding="xs"
                  message={milestoneSummary.totalCount || 0}
                  variant={currentMilestone ? 'default' : 'primary'}
                  size="sm"
                />
              </Box>
            </div>
            {milestoneSummary?.milestones?.map((milestone: any) => (
              <div
                style={{ textDecoration: 'none', fontWeight: '500', cursor: 'pointer' }}
                onClick={() => {
                  if (milestone?.type) {
                    searchParams.set('currentMilestone', milestone?.type);
                  } else {
                    searchParams.delete('currentMilestone');
                  }
                  searchParams.delete('page');
                  setSearchParams(searchParams);
                }}
                key={milestone?.type}
              >
                <Box
                  alignItems="center"
                  padding="md"
                  childGap="sm"
                  className={currentMilestone === milestone?.type ? 'tab-active' : ''}
                  color={currentMilestone === milestone?.type ? 'primary' : 'body-primary'}
                  direction="row"
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  <Box as="span" fontWeight="bold">
                    {milestone?.name}
                  </Box>{' '}
                  <Badge
                    message={milestone?.count || 0}
                    size="sm"
                    variant={currentMilestone === milestone?.type ? 'primary' : 'default'}
                  />
                </Box>
              </div>
            ))}
          </>
        )}
      </Box>
      <Box margin="0 lg 0 lg">
        <FilterBar
          filtersState={{
            searchTerm,
          }}
          handleSearchTerm={(searchTerm: string) => onSearchChange(searchTerm)}
        >
          {!isFiltersLoading && (
            <AddFilterButtonDropdown
              dataFilters={data}
              appliedFilters={appliedFilters}
              onRemoveFilter={handleRemoveAdvancedFilter}
              onApplyFilters={handleApplyAdvancedFilters}
              onClearFilters={handleClearAdvancedFilters}
            />
          )}
        </FilterBar>
        {isFetching || isLoading ? <AccountsListLoading rows={12} /> : <AccountsSummaryTable data={accounts} />}
      </Box>
      {accounts?.length > 0 && (
        <Pagination
          activePage={Number(currentPage) || 1}
          arePagesVisible
          itemsPerPage={20}
          numberOfPagesDisplayed={3}
          onChange={(page) => onPageChange(page)}
          totalItemsCount={total ?? 0}
          isCompact
        />
      )}
    </Card>
  );
}
