import { RuleProperties } from 'json-rules-engine';
import { ObjectId, WithId } from 'mongodb';
import {
  Applicant,
  IsoDateString,
  Meta,
  PayoutHold,
  PayoutHoldHistory,
  ProgramType,
  QuoteDocument,
  WithObjectIdsAsString,
} from '.';

export enum PayeeType {
  organization = 'organization',
  vendor = 'vendor',
}

export interface PayeePlanHistoryEntry {
  date: Date;
  planId: string;
  user: { id: string; name: string };
}

export interface PayeeDocument {
  id?: string;
  externalId: string; // org alias
  type: PayeeType; // this needs to be the enum we create to define org types
  name: string;
  planId?: string;
  planHistory?: Array<PayeePlanHistoryEntry>;
  payoutHold?: PayoutHold;
  payoutHoldHistory?: PayoutHoldHistory;
  metadata?: Record<string, any>;
  meta?: Meta;
}

export interface PaymentPlanDocument {
  id?: string;
  /** LR accountId */
  externalId: string;
  name: string;
  programType: ProgramType;
  meta?: Meta;
  payments: any[];
}

export enum PayeeProjectRole {
  primary = 'primary',
  materials = 'materials',
  //inspection = 'inspection',?
}

export interface ProjectDocument {
  id?: string;
  /** LR accountId */
  externalId: string;
  name: string;
  totalCost: number;
  metadata?: Record<string, any>;
  meta?: Meta;
  programType: ProgramType;
  planId?: string;
  projectDetails?: SolarProjectDetails | Record<string, any>;
  payees: Array<{ id: ObjectId; role: PayeeProjectRole; name: string }>;
}

export interface ProjectTransactionRejection {
  date: Date;
  reason: string;
  rejectedBy: { id: string; name: string };
}

export enum ProjectTransactionStatus {
  open = 'open', // not in a batch
  paid = 'paid',
  pending = 'pending', // added to batch
  approved = 'approved', // approved to be paid
  migrated = 'migrated', // migrated from past events
  // cancelled?
  // error?
}

export enum PayoutEventType {
  milestone = 'milestone',
  materials = 'materials',
  manual = 'manual',
}

export enum PayoutEvent {
  noticeToProceed = 'noticeToProceed',
  noticeToProceedPlus = 'noticeToProceedPlus', // notice to proceed and permit submitted
  estimate = 'estimate',
  invoice = 'invoice',
  installSubmitted = 'installSubmitted',
  installApproved = 'installApproved',
  activationApproved = 'activationApproved',
}

export const PayoutEventMap: Record<
  ProgramType,
  Array<{
    event: PayoutEvent;
    name: string;
    order: number;
    badgeVariant: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'warning' | 'info' | 'success' | 'default'; //BadgeVariant from palmetto-components
  }>
> = {
  [ProgramType.solar]: [
    { event: PayoutEvent.noticeToProceed, name: 'NTP', order: 1, badgeVariant: 'warning' },
    { event: PayoutEvent.noticeToProceedPlus, name: 'NTP+', order: 2, badgeVariant: 'danger' },
    { event: PayoutEvent.installSubmitted, name: 'Install Submitted', order: 3, badgeVariant: 'tertiary' },
    { event: PayoutEvent.installApproved, name: 'Install Approved', order: 4, badgeVariant: 'secondary' },
    { event: PayoutEvent.activationApproved, name: 'Activation Approved', order: 5, badgeVariant: 'primary' },
  ],
  [ProgramType.doePr]: [
    { event: PayoutEvent.noticeToProceed, name: 'NTP', order: 1, badgeVariant: 'warning' },
    { event: PayoutEvent.noticeToProceedPlus, name: 'NTP+', order: 2, badgeVariant: 'danger' },
    { event: PayoutEvent.installSubmitted, name: 'Install Submitted', order: 3, badgeVariant: 'tertiary' },
    { event: PayoutEvent.installApproved, name: 'Install Approved', order: 4, badgeVariant: 'secondary' },
    { event: PayoutEvent.activationApproved, name: 'Activation Approved', order: 5, badgeVariant: 'primary' },
  ],
  [ProgramType.hvac]: [],
  [ProgramType.newHomes]: [
    { event: PayoutEvent.noticeToProceed, name: 'NTP', order: 1, badgeVariant: 'warning' },
    { event: PayoutEvent.noticeToProceedPlus, name: 'NTP+', order: 2, badgeVariant: 'danger' },
    { event: PayoutEvent.installSubmitted, name: 'Install Submitted', order: 3, badgeVariant: 'tertiary' },
    { event: PayoutEvent.installApproved, name: 'Install Approved', order: 4, badgeVariant: 'secondary' },
    { event: PayoutEvent.activationApproved, name: 'Activation Approved', order: 5, badgeVariant: 'primary' },
  ],
};

export interface ProjectTransactionHistory {
  date: Date;
  user: { id: string; name: string };
  summary?: string;
  changes?: Record<string, any>;
}

export interface PayoutCalculationDetails {
  remainingProjectFunds: number;
  currentTotalCost: number;
  asString: string;
  variables: Record<string, number>;
}

export enum PayoutsCollection {
  payees = 'payees',
  projects = 'projects',
  projectTransactions = 'projectTransactions',
  paymentPlans = 'paymentPlans',
  batches = 'batches',
}

export interface PayoutRuleProperties extends RuleProperties {
  appliesToEvents: Array<PayoutEvent>;
}

export interface SolarProjectDetails {
  inverterManufacturer?: string;
}

export interface ProjectTransactionDocument {
  id?: string;
  projectId: ObjectId;
  /** LR accountId */
  projectExternalId: string;
  payeeId: ObjectId;
  payeeType: PayeeType;
  /** org alias */
  payeeExternalId?: string;
  programType: ProgramType;
  amount: number;
  calculation?: PayoutCalculationDetails;
  batchId?: ObjectId;
  planId: ObjectId;
  rejections?: Array<ProjectTransactionRejection>;
  status: ProjectTransactionStatus;
  meta?: Meta;
  prePayment: boolean;
  eventType: PayoutEventType;
  event: PayoutEvent; // should restrict by payout type
  originRule?: string; // rule that created this transaction
  description?: string;
  history?: Array<ProjectTransactionHistory>;
  eventDate: Date;
}

export type ProjectTransactionWithNames = ProjectTransactionDocument & {
  payeeName: string;
  projectName: string;
};

export interface BatchReview {
  status: BatchReviewStatus.approved | BatchReviewStatus.rejected;
  user: { id: string; name: string };
  date: Date;
  changedFrom: any;
  changedTo: any;
}

export enum PaymentTransactionProvider {
  bofa = 'bofa',
}

export enum bofaTransactionStatus { // this should come from the bofa payments package in the future
  processingByBank = 'Processing By Bank',
  rejected = 'Rejected',
  settlementComplete = 'Settlement Complete',
  approved = 'Approved',
  cancelled = 'Cancelled',
  receivedByBank = 'Received By Bank',
}

export enum BatchReviewStatus {
  approved = 'approved',
  rejected = 'rejected',
}

export interface BatchDocument {
  id?: string;
  payeeId: ObjectId;
  payeeExternalId: string;
  transactionEventType: PayoutEventType;
  bankTransaction?: {
    provider: PaymentTransactionProvider;
    id: string;
    status: bofaTransactionStatus;
    statusHistory: Array<{ id: string; status: bofaTransactionStatus; statusDate: Date }>;
  };
  review?: BatchReview;
  reviewHistory?: Array<BatchReview>;
  projectTransactionsCutoffDate: IsoDateString;
  meta?: Meta;
}

export interface BatchInfo {
  id: IsoDateString;
  projectTransactionsCutoffDate: IsoDateString;
  transactionEventType: PayoutEventType;
  payees: number;
  transactions: number;
  amount: number;
  status?: string;
}

export interface BatchGroupInfo {
  batchGroupId: IsoDateString;
  transactionEventType: PayoutEventType;
  batchIds: ObjectId[];
}

export interface BatchGroupCandidate {
  _id: ObjectId; // payeeId
  payee: WithId<PayeeDocument>;
  count: number;
  total: number;
  transactionEventType: PayoutEventType;
  transactions: Array<WithId<ProjectTransactionDocument>>;
}

export interface BatchExportInfo {
  finCoAccountId: string;
  projectTransactionId: string;
  transactionDate: string;
  payoutDate: string;
  amountToPay: string;
  paymentPlanEventPercentage: string;
  paymentPlanName: string;
  payee: string;
  licensedOrganizationName: string;
  organizationAlias: string;
  salesRepName: string;
  primaryApplicant: Applicant;
  quoteDetails: QuoteDocument;
  kwhRate: string;
  cedInvoiceAmountSum: string;
  accountCreatedAtDate: string;
  contractSignedAt: string;
  inverterManufacturer: string;
  utilityName: string;
  hasBattery: string;
  cedAlphaId: string;
  noticeToProceedApprovedAtDate: string;
  ntpPlusSubmittedAtDate: string;
  installApprovedAtDate: string;
  installSubmittedAtDate: string;
  systemActivationApprovedAtDate: string;
}

export type CreateProjectTransactionPayload = Partial<
  Pick<
    WithObjectIdsAsString<ProjectTransactionDocument>,
    'projectId' | 'projectExternalId' | 'event' | 'description' | 'amount' | 'payeeId'
  > & { groupId?: IsoDateString }
>;
