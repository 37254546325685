import {
  Badge,
  BadgeVariant,
  Box,
  Button,
  Card,
  Details,
  TextLink,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusIcon } from '../StatusIcon';
import RequirePermissions from '../auth/requirePermissions';
import { useGetCreditReportSummariesQuery } from '../../services/creditReports';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import FullCreditReportModal from '../FullCreditReportModal';
import { CreditReportSummary } from '../../types/CreditReports';
import { AccountRequirementStatus } from '../../types/Milestone';
import {
  ApplicantType,
  ApplicationDocumentWithAdditionalApplicantData,
  FinancingDecision,
  RawApplicantWithApplicationData,
  UserPermissions,
} from 'types';
import CreditOverrideModal from '../CreditOverrideModal';

interface CreditApplicationsProps {
  status: AccountRequirementStatus;
  applications: ApplicationDocumentWithAdditionalApplicantData[];
  firstApprovedApplication: ApplicationDocumentWithAdditionalApplicantData;
}
interface CreditApplicationDetailsProps {
  applicant: RawApplicantWithApplicationData;
  creditReports: CreditReportSummary[];
  handleViewCreditReport: (item: CreditReportSummary, applicantId?: string) => void;
  handleOpenCreditOverrideModal: (applicationId?: string) => void;
}

export const applicationStatus: {
  [key in FinancingDecision]: {
    label: string;
    variant: BadgeVariant;
  };
} = {
  [FinancingDecision.applicantNotFound]: {
    label: 'Applicant not found',
    variant: 'danger',
  },
  [FinancingDecision.approved]: {
    label: 'Approved',
    variant: 'success',
  },
  [FinancingDecision.approvedWithStipulations]: {
    label: 'Approved',
    variant: 'success',
  },
  [FinancingDecision.canceled]: {
    label: 'Canceled',
    variant: 'danger',
  },
  [FinancingDecision.creditFrozen]: {
    label: 'Credit Frozen',
    variant: 'danger',
  },
  [FinancingDecision.declined]: {
    label: 'Declined',
    variant: 'danger',
  },
  [FinancingDecision.error]: {
    label: 'Error',
    variant: 'danger',
  },
  [FinancingDecision.expired]: {
    label: 'Expired',
    variant: 'danger',
  },
  [FinancingDecision.new]: {
    label: 'New',
    variant: 'info',
  },
  [FinancingDecision.notChosen]: {
    label: 'Not Chosen',
    variant: 'info',
  },
  [FinancingDecision.pending]: {
    label: 'Pending',
    variant: 'warning',
  },
  [FinancingDecision.processing]: {
    label: 'Processing',
    variant: 'warning',
  },
  [FinancingDecision.withdrawn]: {
    label: 'Withdrawn',
    variant: 'warning',
  },
};
export const convertIsoToDateMed = (isoDate: string, opts = {}) =>
  isoDate ? DateTime.fromISO(isoDate, opts).toLocaleString(DateTime.DATE_MED) : null;

function CreditApplicationRow({
  applicant,
  applicationId,
}: {
  applicant: RawApplicantWithApplicationData;
  applicationId: string;
}) {
  return (
    <Box direction="row" childGap="lg" alignItems="center">
      <Box width="xl">
        {applicant.status && (
          <Badge
            message={applicationStatus[applicant.status].label}
            variant={applicationStatus[applicant.status]?.variant}
            style={{ width: 'fit-content' }}
          />
        )}
      </Box>

      <Box flex="auto" childGap="lg">
        <Box childGap="2xs">
          <div>{`${applicant?.firstName} ${applicant?.lastName}`}</div>
          {applicant?.creditExpiryDate && (
            <Box color="body-secondary">{`credit expires ${convertIsoToDateMed(
              applicant.creditExpiryDate as unknown as string,
            )}`}</Box>
          )}
          {applicant?.creditDecisionDate && (
            <Box color="body-secondary">{`credit decision date ${convertIsoToDateMed(
              applicant.creditDecisionDate as unknown as string,
            )}`}</Box>
          )}
          {applicant.status &&
            applicant.underwritingNotes &&
            applicant.status === FinancingDecision.declined &&
            applicant.underwritingNotes?.length && (
              <RequirePalmettoFinanceUser>
                <Box color="body-secondary" fontSize="xs">{`Declined due to: ${applicant.underwritingNotes.join(
                  ', ',
                )}`}</Box>
              </RequirePalmettoFinanceUser>
            )}
          <Box color="body-secondary" fontSize="xs">
            Application Id: {applicationId}
          </Box>
          {applicant?.ssnFormat && (
            <Box color="body-secondary" fontSize="xs">
              ssn format: {applicant.ssnFormat}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

function CreditApplicationDetails({
  applicant,
  creditReports,
  handleViewCreditReport,
  handleOpenCreditOverrideModal,
}: CreditApplicationDetailsProps) {
  if (!applicant) {
    return;
  }
  let creditReport: any = undefined;
  if (applicant.id) {
    // with release of coBorrower some applicants will have an id
    creditReport = creditReports.find((report) => report?.applicantId === applicant?.id);
  } else {
    creditReport = creditReports.find((report) => report?.applicationId === applicant?.applicationId);
  }
  if (!creditReport) {
    creditReport = creditReports[0];
  }
  return (
    <Box
      padding="sm sm sm lg"
      width="100"
      childGap="lg"
      direction="row"
      alignItems="center"
      borderWidth="xs 0 0 0"
      borderColor="separator"
    >
      <Box direction="column" width="100" childGap="xs">
        {applicant &&
          applicant.status && ( // if the applicant has a status, show the row
            <Box childGap="xs">
              <Box direction="row" alignItems="center" justifyContent="space-between">
                <CreditApplicationRow applicant={applicant} applicationId={applicant.applicationId as string} />
                {creditReport && (
                  <RequirePalmettoFinanceUser>
                    <RequirePermissions permissions={[UserPermissions.admin]}>
                      <TextLink
                        style={{
                          cursor: 'pointer',
                          color: 'var(--color-text-body-primary)',
                          textDecoration: 'underline',
                          textAlign: 'right',
                        }}
                        onClick={() =>
                          handleViewCreditReport(creditReport, (applicant?.id as unknown as string) || undefined)
                        }
                      >
                        Credit Report
                      </TextLink>
                    </RequirePermissions>
                  </RequirePalmettoFinanceUser>
                )}
                {applicant.status === FinancingDecision.declined && (
                  <RequirePermissions permissions={[UserPermissions.lightReachCreditAdmin]}>
                    <TextLink
                      style={{
                        cursor: 'pointer',
                        color: 'var(--color-text-body-primary)',
                        textDecoration: 'underline',
                        textAlign: 'right',
                      }}
                      onClick={() =>
                        handleOpenCreditOverrideModal((applicant.applicationId as unknown as string) || undefined)
                      }
                    >
                      Override Credit
                    </TextLink>
                  </RequirePermissions>
                )}
              </Box>
            </Box>
          )}
      </Box>
    </Box>
  );
}

export function CreditApplications({ status, applications, firstApprovedApplication }: CreditApplicationsProps) {
  const navigate = useNavigate();
  const { id } = useParams<{ id: any }>();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const { isOpen: isShowAllApplicationsOpen, handleToggle: handleShowAllApplicationsToggle } = useOpenClose();
  const {
    isOpen: isCreditOverrideModalOpen,
    handleOpen: openCreditOverrideModal,
    handleClose: closeCreditOverrideModal,
  } = useOpenClose();
  const [selectedSummary, setSelectedSummary] = useState<CreditReportSummary | null>(null);
  const [applicantId, setApplicantId] = useState<string | undefined>();
  const [applicationId, setApplicationId] = useState<string | undefined>();
  const { data: creditReports = [] } = useGetCreditReportSummariesQuery(id);

  const [application] = applications;
  const firstApplicationForDisplay = firstApprovedApplication || application;
  const approvedApplicant = firstApplicationForDisplay?.applicants?.find(
    (applicant) =>
      applicant.status === FinancingDecision.approved ||
      applicant.status === FinancingDecision.approvedWithStipulations,
  );
  //show the first approved applicant or the first primary applicant from the application
  const firstApplicantToDisplay =
    approvedApplicant ||
    firstApplicationForDisplay?.applicants?.find((applicant) => applicant.type === ApplicantType.primary);

  // find the additional applicants from the first application to display with the other applicants from the other applications
  const additionalApplicant = firstApplicationForDisplay?.applicants?.find(
    (applicant) => applicant.type !== firstApplicantToDisplay?.type && applicant.status,
  );
  const additionalApplicantsFromOtherApplications = applications.reduce(
    (acc: RawApplicantWithApplicationData[], curr: ApplicationDocumentWithAdditionalApplicantData) => {
      if (curr.id !== firstApplicationForDisplay?.id) {
        // credit checks will have a status on the applicant
        acc.push(...curr.applicants.filter((applicant) => applicant.status));
      }
      return acc;
    },
    [],
  );
  // add the additional applicant from the first application to the other applicants from the other applications, show it at the top
  if (additionalApplicant) {
    additionalApplicantsFromOtherApplications.unshift({
      ...additionalApplicant,
    } as RawApplicantWithApplicationData);
  }
  const handleCloseFullView = () => {
    setApplicantId(undefined);
    setSelectedSummary(null);
    closeModal();
  };

  const handleViewFull = (item: CreditReportSummary, applicantId?: string | undefined) => {
    setApplicantId(applicantId);
    setSelectedSummary(item);
    openModal();
  };

  const handleCloseCreditOverrideModal = () => {
    setApplicationId(undefined);
    closeCreditOverrideModal();
  };

  const handleOpenCreditOverrideModal = (applicationId?: string | undefined) => {
    setApplicationId(applicationId);
    openCreditOverrideModal();
  };

  return (
    <>
      <FullCreditReportModal
        isModalOpen={isModalOpen}
        closeModal={handleCloseFullView}
        summary={selectedSummary}
        applicantId={applicantId}
      />
      <CreditOverrideModal
        isModalOpen={isCreditOverrideModalOpen}
        closeModal={handleCloseCreditOverrideModal}
        applicationId={applicationId}
      />
      <Card.Section childGap="md">
        <Box childGap="lg" alignItems="center" direction="row" justifyContent="space-between">
          <Box direction="row" alignItems="center">
            <Box padding="0 xs 0 0">
              <StatusIcon status={status} size="md" />
            </Box>
            <Box as="h3" fontWeight="medium" fontSize="md">
              Credit Application
            </Box>
          </Box>
          <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
            <Button as="a" navigate={() => navigate(`/accounts/${id}/application/create`)} size="sm" variant="primary" tone="neutral">
              Run Credit Check
            </Button>
          </RequirePermissions>
        </Box>

        {firstApplicantToDisplay && (
          <CreditApplicationDetails
            key={firstApplicationForDisplay?.id}
            applicant={{ ...firstApplicantToDisplay, applicationId: firstApplicationForDisplay?.id }}
            handleViewCreditReport={handleViewFull}
            handleOpenCreditOverrideModal={handleOpenCreditOverrideModal}
            creditReports={creditReports}
          />
        )}
        {additionalApplicantsFromOtherApplications?.length > 0 ? (
          <Details isOpen={isShowAllApplicationsOpen} borderWidth="xs 0 0 0" borderColor="separator">
            <Details.Summary
              isDetailsOpen={isShowAllApplicationsOpen}
              onToggle={handleShowAllApplicationsToggle}
              display="inline"
              padding="sm lg"
            >
              <Box>{isShowAllApplicationsOpen ? 'Hide All Applications' : 'Show All Applications'}</Box>
            </Details.Summary>
            {additionalApplicantsFromOtherApplications.map((applicant, index: number) => (
              <CreditApplicationDetails
                key={`${applicant.applicationId}-${index}`}
                applicant={applicant}
                handleViewCreditReport={handleViewFull}
                handleOpenCreditOverrideModal={handleOpenCreditOverrideModal}
                creditReports={creditReports}
              />
            ))}
          </Details>
        ) : null}
      </Card.Section>
    </>
  );
}
