import useKeyboardShortcut from 'use-keyboard-shortcut';
import { Box, Icon, Popover, TextInput, useOpenClose } from '@palmetto/palmetto-components';
import { useAuth } from './auth/authProvider';
import { useCallback, useMemo, useState } from 'react';
import { useGetOrganizationTreeQuery } from '../services/organizations';
import { FlatOrganization, OrganizationNode } from '../types/Organizations';

const Result = ({
  item,
  handleClick,
  currentOrg,
}: {
  item: FlatOrganization;
  currentOrg: OrganizationNode | undefined;
  handleClick: Function;
}) => {
  const level = item.path.split('/').length - 1 - 1;
  return (
    <Box
      as="button"
      cursor="pointer"
      type="button"
      background="transparent"
      borderColor="separator"
      borderWidth="0 0 xs 0"
      color="body-primary"
      hover={{ background: 'secondary' }}
      radius="sm"
      title={item.alias}
      onClick={() => handleClick(item.alias)}
      textAlign="left"
      direction="row"
      justifyContent="space-between"
      padding="sm xs"
      alignItems="center"
    >
      <Box color="body-secondary" width="sm" margin="0 xs 0 0">
        {currentOrg?.id === item.id && <Icon name="nav-right" />}
      </Box>
      <Box flex="auto" childGap="md" direction="row" alignItems="center">
        <OrgBox orgName={item.name} level={level} />
        <Box direction="column" childGap="2xs">
          <Box width="3xl" fontWeight="medium">
            {item.name}
          </Box>
          <Box color="body-secondary" fontFamily="monospace">
            {item.path}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const OrgBox = ({ className, orgName, level = 0 }: { className?: string; orgName: string; level?: number }) => {
  const firstLettersOfName = orgName
    ? orgName
        .match(/\b(\w)/g)
        ?.join('')
        .toUpperCase()
        .slice(0, 2)
    : [];
  const boxLetters =
    firstLettersOfName && firstLettersOfName?.length > 2 ? firstLettersOfName?.slice(0, -1) : firstLettersOfName;
  const organizationColor =
    level === 0
      ? 'var(--color-brand-primary-400)'
      : level === 1
        ? 'var(--color-brand-secondary-400)'
        : level === 2
          ? 'var(--color-brand-danger-400)'
          : level === 3
            ? 'var(--color-brand-grey-400)'
            : level === 4
              ? 'var(--color-brand-secondary-600)'
              : 'var(--color-brand-tertiary-400)';

  return (
    <Box
      radius="sm"
      color="white"
      style={{ backgroundColor: organizationColor }}
      width="32px"
      height="25px"
      textAlign="center"
      padding={'2xs'}
      fontWeight="medium"
      className={className}
    >
      {boxLetters}
    </Box>
  );
};

const Row = ({
  item,
  level,
  handleClick,
  currentOrg,
}: {
  item: OrganizationNode;
  currentOrg: OrganizationNode | undefined;
  level: number;
  handleClick: Function;
}) => {
  const paddingLeft = `${(level + 1) * 8}px`;

  return (
    <>
      <Box
        as="button"
        cursor="pointer"
        type="button"
        padding={{ base: `xs sm` }}
        style={{
          paddingLeft: paddingLeft,
        }}
        background="transparent"
        borderWidth="0"
        color="body-primary"
        hover={{ background: 'secondary' }}
        radius="sm"
        title={item.alias}
        onClick={() => handleClick(item.alias)}
        textAlign="left"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box color="body-secondary" width="sm" margin="0 xs 0 0">
          {currentOrg?.id === item.id && <Icon name="nav-right" />}
        </Box>
        <Box flex="auto" childGap="xs" direction="row" alignItems="center">
          <OrgBox orgName={item.name} level={level} />
          <Box>{item.name}</Box>
        </Box>
      </Box>
      {item.children?.map((child: any) => (
        <Row key={child.id} item={child} level={level + 1} handleClick={handleClick} currentOrg={currentOrg} />
      ))}
    </>
  );
};

export function findOrg(org: OrganizationNode, alias: string): OrganizationNode | undefined {
  if (org.alias === alias) {
    return org;
  } else if (org.children != null) {
    let i;
    let result;
    for (i = 0; !result && i < org.children.length; i++) {
      result = findOrg(org.children[i], alias);
    }
    return result;
  }
}

export function TreeView({
  items,
  handleClick,
  currentOrg,
}: {
  items: OrganizationNode[];
  handleClick: Function;
  currentOrg: OrganizationNode | undefined;
}) {
  return (
    <Box maxHeight="4xl" overflow="auto">
      {items?.map((item) => (
        <Row key={item.id} item={item} level={0} currentOrg={currentOrg} handleClick={handleClick} />
      ))}
    </Box>
  );
}

export function SearchView({
  items = [],
  searchText,
  handleClick,
  currentOrg,
}: {
  items: FlatOrganization[] | undefined;
  searchText: string;
  handleClick: Function;
  currentOrg: OrganizationNode | undefined;
}) {
  const searchResults = useMemo(() => {
    return items?.filter((item) => {
      return (
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.alias.toLowerCase().startsWith(searchText.toLowerCase())
      );
    });
  }, [items, searchText]);

  return (
    <Box maxHeight="4xl" overflow="auto">
      {searchResults?.map((item) => (
        <Result key={item.id} item={item} currentOrg={currentOrg} handleClick={handleClick} />
      ))}
    </Box>
  );
}

export default function OrganizationMenu({ className }: { className?: string }) {
  const { isOpen, handleClose, handleToggle } = useOpenClose();
  const { data: { tree: items = [], flat } = {}, isLoading } = useGetOrganizationTreeQuery({
    useRootOrganization: true,
  });
  const { login, claims } = useAuth();
  const currentOrg = useMemo(() => {
    let i;
    let result;
    for (i = 0; !result && i < (items?.length || 0); i++) {
      result = findOrg(items[i], claims?.org);
    }
    return result;
  }, [items, claims?.org]);

  const { flushHeldKeys } = useKeyboardShortcut(['Shift', 'O'], () => handleToggle(), {
    overrideSystem: true,
    ignoreInputFields: true,
    repeatOnHold: false,
  });

  const closePopover = useCallback(() => {
    handleClose();
    setValue('');
    flushHeldKeys();
  }, [flushHeldKeys, handleClose]);

  const impersonateOrg = useCallback(
    (alias: string) => {
      login(undefined, alias);
    },
    [login],
  );

  const clearSearch = useCallback(() => {
    setValue('');
    return false;
  }, []);

  const [value, setValue] = useState('');

  const content = (
    <Box childGap="sm" fontSize="sm">
      <TextInput
        id="searchText"
        type="text"
        value={value}
        autoComplete="off"
        hideLabel
        label="Search for an organization"
        placeholder="Search for an organization"
        prefix={<Icon name="search" />}
        onChange={(event: any) => setValue(event.target.value)}
        onClear={clearSearch}
      />

      {value ? (
        <SearchView items={flat} searchText={value} handleClick={impersonateOrg} currentOrg={currentOrg} />
      ) : (
        <TreeView items={items} handleClick={impersonateOrg} currentOrg={currentOrg} />
      )}
    </Box>
  );

  return (
    <Popover
      content={content}
      isOpen={isOpen}
      placement="bottom-start"
      offsetFromTarget={4}
      trapFocus
      contentContainerProps={{
        padding: 'md',
        shadow: 'sm',
        borderColor: 'separator',
        borderWidth: 'xs',
        background: 'primary',
        radius: 'sm',
        color: 'body-primary',
        fontSize: 'sm',
        width: { base: '100', tablet: '80' },
        minWidth: '3xl',
      }}
      arrowColor="white"
      onClickOutside={closePopover}
      portalTarget={document.body}
      withPortal
      hasArrow={false}
    >
      <Box
        as="button"
        className={className}
        fontSize={{ base: 'sm', desktop: 'md' }}
        textAlign="left"
        type="button"
        childGap="2xs"
        cursor="pointer"
        direction="row"
        alignItems="center"
        onClick={handleToggle}
        borderWidth="0"
        background="primary"
        color="body-primary"
        padding={{ base: 'xs sm', desktop: 'sm' }}
        radius="md"
        hover={{
          color: 'contrast',
        }}
      >
        {!isLoading && (
          <>
            <Box fontSize="sm">{currentOrg?.name || claims?.org}</Box>
            <Icon name="caret-sm-down" />
          </>
        )}
      </Box>
    </Popover>
  );
}
