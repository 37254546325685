import { Box, FontColor, Icon, IconName } from '@palmetto/palmetto-components';

export function PropertyValue({
  className,
  label,
  value,
  icon,
  iconColor,
}: {
  className?: string;
  label: string;
  value: string | string[];
  icon?: IconName;
  iconColor?: FontColor;
}) {
  return (
    <Box
      direction="row"
      alignItems="baseline"
      justifyContent="space-between"
      borderWidth="0 0 xs 0"
      borderColor="separator"
      padding="0 0 xs 0"
      className={className}
      childGap="lg"
    >
      <Box direction="row" gap="2xs">
        <Box color="body-secondary" fontWeight="medium">
          {label}
        </Box>
        {icon && <Icon name={icon} color={iconColor} />}
      </Box>
      {value instanceof Array ? (
        <Box direction="column" alignItems="flex-end" gap="2xs">
          {value.map((v, index) => (
            <Box
              key={`${v}-${index}`}
              direction="column"
              childGap="xs"
              alignItems="center"
              textAlign="right"
              style={{ wordBreak: 'break-word' }}
            >
              {v}
            </Box>
          ))}{' '}
        </Box>
      ) : (
        <Box direction="row" childGap="xs" alignItems="center" textAlign="right" style={{ wordBreak: 'break-word' }}>
          {value}
        </Box>
      )}
    </Box>
  );
}
