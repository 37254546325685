import { useCallback, useState } from 'react';
import { Box, Button, Icon, useBreakpoint } from '@palmetto/palmetto-components';
import { OrganizationNode } from '@/types/Organizations';
import PaymentCard from '../components/PaymentCard';
import BankAccountModal from './BankAccountModal';
import { FinancialAccountDocument, OrganizationEmailSettingCategories } from 'types';
import {
  useGetOrganizationEmailSettingsQuery,
  useGetOrganizationPaymentAccountsQuery,
} from '@/services/organizations';

interface ViewPropertiesProps {
  bankAccountDetails: FinancialAccountDocument;
}

const ViewProperties = ({ bankAccountDetails }: ViewPropertiesProps) => {
  const bankName = bankAccountDetails.bankAccount?.bankName?.trim();
  return (
    <Box padding="0 lg lg lg" childGap="xs">
      {bankName && bankName.length > 0 && (
        <Box
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          borderWidth="0 0 xs 0"
          borderColor="separator"
          padding="0 0 xs 0"
        >
          <Box fontWeight="medium">Financial Institution</Box>
          <Box direction="row" childGap="xs" alignItems="center">
            {bankName}
          </Box>
        </Box>
      )}
      <Box
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderWidth="0 0 xs 0"
        borderColor="separator"
        padding="0 0 xs 0"
      >
        <Box fontWeight="medium">Account Number</Box>
        <Box direction="row" childGap="xs" alignItems="center">
          {bankAccountDetails.bankAccount?.last4 ? `****${bankAccountDetails.bankAccount?.last4}` : '-'}
        </Box>
      </Box>
    </Box>
  );
};
interface BankAccountProps {
  organization: OrganizationNode;
  className?: string;
}

const BankAccount = ({ organization }: BankAccountProps) => {
  const { isPhone } = useBreakpoint();
  const { data: paymentSettings, isLoading } = useGetOrganizationPaymentAccountsQuery(organization);
  const { data: emailSettings, isLoading: emailSettingsLoading } =
    useGetOrganizationEmailSettingsQuery(organization);
  const firstBankAccount = paymentSettings?.find((item) => item.bankAccount);

  const isMissingBankAccount = !firstBankAccount;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleBankAccountModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen, setIsModalOpen]);
  let accountingEmail = '';

  const paymentEmail = emailSettings?.emailSettings.find(
    (item) => item.category === OrganizationEmailSettingCategories.accounting,
  );
  if (!paymentEmail) {
    accountingEmail = organization?.email || '';
  } else {
    accountingEmail = paymentEmail.emails[0];
  }

  return (
    organization?.alias && (
      <PaymentCard
        isLoading={isLoading && emailSettingsLoading}
        organization={organization}
        cardTitle="Bank Account"
        isPhone={isPhone}
        onClick={toggleBankAccountModal}
        accountingEmail={accountingEmail}
      >
        {!isMissingBankAccount ? (
          <ViewProperties bankAccountDetails={firstBankAccount} />
        ) : (
          <Box alignItems="center" justifyContent="center" height="300px" childGap="sm">
            <Icon name="t-warning" size="2xl" color="warning-500" />
            <Box>You are currently not able to receive payments</Box>
            <Box display="block" fontSize="sm" color="primary">
              <Button variant="tertiary" tone="neutral" size="sm" onClick={toggleBankAccountModal}>
                {' '}
                Setup Bank Account to Receive Payments <Icon name="arrow-right" />{' '}
              </Button>
            </Box>
          </Box>
        )}
        <BankAccountModal
          closeModal={toggleBankAccountModal}
          isModalOpen={isModalOpen}
          alias={organization.alias}
          accountingEmail={accountingEmail}
        />
      </PaymentCard>
    )
  );
};

export default BankAccount;
