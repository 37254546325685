import { Alert, Box } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { MilestoneType } from '../../types/Milestone';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useGetAccountQuery } from '../../services/accounts';
import { MainContainer } from '../MainContainer';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import AdminStipulationBannerList from '../Stipulations/AdminStipulationBannerList';
import { InstallHVACPackage } from './InstallHVACPackage';

export const InstallHVACMilestonePackage = () => {
  const { id, action } = useParams<{ id: any; action: ReviewPackageActions }>();
  const { data: account, isLoading: accountIsLoading }: any = useGetAccountQuery(id);

  const canSubmitInstallPackage = useMemo(() => {
    if (!account) return false;
    return account?.currentMilestone?.type === MilestoneType.install || false;
  }, [account]);

  return (
    <>
      <Helmet>
        <title>{`${account?.primaryApplicantName} Install Package`}</title>
      </Helmet>
      <MainContainer>
        <AdminStipulationBannerList />
        <PageHeader
          eyebrow={
            <>
              <Link to="/accounts">Accounts</Link> \{' '}
              <Link to={`/accounts/${id}`}>{`${account?.primaryApplicantName}`}</Link>
            </>
          }
          title={`Install Package${accountIsLoading ? '' : ': ' + account?.primaryApplicantName}`}
          description={(<><Box>Submit the following information to complete the install milestone.</Box>
            <Box>You may either submit an Install Document including all required photos, or upload all photos individually.</Box></>) as unknown as string}
        />
        {account && action !== ReviewPackageActions.review && !canSubmitInstallPackage && (
          <Alert
            variant="warning"
            hasIcon
            title="Notice to Proceed has not been issued"
            message="Notice to Proceed has to be issued before submitting the Install Package."
          />
        )}
        {accountIsLoading ? (
          <FormSection title="Loading...">
            <SkeletonBox height="25px" width="100" />
          </FormSection>
        ) : <InstallHVACPackage canSubmitInstallPackage={canSubmitInstallPackage} />}
      </MainContainer>
    </>
  );
};
