import PageHeader from '@/components/PageHeader';
import { PaymentPlanTable } from '../PaymentPlanTable';
import {
  useGetPaymentPlanOrgsQuery,
  useGetPaymentPlanQuery,
  useUpdatePaymentPlanMutation,
} from '@/services/paymentPlans';
import { Box, Button, Card, Table, toast, useOpenClose } from '@palmetto/palmetto-components';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PaymentPlanEditModal } from './PaymentPlanEditModal';
import { useCallback } from 'react';
import { isErrorWithData } from '@/services/helpers';

export const PaymentPlan = () => {
  const { programType = '', id = '' } = useParams<{ programType: any; id: string }>();
  const navigate = useNavigate();
  const { data: paymentPlan, isLoading: isLoadingPaymentPlan } = useGetPaymentPlanQuery({
    id,
  });

  const { data: paymentPlanOrgs = [], isLoading: isLoadingPaymentPlanOrg } = useGetPaymentPlanOrgsQuery({
    id,
  });

  const [updatePaymentPlanMutation] = useUpdatePaymentPlanMutation();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const paymentPlanOrgColumns = [
    {
      heading: 'Organization',
      dataKey: 'name',
      render: (cell?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell}
        </Box>
      ),
    },
  ];

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        const { id: newPaymentPlanId } = await updatePaymentPlanMutation({ id, body: values }).unwrap();
        closeModal();
        navigate(`/admin/payouts/${programType}/payment-plans/${newPaymentPlanId}`, { replace: true });
      } catch (e) {
        if (isErrorWithData(e)) {
          const errorMessage = e.data.message;
          toast.error(`Error updating payment plan: ${errorMessage}`);
        } else {
          console.error(e);
          toast.error('Error getting payment plan');
        }
      }
    },
    [id, programType],
  );

  return (
    <>
      {!isLoadingPaymentPlan && paymentPlan && (
        <Box direction={'column'} childGap={'xs'}>
          <Box style={{ marginLeft: '0' }}>
            <NavLink
              to={`/admin/payouts/${programType}/payment-plans/`}
              style={{ textDecoration: 'none', fontWeight: '500' }}
              end
            >
              {({ isActive }) => (
                <Box
                  as="span"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Payment Plans
                </Box>
              )}
            </NavLink>
          </Box>
          <PageHeader title={paymentPlan?.name}></PageHeader>
          <Card>
            <Card.Header>
              <Box direction="row" justifyContent="space-between" fontSize="md">
                <Box>{'Payment Plan'}</Box>
                <Box>
                  <Button variant="secondary" tone="primary" onClick={openModal}>
                    Edit Payment Plan
                  </Button>
                </Box>
              </Box>
            </Card.Header>
            <PaymentPlanTable programType={programType} paymentPlans={[paymentPlan]} showFirstColumn={false} />
          </Card>
          <PaymentPlanEditModal
            isOpen={isModalOpen}
            handleClose={closeModal}
            handleSubmit={handleSubmit}
            programType={programType}
            modalId="editPaymentPlan"
            modalTitle="Edit Payment Plan"
            submitButtonText="Edit Payment Plan"
            currentPaymentPlan={paymentPlan}
          />
        </Box>
      )}
      <br></br>
      <Box direction={'column'} childGap={'lg'}>
        <Card>
          <Card.Header>
            <Box direction="row" justifyContent="space-between" fontSize="md">
              <Box>{'Mappings'}</Box>
            </Box>
          </Card.Header>
          {!isLoadingPaymentPlanOrg && paymentPlanOrgs?.length > 0 ? (
            <Table rowKey="id" columns={paymentPlanOrgColumns} rows={paymentPlanOrgs} />
          ) : (
            <Box padding="xl" textAlign="center">
              There are no organizations currently using this payment plan
            </Box>
          )}
        </Card>
      </Box>
    </>
  );
};
