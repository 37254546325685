import { Helmet } from 'react-helmet';
import { MainContainer } from '..';
import { FilterableProjectTransactionTable } from '@/components/Payouts/FilterableProjectTransactionTable';
import { useParams } from 'react-router-dom';
import { useGetProjectByExternalIdQuery, useGetProjectTransactionsQuery } from '@/services/payouts';
import { AccountFundingSummary } from '@/components/Account/AccountFundingSummary';
import { Box } from '@palmetto/palmetto-components';

export const AccountFundingDetails = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { data: transactions, isLoading: isTransactionsLoading } = useGetProjectTransactionsQuery({
    projectExternalId: id,
  });
  const { data: project, isLoading: isProjectLoading } = useGetProjectByExternalIdQuery(id);
  const isLoading = isTransactionsLoading || isProjectLoading;

  return (
    <>
      <Helmet>
        <title>Account Funding</title>
      </Helmet>
      <MainContainer maxWidth="100" padding="0 4xl" gap="sm">
        {!isLoading && transactions && project && (
          <>
            <AccountFundingSummary transactions={transactions} project={project} />
            <FilterableProjectTransactionTable
              transactions={transactions}
              hideColumns={['projectName']}
              hideSummary
            />
          </>
        )}
        {!isLoading && (!transactions || !project) && (
          <Box textAlign={'center'}>No funding exists for this account.</Box>
        )}
      </MainContainer>
    </>
  );
};
