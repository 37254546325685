import React from 'react';
import { Box } from '@palmetto/palmetto-components';

interface FormSectionHeaderProps {
  className?: string;
  title: string;
  description?: string | React.ReactNode;
}

const FormSectionHeader = ({ className, title, description }: FormSectionHeaderProps) => {
  const renderDescription =
    typeof description === 'string' ? (
      <Box as="p" color="body-secondary" fontSize="sm">
        {description}
      </Box>
    ) : (
      description
    );

  return (
    <Box
      width={{
        desktop: '2xl',
        hd: '3xl',
      }}
      childGap="2xs"
      className={className}
      style={{
        flexShrink: 0,
      }}
    >
      <Box as="h2" fontSize="md" fontWeight="medium">
        {title}
      </Box>
      {renderDescription}
    </Box>
  );
};

export default FormSectionHeader;
