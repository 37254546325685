import * as yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InverterAndAddersInformation } from './InverterAndAddersInformation';
import { MonitoringActive } from '../MilestonePackagePartials/MonitoringActive';
import { useGetQuotesQuery } from '../../services/quotes';
import { ActivationPackageActions } from '../../types/ActivationPackage';
import { QuoteStatus } from '../../types/Quotes';
import { Alert, Box, toast } from '@palmetto/palmetto-components';
import { MainContainer } from '../MainContainer';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import { useAuth } from '../auth/authProvider';
import { useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import {
  DeepPartial,
  QueueStatus,
  QueueType,
  ActivationPackage as IActivationPackage,
  Flag,
  documentTitles,
  InstallationPackagePhotoCategoryTitles,
  ProgramType,
  ActivationDocumentTypes,
} from 'types';
import { Formik, Form, FormikValues } from 'formik';
import { useGetAccountQuery } from '../../services/accounts';
import { QueueFlagsProvider } from '../QueueFlags/QueueFlagsContext';
import { ExtendedWarranty } from './ExtendedWarranty';
import { SiteImprovement } from './SiteImprovement';
import { NotesDrawer } from '../NotesDrawer/NotesDrawer';
import { Helmet } from 'react-helmet';
import { QueueBanner } from '../MilestonePackagePartials/QueueBanner';
import { Footer } from '../MilestonePackagePartials/Footer';
import { isErrorWithData } from '../../services/helpers';
import {
  useSaveActivationPackageMutation,
  useSubmitActivationPackageMutation,
} from '../../services/activationPackage';
import { DateTime } from 'luxon';
import { ProofOfPto } from './ProofOfPto';
import { Incentives } from '../MilestonePackagePartials/Incentives';
import { DATE_FORMAT, convertToISODateString } from '../../helpers/dates';
import { ConditionallyApprovedDocs } from './ConditionallyApprovedDocs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ElectricianSignOff } from '@/components/MilestonePackagePartials/ElectricianSignOff';

export const formatActivationPackageData = (values: FormikValues): DeepPartial<IActivationPackage> => {
  return {
    monitoring: {
      monitoringSiteId: values.monitoringSiteId,
      batteryMonitoringSiteId: values.batteryMonitoringSiteId,
    },
    ptoGrantedDate: values.ptoGrantedDate ? convertToISODateString(values.ptoGrantedDate) : undefined,
    utility: {
      tariffId: values.tariffId === '' ? null : values.tariffId,
    },
    electricianSignoffDate: values.electricianSignoffDate
      ? { palmetto: convertToISODateString(values.electricianSignoffDate) }
      : undefined,
  };
};
const activationPackageSchema = yup.object().shape({
  monitoringSiteId: yup.string().required(),
});

const getMostRecentObj = (array: any[]) => {
  return array?.reduce((prev: any, current: any) => {
    return prev.date > current.date ? prev : current;
  });
};

export const ActivationPackage = ({ canSubmitActivationPackage }: { canSubmitActivationPackage: boolean }) => {
  const { ptoQueue: usePtoQueue = false } = useFlags();
  const navigate = useNavigate();
  const { id = '', action = 'review' } = useParams<{ id: string; action: ActivationPackageActions }>();

  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(id);
  const { data: quotes, isLoading: isQuotesLoading } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const { data: queue, isLoading: isQueueLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.activationPackage,
  });
  const { data: installationQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.installPackage,
  });
  const { data: ptoQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.permissionToOperate,
  });
  const { data: electricianSignoffQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.electricianSignOff,
  });
  const [triggerSave] = useSaveActivationPackageMutation();
  const [triggerSubmit] = useSubmitActivationPackageMutation();
  const { claims } = useAuth();
  const isFinCoUser = isPalmettoFinanceUser(claims);
  const [isNotesOpen, setIsNotesOpen] = useState(false);

  const canSubmitReview = action === ActivationPackageActions.review && queue;
  const installationWasConditionalApproved = installationQueue?.status === QueueStatus.conditionallyApproved;
  const hasPendingConditionalDocument =
    installationQueue &&
    installationQueue?.flags?.map(
      (flag: Flag & { category: string }) =>
        (Object.values({
          ...documentTitles,
          installPhotoDocumentation: 'Install Photo Documentation',
          designPackageDocuments: 'Design Package Documents',
        }).includes(flag.category) ||
          Object.values(InstallationPackagePhotoCategoryTitles).includes(flag.dataKey)) &&
        !flag.resolved,
    );

  const hasConditionalApprovalFlaggedDoc =
    queue &&
    queue?.flags?.some(
      (flag: Flag & { category: string }) => flag?.category === ActivationDocumentTypes.conditionalApproval,
    );

  const activeQuote = quotes?.find((quote) => quote.status === QuoteStatus.active);

  const isLoading = isQuotesLoading || isQueueLoading || isAccountLoading;

  const installNote = useMemo(() => {
    if (!account) {
      return;
    }
    if (
      installationQueue &&
      [QueueStatus.approved, QueueStatus.conditionallyApproved].includes(installationQueue.status)
    ) {
      return getMostRecentObj(installationQueue.approvalNotes)?.note;
    }
    return account?.notes?.milestones?.install;
  }, [account, installationQueue]);

  const ptoNeedsApproval =
    usePtoQueue && account.programType === ProgramType.solar && ptoQueue
      ? ptoQueue?.status !== QueueStatus.approved
      : false;
  const electricianSignoffNeedsApproval =
    account.programType === ProgramType.doePr && electricianSignoffQueue
      ? electricianSignoffQueue?.status !== QueueStatus.approved
      : false;

  const initialValues = {
    monitoringSiteId: account?.monitoringSiteId,
    batteryMonitoringSiteId: account.batteryMonitoringSiteId,
    ptoGrantedDate: account?.ptoGrantedDate
      ? DateTime.fromISO(account?.ptoGrantedDate).toUTC().toFormat(DATE_FORMAT)
      : '',
    electricianSignoffDate: account?.electricianSignoffDate?.palmetto
      ? DateTime.fromISO(account.electricianSignoffDate.palmetto).toUTC().toFormat(DATE_FORMAT)
      : '',
    lseId: account?.utility?.lseId,
    tariffId: account?.utility?.tariffId || '',
    state: account.address.state,
  };

  useEffect(() => {
    if (!action || !ActivationPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
    if (action === ActivationPackageActions.review && !isFinCoUser) {
      if (queue) {
        navigate(`/accounts/${id}/activation-package/edit`, { replace: true });
      } else {
        navigate(`/accounts/${id}/activation-package/create`, { replace: true });
      }
    }
  }, [id, navigate, action, isFinCoUser, queue]);

  const handleSave = async ({ accountId, data }: { accountId: string; data: FormikValues }) => {
    const formattedData = formatActivationPackageData(data);
    try {
      await triggerSave({ accountId, activationPackage: formattedData }).unwrap();
      toast.success('Activation Package Saved');
    } catch (e) {
      console.error(e);
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        toast.error('Error saving Activation Package');
      }
    }
  };
  const handleSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    try {
      const formattedData = formatActivationPackageData(values) as IActivationPackage;
      await triggerSubmit({ accountId: id, activationPackage: formattedData }).unwrap();
      toast.success('Activation Package Submitted');
      navigate(`/accounts/${id}`);
    } catch (e: any) {
      console.error(e);
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        toast.error('Error saving Activation Package');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleValidation = (values: any) => {
    const errors = {} as any;
    const teslaInverters = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const otherInverters = /^\d{5,8}$/;
    const dateRegex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(20[0-9]{2}|2[1-9][0-9]{3}|[3-9][0-9]{3})$/;
    if (
      values.monitoringSiteId &&
      !teslaInverters.test(values.monitoringSiteId) &&
      !otherInverters.test(values.monitoringSiteId)
    ) {
      errors.monitoringSiteId =
        'Monitoring Site Id must match the required formats: ' +
        '5-8 numbers long for Enphase, SolarEdge, and Tigo. ' +
        'The site ID for Tesla should come from the URL (ex: 8af307d1-ca9f-4843-8079-df2ba9c5736c from https://powerhub.energy.tesla.com/site/8af307d1-ca9f-4843-8079-df2ba9c5736c).';
    }
    if (account.programType === ProgramType.solar) {
      if (!values.ptoGrantedDate) {
        errors.ptoGrantedDate = 'PTO Granted Date is required';
      } else if (!dateRegex.test(values.ptoGrantedDate)) {
        errors.ptoGrantedDate = 'PTO Granted Date is invalid. Must be in the format MM-DD-YYYY';
      }
    }
    if (account.programType === ProgramType.doePr) {
      if (!values.electricianSignoffDate) {
        errors.electricianSignoffDate = 'Electrician Sign Off Date is required';
      }
    }
    return errors;
  };

  if (isLoading) {
    return (
      <MainContainer>
        <FormSection title="Loading...">
          <SkeletonBox height="25px" width="100" />
        </FormSection>
      </MainContainer>
    );
  }

  return (
    <QueueFlagsProvider queueId={queue?.id}>
      <NotesDrawer isOpen={isNotesOpen} setIsOpen={setIsNotesOpen} queueType={QueueType.activationPackage} />
      <Helmet>
        <title>{`${account?.primaryApplicantName} Activation Package`}</title>
      </Helmet>
      <MainContainer>
        {queue && (queue.status === QueueStatus.rejected || isFinCoUser) ? (
          <QueueBanner
            setIsNotesOpen={setIsNotesOpen}
            queueType={QueueType.activationPackage}
            isFinCoUser={isFinCoUser}
          />
        ) : null}
        {action === ActivationPackageActions.review && !canSubmitReview ? (
          <Alert
            variant="warning"
            title="Activation Package Not Submitted"
            message="This activation package is not ready to be reviewed yet. It has not been submitted."
            hasIcon
          />
        ) : null}
        {queue?.status !== QueueStatus.rejected && installNote && (
          <Alert variant="info" hasIcon title="Install Notes" message={installNote} />
        )}
        <Box>
          <InverterAndAddersInformation />
          {installationWasConditionalApproved && hasPendingConditionalDocument ? (
            <ConditionallyApprovedDocs flaggedDocs={installationQueue?.flags} />
          ) : (
            hasConditionalApprovalFlaggedDoc && <ConditionallyApprovedDocs flaggedDocs={installationQueue?.flags} />
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validateOnChange={false}
            validate={handleValidation}
            validationSchema={activationPackageSchema}
          >
            {() => (
              <Form>
                <MonitoringActive adders={activeQuote?.adders} />
                {account?.programType !== ProgramType.doePr && <ProofOfPto />}
                {account?.programType === ProgramType.doePr && <ElectricianSignOff />}
                <Incentives originContext={QueueType.activationPackage} />
                <ExtendedWarranty originContext={QueueType.activationPackage} />
                <SiteImprovement />
                <Footer
                  canSubmitPackage={canSubmitActivationPackage}
                  disableApproval={ptoNeedsApproval || electricianSignoffNeedsApproval}
                  isLoading={isLoading}
                  isFirstSubmission={!queue}
                  canSubmitReview={canSubmitReview}
                  queueType={QueueType.activationPackage}
                  onSave={handleSave}
                />
              </Form>
            )}
          </Formik>
        </Box>
      </MainContainer>
    </QueueFlagsProvider>
  );
};
