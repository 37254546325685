import { Field, FieldArray, useFormikContext } from 'formik';
import { Box, FormikTextInput } from '@palmetto/palmetto-components';
import { MonthNamesShort } from 'types';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { get } from 'lodash';

interface TwelveMonthsNumberInputProps {
  isLoading: boolean;
  fieldName: string;
  min?: number;
  max?: number;
}

export const TwelveMonthsNumberInput = ({
  isLoading,
  fieldName,
  min = Number.MIN_VALUE,
  max = Number.MAX_VALUE,
}: TwelveMonthsNumberInputProps) => {
  const { isSubmitting, errors, setFieldValue, values } = useFormikContext<any>();
  const isAnythingLoadingOrSubmitting = isLoading || isSubmitting;

  const setValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const numericValue = Number(event.target.value);
      if (!isNaN(numericValue) && numericValue >= min && numericValue <= max) {
        setFieldValue(event.target.name, numericValue);
      } else {
        const oldValue = get(values, event.target.name);
        setFieldValue(event.target.name, oldValue ?? '');
      }
    },
    [max, min, setFieldValue, values],
  );

  const fieldError = useMemo(() => {
    //fieldname could have an array index, use lodash to get the value
    return get(errors, fieldName);
  }, [errors, fieldName]);

  return (
    <Box
      childGap={{ base: 'lg', desktop: 'xl' }}
      display="grid"
      style={{
        gridTemplateColumns: 'repeat(6,minmax(0,1fr))',
        columnGap: 'var(--size-spacing-sm)',
        rowGap: 'var(--size-spacing-sm)',
      }}
    >
      <FieldArray
        name="firstYearMonthlyProductionKwh"
        render={() =>
          MonthNamesShort.map((month: string, index: number) => (
            <Box key={index}>
              <Field
                label={month}
                name={`${fieldName}[${index}]`}
                id={month}
                component={FormikTextInput}
                isDisabled={isAnythingLoadingOrSubmitting}
                form={{ errors: [] }}
                onChange={setValue}
              />
            </Box>
          ))
        }
      />
      <Box style={{ gridColumn: '1 / -1' }}>{fieldError ? <Box color="danger">{`${fieldError}`}</Box> : null}</Box>
    </Box>
  );
};
