import { PayoutEvent, PayoutEventMap, PayoutEventType, ProjectTransactionStatus } from 'types';
import { Badge, BadgeVariant, Box, Column, Icon, Popover, Table } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ProjectTransactionClientData } from '@/types/Payouts';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import DateTimeDisplay from '@/components/DateTime';
import { TransactionEventBadge } from '@/components/Payouts/TransactionEventBadge';

interface ProjectTransactionTableProps {
  transactions: ProjectTransactionClientData[];
  selectedIds?: string[];
  handleRowSelected?(ids: string[], selected: boolean): void;
  hideColumns?: string[];
}

const statusBadgeMap: Record<ProjectTransactionStatus, BadgeVariant> = {
  [ProjectTransactionStatus.open]: 'default',
  [ProjectTransactionStatus.paid]: 'success',
  [ProjectTransactionStatus.pending]: 'warning',
  [ProjectTransactionStatus.approved]: 'primary',
  [ProjectTransactionStatus.migrated]: 'info',
};

const getTransactionColumns = (
  hideColumns: string[],
  handleRowSelected: ProjectTransactionTableProps['handleRowSelected'],
  selectedIds: string[],
  transactions: ProjectTransactionClientData[],
) =>
  [
    handleRowSelected && {
      heading: (
        <input
          type="checkbox"
          name="selectedTransaction"
          checked={Boolean(transactions?.length && transactions.length === selectedIds?.length)}
          onChange={() =>
            handleRowSelected(
              transactions?.map((transaction) => `${transaction.id}`) || [],
              !Boolean(transactions?.length && transactions.length === selectedIds?.length),
            )
          }
        />
      ),
      dataKey: 'id',
      render: (_cell?: any, row?: any) =>
        row ? (
          <input
            type="checkbox"
            name="selectedTransaction"
            checked={selectedIds?.includes(row.id)}
            onChange={() => handleRowSelected([row.id], Boolean(!selectedIds?.includes(row.id)))}
          />
        ) : (
          <></>
        ),
    },
    {
      heading: 'Event Date',
      dataKey: 'eventDate',
      render: (cell?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          <DateTimeDisplay value={cell} fontSize="xs" dateFormat="DD h:mm:ss a" inline />
        </Box>
      ),
    },
    {
      heading: 'Payee',
      dataKey: 'payeeName',
      render: (cell?: any, _row?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell}
        </Box>
      ),
    },
    {
      heading: 'Account',
      dataKey: 'projectName',
      render: (cell?: any, row?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          <Link to={`/accounts/${row['projectExternalId']}/funding`} target="_blank">
            {cell} <Icon name="c-caret-right" />
          </Link>
        </Box>
      ),
    },
    {
      heading: 'Payout Event',
      dataKey: 'event',
      render: (cell?: any, row?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          <TransactionEventBadge event={cell} description={row.description} eventType={row.eventType} />
        </Box>
      ),
    },
    {
      heading: 'Status',
      dataKey: 'status',
      render: (cell?: any, _row?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell && <Badge variant={statusBadgeMap[cell as ProjectTransactionStatus]} message={cell} />}
        </Box>
      ),
    },
    {
      heading: <Box alignItems="flex-end">Amount</Box>,
      dataKey: 'amount',
      render: (cell?: any) => (
        <Box fontWeight="medium" alignItems="flex-end">
          {cell ? `${currencyFormatter.format(cell)}` : '-'}
        </Box>
      ),
    },
  ].filter((column) => column && !hideColumns.includes(column.dataKey));

export const ProjectTransactionTable = ({
  transactions,
  selectedIds,
  handleRowSelected,
  hideColumns,
}: ProjectTransactionTableProps) => {
  const columns = useMemo(
    () => getTransactionColumns(hideColumns ?? [], handleRowSelected, selectedIds ?? [], transactions),
    [handleRowSelected, transactions, selectedIds, hideColumns],
  );
  return transactions ? <Table rowKey="id" columns={columns as Column[]} rows={transactions} /> : null;
};
