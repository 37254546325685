import { useMemo, useState } from 'react';
import { Box, Button, FileUpload, FormikTextInput, Modal, toast } from '@palmetto/palmetto-components';
import { Form, useParams } from 'react-router-dom';
import { Field, Formik, FormikValues } from 'formik';
import { useUploadPriceSheetMutation } from '../../../services/pricing';
import { PricingDocumentType, ProgramType } from 'types';
import { isErrorWithData } from '@/services/helpers';

export const AddPriceSheetModal = ({ isOpen, handleClose }: { isOpen: boolean; handleClose: () => void }) => {
  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadPriceSheetMutation();
  const [files, setFiles] = useState<FileList | undefined>();
  const { programType = ProgramType.solar } = useParams<{ programType: string }>();

  const handleValidation = (values: any) => {
    const errors = {} as any;
    if (!values.name.trim()) {
      errors.name = 'Name is required';
    }
    return errors;
  };

  const onFileChange = (event: any) => {
    setFiles(event.target?.files);
  };

  const handleSubmit = async (values: any) => {
    const data = new FormData();
    data.append('type', PricingDocumentType.priceSheet);
    data.append('name', values.name);

    if (!!files) {
      for (let i = 0; i < files.length; i++) {
        data.append(`files[${i}]`, files[i]);
      }
    }

    try {
      await uploadDocument({ formData: data, programType: programType as ProgramType }).unwrap();
      toast.success('Document uploaded');
      handleClose();
    } catch (e) {
      if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        toast.error('An error occurred while uploading documents');
      }
    }
  };

  const closeModal = () => {
    setFiles(undefined);
    handleClose();
  };

  const initialValues: FormikValues = useMemo(() => ({ name: '' }), []);
  return (
    <Formik
      initialValues={initialValues}
      validate={handleValidation}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ errors, isSubmitting, resetForm, submitForm, touched }) => (
        <Form noValidate>
          <Modal
            isOpen={isOpen}
            maxWidth="4xl"
            ariaLabelledBy="addPriceSheetHeader"
            onDismiss={() => {
              resetForm();
              closeModal();
            }}
          >
            <Modal.Header
              id="addPriceSheetHeader"
              title="Add Price Sheet"
              onDismiss={() => {
                resetForm();
                closeModal();
              }}
            />
            <Modal.Body background="secondary" childGap="lg">
              <Box childGap={{ base: 'lg', desktop: 'xl' }}>
                <Field
                  type="text"
                  label="Name"
                  name="name"
                  id="name"
                  component={FormikTextInput}
                  isRequired
                  placeholder="eg. PS-California-EPCName"
                  errors={errors}
                  touched={touched}
                />
                <FileUpload
                  buttonText="Choose File(s)"
                  name="files"
                  labelText="Choose File(s)"
                  id="files"
                  accept=".csv"
                  variant="secondary"
                  tone="neutral"
                  size="md"
                  required
                  onChange={onFileChange}
                  isDisabled={isUploadingDocument}
                  files={files}
                />
              </Box>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                tone="neutral"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                onClick={() => {
                  resetForm();
                  closeModal();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                type="submit"
                onClick={submitForm}
              >
                Create Price Sheet
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
