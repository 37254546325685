import * as yup from 'yup';
import { Box, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { useCallback } from 'react';

const positiveNumber = yup.number().min(0, 'Must be a positive number').typeError('Must be a number');
const percent = yup
  .number()
  .min(0, 'Must be a positive number')
  .max(100, 'Must be less than 100')
  .typeError('Must be a number');
const exclusiveCheck = (value: any, thisField: any) =>
  value?.[0]
    ? thisField.oneOf([null as any, undefined, 0, '0', ''], 'Only a % or per watt can be submitted')
    : thisField.concat(percent);

export const createEnergyCommunityModifierValidationSchema = yup.object({
  name: yup.string().required('Option Name is required'),
  coalTractPercent: yup.number().when('coalTractPerWatt', exclusiveCheck),
  coalTractPerWatt: positiveNumber,
  fossilFuelPercent: yup.number().when('fossilFuelPerWatt', exclusiveCheck),
  fossilFuelPerWatt: positiveNumber,
  combinedPercent: yup.number().when('combinedPerWatt', exclusiveCheck),
  combinedPerWatt: positiveNumber,
});

export const CreateEnergyCommunityModifierForm = () => {
  const { setFieldTouched, setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, exclusive } = event.target as any;
      setFieldValue(name, value);
      if (exclusive) {
        setFieldTouched(exclusive, true, true);
      }
    },
    [setFieldTouched, setFieldValue],
  );

  return (
    <Box direction="column" childGap="md">
      <Field component={FormikTextInput} label="Option Name" name="name" id="name" isRequired />
      <Box childGap="lg">
        <Box fontWeight="medium" color="body-primary">
          Credits
          <Box color="body-primary">Enter a % or $/Watt value for the modifier</Box>
        </Box>
        <Box fontWeight="bold" color="body-primary">
          Coal Closure
        </Box>
        <Box direction={{ base: 'column', desktop: 'row' }} childGap="xl" width="auto" flex="auto">
          <Field
            component={FormikTextInput}
            label=""
            name="coalTractPercent"
            id="coalTractPercent"
            type="number"
            suffix="%"
            onChange={handleChange}
            exclusive="coalTractPerWatt"
          />
          <Field
            component={FormikTextInput}
            label=""
            name="coalTractPerWatt"
            id="coalTractPerWatt"
            type="number"
            prefix="$"
            suffix="/W"
            onChange={handleChange}
            exclusive="coalTractPercent"
          />
        </Box>
        <Box fontWeight="bold" color="body-primary">
          Fossil Fuel Employment
        </Box>
        <Box direction={{ base: 'column', desktop: 'row' }} childGap="xl" width="auto" flex="auto">
          <Field
            component={FormikTextInput}
            label=""
            name="fossilFuelPercent"
            id="fossilFuelPercent"
            type="number"
            suffix="%"
          />
          <Field
            component={FormikTextInput}
            label=""
            name="fossilFuelPerWatt"
            id="fossilFuelPerWatt"
            type="number"
            prefix="$"
            suffix="/W"
          />
        </Box>
        <Box fontWeight="bold" color="body-primary">
          Both
        </Box>
        <Box direction={{ base: 'column', desktop: 'row' }} childGap="xl" width="auto" flex="auto">
          <Field
            component={FormikTextInput}
            label=""
            name="combinedPercent"
            id="combinedPercent"
            type="number"
            suffix="%"
          />
          <Field
            component={FormikTextInput}
            label=""
            name="combinedPerWatt"
            id="combinedPerWatt"
            type="number"
            prefix="$"
            suffix="/W"
          />
        </Box>
      </Box>
    </Box>
  );
};
