import { Box } from '@palmetto/palmetto-components';
import { AccountActivity } from '../Activity/AccountActivity';

const History = () => {
  return (
    <Box>
      <AccountActivity />
    </Box>
  );
};

export default History;
