import { Box, Button, FormLabel, FormikRadioGroup, FormikTextareaInput, Modal } from '@palmetto/palmetto-components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { DocumentRejectionReasons } from 'types';

interface DocumentRejectionModalProps {
  isModalOpen: boolean;
  title: string;
  handleClose: () => void;
  handleSubmit: (values: { notes: string | undefined; rejectionReason: string }) => Promise<void>;
}

const documentRejectionSchema = yup.object({
  notes: yup.string().optional().nullable(),
  rejectionReason: yup.string().required('Rejection Reason is required'),
});

export const DocumentRejectionModal = ({
  isModalOpen,
  title,
  handleClose,
  handleSubmit,
}: DocumentRejectionModalProps) => {
  const rejectionOptions = Object.keys(DocumentRejectionReasons).map((reasons) => ({
    label: DocumentRejectionReasons[reasons as keyof typeof DocumentRejectionReasons],
    value: reasons,
    id: reasons,
  }));
  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={() => {
        handleClose();
      }}
      maxWidth="4xl"
      ariaLabelledBy="documentRejectionModalHeader"
      fullScreenMobile
    >
      <Modal.Header
        id="documentRejectionModalHeader"
        title={`Reject ${title}`}
        onDismiss={() => {
          handleClose();
        }}
      />
      <Formik
        initialValues={{ rejectionReason: '' as any, notes: '' }}
        onSubmit={handleSubmit}
        enableReinitialize={false}
        validationSchema={documentRejectionSchema}
      >
        {({ isSubmitting }) => (
          <Form noValidate id="documentRejectionModal">
            <Modal.Body background="secondary" gap="lg" fontSize="sm">
              <Box gap="lg">
                <FormLabel isFieldRequired inputId="rejectionReason">
                  Rejection Reason
                </FormLabel>
                <Field
                  isRequired
                  component={FormikRadioGroup}
                  id="rejectionReason"
                  label="Rejection Reason"
                  name="rejectionReason"
                  options={rejectionOptions}
                />
                <Field
                  label="Notes (Optional)"
                  name="notes"
                  id="notes"
                  component={FormikTextareaInput}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
              </Box>
            </Modal.Body>
            <Modal.Footer justifyContent={'flex-end'}>
              <Box direction="row" gap="sm">
                <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button tone="danger" size="md" variant="primary" type="submit" isLoading={isSubmitting}>
                  Reject {title}
                </Button>
              </Box>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
