// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, TextInput } from '@palmetto/palmetto-components';
import PlacesAutocomplete from 'react-places-autocomplete';
import styles from './AddressInput.module.scss';

const AddressInput = ({
  onBlur,
  onFocus,
  isDisabled,
  error,
  initialValue,
  name,
  onSelect,
  onChange,
  onError,
  value,
  placeHolderId,
  ...restProps
}) => {
  const handleChange = (data, placeId, suggestion) => {
    onChange(data, placeId, suggestion);
  };

  const handleSelect = (data, placeId, suggestion) => {
    onSelect(data, placeId, suggestion);
  };

  const handleError = (err, clearSuggestion) => {
    if (onError) onError(err, clearSuggestion);
  };
  return (
    <PlacesAutocomplete
      onChange={handleChange}
      onSelect={handleSelect}
      value={value}
      onError={handleError}
      shouldFetchSuggestions={value?.length > 2 ? true : false}
      name={name}
      googleCallbackName={placeHolderId}
    >
      {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => {
        const additionalProps = {
          name,
        };
        const autocompleteInputProps = getInputProps(additionalProps);

        return (
          <div className={styles.autoCompleteRoot}>
            <TextInput
              {...autocompleteInputProps}
              onBlur={onBlur}
              onFocus={onFocus}
              isDisabled={isDisabled}
              error={error}
              {...restProps}
            />
            {(suggestions.length > 0 || loading) && (
              <div className={`${styles?.autoCompleteDropdownContainer} text-light`}>
                {loading && (
                  <div className={styles?.autoCompleteSpinnerContainer}>
                    <Spinner />
                  </div>
                )}
                {suggestions.map((suggestion) => {
                  const suggestionClassName = suggestion.active
                    ? styles.autoCompleteSuggestionActive
                    : styles.autoCompleteSuggestion;
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.placeId}
                      className={suggestionClassName}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

AddressInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.string,
  initialValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onError: PropTypes.func,
  value: PropTypes.string,
};

AddressInput.defaultProps = {
  className: '',
  error: null,
  initialValue: '',
  isDisabled: false,
  onBlur: undefined,
  onFocus: undefined,
  onError: undefined,
  value: '',
};

export default AddressInput;
