import { Badge, BadgeVariant, Box, Button, Card, useBreakpoint } from '@palmetto/palmetto-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useGetAccountQuery } from '../../services/accounts';
import {
  AccountMilestone,
  AccountRequirementDocument,
  AccountRequirementStatus,
  MilestoneRequirement,
  MilestoneType,
  QueueStatus,
  QueueType,
} from 'types';
import { useGetQueueByAccountIdAndTypeQuery } from '@/services/queues';

export const noticeToProceedMilestoneStatusMap: {
  [key in AccountRequirementStatus]: {
    label: string;
    variant: BadgeVariant;
  };
} = {
  [AccountRequirementStatus.completed]: {
    label: 'Approved',
    variant: 'success',
  },
  [AccountRequirementStatus.rejected]: {
    label: 'Rejected',
    variant: 'danger',
  },
  [AccountRequirementStatus.pending]: {
    label: 'Pending',
    variant: 'warning',
  },
  [AccountRequirementStatus.submitted]: {
    label: 'Submitted',
    variant: 'warning',
  },
  [AccountRequirementStatus.resubmitted]: {
    label: 'Resubmitted',
    variant: 'warning',
  },
  [AccountRequirementStatus.inProgress]: {
    label: 'Pending',
    variant: 'warning',
  },
  [AccountRequirementStatus.error]: {
    label: 'Error',
    variant: 'warning',
  },
} as const;

export const NTPMilestonePackageContainer = () => {
  const { id } = useParams<{ id: any }>();
  const { data: account } = useGetAccountQuery(id);
  const { data: queue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.ntpPackage,
  });
  const { isPhone } = useBreakpoint();
  const navigate = useNavigate();

  const noticeToProceedPackageRequirement = useMemo(() => {
    if (!account) {
      return;
    }
    const noticeToProceedMilestone = account.milestones?.find(
      (m: AccountMilestone) => m.type === MilestoneType.noticeToProceed,
    );
    const noticeToProceedPackageRequirement: AccountRequirementDocument = noticeToProceedMilestone.requirements.find(
      (req: AccountRequirementDocument) => req.type === MilestoneRequirement.ntpPackage,
    );
    return noticeToProceedPackageRequirement as AccountRequirementDocument;
  }, [account]);

  const noticeToProceedPackageStatus = noticeToProceedPackageRequirement
    ? noticeToProceedPackageRequirement.status
    : AccountRequirementStatus.pending;
  const isNoticeToProceedPackageCreated = noticeToProceedPackageStatus !== AccountRequirementStatus.pending;
  const queueStatusIsConditionallyApproved = queue && queue.status === QueueStatus.conditionallyApproved;

  return (
    <Card margin="0 0 lg 0">
      <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
        <Box as="h3" fontWeight="medium" fontSize="md">
          Notice To Proceed Package
        </Box>
        {!isNoticeToProceedPackageCreated && (
          <Button
            as="a"
            navigate={() => navigate(`/accounts/${id}/ntp-package/create`)}
            size="sm"
            variant="primary"
            tone="neutral"
            iconPrefix="add"
          >
            {!isPhone && 'NTP Package'}
          </Button>
        )}
      </Box>
      <Box
        borderWidth="xs 0 0 0"
        borderColor="separator"
        background="primary"
        padding={{ base: 'md 0', tablet: '0' }}
        direction="row"
        width="100"
        alignItems="center"
      >
        {isNoticeToProceedPackageCreated ? (
          <>
            <Box width={{ base: '100', tablet: '60' }} direction={{ base: 'column', tablet: 'row' }}>
              <Box
                alignItems="flex-start"
                width={{ base: '100', tablet: '30' }}
                padding={{ base: 'md lg', tablet: 'md md md lg' }}
              >
                {isNoticeToProceedPackageCreated && (
                  <Badge
                    message={noticeToProceedMilestoneStatusMap[noticeToProceedPackageStatus].label}
                    variant={noticeToProceedMilestoneStatusMap[noticeToProceedPackageStatus].variant}
                  />
                )}
              </Box>
              <Box
                width={{ base: '100', tablet: '70' }}
                display="flex"
                childGap="xs"
                padding={{ base: '0 lg', tablet: 'md md md lg' }}
                justifyContent={{ base: queueStatusIsConditionallyApproved ? 'center' : 'flex-start' }}
                direction={{ base: 'column', tablet: 'row' }}
                gap="md"
              ></Box>
            </Box>
            <Box
              width="40"
              padding={{ base: '0 lg', tablet: 'md lg' }}
              direction={{ base: 'column', tablet: 'row' }}
              childGap="xs"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {noticeToProceedPackageStatus !== AccountRequirementStatus.completed && (
                <div>
                  <Button size="sm" variant="secondary" tone="neutral" onClick={() => navigate(`ntp-package/edit`)}>
                    Edit
                  </Button>
                </div>
              )}
              {noticeToProceedPackageStatus === AccountRequirementStatus.completed && (
                <div>
                  <Button
                    size="sm"
                    variant="secondary"
                    tone="neutral"
                    onClick={() => navigate(`ntp-package/review`)}
                  >
                    View
                  </Button>
                </div>
              )}
            </Box>
          </>
        ) : null}
      </Box>
    </Card>
  );
};
