import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';

const SolarFlareSmsNotificationSent = ({ event }: { event: any }) => {
  return (
    <Box display="block" childGap="sm">
      <Box display="block">
        <EventBy event={event} /> sent SMS to <strong>{event?.data?.request?.recipients?.[0].phone}</strong>
      </Box>
      <Box
        fontWeight="medium"
        padding="sm"
        radius="md"
        background="primary"
        display="inline-block"
        borderWidth="xs"
        borderColor="separator"
      >
        {event?.data?.renderedMessage?.output?.body}
      </Box>
    </Box>
  );
};

export default SolarFlareSmsNotificationSent;
