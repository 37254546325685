import { Box, CheckboxInput } from '@palmetto/palmetto-components';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterButtonDropdown } from '../FilterButtonDropdown';
import { DocumentTypes } from 'types';

const sortedDocumentTypes = Object.values(DocumentTypes).sort();

type DocumentTypeFilterProps = {
  isMobile: boolean;
  onFilterChange?: (key: string, values: string[]) => void;
};

export const DocumentTypeFilter = ({ isMobile, onFilterChange }: DocumentTypeFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [documentTypeSearchValue, setDocumentTypeSearchValue] = useState('');
  const [checkedDocumentTypes, setCheckedDocumentTypes] = useState<string[]>([]);
  const documentTypeSearch = searchParams.get('documentType');
  const filteredDocumentTypes = useMemo(
    () => sortedDocumentTypes.filter((type) => type.includes(documentTypeSearchValue)),
    [documentTypeSearchValue],
  );

  useEffect(() => {
    if (documentTypeSearch) {
      setCheckedDocumentTypes(documentTypeSearch.split(','));
    } else {
      setCheckedDocumentTypes([]);
    }
  }, [documentTypeSearch]);

  const handleClearDocumentTypeFilter = () => {
    setCheckedDocumentTypes([]);
    searchParams.delete('documentType');
    setSearchParams(searchParams);
  };

  const handleApplyFilters = () => {
    if (checkedDocumentTypes.length) {
      searchParams.set('documentType', checkedDocumentTypes.join(','));
      setSearchParams(searchParams);
    }
  };

  const handleFilterChange = ({ isSelected, id, updateQueryString, checkedFilters, setCheckedFilters }: any) => {
    const newFilters = [...checkedFilters];

    if (isSelected) {
      newFilters.push(id);
    } else {
      const index = checkedFilters.findIndex((ch: any) => ch === id);
      if (index > -1) newFilters.splice(index, 1);
    }

    if (updateQueryString) {
      searchParams.set(updateQueryString, newFilters.join(','));
      setSearchParams(searchParams);
    }
    setCheckedFilters([...newFilters]);

    if (onFilterChange) {
      onFilterChange('documentType', newFilters);
    }
  };

  return isMobile ? (
    <Box childGap="lg">
      {filteredDocumentTypes.map((val) => {
        return (
          <Box display="block" childGap="xs" key={val}>
            <CheckboxInput
              id={val}
              label={val}
              key={val}
              onChange={(event) =>
                handleFilterChange({
                  isSelected: event.target.checked,
                  id: val,
                  checkedFilters: checkedDocumentTypes,
                  setCheckedFilters: setCheckedDocumentTypes,
                })
              }
              isChecked={!!checkedDocumentTypes.find((documentType) => documentType === val)}
            />
          </Box>
        );
      })}
    </Box>
  ) : (
    <FilterButtonDropdown
      label="Document Type"
      searchValue={documentTypeSearchValue || ''}
      setSearchValue={setDocumentTypeSearchValue}
      onApplyFilters={handleApplyFilters}
      onClearFilters={handleClearDocumentTypeFilter}
    >
      <Box childGap="lg">
        {filteredDocumentTypes.map((val) => {
          return (
            <Box display="block" childGap="xs" key={val}>
              <CheckboxInput
                id={val}
                label={val}
                key={val}
                onChange={(event) =>
                  handleFilterChange({
                    isSelected: event.target.checked,
                    id: val,
                    checkedFilters: checkedDocumentTypes,
                    setCheckedFilters: setCheckedDocumentTypes,
                  })
                }
                isChecked={!!checkedDocumentTypes.find((documentType) => documentType === val)}
              />
            </Box>
          );
        })}
      </Box>
    </FilterButtonDropdown>
  );
};
