import { parsePhoneNumberWithError, ParseError } from 'libphonenumber-js/min';

export const formatPhoneNumber = (phoneNumber: string) => {
  let formattedPhoneNumber = '';
  try {
    formattedPhoneNumber = parsePhoneNumberWithError(phoneNumber, 'US')?.formatNational() || '';
  } catch (error) {
    if (error instanceof ParseError) {
      // Not a phone number, non-existent country, etc.
      console.log(error.message);
    } else {
      console.error('Error parsing phone number: ', error);
    }
  }

  return formattedPhoneNumber;
};
