import { useAuth } from './authProvider';
import { ReactNode } from 'react';
import { isPalmettoFinanceUser } from '@/components/auth/RequirePricingAdminUser';
import { UserPermissions } from 'types';

export const RequirePayoutsAccountingAdminUser = ({ children }: { children: ReactNode }) => {
  const { claims } = useAuth();
  const isPalmettoFinance = isPalmettoFinanceUser(claims);
  const isLightReachAccountingAdmin = claims?.permissions?.includes(UserPermissions.lightReachAccountingAdmin);
  return isPalmettoFinance && isLightReachAccountingAdmin ? <>{children}</> : null;
};
