import { Box, Card } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { OrganizationPricingTabType } from 'types';
import { SectionOption } from './Sections';

export const PricingTabOptions = () => {
  const { pricingType } = useParams<{ pricingType: string; }>();

  return (
    <Card padding="xs" flex="auto">
      <Box margin="0 lg 0 lg">
        {SectionOption[pricingType as OrganizationPricingTabType]}
      </Box>
    </Card>
  );
};
