const nameFilter = (searchParams: URLSearchParams, data: any) => {
  const nameSearch = searchParams.get('name')?.replaceAll(' ', '').toLowerCase();
  if (!nameSearch) {
    return true;
  }
  const accountName =
    data.accounts[0].friendlyName ||
    data.accounts[0].applicants[0].firstName + '-' + data.accounts[0].applicants[0].lastName;
  return accountName.toLowerCase().replaceAll('-', '').includes(nameSearch);
};

const statusFilter = (searchParams: URLSearchParams, data: any) => {
  const statusSearch = searchParams.get('status')?.split(',');

  if (!statusSearch) {
    return true;
  }

  return statusSearch.includes(data.status);
};

const filters = [nameFilter, statusFilter] as const;

export const applyGlobalFilters = (searchParams: URLSearchParams, data: any[]) => {
  const filteredArray = data.filter((d) => {
    let criteriaMatch = true;

    for (let filter of filters) {
      if (!filter(searchParams, d)) {
        criteriaMatch = false;
        break;
      }
    }

    return criteriaMatch;
  });

  return filteredArray;
};
