import { Button, useOpenClose } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import RequirePermissions from '../auth/requirePermissions';
import { useGetAccountQuery } from '../../services/accounts';
import { ApplyAdminStipulationModal } from './ApplyAdminStipulationModal';

const AdminStipulation = () => {
  const { id } = useParams<{ id: any }>();
  const { data: accountData, isLoading }: any = useGetAccountQuery(id);
  const { isOpen, handleClose, handleToggle } = useOpenClose();

  return (
    <>
      <ApplyAdminStipulationModal accountId={accountData?.id} handleClose={handleClose} isOpen={isOpen} />
      {!isLoading && (
        <RequirePermissions permissions={['lightReachSupport']}>
          <Button
            tone="neutral"
            variant="secondary"
            size="sm"
            style={{ width: 'fit-content' }}
            onClick={handleToggle}
            isDisabled={accountData?.cancellation?.isCancelled}
            iconPrefix="flag"
          >
            Apply Stipulation
          </Button>
        </RequirePermissions>
      )}
    </>
  );
};

export default AdminStipulation;
