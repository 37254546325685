import { Auth0PalmettoFinanceUser } from '../../../types/Organizations';
import {
  Box,
  Button,
  Card,
  Modal,
  Table,
  useBreakpoint,
  useOpenClose,
  toast,
  FormikSelectInput,
  FormikTextInput,
  FormikToggle,
} from '@palmetto/palmetto-components';
import RequirePermissions from '../../auth/requirePermissions';
import DateTime from '../../DateTime';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useAddUserMutation, useEditUserMutation, useRemoveUserMutation } from '../../../services/users';
import { SearchOrganizationUser } from '../../filters/SearchOrganizationUser';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSearchUsersQuery } from '../../../services/organizations';

interface AddUserProps {
  handleClose: () => void;
  organizationAlias: string;
}

const AddUser = ({ handleClose, organizationAlias }: AddUserProps) => {
  const [addUser] = useAddUserMutation();

  const handleValidation = (values: any) => {
    const errors = {} as any;
    // TODO: validate email is an email address
    if (!values.email) {
      errors.email = 'An email is required';
    }
    if (!values.permissions || values.permissions?.length < 1) {
      errors.permissions = 'Please select at least one permission';
    }
    return errors;
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const permissionsValues = Array.isArray(values.permissions)
        ? values.permissions.map((p: { value: string; name: string }) => p.value)
        : [values.permissions.value];

      await addUser({
        email: values.email,
        organizationAlias: organizationAlias,
        permissions: permissionsValues,
        machine: values.machine,
      }).unwrap();

      toast.success('User added');
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error adding user');
    }
    setSubmitting(false);
  };

  const permissionOptions = useMemo(
    () => [
      { value: 'reader', label: 'Reader' },
      { value: 'editor', label: 'Editor' },
      { value: 'admin', label: 'Admin' },
    ],
    [],
  );

  const initialValues = useMemo(() => {
    return {
      email: '',
      permissions: permissionOptions[0],
      machine: false,
    };
  }, [permissionOptions]);

  return (
    <Formik
      initialValues={initialValues}
      validate={handleValidation}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form noValidate id="addUserForm">
          <Modal.Body background="secondary">
            <Box childGap="lg">
              <Field
                type="email"
                label="Email Address"
                name="email"
                id="email"
                component={FormikTextInput}
                isRequired
                autoComplete="off"
                isDisabled={isSubmitting}
              />

              <Field
                label="Permissions"
                name="permissions"
                id="permissions"
                isMulti
                options={permissionOptions}
                component={FormikSelectInput}
                isDisabled={isSubmitting}
                isRequired
                menuPortalTarget={document.body}
              />
              <Field
                label="Machine to Machine"
                name="machine"
                id="machine"
                component={FormikToggle}
                isDisabled={isSubmitting}
              />
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
              Cancel
            </Button>
            <Button variant="primary" size="md" type="submit" form="addUserForm" isLoading={isSubmitting}>
              Add User
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

interface EditUserProps {
  handleClose: () => void;
  user?: Auth0PalmettoFinanceUser;
  organizationAlias: string;
}

const EditUser = ({ handleClose, user = {} as Auth0PalmettoFinanceUser, organizationAlias }: EditUserProps) => {
  const [editUser] = useEditUserMutation();

  const handleValidation = (values: any) => {
    const errors = {} as any;
    if (!values.permissions || values.permissions?.length < 1) {
      errors.permissions = 'Please select at least one permission';
    }
    return errors;
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const permissionsValues = Array.isArray(values.permissions)
        ? values.permissions.map((p: { value: string; name: string }) => p.value)
        : [values.permissions.value];

      await editUser({
        organizationAlias: organizationAlias,
        userId: user.id,
        permissions: permissionsValues,
      }).unwrap();

      toast.success('User Saved');
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error editing user');
    }
    setSubmitting(false);
  };

  const permissionOptions = useMemo(
    () => [
      { value: 'reader', label: 'Reader' },
      { value: 'editor', label: 'Editor' },
      { value: 'admin', label: 'Admin' },
    ],
    [],
  );
  const initialValues = useMemo(() => {
    return {
      email: user.email,
      machine: user.type === 'machine',
      permissions: permissionOptions.filter((p) => user.permissions?.find((up: any) => up.name === p.value)),
    };
  }, [permissionOptions, user.email, user.permissions, user.type]);

  return (
    <Formik
      initialValues={initialValues}
      validate={handleValidation}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form noValidate id="editUserForm">
          <Modal.Body background="secondary">
            <Box childGap="lg">
              <Field
                type="email"
                label="Email Address"
                name="email"
                id="email"
                component={FormikTextInput}
                isRequired
                title="Emails cannot be edited after the user is created. You may remove this user and create a new one with the correct email."
                autoComplete="off"
                isDisabled
              />
              <Field
                label="Permissions"
                name="permissions"
                id="permissions"
                isMulti
                options={permissionOptions}
                component={FormikSelectInput}
                isDisabled={isSubmitting}
                isRequired
                menuPortalTarget={document.body}
              />
              <Field
                label="Machine to Machine"
                name="machine"
                id="machine"
                component={FormikToggle}
                title="The user type cannot be edited after the user is created. You may remove this user and create a new one with the correct type."
                isDisabled
              />
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
              Cancel
            </Button>
            <Button variant="primary" size="md" type="submit" form="editUserForm" isLoading={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

interface RemoveUserProps {
  handleClose: () => void;
  user?: Auth0PalmettoFinanceUser;
  organizationAlias: string;
}

const RemoveUser = ({ handleClose, organizationAlias, user = {} as Auth0PalmettoFinanceUser }: RemoveUserProps) => {
  const [removeUser] = useRemoveUserMutation();

  const onClick = useCallback(async () => {
    try {
      await removeUser({ organizationAlias, userId: user.id }).unwrap();
      toast.success('User removed');
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error removing user');
    }
  }, [handleClose, organizationAlias, removeUser, user.id]);

  let name = user.nickname;
  if (user.givenName) {
    name = `${user.givenName} ${user.familyName}`;
  }
  return (
    <>
      <Modal.Body background="secondary" childGap="md">
        <Box>
          Are you sure you would like to remove {name} from {organizationAlias}? Doing so has the following impact
        </Box>
        <ul style={{ paddingLeft: '20px' }}>
          <li>
            They will no longer be able to access any accounts in {organizationAlias} or its child organizations.
          </li>
          <li>The user will not be deleted, and will still retain access to other palmetto systems.</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" tone="neutral" size="md" onClick={handleClose}>
          Cancel
        </Button>
        <Button size="md" type="submit" form="addUserForm" variant="primary" tone="danger" onClick={onClick}>
          Remove User
        </Button>
      </Modal.Footer>
    </>
  );
};

const OrganizationUsers = () => {
  const { alias } = useParams<{ alias: any }>();
  const { isPhone } = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = searchParams.get('search') || '';
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isOpen: isAddOpen, handleClose: handleAddClose, handleToggle: handleAddToggle } = useOpenClose();
  const { isOpen: isRemoveOpen, handleClose: handleRemoveClose, handleToggle: handleRemoveToggle } = useOpenClose();
  const { isOpen: isModifyOpen, handleClose: handleModifyClose, handleToggle: handleModifyToggle } = useOpenClose();
  const [selectedUser, setSelectedUser] = useState<Auth0PalmettoFinanceUser | undefined>(undefined);

  const location = useLocation();
  const usersRef = useRef<HTMLDivElement>(null);

  const {
    data: userData,
    error: searchError,
    refetch,
    isLoading,
    isFetching,
  } = useSearchUsersQuery({ orgAlias: alias, searchUsers: searchParam, page, limit: 10 }, { skip: !alias });

  const userColumns = [
    {
      heading: '',
      dataKey: 'picture',
      render: (cell: any) => {
        return <img src={cell} alt="user profile" style={{ maxHeight: '35px', maxWidth: '35px' }} />;
      },
      width: 83,
    },
    {
      heading: 'user',
      dataKey: 'userInfo',
      render: (_cell: any, row: any) => {
        let name = row.nickname;
        if (row.firstName) {
          name = `${row.givenName} ${row.familyName}`;
        }
        return (
          <Box childGap="2xs">
            <Box fontWeight="medium">{name}</Box>
            <Box color="body-secondary">{row.email}</Box>
            {row.type === 'machine' && <Box color="body-secondary">Machine to Machine</Box>}
          </Box>
        );
      },
    },
    {
      heading: 'permissions',
      dataKey: 'permissions',
      render: (cell: any) => {
        return cell.map((permission: any) => permission.name).join(', ');
      },
    },
    {
      heading: 'email verified',
      dataKey: 'emailVerified',
      render: (cell: any) => (cell ? 'yes' : 'no'),
      width: 125,
    },
    {
      heading: 'last login (Local)',
      dataKey: 'lastLogin',
      render: (cell: any) => (cell ? <DateTime value={cell} /> : 'never'),
      width: 200,
    },
    {
      heading: '',
      render: (_cell: any, row: any) => {
        return (
          <Box gap="xs" direction="row" justifyContent="flex-end">
            <Button
              variant="secondary"
              tone="neutral"
              size="sm"
              onClick={() => {
                setSelectedUser(row);
                handleModifyToggle();
              }}
            >
              Edit
            </Button>
            <Button
              variant="secondary"
              tone="danger"
              size="sm"
              onClick={() => {
                setSelectedUser(row);
                handleRemoveToggle();
              }}
            >
              Remove
            </Button>
          </Box>
        );
      },
    },
  ];

  const usersLoading = isLoading || isFetching;

  const onSearchChange = useMemo(
    () => (searchUsers: string) => {
      if (searchUsers && searchUsers.length >= 3) {
        searchParams.set('search', searchUsers);
      } else {
        searchParams.delete('search');
      }
      searchParams.delete('page');
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handlePageChange = (newPage: number) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const fetchUsers = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const { current } = usersRef;
    if (current !== null) {
      current.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  }, [location]);

  return (
    <>
      <Modal isOpen={isAddOpen} onDismiss={handleAddClose} maxWidth="4xl" ariaLabelledBy="addOrgUserHeader">
        <Modal.Header id="addOrgUserHeader" title="Add Organization User" onDismiss={handleAddClose} />
        <AddUser
          handleClose={() => {
            handleAddClose();
            fetchUsers();
          }}
          organizationAlias={alias || ''}
        />
      </Modal>

      <Modal
        isOpen={isRemoveOpen}
        onDismiss={handleRemoveClose}
        maxWidth="4xl"
        ariaLabelledBy="removeOrgUserHeader"
        fullScreenMobile
      >
        <Modal.Header
          id="removeOrgUserHeader"
          title={`Remove ${selectedUser?.name}?`}
          onDismiss={() => {
            handleRemoveClose();
            setSelectedUser(undefined);
          }}
        />
        <RemoveUser
          handleClose={() => {
            handleRemoveClose();
            setSelectedUser(undefined);
            fetchUsers();
          }}
          organizationAlias={alias || ''}
          user={selectedUser}
        />
      </Modal>

      <Modal
        isOpen={isModifyOpen}
        onDismiss={() => {
          handleModifyClose();
          setSelectedUser(undefined);
        }}
        maxWidth="4xl"
        ariaLabelledBy="modifyUserHeader"
        fullScreenMobile
      >
        <Modal.Header
          id="modifyUserHeader"
          title={`Edit ${selectedUser?.name}`}
          onDismiss={() => {
            handleModifyClose();
            setSelectedUser(undefined);
          }}
        />
        <EditUser
          handleClose={() => {
            handleModifyClose();
            setSelectedUser(undefined);
            fetchUsers();
          }}
          organizationAlias={alias || ''}
          user={selectedUser}
        />
      </Modal>

      <Card ref={usersRef}>
        <Box
          gap="lg"
          padding="lg"
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          style={{ display: 'flex' }}
        >
          <Box as="h3" fontWeight="medium" fontSize="md" width="5%">
            Users
          </Box>
          <Box style={{ marginRight: 'auto' }}>
            <SearchOrganizationUser
              orgAlias={alias}
              setSearchText={(searchUsers: string) => onSearchChange(searchUsers)}
              searchUsers={searchParam}
              autoFocus
              disabled={usersLoading}
            />
          </Box>
          <RequirePermissions permissions={['admin']}>
            <Button
              onClick={handleAddToggle}
              variant="primary"
              tone="neutral"
              iconPrefix="add"
              aria-label="add user"
              size="sm"
              isLoading={usersLoading}
            >
              {!isPhone && 'Add User'}
            </Button>
          </RequirePermissions>
        </Box>

        {!searchError && (
          <Box gap="md">
            <Table
              rowKey="id"
              columns={userColumns}
              rows={userData?.data || []}
              isLoading={usersLoading}
              isScrollable={{
                x: true,
                y: true,
              }}
            />
            {!usersLoading && searchError && (
              <Box gap="lg" padding="lg" alignItems="center">
                Error pulling users, please try again later or contact support
              </Box>
            )}
            {!usersLoading && (!userData?.data || userData?.data.length === 0) && (
              <Box gap="lg" padding="lg" alignItems="center">
                No users found
              </Box>
            )}
            <Box direction="row" childGap="xs" padding="0 lg lg lg">
              <Button
                isDisabled={!userData?.meta?.prevPage}
                onClick={() => handlePageChange(userData?.meta?.prevPage || 1)}
                variant="secondary"
                tone="neutral"
                iconPrefix="caret-left"
                size="sm"
              >
                Prev
              </Button>
              <Button
                isDisabled={
                  !userData?.meta?.nextPage ||
                  (userData?.data && userData?.meta.total !== undefined && userData?.meta.total < 10)
                }
                onClick={() => handlePageChange(userData?.meta?.nextPage || 1)}
                variant="secondary"
                tone="neutral"
                iconSuffix="caret-right"
                size="sm"
              >
                Next
              </Button>
            </Box>
          </Box>
        )}
      </Card>
    </>
  );
};

export default OrganizationUsers;
