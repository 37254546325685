export const kWHFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
});
/**
 * @param value The value of the KWH
 * @returns This returns a formatted KWH number in a common display format. Example: from 8102.35, returns 8,102
 */
export function formatKWHNumber(value: number) {
  return kWHFormatter.format(value);
}
