export enum BatchComponent {
  queued = 'queued',
  paid = 'paid',
}
import { PayoutEvent, PayoutEventMap, ProjectTransactionWithNames, WithObjectIdsAsString } from 'types';

export type ProjectTransactionClientData = WithObjectIdsAsString<ProjectTransactionWithNames>;

export const materialsLabels = [
  { event: PayoutEvent.estimate, name: 'Materials Estimate', order: 1, badgeVariant: 'info' },
  { event: PayoutEvent.invoice, name: 'Materials Invoice', order: 2, badgeVariant: 'primary' },
];
export const flattenedPaymentEventLabelInfo = [...Object.values(PayoutEventMap).flat(), ...materialsLabels];
