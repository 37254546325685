import { Box, Icon, Popover, useOpenClose } from '@palmetto/palmetto-components';
import { useAuth } from './auth/authProvider';
import { NavLink } from 'react-router-dom';

export function UserMenu() {
  const { isOpen, handleClose, handleToggle } = useOpenClose();
  const { logout, refreshToken, user } = useAuth();

  const displayUserName =
    user?.given_name && user?.family_name ? `${user?.given_name} ${user?.family_name}` : user?.email;

  const content = (
    <>
      <Box borderWidth="xs 0 xs 0" borderColor="separator" padding="2xs">
        <Box
          className="truncate"
          padding={{ base: 'xs sm', desktop: 'sm' }}
          background="transparent"
          borderWidth="0"
          color="body-primary"
          hover={{ background: 'secondary' }}
          radius="sm"
        >
          <NavLink
            to="/profile"
            onClick={() => {
              handleClose();
            }}
            className="nav-link"
          >
            Your Profile
          </NavLink>
        </Box>
        <Box
          as="button"
          cursor="pointer"
          type="button"
          padding={{ base: 'xs sm', desktop: 'sm' }}
          background="transparent"
          borderWidth="0"
          color="body-primary"
          hover={{ background: 'secondary' }}
          textAlign="left"
          onClick={() => {
            handleClose();
            refreshToken();
          }}
          radius="sm"
          className="nav-link"
        >
          Refresh Token
        </Box>
        <Box
          cursor="pointer"
          padding={{ base: 'xs sm', desktop: 'sm' }}
          background="transparent"
          borderWidth="0"
          color="body-primary"
          hover={{ background: 'secondary' }}
          radius="sm"
          textAlign="left"
        >
          <NavLink
            to="/settings"
            onClick={() => {
              handleClose();
            }}
            className="nav-link"
          >
            Organizations &amp; Settings
          </NavLink>
        </Box>
      </Box>
      <Box padding="2xs">
        <Box
          as="button"
          cursor="pointer"
          type="button"
          padding={{ base: 'xs sm', desktop: 'sm' }}
          background="transparent"
          borderWidth="0"
          color="body-primary"
          hover={{ background: 'secondary' }}
          radius="sm"
          onClick={() => logout()}
          className="nav-link"
        >
          Logout
        </Box>
      </Box>
    </>
  );
  return (
    <Box
      as="nav"
      padding={{ base: '2xs', desktop: '2xs md 2xs sm' }}
      borderColor="separator"
      borderWidth="0 0 0 xs"
      justifyContent="center"
      maxWidth={{ base: '2xl', tablet: '30' }}
    >
      <Popover
        content={content}
        isOpen={isOpen}
        placement="bottom-start"
        offsetFromTarget={4}
        trapFocus
        contentContainerProps={{
          padding: '0',
          shadow: 'sm',
          borderColor: 'separator',
          borderWidth: 'xs',
          background: 'primary',
          radius: 'sm',
          color: 'body-primary',
          fontSize: 'sm',
          maxWidth: '3xl',
        }}
        arrowColor="white"
        onClickOutside={handleClose}
        portalTarget={document.body}
        withPortal
        hasArrow={false}
      >
        <Box
          as="button"
          fontSize="sm"
          type="button"
          childGap="2xs"
          cursor="pointer"
          direction="row"
          alignItems="center"
          onClick={handleToggle}
          borderWidth="0"
          background="primary"
          color="body-primary"
          padding={{ base: 'xs sm', desktop: 'sm' }}
          radius="md"
          hover={{
            borderColor: 'default',
            color: 'contrast',
          }}
        >
          <Box
            overflow="hidden"
            display="block"
            style={{ textOverflow: 'ellipsis', minWidth: '0', whiteSpace: 'nowrap' }}
          >
            {displayUserName}
          </Box>
          <Icon name="caret-sm-down" />
        </Box>
      </Popover>
    </Box>
  );
}
