import { Box, FormikSelectInputNative, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, FormikErrors, useFormikContext } from 'formik';
import { getStateOptions } from '../../helpers/getStateOptions';
import capitalize from 'lodash/capitalize';
import FormikAddressInput from './FormikAddressInput';
import { AddressFieldNames, setAddressDetails } from '../../helpers/setAddressDetails';

type SetFieldValue = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined,
) => Promise<void | FormikErrors<unknown>>;
const concatWithPrefix = (prefix: string | undefined, nameOrId: string) => {
  return prefix ? prefix + capitalize(nameOrId) : nameOrId;
};

export const AddressFormFields = ({
  isSubmitting,
  prefix,
  placeHolderId,
  showLatLon = false,
  showAddress2 = false,
  isAddressRequired = true,
  disabledFields = [],
  includePR = false,
}: {
  placeHolderId: string;
  isSubmitting: boolean;
  prefix?: string | undefined;
  showLatLon?: boolean;
  showAddress2?: boolean;
  isAddressRequired?: boolean;
  disabledFields?: string[];
  includePR?: boolean;
}) => {
  const { setFieldValue, values }: { setFieldValue: SetFieldValue; values: any } = useFormikContext();
  const address1FieldName = concatWithPrefix(prefix, 'address1');
  const address2FieldName = concatWithPrefix(prefix, 'address2');
  const cityFieldName = concatWithPrefix(prefix, 'city');
  const stateFieldName = concatWithPrefix(prefix, 'state');
  const zipFieldName = concatWithPrefix(prefix, 'zip');
  const latFieldName = concatWithPrefix(prefix, 'lat');
  const lonFieldName = concatWithPrefix(prefix, 'lon');

  const addressFieldNames: AddressFieldNames = {
    address1FieldName,
    address2FieldName,
    cityFieldName,
    stateFieldName,
    zipFieldName,
    latFieldName,
    lonFieldName,
  };
  return (
    <Box childGap={{ base: 'lg', desktop: 'xl' }}>
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
      >
        <Field
          type="text"
          label={showAddress2 ? 'Address 1' : 'Address'}
          name={address1FieldName}
          id={address1FieldName}
          component={FormikAddressInput}
          onSelect={(_data: string, placeId: string) => setAddressDetails(placeId, setFieldValue, addressFieldNames)}
          onChange={(data: string) => setFieldValue(address1FieldName, data)}
          isRequired={isAddressRequired}
          autoComplete="off"
          isDisabled={isSubmitting || disabledFields.includes(address1FieldName)}
          value={values ? (values[address1FieldName] as string) : ''}
          placeHolderId={placeHolderId}
        />
        {showAddress2 && (
          <Field
            type="text"
            label="Address 2"
            name={address2FieldName}
            id={address2FieldName}
            component={FormikTextInput}
            autoComplete="off"
            isDisabled={isSubmitting || disabledFields.includes(address2FieldName)}
          />
        )}
      </Box>
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
      >
        <Field
          type="text"
          label="City"
          name={cityFieldName}
          id={cityFieldName}
          component={FormikTextInput}
          isRequired={isAddressRequired}
          autoComplete="off"
          isDisabled={isSubmitting || disabledFields.includes(cityFieldName)}
        />
        <Field
          type="text"
          label="State"
          name={stateFieldName}
          id={stateFieldName}
          options={getStateOptions(includePR)}
          component={FormikSelectInputNative}
          isRequired={isAddressRequired}
          autoComplete="off"
          isDisabled={isSubmitting || disabledFields.includes(stateFieldName)}
        />
        <Field
          maxLength={5}
          type="text"
          label="Zip Code"
          name={zipFieldName}
          id={zipFieldName}
          component={FormikTextInput}
          isRequired={isAddressRequired}
          autoComplete="off"
          isDisabled={isSubmitting || disabledFields.includes(zipFieldName)}
        />
      </Box>
      {showLatLon && (
        <Box
          direction={{
            base: 'column',
            tablet: 'row',
          }}
          childGap={{ base: 'lg', desktop: 'xl' }}
        >
          <Field
            type="number"
            label="Latitude"
            name={latFieldName}
            id={latFieldName}
            component={FormikTextInput}
            autoComplete="off"
            isDisabled={isSubmitting || disabledFields.includes(latFieldName)}
          />
          <Field
            type="number"
            label="Longitude"
            name={lonFieldName}
            id={lonFieldName}
            component={FormikTextInput}
            autoComplete="off"
            isDisabled={isSubmitting || disabledFields.includes(lonFieldName)}
          />
        </Box>
      )}
    </Box>
  );
};
