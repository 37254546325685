import {
  Box,
  Button,
  FormikSelectInputNative,
  FormikTextInput,
  Modal,
  SelectInput,
  toast,
} from '@palmetto/palmetto-components';
import { Form } from 'react-router-dom';
import * as yup from 'yup';
import { getStateOptions } from '../../helpers/getStateOptions';
import { Field, Formik } from 'formik';
import { ProgramType, ProgramTypeLabels, UtilityDocument } from 'types';
import { useCreateUtilityMutation } from '../../services/utilities';
import { isErrorWithData } from '../../services/helpers';

const marketSchema = yup.object().shape({
  state: yup.string().required('State is a required field.'),
  name: yup.string().required('Utility Name is a required field.'),
  lseId: yup.number().required('LSE ID is a required field.'),
  contractType: yup.string().required('Contract Type is a required field.'),
  programTypes: yup.array().required('Program Types is a required field.'),
});

const initialValues: { name: string; state: string; contractType: string; lseId: any; programTypes: string[] } = {
  name: '',
  state: '',
  contractType: '',
  lseId: null,
  programTypes: [],
};

export type AddMarketSchema = yup.InferType<typeof marketSchema>;
export const AddMarketModal = ({ isOpen, handleClose }: { isOpen: boolean; handleClose: () => void }) => {
  const [createUtility] = useCreateUtilityMutation();
  const handleSubmit = async (values: AddMarketSchema) => {
    try {
      // an active field will be added to the form later
      await createUtility({
        marketData: { ...values, programTypes: values.programTypes.map((v: any) => v.value), status: 'active' },
      }).unwrap();
      toast.success('Market created');
      handleClose();
    } catch (e) {
      if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        console.error(e);
        toast.error('Error creating market');
      }
    }
  };

  return (
    <Formik
      validationSchema={marketSchema}
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ errors, handleBlur, isSubmitting, resetForm, setFieldValue, submitForm, touched, values }) => (
        <Form noValidate>
          <Modal
            isOpen={isOpen}
            maxWidth="4xl"
            ariaLabelledBy="addMarketHeader"
            onDismiss={() => {
              resetForm();
              handleClose();
            }}
          >
            <Modal.Header
              id="addMarketHeader"
              title="Add Market"
              onDismiss={() => {
                resetForm();
                handleClose();
              }}
            />
            <Modal.Body background="secondary" childGap="lg">
              <Box childGap={{ base: 'lg', desktop: 'xl' }}>
                <SelectInput
                  id="programTypes"
                  name="programTypes"
                  label="Program Types"
                  value={values.programTypes}
                  onChange={(event) => setFieldValue('programTypes', event.target.value)}
                  options={Object.values(ProgramType).map((programType) => ({
                    label: ProgramTypeLabels[programType],
                    value: programType,
                  }))}
                  onBlur={handleBlur}
                  isMulti
                  isClearable
                  isRequired
                  error={touched.programTypes && (errors.programTypes as string)}
                />

                <Field
                  type="text"
                  label="State"
                  name="state"
                  id="state"
                  options={getStateOptions(true)}
                  component={FormikSelectInputNative}
                  isRequired
                />

                <Field
                  type="text"
                  label="Utility Name"
                  name="name"
                  id="name"
                  component={FormikTextInput}
                  isRequired
                  placeholder="eg. Pacific Gas & Electric"
                />

                <Field
                  maxLength={5}
                  type="number"
                  label="Load Serving Entity (LSE) ID"
                  name="lseId"
                  id="lseId"
                  component={FormikTextInput}
                  isRequired
                  placeholder="eg. 734"
                />

                <Field
                  type="text"
                  label="Contract Type"
                  name="contractType"
                  id="contractType"
                  options={[
                    { value: 'ppa', label: 'PPA' },
                    { value: 'lease', label: 'Lease' },
                  ]}
                  component={FormikSelectInputNative}
                  isRequired
                />
              </Box>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                tone="neutral"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                type="submit"
                onClick={submitForm}
              >
                Add Market
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
