type ErrorWithData = {
  data: {
    errorCode: string;
    message: string;
    requestId: string;
  };
};

type ErrorWithErrors = {
  data: {
    errorCode: string;
    message: string;
    requestId: string;
    errors: string[];
  };
};

export function isErrorWithData(error: unknown): error is ErrorWithData {
  return (
    typeof error === 'object' && error != null && 'data' in error && typeof (error.data as any).message === 'string'
  );
}

export function isErrorWithErrors(error: unknown): error is ErrorWithErrors {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    typeof (error.data as any).message === 'string' &&
    typeof (error.data as any).errors === 'object'
  );
}
