import React from 'react'
import { Box, Icon } from '@palmetto/palmetto-components';
import { camelCaseToTitleCase } from '../../../helpers/camelCaseToTitleCase';
import { normalizeDiffDisplayValue } from '../../../helpers/normalizeDiffDisplayValue';

export const DiffValue = ({ property, from, to, hideInitialNulls }: { property: string; from: string; to: string, hideInitialNulls?: boolean }) => {
  const normalizedFrom = normalizeDiffDisplayValue(from);
  const normalizedTo = normalizeDiffDisplayValue(to);
  const emptyFrom = ['undefined', 'null'].includes(normalizedFrom);
  const emptyTo = ['undefined', 'null'].includes(normalizedTo);

  if (emptyFrom && emptyTo) {
    return null;
  }

  const fromToDisplay = typeof normalizedFrom === 'object' && !Array.isArray(normalizedFrom) && normalizedFrom !== null ? JSON.stringify(normalizedFrom, null, 2) : normalizedFrom;
  const toToDisplay = typeof normalizedTo === 'object' && !Array.isArray(normalizedTo) && normalizedTo !== null ? JSON.stringify(normalizedTo, null, 2) : normalizedTo;
  const hideInitial = hideInitialNulls && ((fromToDisplay == null) || (fromToDisplay === 'null'))

  return (
    <>
      <Box display='inline-block' fontWeight='bold' margin='0 0 2xs 0'>
        {camelCaseToTitleCase(property)}
      </Box>
      <Box direction='row' alignItems='center' childGap='xs' margin='0 0 xs 0'>
        {
          !hideInitial && <Box>
            {fromToDisplay}
          </Box>
        }
        {
          !hideInitial && <Box>
            <Icon name='arrow-right' />
          </Box>
        }
        <Box>
          {toToDisplay}
        </Box>
      </Box>
    </>
  )
};

function canSkip(value1: any, value2: any) {
  const avoidableValues = [undefined, "undefined", null];
  return avoidableValues.includes(value1) && avoidableValues.includes(value2);
}

function DiffObject({
  diff,
  key,
  hiddenProperties,
  trailingKeys,
  hideInitialNulls
}: {
  diff: any,
  key: string,
  hiddenProperties: string[],
  trailingKeys: string[],
  hideInitialNulls?: boolean
}) {
  const elementKeys = Object.keys(diff);
  let subElements: any[] = [];
  const fullPath = trailingKeys.join(".");
  if (hiddenProperties.includes(fullPath)) {
    return null;
  }
  subElements = elementKeys.map((subElementKey) => {
    const fullPathv2 = [...trailingKeys, subElementKey].join(".");
    if (hiddenProperties.includes(fullPathv2)) {
      return null;
    }
    const subElementKeys = Object.keys(diff[subElementKey]);
    if (subElementKeys.includes('from') || subElementKeys.includes('to')) {
      return <DiffValue key={subElementKey} property={subElementKey} from={diff[subElementKey]['from']} to={diff[subElementKey]['to']} />
    }
    return DiffObject({
      diff: diff[subElementKey],
      key: subElementKey,
      hiddenProperties,
      trailingKeys: [...trailingKeys, subElementKey],
      hideInitialNulls
    });
  });
  return <Box key={key} childGap='xs'>
    <Box fontWeight='bold'>{camelCaseToTitleCase(key)}</Box>
    <Box padding='0 0 0 md'>
      {
        subElements
      }
    </Box>
  </Box>
}

export const Diffs = ({
  diff,
  hiddenProperties,
  hideInitialNulls
}: {
  diff: any,
  hiddenProperties: string[]
  hideInitialNulls?: boolean
}): any => {
  const keys = diff ? Object.keys(diff) : [];
  if (Array.isArray(diff)) {
    return diff.map(element => Diffs({ diff: element, hiddenProperties, hideInitialNulls }));
  }
  const elements: any[] = [];
  keys.forEach(key => {
    if (hiddenProperties.includes(key)) {
      return;
    }
    const diffObject = diff[key];
    if (Array.isArray(diffObject)) {
      const subElements = diffObject.map(element => Diffs({ diff: element, hiddenProperties, hideInitialNulls }));
      elements.push(
        <Box key={key} childGap='xs'>
          <Box fontWeight='bold'>{camelCaseToTitleCase(key)}</Box>
          <Box padding='0 0 0 md'>
            {subElements.map((item: any, index: number) => {
              if (Array.isArray(item) && item.length === 0) {
                return null;
              }
              return <Box key={`${key}_${index}`}>
                <Box fontWeight='bold' padding={'xs 0 0 0'}>{camelCaseToTitleCase(key)} #{index + 1}</Box>
                {item}
              </Box>
            })}
          </Box>
        </Box>
      )
    } else {
      const diffObjectKeys = Object.keys(diffObject || {});
      if (diffObjectKeys.includes('from') || diffObjectKeys.includes('to')) {
        const from = normalizeDiffDisplayValue(diffObject['from']), to = normalizeDiffDisplayValue(diffObject['to']);
        if (canSkip(diffObject['from'], diffObject['to'])) {
          return;
        }
        elements.push(
          <Box key={key} childGap='xs'>
            <DiffValue hideInitialNulls={hideInitialNulls} key={key} property={key} from={from} to={to} />
          </Box>
        )
      } else {
        if (key === 'from' || key === 'to') {
          elements.push(
            <Box key={key} childGap='xs'>
              <DiffValue hideInitialNulls={hideInitialNulls} key={key} property={''} from={key === 'from' ? diffObject : null} to={key === 'to' ? diffObject : null} />
            </Box>
          )
        } else {
          elements.push(DiffObject({
            diff: diffObject,
            key,
            hiddenProperties,
            trailingKeys: [key],
            hideInitialNulls
          }))
        }
      }
    }
  });
  return elements;
};