import { useAuth } from './authProvider';
import { ReactNode } from 'react';

const palmettoFinanceOrgAlias = 'sabal';

export const isPalmettoFinanceUser = (claims: any) =>
	claims?.org === palmettoFinanceOrgAlias || claims?.rootOrg === palmettoFinanceOrgAlias;

export const HiddenLightReachInfoForEpcUser = ({ children, fallbackChildren }: { children: ReactNode, fallbackChildren?: ReactNode }) => {
	const { claims } = useAuth();
	const show = isPalmettoFinanceUser(claims);
	return show ? <>{children}</> : fallbackChildren ? <>{fallbackChildren}</> : null;
}
