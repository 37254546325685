import FormSection from '../Forms/FormSection';
import { Box } from '@palmetto/palmetto-components';
import { ActivationDocumentTypes } from 'types';
import { DocumentList } from '../MilestonePackagePartials/Documents/DocumentList';

type ExtendedWarrantyProps = {
  originContext?: string;
};

export const ExtendedWarranty = ({originContext}: ExtendedWarrantyProps) => {
  const ExtendedWarrantyDescription = (
    <Box color="body-secondary" fontSize="sm" childGap="2xs" margin="sm 0 0 0">
      <Box as="p">Extended warranty purchased for inverter(s) without standard 25 year warranty.</Box>
      <Box as="p">Receipt or confirmation specific to installed inverters</Box>
    </Box>
  );
  return (
    <FormSection title="Extended Warranty" description={ExtendedWarrantyDescription}>
      <DocumentList
        documentTypeFilter={[ActivationDocumentTypes.extendedWarranty]}
        title="Proof of Extended Warranty"
        showTypeLabelOnItems={false}
        baseCategory={'Extended Warranty'}
        uploadContext={originContext}
      />
    </FormSection>
  );
};
