import { DeepPartial, InstallHVACPackage, InstallPackage } from 'types';
import { api } from './api';

export const installPackageApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveInstallHVACPackage: build.mutation<
      any,
      { accountId: string; installPackage: DeepPartial<InstallHVACPackage> }
    >({
      query: ({ accountId, installPackage }) => ({
        url: `v2/accounts/${accountId}/install-package/hvac/save`,
        method: 'POST',
        body: { ...installPackage },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'SystemDesign', accountId: arg?.accountId }],
    }),
    submitInstallHVACPackage: build.mutation<any, { accountId: string; installPackage: InstallHVACPackage }>({
      query: ({ accountId, installPackage }) => ({
        url: `v2/accounts/${accountId}/install-package/hvac/submit`,
        method: 'POST',
        body: { ...installPackage },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'SystemDesign', accountId: arg?.accountId }],
    }),
  }),
});

export const { useSaveInstallHVACPackageMutation, useSubmitInstallHVACPackageMutation } = installPackageApi;
