import { isNumber } from 'lodash';
import { AccountDocument, ProgramType, SystemDesign } from 'types';

export const setPvSystemInitialValues = (
  account: AccountDocument<ProgramType.solar | ProgramType.doePr>,
): SystemDesign => {
  const { systemDesign } = account;
  const initialValues = {
    systemSizeKw: systemDesign?.systemSizeKw ?? '',
    systemFirstYearProductionKwh: systemDesign?.systemFirstYearProductionKwh ?? '',
    totalPanelCount: systemDesign?.totalPanelCount || 0,
    panelManufacturer: systemDesign?.panelManufacturer ?? '',
    panelModel: systemDesign?.panelModel ?? '',
    inverterModels: systemDesign?.inverters?.length
      ? systemDesign.inverters.map((inverter) => {
          return { model: inverter.model, count: inverter.count };
        })
      : [{}],
    inverterManufacturer: systemDesign?.inverters?.[0]?.manufacturer ?? '',
    mountingType: systemDesign?.mountingType ?? '',
    mountingManufacturer: systemDesign?.mountingManufacturer ?? '',
    arrays: systemDesign?.arrays
      ? systemDesign.arrays.map((array) => ({
          ...array,
          monthlySolarAccess: array.monthlySolarAccess?.map((item) => item.accessPercent),
          inverter: isNumber(array.inverter) ? array.inverter + 1 : array.inverter,
        }))
      : [],
    firstYearMonthlyProductionKwh:
      systemDesign?.firstYearMonthlyProductionKwh?.map((item: any) => item.productionKwh as number) ?? [],
  };
  return initialValues as unknown as SystemDesign;
};
