import { Box } from '@palmetto/palmetto-components';
interface SidebarProps {
  title?: string;
  children?: React.ReactNode;
}

export const Sidebar: React.FC<SidebarProps> = ({ title, children }) => {
  return (
    <Box width={{ base: '100', desktop: '30' }}>
      <Box childGap="lg" padding={{ base: 'lg', desktop: 'xl' }} background="primary" height="100">
        {title ? (
          <Box as="h2" fontWeight="medium" fontSize="lg">
            {title}
          </Box>
        ) : null}
        {children}
      </Box>
    </Box>
  );
};
