import React, { ReactNode } from 'react';
import { Box, Button, Icon, useBreakpoint } from '@palmetto/palmetto-components';

type AppliedFiltersContainerProps = {
  children: ReactNode;
  onClearFilters: () => void;
};

export const AppliedFiltersList = ({ children, onClearFilters }: AppliedFiltersContainerProps) => {
  const { isPhone, isTablet } = useBreakpoint();
  const isMobile = isPhone || isTablet;

  return (
    <Box direction={{ base: 'column', desktop: 'row' }} alignItems={{ base: 'flex-start', desktop: 'center' }}>
      <Box
        direction="row"
        gap="sm"
        alignItems="center"
        justifyContent="flex-start"
        fontSize="sm"
        padding={{ base: 'md lg', desktop: '0' }}
        wrap
      >
        {children}
        {!isMobile && (
          <Button size="sm" iconSuffix="c-remove" variant="tertiary" tone="neutral" onClick={onClearFilters}>
            clear all filters
          </Button>
        )}
      </Box>
      {isMobile && (
        <Box minWidth="xl" display="block"  padding={{ base: '0 lg md', desktop: '0' }}>
          <Box as="button"
            padding="0"
            color="body-secondary"
            fontSize="sm"
            fontWeight="bold"
            direction="row"
            alignItems="center"
            margin="0 0 sm 0"
            background="transparent"
            borderWidth="0"
            onClick={onClearFilters}
          >
            clear all filters <Icon name="c-remove" className="m-left-xs" />
          </Box>
        </Box>
      )}
    </Box>
  );
};
