import { Alert, Box, Button, useBreakpoint } from '@palmetto/palmetto-components';
import { CommonFormSection } from '../ProductionValueCheck/Form';
import { formatKWHNumber } from '../../helpers/kwhFormatter';
import { percentageFormatter } from '../../helpers/percentageFormatter';
import { FlagBox } from '../QueueFlags/FlagBox';
import { ContractKwhInfoDescription } from './InstallationFormSectionDescriptions';
import FormSection from '../Forms/FormSection';
import { ToleranceException } from 'types';
import { useNavigate, useParams } from 'react-router-dom';
import { Origin } from '../../types/ProductionValueCheck';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import { isProductionWithinChangeToleranceFE } from '../../helpers/productionValueCheck';
import { ReviewPackageActions } from '../../types/ReviewPackage';

type ContractKwhInfoProps = {
  firstYearContracted: number;
  firstYearInstalled: number;
  toleranceException?: ToleranceException;
  outOfRange: boolean;
  hideFlags?: boolean;
};
export const ContractKwhInfo = ({
  firstYearContracted,
  firstYearInstalled,
  toleranceException,
  outOfRange,
  hideFlags = false,
}: ContractKwhInfoProps) => {
  const navigate = useNavigate();
  const { id: accountId, action } = useParams<{ id: any; action: ReviewPackageActions }>();
  const { isPhone, isTablet } = useBreakpoint();
  const isMobile = isPhone || isTablet;
  let percentDiff: number = 0;
  if (firstYearContracted !== 0) {
    const diffResults = isProductionWithinChangeToleranceFE({
      newProductionKwh: firstYearInstalled,
      originalProductionKwh: firstYearContracted,
    });
    percentDiff =
      Number(formatKWHNumber(firstYearInstalled)) < Number(formatKWHNumber(firstYearContracted))
        ? diffResults.percentDifference * -1
        : diffResults.percentDifference;
  } else {
    percentDiff = 1;
  }

  const handleOnClick = () => {
    navigate(`/accounts/${accountId}/production-value-check`, { state: { from: Origin.INSTALL_PACKAGE, action } });
  };

  return (
    <>
      <CommonFormSection title="Contracted Year-1 Total Production Estimate">
        <Box fontWeight={'medium'} fontSize={'lg'}>
          {formatKWHNumber(firstYearContracted)} kWh
        </Box>
      </CommonFormSection>
      <CommonFormSection title="Installed Year-1 Total Production Estimate">
        <Box fontWeight={'medium'} fontSize={'lg'} display="flex" direction="row" height="" gap="xs">
          {formatKWHNumber(firstYearInstalled)} kWh{' '}
          <Button variant="primary" tone="neutral" size="xs" onClick={handleOnClick}>
            edit
          </Button>
        </Box>
      </CommonFormSection>
      <FormSection title="Difference" description={ContractKwhInfoDescription} className="border-out">
        <Box
          fontWeight={'medium'}
          fontSize={'lg'}
          display={{ base: 'flex' }}
          direction={{ base: 'column', tablet: 'row' }}
        >
          <Box margin="xl 0 0 0" color={outOfRange && !toleranceException ? 'danger' : 'body-primary'}>
            {percentageFormatter.format(percentDiff)}
          </Box>
          <Box margin="0 0 0 xl">
            {toleranceException && (
              <Alert
                variant="warning"
                hasIcon
                title="Production Tolerance Exception"
                message={
                  <RequirePalmettoFinanceUser
                    children={`${toleranceException.note} - ${toleranceException.exceptionGivenBy}`}
                  />
                }
              />
            )}
          </Box>
        </Box>
      </FormSection>
      {!hideFlags && (
        <Box style={{ marginLeft: isMobile ? '' : '18rem' }}>
          <FlagBox dataKey="Year-1 Production Estimate" />
        </Box>
      )}
    </>
  );
};
