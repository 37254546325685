import { Box, Icon, useBreakpoint } from '@palmetto/palmetto-components';
import { SkeletonBox } from '../SkeletonBox';

interface AccountsListLoadingProps {
  rows: number;
}

export function FundAccountsListLoading({ rows }: AccountsListLoadingProps): JSX.Element {
  const { isPhone } = useBreakpoint();

  const displayRows = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rows; i++) {
    displayRows.push(
      <Box borderWidth="0 0 xs 0" borderColor="separator" direction="row" padding="0" key={`loading-row-${i}`}>
        <Box
          childGap="sm"
          alignItems="center"
          justifyContent="center"
          padding="lg"
          width="150px"
          minWidth="150px"
          color="disabled"
          borderColor="separator"
          borderWidth="0 xs 0 xs"
        >
          <SkeletonBox height="10px" width="100px" />
          <SkeletonBox height="14px" width="90px" />
          <Box direction="row" margin="2xs 0 0 0" childGap="xs">
            <Icon name="circle" size="md" />
            <Icon name="circle" size="md" />
            <Icon name="circle" size="md" />
          </Box>
        </Box>
        <Box flex="auto" minWidth="150px" childGap="xs" padding="lg">
          <SkeletonBox height="12px" width="130px" />
          <SkeletonBox height="8px" width="120px" />
          <SkeletonBox height="8px" width="100px" />
          <SkeletonBox height="8px" width="160px" />
        </Box>
        <Box minWidth="160px" padding="lg">
          <SkeletonBox height="12px" width="70px" />
        </Box>
        <Box width="172px" padding="lg">
          <SkeletonBox height="12px" width="50px" />
        </Box>
        <Box
          width="165px"
          wrap
          style={{ gap: 'var(--size-spacing-2xs)' }}
          direction="row"
          padding="lg"
          alignItems="flex-start"
        >
          <SkeletonBox height="12px" width="82px" />
        </Box>
      </Box>,
    );
  }

  return (
    <>
      <Box
        direction="row"
        borderWidth="0 0 xs 0"
        borderColor="separator"
        height="51px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box width="150px" minWidth="150px" padding="lg" />
        <Box padding="lg" flex="auto" minWidth="150px">
          <SkeletonBox height="12px" width="120px" />
        </Box>
        {!isPhone && (
          <>
            <Box padding="lg" minWidth="160px">
              <SkeletonBox height="12px" width="70px" />
            </Box>
            <Box padding="lg" width="172px">
              <SkeletonBox height="12px" width="80px" />
            </Box>
            <Box padding="lg" width="165px">
              <SkeletonBox height="12px" width="60px" />
            </Box>
          </>
        )}
      </Box>
      {displayRows}
    </>
  );
}
