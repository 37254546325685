import { Box } from '@palmetto/palmetto-components';
import { AccountStipulationType } from 'types';
import { stipulationTypeToLabel } from '../../Account/PauseBanner';

import EventBy from './EventBy';

const AccountStipulation = ({ event }: { event: any }) => {
  const isSatisfied = event?.data?.isSatisfied as boolean;
  const type = event?.data?.stipulationType as AccountStipulationType;
  return (
    <Box display="block">
      <Box as="span" display="inline-block" margin="0 2xs 0 0" childGap="md">
        <EventBy event={event} /> {isSatisfied ? 'cleared' : 'flagged'} {stipulationTypeToLabel[type]?.toLowerCase()}
      </Box>
    </Box>
  );
};

export default AccountStipulation;
