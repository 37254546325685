import { Box, Modal } from '@palmetto/palmetto-components';

interface QuoteModalProps {
  isModalOpen: boolean;
  closeModal: any;
}

const QuoteModal = ({ isModalOpen, closeModal }: QuoteModalProps) => {
  return (
    <Modal ariaLabelledBy="titleBasic" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="4xl">
      <Modal.Header id="titleBasic" title="Quote Already Active" onDismiss={closeModal} />
      <Modal.Body>
        <Box as="p">
          This account already has an active or contracted quote. It must be voided before a new quote can be
          created.
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default QuoteModal;
