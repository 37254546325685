import FormSection from '../Forms/FormSection';
import { InstallerDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';
import { Box, FormikSelectInputNative, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { getStateOptions } from '../../helpers/getStateOptions';
import { AddressFieldNames, setAddressDetails } from '../../helpers/setAddressDetails';
import FormikAddressInput from '../AddressFormFields/FormikAddressInput';

const addressFieldNames: AddressFieldNames = {
  address1FieldName: 'address',
  address2FieldName: 'address2',
  cityFieldName: 'city',
  stateFieldName: 'installerState',
  zipFieldName: 'zip',
  latFieldName: 'lat',
  lonFieldName: 'lon',
};
export const Installer = () => {
  const { isSubmitting, setFieldValue } = useFormikContext<any>();
  return (
    <FormSection description={InstallerDescription} title="Installer">
      <Box gap="md" direction={{ base: 'column', tablet: 'row' }} wrap>
        <Field
          id="legalName"
          component={FormikTextInput}
          autoComplete="off"
          label="Installer Legal Name"
          name="legalName"
          type="text"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl' }}
        />
        <Field
          id="iccDocketNumber"
          component={FormikTextInput}
          autoComplete="off"
          label="ICC Docket Number"
          name="iccDocketNumber"
          type="text"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl' }}
        />
        <Field
          id="qualifiedPersonOnsiteFirstName"
          component={FormikTextInput}
          autoComplete="off"
          label="Qualified Person Onsite First Name"
          name="qualifiedPersonOnsiteFirstName"
          type="text"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl' }}
        />
        <Field
          id="qualifiedPersonOnsiteLastName"
          component={FormikTextInput}
          autoComplete="off"
          label="Qualified Person Onsite Last Name"
          name="qualifiedPersonOnsiteLastName"
          type="text"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl' }}
        />
        <Field
          id="phoneNumber"
          component={FormikTextInput}
          autoComplete="off"
          label="Phone Number"
          name="phoneNumber"
          type="text"
          inputMask="phone"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl' }}
        />
        <Field
          id="email"
          component={FormikTextInput}
          autoComplete="off"
          label="Email"
          name="email"
          type="email"
          inputMask="email"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl' }}
        />
        <Field
          id={addressFieldNames.address1FieldName}
          component={FormikAddressInput}
          autoComplete="off"
          label="Address"
          name={addressFieldNames.address1FieldName}
          type="text"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl', hd: '100' }}
          onSelect={(_data: string, placeId: string) => setAddressDetails(placeId, setFieldValue, addressFieldNames)}
          onChange={(data: string) => setFieldValue(addressFieldNames.address1FieldName, data)}
        />
        <Field
          id={addressFieldNames.cityFieldName}
          component={FormikTextInput}
          autoComplete="off"
          label="City"
          name={addressFieldNames.cityFieldName}
          type="text"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl' }}
        />
        <Field
          label="State"
          name={addressFieldNames.stateFieldName}
          id={addressFieldNames.stateFieldName}
          type="text"
          component={FormikSelectInputNative}
          options={getStateOptions()}
          autoComplete="off"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl' }}
        />
      </Box>
    </FormSection>
  );
};
