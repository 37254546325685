import { Box, Button, CheckboxInput, Column, Table } from '@palmetto/palmetto-components';
import { Icon } from '@palmetto/palmetto-components';

interface AssignAccountsResultProps {
  results: {
    completedAccounts: any;
    invalidAccounts: any;
    accountsAlreadyAssigned: any;
    fundKey: string;
    trancheName: string;
  };
  accountsForReassign: string[];
  handleSelectedAccount: (data: { isSelected: boolean; accountId: string }) => void;
  onViewFundClick: () => void;
  onReassignAccountsClick: () => void;
  isReassigningAccounts: boolean;
}

interface StatusResultProps {
  icon: 'c-check' | 't-warning' | 'remove';
  color: 'success' | 'danger' | 'warning-500';
  text: string;
  accountsTable?: React.ReactNode;
}

interface AccountsAlreadyAssignedResult {
  accountId: string;
  trancheName: string;
}

const StatusResult = ({ icon, color, text, accountsTable }: StatusResultProps) => (
  <Box direction="row">
    <Box>
      <Icon name={icon} color={color} size="1xl" />
    </Box>
    <Box display="block" width="100" padding="xs 0 0 md" childGap="md">
      <Box as="h3" fontWeight="bold">
        {text}
      </Box>
      {accountsTable}
    </Box>
  </Box>
);

const normalizeAccountLabel = (length: number) => `${length} ${length === 1 ? 'account' : 'accounts'}`;

const invalidAccountsFoundTableColumnConfig: Column[] = [
  { heading: 'ACCOUNT ID', dataKey: 'accountId' },
  { heading: 'Reason', dataKey: 'reason' },
];

const accountsAlreadyAssignedTableColumnConfig = (
  accountsForReassign: string[],
  handleSelectedAccount: (data: { isSelected: boolean; accountId: string }) => void,
): Column[] => [
  {
    heading: '',
    dataKey: 'accountId',
    render: (accountId: any) => (
      <CheckboxInput
        id={accountId}
        label=""
        isChecked={accountsForReassign.includes(accountId)}
        onChange={(event) =>
          handleSelectedAccount({
            isSelected: event.target.checked,
            accountId,
          })
        }
      />
    ),
  },
  { heading: 'ACCOUNT ID', dataKey: 'accountId' },
  { heading: 'Current Tranche', dataKey: 'trancheName' },
  {
    heading: 'New Tranche',
    dataKey: 'newTrancheName',
    render: (newTrancheName: any, row: any) => {
      if (!!accountsForReassign.find((id) => id === row.accountId))
        return (
          <Box direction="row" childGap="xs">
            <Icon name="arrow-right" size="md" />
            <Box>{newTrancheName}</Box>
          </Box>
        );
      return null;
    },
  },
];

export function AssignAccountsResult({
  results,
  accountsForReassign,
  handleSelectedAccount,
  onViewFundClick,
  onReassignAccountsClick,
  isReassigningAccounts,
}: AssignAccountsResultProps) {
  const transformAccounts = (accounts: AccountsAlreadyAssignedResult[]) => {
    return accounts.map((account: AccountsAlreadyAssignedResult) => ({
      ...account,
      newTrancheName: results.trancheName,
    }));
  };

  return (
    <Box childGap={'xl'} padding={{ base: 'sm md', desktop: '0 0', tablet: '0 0' }}>
      {results.completedAccounts.length > 0 && (
        <Box>
          <StatusResult
            icon="c-check"
            color="success"
            text={`${normalizeAccountLabel(results.completedAccounts.length)} added to ${results.trancheName}`}
          />
        </Box>
      )}
      {results.invalidAccounts.length > 0 && (
        <Box>
          <StatusResult
            icon="remove"
            color="danger"
            text={`${normalizeAccountLabel(results.invalidAccounts.length)} failed`}
            accountsTable={
              <Table
                isScrollable={{
                  x: true,
                }}
                rowKey="accountId"
                columns={invalidAccountsFoundTableColumnConfig}
                rows={results.invalidAccounts}
              />
            }
          />
        </Box>
      )}
      {results.accountsAlreadyAssigned.length > 0 && (
        <Box>
          <StatusResult
            icon="t-warning"
            color="warning-500"
            text={`${normalizeAccountLabel(results.accountsAlreadyAssigned.length)} are already assigned to a tranche. Select accounts you wish to move.`}
            accountsTable={
              <Table
                isScrollable={{
                  x: true,
                }}
                rowKey="accountId"
                rows={transformAccounts(results.accountsAlreadyAssigned)}
                columns={accountsAlreadyAssignedTableColumnConfig(accountsForReassign, handleSelectedAccount)}
              />
            }
          />
        </Box>
      )}
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        alignItems={{
          base: 'stretch',
          tablet: 'flex-end',
        }}
        justifyContent={{
          tablet: 'flex-end',
        }}
        childGap="sm"
        style={{ flexShrink: 0 }}
      >
        <Button
          size="md"
          variant="secondary"
          tone="neutral"
          aria-label="view found"
          onClick={onViewFundClick}
          disabled={isReassigningAccounts}
        >
          View Fund
        </Button>

        {results.accountsAlreadyAssigned.length > 0 && (
          <Button
            isLoading={isReassigningAccounts}
            variant="primary"
            size="md"
            aria-label="reassign accounts"
            onClick={onReassignAccountsClick}
            disabled={!accountsForReassign.length}
          >
            Reassign Accounts
          </Button>
        )}
      </Box>
    </Box>
  );
}
