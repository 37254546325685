import { Box, Card } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { useGetApplicationsQuery } from '../../services/applications';
import { StatusIcon } from '../StatusIcon';
import { CreditApplications } from './CreditApplications';
import { IdentityVerification } from './IdentityVerification';
import { TitleVerification } from './TitleVerification';
import { useMemo } from 'react';
import { useGetAccountQuery } from '../../services/accounts';
import {
  Applicant,
  ApplicantType,
  ApplicationDocumentWithAdditionalApplicantData,
  FinancingDecision,
  MilestoneRequirement,
} from 'types';
import { TermsAndConditions } from './TermsAndConditions';

export function Underwriting() {
  const { id } = useParams<{ id: any }>();
  const { data: account } = useGetAccountQuery(id);
  const { data: applications = [] } = useGetApplicationsQuery({ accountId: id });

  const statuses = useMemo(
    () =>
      account?.milestones.reduce(
        (acc: any, milestone: any) => {
          function findReqStatus(requirements: any, type: MilestoneRequirement) {
            return requirements?.find((req: any) => req.type === type)?.status;
          }
          const underwritingReq = milestone.requirements.find((req: any) => req.type === 'underwriting');
          if (underwritingReq) {
            acc.underwriting = underwritingReq.status;
          }
          if (!acc.title) {
            acc.title = findReqStatus(
              underwritingReq ? underwritingReq.requirements : milestone?.requirements,
              MilestoneRequirement.titleApproved,
            );
          }
          if (!acc.identity) {
            acc.identity = findReqStatus(
              underwritingReq ? underwritingReq.requirements : milestone?.requirements,
              MilestoneRequirement.identityVerified,
            );
          }
          if (!acc.termsAndConditions) {
            acc.termsAndConditions = findReqStatus(
              underwritingReq ? underwritingReq.requirements : milestone?.requirements,
              MilestoneRequirement.acceptTermsAndConditions,
            );
          }

          if (!acc.credit) {
            acc.credit = findReqStatus(
              underwritingReq ? underwritingReq.requirements : milestone?.requirements,
              MilestoneRequirement.creditApproved,
            );
          }
          return acc;
        },
        {} as Record<any, any>,
      ),
    [account],
  );

  const approvedApplications = applications.filter(
    (application: ApplicationDocumentWithAdditionalApplicantData) =>
      application.status === FinancingDecision.approved ||
      application.status === FinancingDecision.approvedWithStipulations,
  );

  const firstApprovedApplication = approvedApplications[0];

  return (
    <Box>
      <Card>
        {statuses?.underwriting && (
          <Card.Section>
            <Box alignItems="center" direction="row">
              <Box padding="0 xs 0 0">
                <StatusIcon status={statuses?.underwriting} size="md" />
              </Box>
              <Box as="h3" fontWeight="medium" fontSize="md">
                Underwriting
              </Box>
            </Box>
          </Card.Section>
        )}
        {statuses?.credit && (
          <CreditApplications
            status={statuses?.credit}
            applications={applications}
            firstApprovedApplication={firstApprovedApplication}
          />
        )}
        {statuses?.termsAndConditions && (
          <TermsAndConditions
            status={statuses?.termsAndConditions}
            programType={account?.programType}
            primaryApplicant={account?.applicants?.find(
              (applicant: Applicant) => applicant?.type === ApplicantType.primary,
            )}
          />
        )}
        <IdentityVerification
          status={statuses?.identity}
          creditStatus={statuses?.credit}
          programType={account?.programType}
        />
        {statuses?.title && (
          <TitleVerification
            status={statuses?.title}
            application={firstApprovedApplication}
            creditStatus={statuses?.credit}
            programType={account?.programType}
          />
        )}
      </Card>
    </Box>
  );
}
