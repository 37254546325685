export const applyDocumentFilters = (searchParams: URLSearchParams, data: any[]) => {
  const documentTypeFilter = searchParams.get('documentType')?.split(',');
  if (!documentTypeFilter) {
    return data;
  }

  return data.filter((dataItem) => {
    return documentTypeFilter.some((documentType) => dataItem.description.includes(documentType));
  });
};
