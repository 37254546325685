import { Box } from '@palmetto/palmetto-components';

export function ApplicationSuccess() {
  return (
    <Box childGap={{ base: 'sm', desktop: 'md' }}>
      <Box as="h1" fontSize={{ base: 'lg', tablet: 'xl', desktop: 'xl' }} fontWeight="medium">Thank you for applying for a LightReach Energy Plan!</Box>
      <Box>Your no-impact credit check is complete and we have sent you an email with more information.</Box>
      <Box>Please work with your dedicated Energy Consultant on the next steps for your application.</Box>
      <Box flex='none'>If you have any questions, please reach out to <a style={{ color: 'green' }} href="mailto:help@golightreach.com">help@golightreach.com</a>.</Box>
    </Box>
  );
}
