import { Box } from '@palmetto/palmetto-components';

const ContractSigned = ({ event }: { event: any }) => {
  const signerEmails = event.data?.signers;
  return (
    <Box display="block">
      <Box as="span" display="inline-block" margin="0 2xs 0 0">
        Contract Signed {signerEmails && signerEmails.length > 0 ? ` by ${signerEmails.join(', ')}` : ''}
      </Box>
    </Box>
  );
};

export default ContractSigned;
