import { Box, FormikSelectInputNative } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { useGetPriceSheetsQuery, useLazyGetPriceSheetDetailsQuery } from '../../../../services/pricing';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { percentageFormatter } from '../../../../helpers/percentageFormatter';
import { kwhPriceFormatter } from '../../../../helpers/currencyFormatter';
import { ProgramType } from 'types';
import { useParams } from 'react-router-dom';

export interface PricingFormValues {
  priceSheet: string;
  escalationRateMin: string;
  escalationRateMax: string;
  pvOnlyMin: string;
  pvOnlyCeiling: string;
  zeroEscalatorCeiling: string;
  absoluteCeiling: string;
}

export const BulkEditPricingFormSection = () => {
  const { setFieldValue, isSubmitting, values } = useFormikContext<PricingFormValues>();
  const { programType } = useParams<{ programType: string }>();
  const { priceSheet } = values;
  const { data: priceSheetResults }: any = useGetPriceSheetsQuery({
    pageNum: 1,
    pageSize: 1000,
    programType: programType as ProgramType,
  });
  const [trigger, { data: priceSheetDetails = {} }] = useLazyGetPriceSheetDetailsQuery();

  useEffect(() => {
    if (priceSheetResults?.data?.length && priceSheet) {
      trigger({ id: priceSheet });
    }
  }, [priceSheet, priceSheetResults?.data, trigger]);

  const priceSheetOptions = useMemo(
    () =>
      priceSheetResults?.data?.map((priceSheet: any) => ({
        label: priceSheet.name,
        value: priceSheet.id,
      })) ?? [],
    [priceSheetResults?.data],
  );

  const kwhRateOptions = useMemo(() => {
    const kwhRates = new Set();
    priceSheetDetails?.prices?.forEach((p: any) => {
      kwhRates.add(p.kwhRate);
    });
    return Array.from(kwhRates)
      .sort()
      .map((rate: any) => ({ label: kwhPriceFormatter.format(rate), value: rate }));
  }, [priceSheetDetails?.prices]);

  const escRateOptions = useMemo(() => {
    const escRates = new Set();
    priceSheetDetails?.prices?.forEach((p: any) => {
      escRates.add(p.escalationRate);
    });
    return Array.from(escRates)
      .sort()
      .map((rate: any) => ({ label: percentageFormatter.format(rate), value: rate }));
  }, [priceSheetDetails?.prices]);

  const handlePriceSheetMappingSelection = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue('priceSheet', event.target.value);
      setFieldValue('escalationRateMin', '');
      setFieldValue('escalationRateMax', '');
      setFieldValue('pvOnlyMin', '');
      setFieldValue('pvOnlyCeiling', '');
      setFieldValue('zeroEscalatorCeiling', '');
      setFieldValue('absoluteCeiling', '');
      trigger({ id: event.target.value });
    },
    [setFieldValue, trigger],
  );

  return (
    <Box childGap="lg">
      <Field
        name="priceSheet"
        id="priceSheet"
        label="Price Sheet"
        component={FormikSelectInputNative}
        options={priceSheetOptions}
        onChange={handlePriceSheetMappingSelection}
        isRequired
      />
      <Box direction="row" childGap="lg">
        <Field
          name="escalationRateMin"
          id="escalationRateMin"
          label="Escalator Min"
          component={FormikSelectInputNative}
          options={escRateOptions}
          isDisabled={!escRateOptions.length || isSubmitting}
          isRequired
        />
        <Field
          name="escalationRateMax"
          id="escalationRateMax"
          label="Escalator Max"
          component={FormikSelectInputNative}
          options={escRateOptions}
          isDisabled={!escRateOptions.length || isSubmitting}
          isRequired
        />
      </Box>
      <Box direction="row" childGap="lg">
        <Field
          name="pvOnlyMin"
          id="pvOnlyMin"
          label="PV-Only Min"
          component={FormikSelectInputNative}
          options={kwhRateOptions}
          isDisabled={!kwhRateOptions.length || isSubmitting}
          isRequired
        />
        <Field
          name="pvOnlyCeiling"
          id="pvOnlyCeiling"
          label="PV-Only Max"
          component={FormikSelectInputNative}
          options={kwhRateOptions}
          isDisabled={!kwhRateOptions.length || isSubmitting}
          isRequired
        />
      </Box>
      <Box direction="row" childGap="lg">
        <Field
          name="zeroEscalatorCeiling"
          id="zeroEscalatorCeiling"
          label="Zero Esc Max"
          component={FormikSelectInputNative}
          options={kwhRateOptions}
          isDisabled={!kwhRateOptions.length || isSubmitting}
          isRequired
        />
        <Field
          name="absoluteCeiling"
          id="absoluteCeiling"
          label="Absolute Max"
          component={FormikSelectInputNative}
          options={kwhRateOptions}
          isDisabled={!kwhRateOptions.length || isSubmitting}
          isRequired
        />
      </Box>
    </Box>
  );
};
