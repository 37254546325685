import { isBoolean } from "lodash";

export function normalizeDiffDisplayValue(value: any) {
    if (typeof value === 'undefined') {
        return value;
    }
    if (Array.isArray(value)) {
        return JSON.stringify(value);
    }
    if (isBoolean(value)) {
        return JSON.stringify(value);
    }
    if (value === '') {
        return JSON.stringify(value);
    }
    if (value == null) {
        return 'null';
    }
    return value;
}