import { useCallback, useEffect, useState } from 'react';
import { Box, Column, Pagination, Table } from '@palmetto/palmetto-components';
import { useParams, useSearchParams } from 'react-router-dom';
import { AppliedDataFilter, ProgramType } from 'types';
import {
  useGetPriceSheetMappingsListFiltersQuery,
  useLazyGetPriceSheetMappingsQuery,
} from '../../../services/pricing';
import { FilterBar } from '../../filters';
import { TableLoading } from '../../TableLoading';
import './mappings.css';
import { AddFilterButtonDropdown } from '../../filters/AddFilterButton';

interface FilteredMappingsListProps {
  columnConfig: Column[];
}

export const FilteredMappingsList = ({ columnConfig }: FilteredMappingsListProps) => {
  const { programType = ProgramType.solar } = useParams<{ programType: string }>();
  const [trigger, { data: mappingResponse, isLoading, isFetching }] = useLazyGetPriceSheetMappingsQuery({});
  const { data: filters = [], isLoading: isFiltersLoading } = useGetPriceSheetMappingsListFiltersQuery({
    programType: programType as ProgramType,
  });
  const { data, total } = mappingResponse || { data: [], total: 0 };
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('page');
  const advancedFilters = searchParams.get('advancedFilters');
  const searchTerm = searchParams.get('search') || undefined;
  const [appliedFilters, setAppliedFilters] = useState<Array<any>>([]);

  useEffect(() => {
    const pageNum = currentPage ? Number(currentPage) : 1;
    const filters = advancedFilters || JSON.stringify([]);

    setAppliedFilters(JSON.parse(filters));
    trigger({
      pageNum,
      advancedFilters: filters,
      searchTerm,
      programType: programType as ProgramType,
    });
  }, [trigger, currentPage, advancedFilters, searchTerm, programType]);

  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      searchParams.delete('page');
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );

  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      searchParams.delete('page');
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );

  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    searchParams.delete('page');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);

  const onPageChange = (newPage: number) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const onSearchChange = (searchTerm: string) => {
    if (searchTerm && searchTerm !== '') {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <>
      <Box margin="0 lg 0 lg">
        <FilterBar
          filtersState={{
            searchTerm,
          }}
          handleSearchTerm={(searchTerm: string) => onSearchChange(searchTerm)}
          placeholder="Search by org, state, utility"
        >
          <Box direction="row" style={{ flexGrow: 1 }}>
            {!isFiltersLoading && (
              <AddFilterButtonDropdown
                dataFilters={filters}
                appliedFilters={appliedFilters}
                onRemoveFilter={handleRemoveAdvancedFilter}
                onApplyFilters={handleApplyAdvancedFilters}
                onClearFilters={handleClearAdvancedFilters}
              />
            )}
          </Box>
        </FilterBar>
        {isFetching || isLoading ? (
          <TableLoading rows={12} />
        ) : (
          <Table
            rowKey="id"
            columns={columnConfig as Column[]}
            rows={data || []}
            isLoading={isLoading || isFetching}
            isScrollable={{ x: true }}
            hasStickyHeader
          />
        )}
      </Box>

      {data?.length > 0 && (
        <Pagination
          activePage={Number(currentPage) || 1}
          arePagesVisible
          itemsPerPage={20}
          numberOfPagesDisplayed={3}
          onChange={(page) => onPageChange(page)}
          totalItemsCount={total ?? 0}
          isCompact
        />
      )}
    </>
  );
};
