import { Box, toast } from '@palmetto/palmetto-components';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useCreatePriceSheetMappingMutation } from '../../../services/pricing';
import { isErrorWithErrors } from '../../../services/helpers';
import { Helmet } from 'react-helmet';
import { MainContainer } from '../../MainContainer';
import { ProgramType } from 'types';
import { MappingFormFactory } from './MappingFormFactory';

export const CreateMapping = () => {
  const navigate = useNavigate();
  const [createPriceSheetMapping] = useCreatePriceSheetMappingMutation();
  const { programType = ProgramType.solar } = useParams<{ programType: ProgramType }>();

  const handleSubmit = async (data: any) => {
    try {
      await createPriceSheetMapping({ data, programType }).unwrap();
      toast.success('Mapping created successfully');
      navigate(`/admin/pricing/${programType}`);
    } catch (e) {
      if (isErrorWithErrors(e)) {
        const errorMessage = e.data.message;
        toast.error(`${errorMessage}: ${e.data.errors.map((e) => e).join(',')}`);
      } else {
        console.error(e);
        toast.error('Error creating mapping');
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Price Mapping</title>
      </Helmet>
      <MainContainer maxWidth="100">
        <Box style={{ marginLeft: '0' }}>
          <NavLink to={`/admin/pricing/${programType}`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Pricing
              </Box>
            )}
          </NavLink>
        </Box>
        <Box fontSize="2xl" margin="0 0 xl 0">
          Create Price Mapping
        </Box>
        <MappingFormFactory handleSubmit={handleSubmit} programType={programType} />
      </MainContainer>
    </>
  );
};
