import { ObjectId } from 'mongodb';
import { FinanceType } from './ProductPricing';
import { Actor } from './UserTypes';
import { MilestoneRequirement } from './Milestones';
import { EnergyCommunityModifier, Holdback, PpwModifier } from './PriceSheetMapping';
import { PriceSheetStatus } from './PriceSheets';
import { ProgramType } from '.';

export enum OrganizationSettingsTypes {
  authorizedSigners = 'authorizedSigners',
  contractSettings = 'contractSettings',
  sendContractSettings = 'sendContractSettings',
  customQuoteSettings = 'customQuoteSettings',
  emailSettings = 'emailSettings',
  energyCommunityModifierSettings = 'energyCommunityModifierSettings',
  domesticContentModifierSettings = 'domesticContentModifierSettings',
  generalSettings = 'generalSettings',
  /**
   * @deprecated
   */
  globalPPWModifierSettings = 'globalPPWModifierSettings',
  holdbackSettings = 'holdbackSettings',
  milestonesSettings = 'milestonesSettings',
  paymentSettings = 'paymentSettings',
  ppwModifierSettings = 'ppwModifierSettings',
  pricingSettings = 'pricingSettings',
}

export enum OrganizationEmailSettingCategories {
  programUpdates = 'programUpdates',
  ntpMilestone = 'ntpMilestone',
  consumerAgreementMilestone = 'consumerAgreementMilestone',
  initialAccountDetailsMilestone = 'initialAccountDetailsMilestone',
  installMilestone = 'installMilestone',
  activationMilestone = 'activationMilestone',
  postActivationMilestone = 'postActivationMilestone',
  accounting = 'accounting',
  sales = 'sales',
  compliance = 'compliance',
  crmContact = 'crmContact',
  servicePostPTO = 'servicePostPTO',
}

export interface BaseOrganizationSettings {
  id: ObjectId;
  organizationId: string;
  type: OrganizationSettingsTypes;
  programType?: ProgramType;
  value?: Record<string, any>;
}

export interface ContractSetting {
  state: string;
  type: FinanceType;
  requiresEpcLicenseNumbers: boolean;
  contractDocumentSettingsId: ObjectId;
}

export interface OrganizationContractSettingsDocument extends BaseOrganizationSettings {
  contractSettings: Array<ContractSetting>;
}

export enum RequirementCheckPolicy {
  All = 'all',
  Any = 'any',
}

export interface OrganizationSendContractSettingsDocument extends BaseOrganizationSettings {
  value: {
    checkPolicy: RequirementCheckPolicy;
    /** The requirements that must be complete to send a contract */
    milestoneRequirements: MilestoneRequirement[];
  };
}

export interface PricingSetting {
  state: string;
  lseId: number;
  utilityName: string;
  pvOnlyCeiling: number;
  arbitrageBattery: number;
  backupBattery: number;
  electricalUpgrade: number;
  roofUpgrade: number;
  adderCeiling: number; // TODO:  Do we really need this, or is the ceiling just the max kwhRate?
  zeroEscalatorCeiling?: number;
}

export const enum BankAccountTypes {
  checking = 'checking',
  savings = 'savings',
}
export type BankAccountType = keyof typeof BankAccountTypes;
export interface BankAccountRegistration {
  bankAccountNumber: string;
  bankRoutingNumber: string;
  bankAccountType: BankAccountType;
  email: string;
}

/**
 * Some of these fields are sensitive and should not be exposed to the client.
 */

export interface OutboundPaymentsBankAccount {
  bankName: string;
  bankAccountLast4: string;
  bankAccountId: string;
  bankAccountType: BankAccountType;
  createdAt: Date;
}

export interface PayoutHold {
  active: boolean;
  reason: string;
  createdDate: Date;
  createdBy: { id: string; username: string };
}
export type PayoutHoldHistory = Array<
  Omit<PayoutHold, 'active'> & {
    resolvedDate: Date;
    resolvedBy: {
      id: string;
      username: string;
    };
  }
>;

export interface PaymentSettings {
  paymentPlanId?: ObjectId;
  netSuiteVendorId?: string;
  inheritNetSuiteVendorId?: boolean;
  inheritBankAccount?: boolean;
  inheritPaymentPlan?: boolean;
  paymentPlanHistory?: {
    paymentPlanId: ObjectId;
    setBy: Actor;
    dateSet: Date;
  }[];
  payoutHold?: PayoutHold;
  email?: string;
}

export interface ClientPaymentSettings {
  email?: string;
  bankAccountLast4?: string;
  bankName?: string;
}

export type PaymentSettingsWithMetadata = PaymentSettings & {
  scheduleOwnerAlias?: string;
  inherited?: boolean;
};

export interface OrganizationPricingSettingsDocument extends BaseOrganizationSettings {
  value: Array<PricingSetting>;
}
export interface OrganizationPaymentSettingsDocument extends BaseOrganizationSettings {
  value: PaymentSettings;
  outboundPaymentsDeactivatedBankAccounts?: Array<OutboundPaymentsBankAccount & { archivedDate: Date }>;
  payoutHoldHistory?: Array<PayoutHoldHistory>;
}

export interface OrganizationGeneralSettingsDocument extends BaseOrganizationSettings {
  value: {
    productionFactorTolerance: number;
    bypassProductionFactorToleranceForInFlightProjects: boolean;
  };
}

export interface OrganizationMilestonesSettingsDocument extends BaseOrganizationSettings {
  value: {
    programType?: ProgramType;
    milestonesTemplate: any;
  };
}

export interface OrganizationEmailSettingsDocument extends BaseOrganizationSettings {
  value: {
    emailSettings: EmailSetting[];
  };
}

export interface EmailSetting {
  category: OrganizationEmailSettingCategories;
  emails: string[];
}

export interface OrganizationCustomQuoteSettingsDocument extends BaseOrganizationSettings {
  value: {
    isEnabled: boolean;
    maxEscalationRate: number;
  };
}

export interface ppwModifier {
  state: string;
  adjustment: number;
}
export interface OrganizationPPWModifierSettingsDocument extends BaseOrganizationSettings {
  ppwModifications: ppwModifier[];
}

export const milestonePackageToEmailSettingMap: {
  [value in MilestoneRequirement]?: OrganizationEmailSettingCategories;
} = {
  [MilestoneRequirement.paymentMethodSubmitted]: OrganizationEmailSettingCategories.ntpMilestone,
  [MilestoneRequirement.identityVerified]: OrganizationEmailSettingCategories.ntpMilestone,
  [MilestoneRequirement.titleApproved]: OrganizationEmailSettingCategories.ntpMilestone,
  [MilestoneRequirement.installPackage]: OrganizationEmailSettingCategories.installMilestone,
  [MilestoneRequirement.activationPackage]: OrganizationEmailSettingCategories.activationMilestone,
};

interface DomesticContentModifier {
  id: ObjectId;
  name: string;
  values: {
    holdback: {
      value: number;
      format: 'percent' | 'perWatt';
    };
    modifier: {
      value: number;
      format: 'percent' | 'perWatt';
    };
  };
}

export interface BaseOrganizationPricingSettings extends BaseOrganizationSettings {
  organizationName: string;
  status: PriceSheetStatus;
  state: string;
}
export interface OrganizationPpwModifierSettingDocument extends BaseOrganizationPricingSettings {
  value: PpwModifier;
}

export interface OrganizationHoldbackSettingsDocument extends BaseOrganizationPricingSettings {
  value: Holdback;
}
export interface OrganizationEnergyCommunityModifierSettingsDocument extends BaseOrganizationPricingSettings {
  value: EnergyCommunityModifier;
}

export interface OrganizationDomesticContentModifierSettingsDocument extends BaseOrganizationPricingSettings {
  value: DomesticContentModifier;
}
