import { Alert, Box, Icon, Popover, useOpenClose } from '@palmetto/palmetto-components';

export const stipulationTypeToLabel: Partial<Record<string, string>> = {
  administrative: 'Administrative Stipulation',
  designVerification: 'Design Stipulation',
};

interface PauseBannerProps {
  stipulationType: string;
  description: string;
  className?: string;
}

const PauseBanner = ({ stipulationType, description, className }: PauseBannerProps) => {
  const { isOpen, handleClose, handleToggle } = useOpenClose();
  return (
    <Alert
      className={className}
      hasIcon
      fontWeight="bold"
      isCompact
      variant="warning"
      message={
        <Box direction="row" childGap="md" flex="auto" justifyContent="space-between" padding="2xs">
          <Box>{stipulationTypeToLabel[stipulationType]}</Box>
          <Popover
            isOpen={isOpen}
            content={description}
            placement="top"
            contentContainerProps={{
              padding: 'lg',
              background: 'grey-700',
              color: 'white',
              fontSize: 'sm',
              fontWeight: 'regular',
              maxWidth: { base: '100', tablet: '4xl' },
              minWidth: { base: '100', tablet: '4xl' },
            }}
            onClickOutside={handleClose}
            portalTarget={document.body}
            withPortal
          >
            <Box>
              <Icon name="c-info" size="md" color="info" onClick={handleToggle} />
            </Box>
          </Popover>
        </Box>
      }
    />
  );
};

export default PauseBanner;
