import { Box, Accordion } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { ReviewPackagePhotoCategory } from '../../../types/ReviewPackage';
import { ReviewPackageActions, ReviewPhotoSectionType, reviewPhotoSections } from '../../../types/ReviewPackage';
import { InstallationPhotoItem } from './InstallationPhotoItem';
import './InstallationPhotos.css';
import { useGetTasksQuery } from '../../../services/tasks';
import { sortDescending } from '../../../helpers/sort';

export const InstallationPhotos = ({
  partnerReviewTaskSections,
  isInstallPackagePendingReview,
}: {
  partnerReviewTaskSections: ReviewPackagePhotoCategory[];
  isInstallPackagePendingReview: boolean;
}) => {
  const { id, action } = useParams<{ id: any; action: ReviewPackageActions }>();
  const { data: items = [], isLoading } = useGetTasksQuery({ id, assignee: 'internal' });

  const findAdminTaskByInstallPackageSection = (section: ReviewPhotoSectionType, items: any[]) => {
    const adminTask = items.filter((item) => {
      return item.type === section.id;
    });
    // return the most recent
    return sortDescending(adminTask, 'createdAt')[0];
  };
  return (
    <Box position="relative">
      <Accordion className="accordion" padding={{ base: '0 2xs 0 2xs', tablet: '0' }}>
        {!isLoading &&
          Object.values(reviewPhotoSections).map((section) => {
            const task = findAdminTaskByInstallPackageSection(section, items);
            return (
              <InstallationPhotoItem
                key={section.id}
                section={section}
                showUploadButton={
                  task?.status !== 'completed' &&
                  action !== ReviewPackageActions.review &&
                  (partnerReviewTaskSections.length === 0 || partnerReviewTaskSections.includes(section.id)) &&
                  !isInstallPackagePendingReview
                }
                task={task}
              />
            );
          })}
      </Accordion>
    </Box>
  );
};
