import { Box } from '@palmetto/palmetto-components';
import RequirePalmettoFinanceUser from '../../auth/RequirePalmettoFinanceUser';

const HeliosCreated = ({ event }: { event: any }) => {
  return (
    <Box>
      <Box display="block">
        <strong>Customer</strong> created their Palmetto account
      </Box>
      <RequirePalmettoFinanceUser>
        <Box as="span" display="inline-block" margin="0 2xs 0 0">
          <a href={event.data?.heliosControlUserUrl} target="_blank" rel="noopener noreferrer">
            {event.data?.heliosControlUserUrl}
          </a>
        </Box>
      </RequirePalmettoFinanceUser>
    </Box>
  );
};

export default HeliosCreated;
