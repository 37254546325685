import { Box } from '@palmetto/palmetto-components';
import { AllDocumentTypes, documentTitles } from 'types';
import EventBy from './EventBy';

const SabalDocumentArchived = ({ event }: { event: any }) => {
  const files = event.data?.document?.files?.map((file: any) => file.originalName).join(', ');
  const typeOfDocument: AllDocumentTypes = event.data?.document?.type;

  return (
    <Box display="block">
      <EventBy event={event} /> archived <strong>{documentTitles[typeOfDocument]}</strong> document
      {event.data?.document?.files?.length > 1 && 's'} <strong>{files}</strong>
    </Box>
  );
};

export default SabalDocumentArchived;
