import { useGetAccountQuery } from '@/services/accounts';
import { useGetQuotesQuery, useVoidQuoteMutation } from '@/services/quotes';
import { useCreateHVACSystemDesignMutation, useGetCurrentDesignQuery } from '@/services/systemDesign';
import { Box, Button, Modal, Spinner, TextLink, toast, useOpenClose } from '@palmetto/palmetto-components';
import { Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ContractStatus,
  HVACDiscountDetails,
  HVACEquipmentDetails,
  HVACInstallKitDetails,
  HvacPricingDetails,
  HVACServiceDetails,
  ProductDTO,
  ProgramType,
  QuoteStatus,
  UserPermissions,
} from 'types';
import FormSection from '../Forms/FormSection';
import FormSectionContainer from '../Forms/FormSectionContainer';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { MainContainer } from '../MainContainer';
import EquipmentSection from '../SystemDesign/hvac/EquipmentSection';
import InstallKitsSection from '../SystemDesign/hvac/InstallKitsSection';
import ServicesSection from '../SystemDesign/hvac/ServicesSection';
import DiscountsSection from '../SystemDesign/hvac/DiscountsSection';
import RequirePermissions from '../auth/requirePermissions';
import usePermissions from '@/hooks/usePermissions';
import { useGetProductsQuery } from '@/services/productCatalog';
import { useGetContractsQuery, useVoidContractMutation } from '@/services/contract';

export const HVACSystemDesign = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const { data: systemDesign }: any = useGetCurrentDesignQuery({
    id,
    programType: ProgramType.hvac,
  });
  const { data: account, isLoading: isAccountLoading }: any = useGetAccountQuery(id);
  const { data: quotes, isLoading: isQuotesLoading } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const { data: products, isLoading: isProductsLoading } = useGetProductsQuery({
    classification: 'hvac',
  });
  const [voidQuote, { isLoading: isVoidingQuote }] = useVoidQuoteMutation({
    fixedCacheKey: 'useVoidQuoteMutationKey',
  });
  const { data: contracts, isLoading: areContractsLoading } = useGetContractsQuery(id);

  const isLoading = isAccountLoading || isQuotesLoading || isProductsLoading || areContractsLoading;
  const [createSystemDesign] = useCreateHVACSystemDesignMutation();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [formValues, setFormValues] = useState<any>({});
  const permissions = usePermissions();
  const userOnlyHasReaderPermission = permissions?.every((permission) => permission.includes(UserPermissions.read));

  const productsByClassification = useMemo(() => {
    if (!products) return {};
    return products?.reduce((acc: any, item: ProductDTO) => {
      if (!acc[item.productType]) {
        acc[item.productType] = [];
      }
      acc[item.productType].push(item);
      return acc;
    }, {});
  }, [products]);

  const systemEquipmentWithProductOption = useMemo(() => {
    if (!systemDesign?.equipment || !Object.keys(productsByClassification).length) return [];
    return systemDesign.equipment.map((e: HVACEquipmentDetails) => {
      // try to match items based on model and manufacturer
      // if no match but there is a productId from the product catalog then we should signify this noting changes have been made
      const selectedItem = productsByClassification?.[e.type]?.find(
        (item: ProductDTO) => item.sku === e.model && item.manufacturer === e.manufacturer,
      );
      const productOption = selectedItem
        ? { label: `${e.manufacturer} - ${e.model}`, value: selectedItem.id }
        : { label: `${e.manufacturer} - ${e.model} ${e.productId ? '*' : '**'}`, value: e.model };
      return {
        ...e,
        option: productOption,
      };
    });
  }, [systemDesign?.equipment, productsByClassification]);

  const activeContract = useMemo(() => {
    return contracts?.find(
      (contract: any) => ![ContractStatus.voided, ContractStatus.rejected].includes(contract.status),
    );
  }, [contracts]);

  const [voidContract, { isLoading: isVoidingActiveContract }] = useVoidContractMutation({
    fixedCacheKey: 'useVoidContractMutationKey',
  });

  const activeQuote = quotes?.find((quote: any) => quote.status === QuoteStatus.active);

  const calculateTotalCost = useCallback(
    (values: {
      equipment: HVACEquipmentDetails[];
      installKits: HVACInstallKitDetails[];
      services: HVACServiceDetails[];
      discounts: HVACDiscountDetails[];
    }) => {
      const equipmentCost = values.equipment.reduce((sum, item) => sum + Number(item.cost ?? 0), 0);
      const installKitsCost = values.installKits.reduce((sum, item) => sum + Number(item.cost ?? 0), 0);
      const servicesCost = values.services.reduce((sum, item) => sum + Number(item.cost ?? 0), 0);
      const discountsCost = values.discounts.reduce((sum, item) => sum + Number(item.cost ?? 0), 0);

      return equipmentCost + installKitsCost + servicesCost - discountsCost;
    },
    [],
  );

  const handleSubmit = async (
    data: {
      services: HVACServiceDetails[];
      installKits: HVACInstallKitDetails[];
      equipment: HVACEquipmentDetails[];
      discounts: HVACDiscountDetails[];
    },
    { setSubmitting }: any,
  ) => {
    try {
      const totalSystemDesign = calculateTotalCost(data);

      if (activeQuote && totalSystemDesign !== activeQuote.totalSystemCost) {
        setFormValues(data);
        openModal();
        setSubmitting(false);
        return;
      }

      await createSystemDesign({
        systemDesign: {
          equipment: data.equipment,
          installKits: data?.installKits || [],
          services: data?.services || [],
          discounts: data?.discounts || [],
        },
        accountId: id,
      }).unwrap();
      toast.success('System design saved successfully');
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error saving system design');
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * @description Voids a quote (and an active contract, if any)
   * and generates a new system design.
   */
  const handleConfirmModalSubmit = async () => {
    closeModal();
    if (formValues) {
      try {
        if (activeContract) {
          await voidContract({
            accountId: String(id),
            contractId: String(activeContract.id),
          }).unwrap();
          toast.success('Active Contract voided successfully');
        }
        await voidQuote({ accountId: id.toString(), quoteId: activeQuote.id.toString() }).unwrap();
        toast.success('Quote voided successfully');
        await createSystemDesign({
          systemDesign: {
            equipment: formValues.equipment,
            installKits: formValues?.installKits || [],
            services: formValues?.services || [],
            discounts: formValues?.discounts || [],
          },
          accountId: id,
        }).unwrap();
        toast.success('System design saved successfully');
      } catch (e: any) {
        toast.error(e?.data?.message || 'Error saving system design');
      }
    }
  };

  const isVoidingSomething = isVoidingQuote || isVoidingActiveContract;

  return (
    <>
      <MainContainer>
        {isLoading ? (
          <Box
            display="block"
            textAlign="center"
            padding="lg"
            childGap="xl"
            borderColor="separator"
            borderWidth="xs 0 0 0"
          >
            <Spinner size="lg" />
          </Box>
        ) : (
          <Box
            overflow="hidden"
            direction={{ base: 'column', desktop: 'row' }}
            childGap="lg"
            padding={{ base: '0', desktop: 'xl' }}
          >
            <Formik
              initialValues={{
                equipment: systemEquipmentWithProductOption,
                installKits: systemDesign?.installKits || [],
                services: systemDesign?.services || [],
                discounts: systemDesign?.discounts || [],
              }}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validateOnChange={false}
            >
              {({ isSubmitting, values }) => {
                const totalSystemDesignCost = calculateTotalCost(values);
                return (
                  <Form noValidate style={{ width: '100%' }}>
                    <Box as="h2" fontWeight="medium" fontSize="lg" padding={{ base: 'lg lg 0 lg', desktop: '0' }}>
                      HVAC System Design
                    </Box>
                    <FormSectionContainer>
                      <EquipmentSection
                        productsByClassification={productsByClassification}
                        userOnlyHasReaderPermission={userOnlyHasReaderPermission}
                      />
                      <FormSection
                        title="Install Kits and Services"
                        description={
                          <Box gap="xs">
                            <Box>List all that apply</Box>
                          </Box>
                        }
                      >
                        <Box gap="lg">
                          <InstallKitsSection userOnlyHasReaderPermission={userOnlyHasReaderPermission} />
                          <ServicesSection userOnlyHasReaderPermission={userOnlyHasReaderPermission} />
                        </Box>
                      </FormSection>
                      <DiscountsSection userOnlyHasReaderPermission={userOnlyHasReaderPermission} />
                    </FormSectionContainer>
                    <Box alignItems="flex-end" margin="md">
                      <Box fontWeight="bold" fontSize="lg">
                        Total System Design Cost
                      </Box>
                      <Box fontWeight="bold" fontSize="lg">
                        {currencyFormatter.format(totalSystemDesignCost)}
                      </Box>
                    </Box>
                    <Box
                      direction={{
                        base: 'column',
                        tablet: 'row',
                      }}
                      childGap="sm"
                      style={{ flexShrink: 0 }}
                      padding={{ base: 'lg' }}
                      background="primary"
                    >
                      {activeQuote ? (
                        <Box alignSelf="flex-start" direction="row" gap="lg">
                          <Box gap="xs">
                            <Box fontSize="xl" fontWeight="bold">
                              {currencyFormatter.format(activeQuote.totalSystemCost)}
                            </Box>
                            <Box fontSize="md">Total Financed Amount</Box>
                          </Box>
                          <Box gap="xs">
                            <Box direction="row" alignItems="center" gap="2xs">
                              <Box fontSize="xl" fontWeight="bold">
                                {activeQuote.systemPricingDetails.find((d: HvacPricingDetails) => d.year === 1)
                                  ?.monthlyPayment || 0}
                              </Box>
                              <Box fontSize="md">/mo</Box>
                            </Box>
                            <Box fontSize="md">Customer Payment</Box>
                          </Box>
                          <Box gap="xs">
                            <Box direction="row" alignItems="center" gap="2xs">
                              <Box fontSize="xl" fontWeight="bold">
                                {activeQuote.systemPricingDetails.length}
                              </Box>
                              <Box fontSize="md">
                                {activeQuote.systemPricingDetails.length > 1 ? `years` : `year`}
                              </Box>
                            </Box>
                            <Box fontSize="md">Customer Term Length</Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box childGap="xs" color="body-secondary">
                          <p>
                            There is no active quote for this Account. Please{' '}
                            <TextLink
                              navigate={() => navigate(`/accounts/${id}/quote-calculator`)}
                              style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            >
                              create a quote
                            </TextLink>{' '}
                            that matches the System Cost above.
                          </p>
                        </Box>
                      )}
                      <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
                        <Box style={{ marginLeft: 'auto' }} direction="row" gap="sm">
                          <Button
                            as="a"
                            href={`/accounts/${id ? id : ''}`}
                            variant="secondary"
                            tone="neutral"
                            size="md"
                            isLoading={isSubmitting || isVoidingSomething}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="md"
                            variant="primary"
                            type="submit"
                            isLoading={isSubmitting || isVoidingSomething}
                          >
                            Save
                          </Button>
                        </Box>
                      </RequirePermissions>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        )}
      </MainContainer>

      <Modal ariaLabelledBy="costMismatch" isOpen={isModalOpen} onDismiss={closeModal}>
        <Modal.Header id="costMismatch" title="Cost Mismatch" onDismiss={closeModal} />
        <Modal.Body>
          The total system design cost does not match the active quote cost. Would you like to void the existing
          quote {activeContract ? 'and active contract' : ''} and create a new one?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" tone="neutral" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmModalSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
