import { Box } from '@palmetto/palmetto-components';
import { AllDocumentTypes, documentTitles } from 'types';
import EventBy from './EventBy';

const SabalDocumentUploaded = ({ event }: { event: any }) => {
  const numberOfDocuments = event.data?.documents?.[0]?.files.length || 0;
  const typeOfDocument: AllDocumentTypes = event.data?.documents?.[0]?.type;

  const files = event.data?.documents?.[0]?.files?.map((file: any) => file.originalName).join(', ');

  return (
    <Box display="block">
      <EventBy event={event} /> uploaded <strong>{files}</strong> as {` `}
      <strong>{documentTitles[typeOfDocument]}</strong> document{numberOfDocuments === 1 ? '' : 's'}
    </Box>
  );
};

export default SabalDocumentUploaded;
