import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';
import { Diffs } from './Diffs';

const hiddenProperties = ['isCurrent', 'accountId', 'meta', '_id', 'id', 'attestment.userId'];

const SabalSystemDesignUpdated = ({ event }: { event: any }) => {
  return (
    <Box display="block">
      <EventBy event={event} /> updated
      <br />
      <Diffs diff={event?.data?.diff} hiddenProperties={hiddenProperties}/>
    </Box>
  );
};

export default SabalSystemDesignUpdated;
