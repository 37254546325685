import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Box, toast } from '@palmetto/palmetto-components';

import { useGetOrganizationTreeQuery } from '../../../services/organizations';
import PageHeader from '../../PageHeader';
import { useAuth } from '../../auth/authProvider';
import { useCreateOrganizationMutation } from '../../../services/organizations';
import { isErrorWithData, isErrorWithErrors } from '../../../services/helpers';
import { OrganizationForm } from './OrganizationForm';
import { flattenToOptions } from '../../../helpers/flattenOrgs';
import { MainContainer } from '../../MainContainer';
import { OrganizationType, ProgramType, UserPermissions } from 'types';
import usePermissions from '../../../hooks/usePermissions';
import { isPalmettoFinanceUser } from '../../auth/RequirePalmettoFinanceUser';

export function Create() {
  const { data: { tree = [] } = {}, isLoading } = useGetOrganizationTreeQuery();
  const { claims } = useAuth();
  const [createOrganization] = useCreateOrganizationMutation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { parent: startingParentAlias } = queryString.parse(search);
  const permissions = usePermissions();

  const parentOptions = useMemo(() => {
    return flattenToOptions(tree);
  }, [tree]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      setSubmitting(true);

      if (!values.programTypes.includes(ProgramType.hvac)) {
        values.extendedWarrantyDealerInformation = undefined;
      }

      await createOrganization({
        name: values.name,
        alias: values.alias,
        parentAlias: values.parent.value,
        address: values.address1,
        city: values.city,
        state: values.state,
        zip: values.zip,
        licenses: values.stateLicense,
        insuranceCompanyPhoneNumber: values.insuranceCompanyPhoneNumber,
        insuranceCompanyName: values.insuranceCompanyName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        federalEmployerId: values.federalEmployerId,
        programTypes: values.programTypes,
        organizationTypes: values.organizationTypes,
        extendedWarrantyDealerInformation: values.extendedWarrantyDealerInformation,
      }).unwrap();
      toast.success('Organization created successfully');
      navigate('/settings/organizations');
    } catch (e) {
      if (isErrorWithErrors(e)) {
        const errorMessage = e.data.message;
        toast.error(`${errorMessage}: ${e.data.errors.map((e) => e).join(', ')}`);
      } else if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error creating organization: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error creating organization');
      }
    }
    setSubmitting(false);
  };

  const initialValues = useMemo(() => {
    return {
      name: '',
      alias: '',
      parent: parentOptions.find((p) => p.value === (!!startingParentAlias ? startingParentAlias : claims?.org)),
      address1: '',
      city: '',
      state: '',
      zip: '',
      phoneNumber: '',
      email: '',
      ...(isPalmettoFinanceUser(claims) &&
        permissions.includes(UserPermissions.admin) && {
          stateLicense: [{ state: '', contractorLicenseNumber: '', additionalLicenseNumbers: '' }],
        }),
      programTypes: [ProgramType.solar],
      organizationTypes: [OrganizationType.epc],
      extendedWarrantyDealerInformation: [{ warrantyProvider: 'JB Warranties' }],
    };
  }, [claims, parentOptions, startingParentAlias, permissions]);

  return (
    <>
      <Helmet>
        <title>Create Organizations | Settings</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          title="Create An Organization"
          eyebrow={<Link to="/settings/organizations">Organizations</Link>}
        />
        <Box
          padding={{
            base: '0 lg',
            tablet: '0',
          }}
        >
          <OrganizationForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            parentOptions={parentOptions}
          />
        </Box>
      </MainContainer>
    </>
  );
}
