import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';

const SabalAccountCreated = ({ event }: { event: any }) => {
  return (
    <Box display="block">
      <EventBy event={event} /> created account {event?.data?.account?.friendlyName}
    </Box>
  );
};

export default SabalAccountCreated;
