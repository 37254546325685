import { Box, Card, RadioGroup, useTheme, Theme } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import { useAuth } from './auth/authProvider';
import { MainContainer } from './MainContainer';
import { PropertyValue } from './PropertyValue';

export function YourProfile() {
  const { user } = useAuth();
  const displayName = user?.given_name ? `${user?.given_name} ${user?.family_name}` : `${user?.email}`;

  const { theme, setTheme } = useTheme();
  const options = [
    {
      id: 'light',
      value: 'light',
      label: 'Light',
    },
    {
      id: 'dark',
      value: 'dark',
      label: 'Dark',
    },
    {
      id: 'system',
      value: 'system',
      label: 'System',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Your Palmetto Finance Profile</title>
      </Helmet>
      <MainContainer>
        <Box gap="2xs" padding={{ base: '0 lg', tablet: '0' }}>
          <Box as="h1" fontSize={{ base: 'lg', tablet: 'xl', desktop: '2xl' }} fontWeight="medium">
            Your Profile
          </Box>
          <Box
            overflow="hidden"
            display="block"
            style={{ textOverflow: 'ellipsis', minWidth: '0', whiteSpace: 'nowrap' }}
          >
            {displayName}
          </Box>
        </Box>
        <Card>
          <Box padding="lg" gap="sm">
            <PropertyValue label="Given Name" value={user?.given_name} />
            <PropertyValue label="Family Name" value={user?.family_name} />
            <PropertyValue label="Name" value={user?.name} />
            <PropertyValue label="Nickname" value={user?.nickname} />
            <PropertyValue label="Email" value={user?.email} />
            <PropertyValue label="Email Verified" value={user?.email_verified ? 'yes' : 'no'} />
          </Box>
        </Card>

        <Card>
          <Card.Section>
            <RadioGroup
              title="Appearance"
              name="SelectTheme"
              value={theme}
              options={options}
              onChange={(event) => setTheme(event.target.value as Theme)}
            />
          </Card.Section>
        </Card>
      </MainContainer>
    </>
  );
}
