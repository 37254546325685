import { Box, Icon } from '@palmetto/palmetto-components';
import { ReactNode } from 'react';

export const StatusIcon = ({
  status,
  size,
  className,
}: {
  status:
    | 'completed'
    | 'inProgress'
    | 'paused'
    | 'pending'
    | 'rejected'
    | 'submitted'
    | 'resubmitted'
    | 'error'
    | undefined;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}) => {
  let icon: ReactNode;

  if (status === 'completed') {
    icon = <Icon name="c-check" size={size || 'lg'} color="success" />;
  } else if (status === 'submitted' || status === 'resubmitted' || status === 'inProgress') {
    icon = <Icon name="c-in-progress" size={size || 'lg'} color="success" />;
  } else if (status === 'paused') {
    icon = <Icon name="circle" size={size || 'lg'} color="success" />;
  } else if (status === 'rejected' || status === 'error') {
    icon = <Icon name="c-remove" size={size || 'lg'} color="danger" />;
  } else if (status === 'pending' || !status) {
    icon = <Icon name="circle" size={size || 'lg'} color="disabled" />;
  }

  return (
    <Box background="primary" className={className}>
      {icon}
    </Box>
  );
};
