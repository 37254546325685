import FormSection from "@/components/Forms/FormSection"
import { CheckboxInput } from "@palmetto/palmetto-components"

type AttestationSectionProps = {
	incentiveAttestLabel: string
	permitAttestLabel: string
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
	values: any
	isSubmitting: boolean
	touched: any
	errors: any
}

export const AttestationSection = ({ incentiveAttestLabel, permitAttestLabel, setFieldValue, values, isSubmitting, touched, errors }: AttestationSectionProps) => {
	return (
		<><FormSection title="Incentive Attestation">
			<CheckboxInput
				id="incentiveAttestationCheckbox"
				label={incentiveAttestLabel}
				onChange={(event) => event.target.checked ? setFieldValue('incentiveAttestationCheckbox', true) : setFieldValue('incentiveAttestationCheckbox', false)}
				isChecked={values.incentiveAttestationCheckbox ? true : false}
				isDisabled={isSubmitting}
				error={touched.incentiveAttestationCheckbox && (errors.incentiveAttestationCheckbox as string)} />
		</FormSection><FormSection title="Permit Attestation">
				<CheckboxInput
					id="permitAttestationCheckbox"
					name="permitAttestationCheckbox"
					label={permitAttestLabel}
					onChange={(event) => event.target.checked ? setFieldValue('permitAttestationCheckbox', true) : setFieldValue('permitAttestationCheckbox', false)}
					isChecked={values.permitAttestationCheckbox ? true : false}
					isDisabled={isSubmitting}
					error={touched.permitAttestationCheckbox && (errors.permitAttestationCheckbox as string)} />
			</FormSection>
		</>
	)
}
