import React, { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { Box, Button, FormikSelectInput, FormikTextareaInput, Icon, Modal } from '@palmetto/palmetto-components';
import * as yup from 'yup';

type RejectionReason = { value: string; label: string };

interface RejectionModalProps {
  rejectionReasons: RejectionReason[];
  isModalOpen: boolean;
  closeModal: any;
  onSubmit: (values: any) => void;
  title?: string;
}

const rejectionSchema = yup.object().shape({
  rejectionReason: yup.array().required('Rejection reason is a required field'),
  rejectionNote: yup.string().optional().nullable(),
});

export const RejectionModal = ({
  rejectionReasons,
  isModalOpen,
  closeModal,
  onSubmit,
  title = 'Reject',
}: RejectionModalProps) => {
  const initialValues = useMemo(() => {
    return {
      rejectionReason: null,
      rejectionNote: '',
    };
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={rejectionSchema} enableReinitialize>
      {({ isSubmitting, resetForm, submitForm }) => (
        <Form noValidate>
          <Modal
            ariaLabelledBy="titleBasic"
            isOpen={isModalOpen}
            onDismiss={() => {
              resetForm();
              closeModal();
            }}
            maxWidth="4xl"
          >
            <Modal.Body background="danger" childGap="lg" fontSize="sm">
              <Box childGap="xs" alignItems="center">
                <Icon name="remove" size="2xl" color="danger" />
                <Box id="rejectModalTitle" as="h4" fontSize="lg" textAlign="center">
                  {title}
                </Box>
              </Box>
              <Box direction="column" childGap="md">
                <Field
                  label="Rejection Reason"
                  name="rejectionReason"
                  id="rejectionReason"
                  isMulti
                  options={rejectionReasons}
                  component={FormikSelectInput}
                  isDisabled={isSubmitting}
                  isRequired
                  menuPortalTarget={document.body}
                />
                <Field
                  label="Rejection Note (Optional)"
                  name="rejectionNote"
                  id="rejectionNote"
                  component={FormikTextareaInput}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
              </Box>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                tone="neutral"
                onClick={() => {
                  resetForm();
                  closeModal();
                }}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                tone="danger"
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={submitForm}
              >
                Reject
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
