import { Box, Modal, Button, toast } from '@palmetto/palmetto-components';
import { useState, } from 'react';
import { useParams } from 'react-router-dom';
import { useOverrideApplicationMutation } from '../services/applications';
import { isErrorWithData } from '../services/helpers';

interface CreditOverrideModalProps {
  isModalOpen: boolean;
  closeModal: any;
  applicationId?: string;
}


const CreditOverrideModal = (props: CreditOverrideModalProps) => {
  const { isModalOpen, closeModal, applicationId } = props;
  const [overrideCreditApplication] = useOverrideApplicationMutation();

  const [ isSubmitting, setSubmitting ] = useState(false);

  const { id } = useParams<{ id: any }>();

  const handleCreditOverride = async () => {
    setSubmitting(true)
    try {
      await overrideCreditApplication({
        accountId: id,
        applicationId: applicationId,
      }).unwrap();
      toast.success('Credit override successful');
      closeModal();
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error overriding credit');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal ariaLabelledBy="titleBasic" isOpen={isModalOpen} onDismiss={closeModal}>
      <Modal.Header
        id="titleBasic"
        title={`Override credit for application id: ${applicationId}`}
        onDismiss={closeModal}
      />
      <Modal.Body padding="0 md md">
        <Box childGap="md">
          <Box childGap="sm">
            <p>
              Verify that you want to override the credit decision for this application and it meets the requirements:
            </p>
            <ul>
              <li>Application is in a declined state</li>
              <li>No bankruptcy exists on application</li>
              <li>Fico should be acceptable (ex: between 650-660)</li>
            </ul>
          </Box>
          <Box>
            <Button
              variant="primary"
              onClick={() => handleCreditOverride()}
              isLoading={isSubmitting}
            >
              Override Credit
            </Button>
          </Box>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default CreditOverrideModal;
