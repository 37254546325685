import { Box, FormikSelectInputNative, FormikTextInput } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { useGetAccountQuery } from '@/services/accounts';
import { CreateProjectTransactionPayload, PayoutEventMap, ProgramType } from 'types';
import { useGetProjectByExternalIdQuery } from '@/services/payouts';

export const AddProjectTransactionForm = () => {
  const { values } = useFormikContext<CreateProjectTransactionPayload>();
  const { projectExternalId: accountId } = values;
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(
    accountId?.length === 24 ? accountId : (undefined as any),
  );
  const { data: project, isLoading: isProjectLoading } = useGetProjectByExternalIdQuery(
    accountId?.length === 24 ? accountId : (undefined as any),
  );
  const primaryApplicant = account?.applicants.find((applicant: any) => applicant.type === 'primary');
  const isLoading = isAccountLoading || isProjectLoading;
  const payoutEventOptions = useMemo(
    () => [
      ...(!isLoading && account?.programType
        ? PayoutEventMap[account.programType as ProgramType].map((event: any) => ({
            value: event.event,
            label: event.name,
          }))
        : []),
      { label: 'Other', value: 'other' },
    ],
    [account?.programType, isLoading],
  );
  const payeeOptions = useMemo(
    () =>
      !isLoading && project?.payees
        ? project.payees.map((payee) => ({
            value: payee.id,
            label: payee.name,
          }))
        : [],
    [project?.payees, isLoading],
  );

  return (
    <Box childGap={{ base: 'lg', desktop: 'xl' }}>
      <Box fontSize="sm" padding="md 0" childGap="2xs">
        {primaryApplicant && project && account && (
          <>
            <Box fontWeight="medium" fontSize="md">
              {account.primaryApplicantName}
            </Box>
            <Box>{primaryApplicant.address.address1}</Box>
            <Box>
              {primaryApplicant.address.city}, {primaryApplicant.address.state} {primaryApplicant.address.zip}
            </Box>
            <Box padding="sm 0 0 0">{primaryApplicant.email}</Box>
          </>
        )}
      </Box>
      <Field
        label="Payee"
        name="payeeId"
        id="payeeId"
        component={FormikSelectInputNative}
        isRequired={true}
        options={payeeOptions}
      />
      <Field
        label="Payout Event"
        name="event"
        id="event"
        component={FormikSelectInputNative}
        isRequired={true}
        options={payoutEventOptions}
      />
      <Field
        type="number"
        name="amount"
        id="amount"
        label="Payout Amount"
        component={FormikTextInput}
        isRequired={true}
        prefix="$"
      />
      <Field name="description" id="description" label="Reason" component={FormikTextInput} isRequired={true} />
    </Box>
  );
};
