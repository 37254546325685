import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';
import { AdderType } from 'types';
import { Diffs } from './Diffs';
import { getAdderTypeLabel } from '../../../helpers/addersHelpers';

const hiddenProperties = ['currentMilestone', 'requirements', 'milestones'];

const getLabel = (addersDiff: any[], existingAdders: any[]) => {
  return addersDiff.map((element, index) => element ? getAdderTypeLabel(existingAdders[index]?.type as AdderType) : null).filter(Boolean).join(',')
}

const SabalAddersUpdated = ({ event }: { event: any }) => {
  const foundLabel = getLabel(event?.data?.diff, event?.data?.adders || []);
  const label = `${foundLabel ? `${foundLabel} ` : ''}Adder`.trim();
  return (
    <Box display='block'>
      <EventBy event={event} /> updated
      <br />
      <strong>{label || ''}</strong>
      {label ? <br /> : null}
      <Diffs diff={event?.data?.diff} hiddenProperties={hiddenProperties}/>
    </Box>
  );
};

export default SabalAddersUpdated;
