import { Box, Button, Modal, ModalProps, TextareaInput } from '@palmetto/palmetto-components';
import { ChangeEvent, useCallback, useState } from 'react';

export function GrantToleranceExceptionModal({ isOpen, onDismiss, handleToleranceExceptionModal }: ModalProps) {
  const [note, setNote] = useState('');
  const [buttonStatus, setButtonStatus] = useState(false);

  const setNoteText = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  }, []);

  const handleSubmit = async () => {
    if (!note || note === '') return;
    setButtonStatus(!buttonStatus);
    const status = await handleToleranceExceptionModal(note);
    setButtonStatus(status);
    setNote('');
    onDismiss();
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth="4xl" ariaLabelledBy="confirmContractVoiding">
      <Modal.Header id="grantToleranceException" title="Grant Tolerance Exception" onDismiss={onDismiss} />
      <Modal.Body background="secondary" childGap="lg">
        <Box fontSize={'sm'} color="body-primary" data-testid="grant-tolerance-exception-modal-body">
          Granting a production tolerance exception will:
          <br />
          <br />
          <ul>
            <li>
              Enable the EPC to submit the installation package for review, regardless of whether the year-1
              production estimate for the installed system exceeds the -5-10% variance threshold from the contracted
              year-1 production estimate
            </li>
            <li>If the package is rejected for after review, the exception will remain in place</li>
            <li>EPC can make necessary corrections and resubmit</li>
          </ul>
          <br />
          <TextareaInput
            placeholder="Write notes here"
            id="notesTextArea"
            data-testid="grant-tolerance-exception-notes"
            label={'Exception Notes'}
            onChange={setNoteText}
            value={note}
            isRequired
          />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          tone="neutral"
          onClick={onDismiss}
          data-testid="grant-tolerance-exception-modal-cancel-cta"
        >
          Cancel
        </Button>
        <Button
          isLoading={buttonStatus}
          variant="primary"
          tone="primary"
          onClick={handleSubmit}
          data-testid="grant-tolerance-exception-modal-confirm-cta"
        >
          Grant Tolerance Exception
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
