import { useMemo } from 'react';
import { Box, FormikTextInput, TextLink } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { AdderType } from '../../types/Quotes';
import FormSection from '../Forms/FormSection';
import { FlagBox } from '../QueueFlags/FlagBox';

interface MonitoringActiveProps {
  adders: AdderType[];
}

export const MonitoringActive = ({ adders }: MonitoringActiveProps) => {
  const hasBattery = useMemo(
    () => adders?.includes(AdderType.arbitrageBattery) || adders?.includes(AdderType.backupBattery),
    [adders],
  );
  const { isSubmitting } = useFormikContext<any>();
  return (
    <FormSection
      description={
        <Box childGap="xs" color="body-secondary">
          <p>Monitoring portal ID for the inverter (and battery, if applicable).</p>
          <p>Ensure site access is shared to Palmetto as owner & CTs are configured.</p>
          <p>
            <TextLink
              href="https://help.palmetto.finance/en/articles/8306383-monitoring-portal-access"
              target="_blank"
              rel="noreferrer"
            >
              See monitoring portal access requirements
            </TextLink>
          </p>
        </Box>
      }
      title="Monitoring Portal Site ID"
    >
      <FlagBox dataKey="Inverter Monitoring ID" displayWithNoQueue isLast={!hasBattery}>
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          alignItems={{ base: 'flex-start', tablet: 'center' }}
          childGap={{ base: 'lg', tablet: '3xl' }}
          width="100"
        >
          <Field
            hideLabel
            name="monitoringSiteId"
            id="monitoringSiteId"
            component={FormikTextInput}
            type="text"
            isDisabled={isSubmitting}
          />
        </Box>
      </FlagBox>
      {hasBattery && (
        <FlagBox dataKey="Battery Monitoring ID" displayWithNoQueue>
          <Box
            direction={{ base: 'column', tablet: 'row' }}
            alignItems="center"
            childGap={{ base: 'lg', tablet: '3xl' }}
            width="100"
          >
            <Field
              hideLabel
              name="batteryMonitoringSiteId"
              id="batteryMonitoringSiteId"
              component={FormikTextInput}
              type="text"
              isDisabled={isSubmitting}
            />
          </Box>
        </FlagBox>
      )}
    </FormSection>
  );
};
