import { FieldArray, Field, useFormikContext } from 'formik';
import { Box, Button, FormikTextInput, SimulatedEventPayloadType, TextInput } from '@palmetto/palmetto-components';
import { HVACInstallKitDetails } from 'types';

const InstallKitsSection = ({ userOnlyHasReadPermissions }: any) => {
  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <>
      <Box fontWeight="medium">Install Kits</Box>
      <FieldArray
        name="installKits"
        render={(arrayHelpers) => (
          <Box gap="lg">
            {values.installKits && values.installKits.length
              ? values.installKits.map((installKit: HVACInstallKitDetails, index: number) => (
                  <Box
                    key={`installKit-${index}`}
                    direction="row"
                    gap="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box direction="column" gap="sm" width="512px">
                      <Field
                        label={`Install Kit ${index + 1}`}
                        name={`installKits.${index}.name`}
                        id={`installKits.${index}.name`}
                        placeholder={'Enter Install Kit'}
                        component={FormikTextInput}
                        isDisabled={userOnlyHasReadPermissions}
                      />
                    </Box>
                    <Field
                      label="Quantity"
                      type="number"
                      name={`installKits.${index}.quantity`}
                      id={`installKits.${index}.quantity`}
                      component={FormikTextInput}
                      maxWidth="lg"
                      width="100"
                      min={1}
                      isDisabled={userOnlyHasReadPermissions}
                      onChange={(event: SimulatedEventPayloadType) => {
                        const value = parseInt(event.target.value, 10);
                        if (value < 1) {
                          setFieldValue(`installKits.${index}.quantity`, 1);
                        } else {
                          setFieldValue(`installKits.${index}.quantity`, value);
                          const originalCost = (installKit.cost ?? 0) / (installKit.quantity || 1);
                          setFieldValue(`installKits.${index}.cost`, originalCost * value);
                        }
                      }}
                    />
                    <Box direction="column" gap="lg" width="xl">
                      <TextInput
                        label="Total Cost"
                        name={`installKits.${index}.cost`}
                        id={`installKits.${index}.cost`}
                        value={installKit.cost}
                        type="number"
                        prefix="$"
                        width="xl"
                        isDisabled={userOnlyHasReadPermissions}
                        onFocus={(event: SimulatedEventPayloadType) => {
                          if (event.target.value === '0') {
                            setFieldValue(`installKits.${index}.cost`, '');
                          }
                        }}
                        onBlur={(event: SimulatedEventPayloadType) => {
                          const value = parseFloat(event.target.value);
                          setFieldValue(`installKits.${index}.cost`, isNaN(value) ? 0 : value);
                        }}
                        onChange={(event: SimulatedEventPayloadType) => {
                          const value = event.target.value;
                          setFieldValue(`installKits.${index}.cost`, value);
                        }}
                      />
                    </Box>
                    <Box padding="lg 0 0 0">
                      <Button
                        as="button"
                        variant="tertiary"
                        tone="danger"
                        isDisabled={userOnlyHasReadPermissions}
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                ))
              : null}
            <Box alignItems="flex-start">
              <Button
                as="button"
                variant="secondary"
                size="sm"
                iconPrefix="add"
                isDisabled={userOnlyHasReadPermissions}
                onClick={() =>
                  arrayHelpers.push({
                    name: '',
                    quantity: 1,
                    cost: 0,
                  })
                }
              >
                Add Install Kit
              </Button>
            </Box>
          </Box>
        )}
      />
    </>
  );
};

export default InstallKitsSection;
