import React from 'react';
import { Box, BoxProps } from '@palmetto/palmetto-components';
import FormSectionHeader from './FormSectionHeader';

export interface FormSectionProps {
  className?: string;
  title?: string;
  description?: React.ReactNode;
  children?: React.ReactNode;
  baseStyle?: BoxProps;
}

const FormSection = ({ className, children, title, description, baseStyle = {} }: FormSectionProps) => {
  if (title) {
    return (
      <Box
        borderWidth="0 0 xs 0"
        borderColor="separator"
        className={className}
        childGap={{ base: 'md', desktop: 'xl' }}
        padding={{ desktop: 'xl 0', base: 'xl lg' }}
        direction={{
          base: 'column',
          desktop: 'row',
        }}
        {...baseStyle}
      >
        <FormSectionHeader title={title} description={description} />
        <Box display="block" width="100" childGap={{ base: 'lg', desktop: 'xl' }}>
          {children}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      flex="auto"
      direction={{
        base: 'column',
        desktop: 'row',
      }}
      padding="0 0 xl 0"
      borderWidth="0 0 xs"
      borderColor="separator"
      className={className}
    >
      {children}
    </Box>
  );
};

export default FormSection;
