import { api } from './api';
import { ReviewTaskType } from '../types/Queues';
import { Task } from '../types/Tasks';
import { TaskAssigneeType } from '../types/Tasks';

export const tasksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTasks: build.query<Task[], { id: string; assignee?: string }>({
      query: ({ id, assignee = 'any-assignee' }) => ({ url: `/accounts/${id}/tasks/${assignee}` }),
      providesTags: () => [{ type: 'Tasks', id: 'LIST' }],
    }),
    getReviewTasksByType: build.query<any[], { type: ReviewTaskType; assigneeType: TaskAssigneeType }>({
      query: ({ type, assigneeType }) => ({ url: `/tasks/review?type=${type}&assigneeType=${assigneeType}` }),
      providesTags: () => [{ type: 'QueueTasks', id: 'LIST' }],
    }),
    updateTaskStatus: build.mutation<
      any,
      {
        accountId: string;
        status: {
          status: 'completed' | 'rejected';
          rejectionNote?: string;
          rejectionReasons?: string[];
        };
        taskId: string;
      }
    >({
      query: ({ accountId, status, taskId }) => ({
        url: `/accounts/${accountId}/tasks/${taskId}/status`,
        method: 'PATCH',
        body: status,
      }),
      invalidatesTags: [
        { type: 'Tasks', id: 'LIST' },
        { type: 'QueueTasks', id: 'LIST' },
      ],
    }),
  }),
});

export const { useGetReviewTasksByTypeQuery, useGetTasksQuery, useUpdateTaskStatusMutation } = tasksApi;
