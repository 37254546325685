import { Helmet } from 'react-helmet';
import { Box } from '@palmetto/palmetto-components';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useGetPriceSheetDetailsQuery } from '../../../services/pricing';
import { MainContainer } from '../../MainContainer';
import { ProgramType } from 'types';

function PriceSheet() {
  const { id, programType = ProgramType.solar } = useParams<{ id: any; programType: string }>();
  const { data: priceSheetDetails } = useGetPriceSheetDetailsQuery({ id });

  return (
    <>
      <Helmet>
        <title>{priceSheetDetails?.name || 'Price Sheet'}</title>
      </Helmet>
      <MainContainer maxWidth="100">
        <Box style={{ marginLeft: '0' }}>
          <NavLink
            to={`/admin/pricing/${programType}/price-sheets/`}
            style={{ textDecoration: 'none', fontWeight: '500' }}
            end
          >
            {({ isActive }) => (
              <Box
                as="span"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Price Sheets
              </Box>
            )}
          </NavLink>
        </Box>

        <Box fontSize="2xl" margin="0 0 xl 0">
          {priceSheetDetails?.name || ''}
        </Box>
        <Box direction="row" borderWidth="0 0 xs 0" borderColor="separator" margin="0 0 md 0">
          <NavLink to={`.`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Pricing
              </Box>
            )}
          </NavLink>
          <NavLink to={`./mappings`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Mappings
              </Box>
            )}
          </NavLink>
        </Box>
        <Outlet />
      </MainContainer>
    </>
  );
}

export default PriceSheet;
