import { Box, Icon } from '@palmetto/palmetto-components';
import { ActivityHistoryEventType, CancelReason, cancelReasonLabels } from 'types';
import { DocumentDocument, DocumentFile } from 'types';
import { FileItem } from '../../documents/FileItem';

import EventBy from './EventBy';

const AccountCancelled = ({ event }: { event: any }) => {
  const { cancelNotes, cancelReason, primaryApplicantName, supportingDocuments } = event?.data || {};
  const isCancelled = event?.eventType === ActivityHistoryEventType.sabalAccountCancelled;
  return (
    <Box display="block">
      <Box as="span" display="inline-block" margin="0 2xs 0 0">
        <EventBy event={event} /> {isCancelled ? 'cancelled' : 'reactivated'} account{' '}
        <Box as="span" fontWeight="bold" display="inline">
          {primaryApplicantName}
        </Box>
        {cancelReason && (
          <Box direction="row">
            <Box minWidth="100px">Reason</Box>
            <Box>{cancelReasonLabels[event?.data?.cancelReason as CancelReason]}</Box>
          </Box>
        )}
        {cancelNotes && (
          <Box direction="row">
            <Box minWidth="100px">Note</Box>
            <Box>{event?.data?.cancelNotes}</Box>
          </Box>
        )}
        {supportingDocuments?.length > 0 &&
          supportingDocuments?.map((document: DocumentDocument) => {
            return document?.files?.map((file: DocumentFile, idx) => (
              <Box direction="row">
                <Box minWidth="100px">Attachment{document?.files?.length > 1 ? ` ${idx + 1}` : ''}</Box>
                <Box direction="row" gap="2xs">
                  <Icon name="paperclip" size="xs" color="body-secondary" />
                  <FileItem file={file} key={file?.md5Hash} />
                </Box>
              </Box>
            ));
          })}
      </Box>
    </Box>
  );
};

export default AccountCancelled;
