import { ProgramType } from 'types';
import PVSystem from './PVSystem';
import { HVACSystemDesign } from './HVACSystemDesign';

export const SystemDesignFactory = ({ programType }: { programType: ProgramType }) => {
  switch (programType) {
    case ProgramType.hvac:
      return <HVACSystemDesign />;
    case ProgramType.solar:
    case ProgramType.doePr:
    case ProgramType.newHomes:
      return <PVSystem />;
    default:
      return null;
  }
};
