import { FieldArray, useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { Box, Button } from '@palmetto/palmetto-components';
import { useCallback, useMemo } from 'react';
import { SolarArray } from './SolarArray';
import { Option } from './AvlFormUtils';

interface SolarArraysProps {
  isLoading: boolean;
}

export const SolarArraysFormSection = ({ isLoading }: SolarArraysProps) => {
  const { isSubmitting, values, setFieldValue } = useFormikContext<any>();
  const isAnythingLoadingOrSubmitting = isLoading || isSubmitting;

  const addArray = useCallback(() => {
    setFieldValue('arrays', [
      ...values.arrays,
      { panelCount: '', annualTSRF: '', inverter: '', orientation: '', tilt: '', azimuth: '' },
    ]);
  }, [setFieldValue, values.arrays]);

  const inverterOptions: Option[] = useMemo(() => {
    return values.inverterModels?.map((inverter: {model: string, count: number}, index: number) => ({
      value: index + 1,
      label: `Inverter ${index + 1} (${inverter.model})`,
    }));
  }, [values.inverterModels]);

  return (
    <FormSection title="Arrays" description="This section is only visible for LightReach team members">
      <Box alignItems="flex-start" gap="lg">
        <FieldArray
          name="arrays"
          render={() => (
            <Box childGap={{ base: 'lg', desktop: 'xl' }}>
              {(values.arrays ?? [{}]).map((_array: unknown, index: number) => (
                <Box key={index}>
                  <SolarArray
                    isLoading={isAnythingLoadingOrSubmitting}
                    fieldName="arrays"
                    arrayNumber={index}
                    inverterOptions={inverterOptions}
                  />
                </Box>
              ))}
            </Box>
          )}
        />
        <Button
          variant="primary"
          tone="neutral"
          onClick={addArray}
          isDisabled={isAnythingLoadingOrSubmitting}
          iconPrefix="add"
          size="sm"
        >
          Array
        </Button>
      </Box>
    </FormSection>
  );
};
