import { Badge, BadgeVariant, Box, Button, Details, toast, useOpenClose } from '@palmetto/palmetto-components';
import { QuoteStatus } from '../../types/Quotes';
import { useVoidQuoteMutation } from '../../services/quotes';
import { currencyFormatter } from '../../helpers/currencyFormatter';
import RequirePermissions from '../auth/requirePermissions';
import { isErrorWithData } from '../../services/helpers';
import { HVACQuoteDocument } from 'types';
import { useSendContractMutation } from '../../services/contract';
import { percentageFormatter } from '../../helpers/percentageFormatter';

interface HvacQuoteListItemProps {
  item: HVACQuoteDocument;
  isMainQuote?: boolean;
}

interface ListItemElementProps {
  label: string;
  value: string;
}

export function HvacListItemElement({ label, value }: ListItemElementProps) {
  return (
    <Box
      direction="row"
      padding={{ base: '0 md 0 lg' }}
      gap="5xl"
      width={{
        base: '100',
        tablet: 'auto',
        desktop: 'auto',
      }}
    >
      <Box display="block" padding={'xs 0'}>
        <Box fontSize={'lg'} fontWeight={'medium'} padding={'0 0 xs 0'}>
          {value}
        </Box>
        <Box fontSize={'xs'}>{label}</Box>
      </Box>
    </Box>
  );
}

export function HvacQuoteListItem({ item, isMainQuote }: HvacQuoteListItemProps): JSX.Element {
  const { totalAmountPaid, systemPricingDetails, escalationRate, totalSystemCost, productName, status } = item;
  const showVoidButton = status === QuoteStatus.active || status === QuoteStatus.contracted;
  const title = productName;
  const [voidQuote, { isLoading: isVoidingQuote }] = useVoidQuoteMutation({
    fixedCacheKey: 'useVoidQuoteMutationKey',
  });
  const [_sendContract, { isLoading: isSendingContract }] = useSendContractMutation({
    fixedCacheKey: 'useSendContractMutationKey',
  });
  const { isOpen: isDetailsOpen, handleToggle: handleDetailsToggle } = useOpenClose({
    defaultIsOpen: isMainQuote || showVoidButton,
  });

  const quoteStatus: {
    [key in QuoteStatus]: {
      label: string;
      variant: BadgeVariant;
    };
  } = {
    [QuoteStatus.contracted]: {
      label: 'Contracted',
      variant: 'success',
    },
    [QuoteStatus.active]: {
      label: 'Active',
      variant: 'success',
    },
    [QuoteStatus.voided]: {
      label: 'Voided',
      variant: 'warning',
    },
  };

  const handleVoidQuote = async () => {
    const toastId = toast.loading('Voiding quote');

    try {
      const confirmed = window.confirm('Are you sure you want to Void this Quote? This action cannot be undone.');

      if (!confirmed) return;
      await voidQuote({ accountId: item.accountId.toString(), quoteId: item.id.toString() }).unwrap();
      toast.success('Quote voided successfully');
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error voiding quote');
      }
    } finally {
      toast.dismiss(toastId);
    }
  };
  return (
    <Details isOpen={isDetailsOpen} gap="sm" borderWidth="xs 0 0 0" borderColor="separator">
      <Details.Summary
        isDetailsOpen={isDetailsOpen}
        onToggle={showVoidButton ? undefined : handleDetailsToggle}
        display="inline"
        cursor={'pointer'}
        width="100"
      >
        <Box width="100" gap="5xl-tablet 5xl-desktop" columnGap={'md'} direction="row" padding={'sm lg xs lg'}>
          <Box width="lg">
            <Badge
              message={quoteStatus[status as keyof typeof QuoteStatus]?.label}
              variant={quoteStatus[status as keyof typeof QuoteStatus]?.variant}
              style={{ width: 'fit-content' }}
            />
          </Box>
          <Box gap="xs" flex="auto">
            <Box>{title || '---'}</Box>
          </Box>
          <Box display={'flex'}>
            {showVoidButton ? (
              <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
                <Button
                  size="sm"
                  variant="secondary"
                  tone="danger"
                  disabled={isVoidingQuote || isSendingContract}
                  onClick={handleVoidQuote}
                >
                  Void
                </Button>
              </RequirePermissions>
            ) : (
              <Box fontSize="xs">{isDetailsOpen ? 'Hide Details' : 'Show Details'}</Box>
            )}
          </Box>
        </Box>
      </Details.Summary>
      <Box padding="0 0 xs 0">
        <Box
          display={{ base: 'grid', tablet: 'inherit', desktop: 'inherit' }}
          style={{
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          }}
        >
          <HvacListItemElement label={'Total Financed Cost'} value={currencyFormatter.format(totalSystemCost)} />
          <HvacListItemElement
            label={'Customer Payment'}
            value={`${currencyFormatter.format(systemPricingDetails[0]?.monthlyPayment)}/mo`}
          />
          <HvacListItemElement label={'Payment Escalator'} value={`${percentageFormatter.format(escalationRate)}`} />
          <HvacListItemElement label={'Contract Term'} value={`${systemPricingDetails.length} Years`} />
          <HvacListItemElement label={'Total Monthly Payments'} value={currencyFormatter.format(totalAmountPaid)} />
        </Box>
      </Box>
    </Details>
  );
}
