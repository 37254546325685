import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Applicant } from 'types';
import { isErrorWithData } from '../../services/helpers';
import { Box, Button, Modal, toast, useOpenClose } from '@palmetto/palmetto-components';
import {
  useLazyGetExternalApplicationUrlQuery,
  useSendExternalApplicationUrlMutation,
} from '../../services/applications';
import RequirePermissions from '../auth/requirePermissions';

interface GetTermsLinkModalProps {
  primaryApplicant?: Applicant;
}

export function GetTermsLinkModal({ primaryApplicant }: GetTermsLinkModalProps) {
  const { email, firstName } = primaryApplicant || {};
  const { id = '' } = useParams<{ id: string }>();
  const [trigger, { data: inviteData, isLoading: isGettingInviteLink }] = useLazyGetExternalApplicationUrlQuery();

  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const [sendInvite] = useSendExternalApplicationUrlMutation();

  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const handleCopyToClipBoard = useCallback(async () => {
    if (inviteData?.url) {
      await navigator.clipboard.writeText(inviteData?.url);
      toast.success('Copied to clipboard');
    }
  }, [inviteData, navigator]);

  const handleGetInviteUrl = useCallback(async () => {
    try {
      await trigger(id).unwrap();
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error getting invite link: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error getting invite link');
      }
    }
  }, [trigger, id]);

  const handleSendInvite = useCallback(async () => {
    setIsSendingInvite(true);
    try {
      if (email && firstName) {
        await sendInvite({ accountId: id, email, firstName }).unwrap();
      }
      toast.success('Invite sent');
      closeModal();
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error sending invite: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error sending invite');
      }
    }
    setIsSendingInvite(false);
  }, [email, id, sendInvite]);

  return (
    <Box>
      <Button variant="secondary" tone="neutral" size="sm" onClick={openModal} iconPrefix="send">
        Send Terms
      </Button>
      <Modal ariaLabelledBy="titleBasic" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="4xl">
        <Modal.Header id="titleBasic" title="Invite the customer to accept terms" onDismiss={closeModal} />
        <Modal.Body childGap="md" background="secondary">
          <Box as="p">You can send a one-time-use URL to the customer by email.</Box>
          <Box as="p">If the customer has not received their invite email:</Box>
          <Box as="ul" padding="0 0 0 md" childGap="sm">
            {email && (
              <li>
                Verify email address on file is correct: <strong>{email}</strong>
              </li>
            )}
            <li>Advise the customer to check their spam/junk folder</li>
          </Box>
          <RequirePermissions permissions={['lightReachSupport']}>
            <Box as="p">As technical support, you may also generate the URL and copy it from here.</Box>
          </RequirePermissions>
        </Modal.Body>
        <Modal.Footer justifyContent="space-between">
          <RequirePermissions permissions={['lightReachSupport']}>
            {inviteData ? (
              <Box width="100" childGap="md" direction="row" justifyContent="space-between">
                <Button onClick={handleCopyToClipBoard} variant="secondary" tone="neutral">
                  Copy URL To Clipboard
                </Button>
              </Box>
            ) : (
              <Button
                variant="secondary"
                tone="neutral"
                isLoading={isGettingInviteLink || isSendingInvite}
                onClick={handleGetInviteUrl}
              >
                Get Terms URL
              </Button>
            )}
          </RequirePermissions>
          <Button
            variant="primary"
            iconPrefix="mail"
            className="shadow-xs"
            onClick={handleSendInvite}
            style={{ whiteSpace: 'nowrap' }}
            isDisabled={!email}
            isLoading={isSendingInvite || isGettingInviteLink}
          >
            Send Terms
          </Button>
        </Modal.Footer>
      </Modal>
    </Box>
  );
}
