import { Helmet } from 'react-helmet';
import { Box, Button, TextInput, toast } from '@palmetto/palmetto-components';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useEditPriceSheetMutation, useGetPriceSheetDetailsQuery } from '../../../services/pricing';
import { MainContainer } from '../../MainContainer';
import * as yup from 'yup';
import { useFormik, FormikValues } from 'formik';
import { ProgramType } from 'types';

function PriceSheet() {
  const navigate = useNavigate();
  const { id, programType = ProgramType.solar } = useParams<{ id: any, programType: ProgramType }>();
  const { data: priceSheetDetails } = useGetPriceSheetDetailsQuery({ id });
  const [editPriceSheet] = useEditPriceSheetMutation();

  const formik = useFormik({
    initialValues: {
      name: priceSheetDetails?.name,
    },
    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
    }),
    enableReinitialize: true,
    onSubmit: async (values: FormikValues, { setSubmitting }) => {
      try {
        await editPriceSheet({
          id,
          data: { name: values.name },
        }).unwrap();
        toast.success('Price sheet updated successfully');
        navigate(`/admin/pricing/${programType}/price-sheets`);
      } catch (e) {
        console.log(e);
        toast.error('Error updating price sheet');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Pricing Sheet Details</title>
      </Helmet>
      <MainContainer>
        <Box style={{ marginLeft: '0' }}>
          <NavLink to={`/admin/pricing/${programType}/price-sheets`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Price Sheets
              </Box>
            )}
          </NavLink>
        </Box>
        <Box fontSize="2xl" margin="0 0 xl 0">
          <form onSubmit={formik.handleSubmit}>
            <TextInput
              type="text"
              label="Price Sheet Name"
              name="name"
              id="name"
              value={formik.values.name}
              onChange={(event) => formik.setFieldValue('name', event.target.value)}
              isRequired
              onBlur={formik.handleBlur}
              error={formik.touched.name && (formik.errors.name as string)}
            />
            <Button variant="primary" size="md" type="submit" isLoading={formik.isSubmitting}>
              Save
            </Button>
          </form>
        </Box>
        <Outlet />
      </MainContainer>
    </>
  );
}

export default PriceSheet;
