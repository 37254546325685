import { MilestoneReviewStatus } from 'types';
import { ActivationPackageActions } from '../types/ActivationPackage';
import { api } from './api';

type SubmitActivationPackage = {
  monitoringSiteId: string;
  ptoGrantedDate: string;
  action?: ActivationPackageActions;
  reviewStatus?: MilestoneReviewStatus;
};

export const activationPacketApi = api.injectEndpoints({
  endpoints: (build) => ({
    submitActivationPacket: build.mutation<any, { accountId: string; data: SubmitActivationPackage }>({
      query: ({ accountId, data }) => ({
        url: `accounts/${accountId}/activation-package`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Tasks', id: 'LIST' },
        { type: 'Accounts', id: arg.accountId },
      ],
    }),
  }),
});

export const { useSubmitActivationPacketMutation } = activationPacketApi;
