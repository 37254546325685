import { Box } from '@palmetto/palmetto-components';
import { NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { OrganizationPricingTabType, ProgramType, organizationPricingTabMap } from 'types';
import { PricingTabOptions } from './PricingTabOptions';
import { useEffect } from 'react';
import { useGetOrganizationDetailsQuery } from '@/services/organizations';

export const Pricing = () => {
  const { alias, pricingType } = useParams();
  const { data: org } = useGetOrganizationDetailsQuery({ alias: alias as string }, { skip: !alias });

  const navigate = useNavigate();

  useEffect(() => {
    if (!pricingType) {
      const modifier = Object.keys(OrganizationPricingTabType)[0] as OrganizationPricingTabType;
      navigate(`${modifier}`, { replace: true });
    }
  }, [pricingType, navigate]);

  return (
    <Box direction="row" childGap="3xl">
      <Box>
        {org &&
          org?.programTypes?.includes(ProgramType.solar) &&
          Object.keys(organizationPricingTabMap).map((type) => (
            <NavLink
              to={`/settings/organizations/${alias}/pricing/${type}`}
              style={{ textDecoration: 'none', fontWeight: '500' }}
              key={type}
              end
            >
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s', borderBottom: 0 }}
                >
                  {organizationPricingTabMap[type as OrganizationPricingTabType]}
                </Box>
              )}
            </NavLink>
          ))}
        {org && org?.programTypes?.includes(ProgramType.doePr) && (
          <NavLink
            to={`/settings/organizations/${alias}/pricing/mappings`}
            style={{ textDecoration: 'none', fontWeight: '500' }}
            key={'mappings'}
            end
          >
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s', borderBottom: 0 }}
              >
                {organizationPricingTabMap['mappings']}
              </Box>
            )}
          </NavLink>
        )}
      </Box>
      <Box flex="auto">
        <Routes>
          <Route path="/" element={<PricingTabOptions />} />
        </Routes>
      </Box>
    </Box>
  );
};
