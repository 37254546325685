import { Box, Button, Modal } from '@palmetto/palmetto-components';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MobileFilterContainer } from './MobileFiltersContainer';
import { AppliedFiltersList } from './AppliedFiltersList';
import { AppliedFilterItem } from './AppliedFilterItem';
import { StatusFilter } from './StatusFilter';
import { DocumentTypeFilter } from './Documents/DocumentTypeFilter';
import { LegacyReviewQueueType } from 'types';

type MobileFiltersModalProps = {
  isMobile: boolean;
  showFilterModal: boolean;
  closeFilterModal: () => void;
  isFiltered: boolean;
  handleClearFilters: () => void;
  searchFilters: [string, string][];
  handleClearFilter: (key: string, filterItem: string) => void;
  queue: LegacyReviewQueueType;
};
export type Filters = Record<string, string[]>;

export const MobileFiltersModal = ({
  isMobile,
  showFilterModal,
  closeFilterModal,
  isFiltered,
  handleClearFilters,
  searchFilters,
  handleClearFilter,
  queue,
}: MobileFiltersModalProps) => {
  const [filters, setFilters] = useState<Filters>({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchFilters && searchFilters.length) {
      const filterObj = searchFilters.reduce((acc, [key, value]) => {
        acc[key] = value.split(',');
        return acc;
      }, {} as Filters);
      setFilters(filterObj);
    }
  }, [searchFilters, setFilters]);

  const onFilterChange = (key: string, values: string[]) => {
    const filterObj = {
      ...filters,
      [key]: values,
    };

    setFilters(filterObj);
  };

  const handleApplyFilters = () => {
    Object.keys(filters).forEach((filter) => {
      if (filters[filter].length) {
        searchParams.set(filter, filters[filter].join(','));
      } else {
        searchParams.delete(filter);
      }
    });
    setSearchParams(searchParams);
  };

  return (
    <Modal ariaLabelledBy="filterModalHeader" fullScreenMobile isOpen={showFilterModal} onDismiss={closeFilterModal}>
      <Modal.Header
        id="filterModalHeader"
        title={isMobile ? 'Filters' : 'More Filters'}
        onDismiss={closeFilterModal}
      />
      <Modal.Body display="block" overflow="auto" padding="0">
        {isMobile && isFiltered && (
          <Box borderWidth="0 0 xs 0" borderColor="separator">
            {isFiltered && (
              <AppliedFiltersList onClearFilters={handleClearFilters}>
                {searchFilters.map((filterArr) => {
                  const [key, values] = filterArr;
                  return values
                    .split(',')
                    .map((filterItem) => (
                      <AppliedFilterItem
                        key={filterItem}
                        onRemoveFilter={() => handleClearFilter(key, filterItem)}
                        value={filterItem}
                      />
                    ));
                })}
              </AppliedFiltersList>
            )}
          </Box>
        )}
        <MobileFilterContainer title="Status" type="status" isMobile={isMobile}>
          <StatusFilter isMobile={isMobile} onFilterChange={onFilterChange} />
        </MobileFilterContainer>
        {queue === LegacyReviewQueueType.uploadedDocument ? (
          <MobileFilterContainer title="Document Type" type="documentType" isMobile={isMobile}>
            <DocumentTypeFilter isMobile={isMobile} onFilterChange={onFilterChange} />
          </MobileFilterContainer>
        ) : null}
      </Modal.Body>
      <Modal.Footer justifyContent="space-between" alignItems="center">
        {!isMobile && (
          <button
            className="naked font-size-md font-color-grey-500 p-v-xs"
            style={{ textDecoration: 'underline' }}
            onClick={handleClearFilters}
          >
            Clear All
          </button>
        )}
        <Box display="block" width={{ base: '100', desktop: 'auto' }} shadow="sm" radius="md">
          <Button
            variant="primary"
            fullWidth={isMobile}
            onClick={() => {
              handleApplyFilters();
              closeFilterModal();
            }}
          >
            Done
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};
