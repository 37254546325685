import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  shouldLogout: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setShouldLogout: (state, action: PayloadAction<boolean>) => ({
      ...state,
      shouldLogout: action.payload,
    }),
  },
});

export const { setShouldLogout } = authSlice.actions;
export default authSlice.reducer;
