import { Box, Button, Modal, TextInput, toast } from '@palmetto/palmetto-components';
import { FormikValues, useFormik } from 'formik';
import { SyntheticEvent } from 'react';
import * as yup from 'yup';
import { useCreateHoldbackMutation } from '../../../services/pricing';

export const CreateHoldbackModal = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
}) => {
  const [createHoldback] = useCreateHoldbackMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      amount: undefined,
    },
    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
      amount: yup.number().required('Amount is required'),
    }),
    onSubmit: async (values: FormikValues, { setSubmitting, resetForm }) => {
      try {
        await createHoldback({
          data: { name: values.name, amount: values.amount },
        }).unwrap();
        toast.success('Holdback created successfully');
        resetForm();
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Error creating holdback');
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Modal ariaLabelledBy="createHoldback" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header id="createHoldback" title="Add Holdback" onDismiss={closeModal} />
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body background="secondary">
          <Box direction="column" childGap="md">
            <TextInput
              type="text"
              label="Name"
              name="name"
              id="name"
              value={formik.values.name}
              onChange={(event) => formik.setFieldValue('name', event.target.value)}
              isRequired
              onBlur={formik.handleBlur}
              error={formik.touched.name && (formik.errors.name as string)}
            />
            <TextInput
              type="text"
              label="Amount"
              name="amount"
              id="amount"
              value={formik.values.amount}
              onChange={(event) => formik.setFieldValue('amount', event.target.value)}
              prefix="$"
              suffix="/Watt"
              isRequired
              onBlur={formik.handleBlur}
              error={formik.touched.amount && (formik.errors.amount as string)}
            />
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            as="button"
            onClick={() => {
              formik.resetForm();
              closeModal();
            }}
            variant="secondary"
            tone="neutral"
            size="md"
            isLoading={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button variant="primary" size="md" type="submit" isLoading={formik.isSubmitting}>
            Add Holdback
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
