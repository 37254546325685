import { Box, CheckboxInput } from '@palmetto/palmetto-components';
import { HvacNtpRejectionReasonOptions } from 'types';

interface RejectionReasonInputsProps {
  handleChange: (field: string, value: HvacNtpRejectionReasonOptions[]) => any;
  isSubmitting: boolean;
  rejectionReasonsValues: HvacNtpRejectionReasonOptions[];
}

export const HvacRejectionReasonCheckboxGroup = ({
  rejectionReasonsValues,
  handleChange,
  isSubmitting,
}: RejectionReasonInputsProps) => {
  const rejectionOptions = Object.values(HvacNtpRejectionReasonOptions);

  const handleReasonSelection = ({
    isSelected,
    value,
    selectedOptions,
  }: {
    isSelected: boolean;
    value: HvacNtpRejectionReasonOptions;
    selectedOptions: HvacNtpRejectionReasonOptions[];
  }) => {
    const newSelectedOptions = [...selectedOptions];

    if (isSelected) {
      newSelectedOptions.push(value);
    } else {
      const index = selectedOptions.findIndex((selectedOption: any) => selectedOption === value);
      if (index > -1) newSelectedOptions.splice(index, 1);
    }
    handleChange('rejectionReasons', newSelectedOptions);
  };

  return (
    <Box gap="sm">
      {rejectionOptions.map((option) => (
        <CheckboxInput
          id={option}
          label={option}
          name={option}
          key={option}
          isChecked={rejectionReasonsValues?.includes(option) || false}
          isDisabled={isSubmitting}
          onChange={(event) =>
            handleReasonSelection({
              isSelected: event.target.checked,
              value: option,
              selectedOptions: rejectionReasonsValues,
            })
          }
        />
      ))}
    </Box>
  );
};
