import { MilestoneReviewStatus } from 'types';
import { ReviewPackageActions } from '../types/ReviewPackage';
import { api } from './api';

/**
 * @deprecated use installPackage.ts
 */
export const installPacketApi = api.injectEndpoints({
  endpoints: (build) => ({
    submitInstallPacket: build.mutation<
      any,
      { accountId: string; action?: ReviewPackageActions; reviewStatus?: MilestoneReviewStatus }
    >({
      query: ({ accountId, action, reviewStatus }) => ({
        url: `accounts/${accountId}/install-package`,
        method: 'POST',
        body: { action, ...(reviewStatus && { reviewStatus }) },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Tasks', id: 'LIST' },
        { type: 'Accounts', id: arg.accountId },
      ],
    }),
  }),
});

export const { useSubmitInstallPacketMutation } = installPacketApi;
