import { Box, Icon } from '@palmetto/palmetto-components';
import styles from './GalleryItem.module.scss';

export type GalleryItemProp = {
  item: any;
  index: number;
  useLightbox?: boolean;
  onClick?: (index: number) => void;
};

export const GalleryItem = ({ item: document, index, useLightbox = false, onClick }: GalleryItemProp) => {
  const originalViewUrl = document.files[0].viewUrls[0].url;
  const fileName = document.files[0].originalName;
  const isImage = document.files[0].contentType.includes('image');

  return (
    <Box
      as="a"
      title={fileName}
      radius="sm"
      overflow="hidden"
      padding="2xs"
      background="tertiary"
      hover={{ background: 'grey-200' }}
      cursor="pointer"
      target="_blank"
      onClick={(event: any) => {
        if (useLightbox) {
          event.preventDefault();
        }
        if (onClick) {
          onClick(index);
        }
      }}
    >
      <Box
        className={styles.imageContainer}
        href={originalViewUrl}
        data-grid-inline-offset="true"
        height={{ base: 'md', tablet: 'lg' }}
      >
        {isImage ? (
          <img className={styles.image} src={originalViewUrl} alt={fileName} height="100%" />
        ) : (
          <Icon name="document" size="3xl" style={{ margin: 'auto' }} />
        )}
      </Box>
      <Box padding="xs" fontSize="xs" background="primary" childGap="2xs">
        <span className={styles.documentFileName}>{fileName}</span>
      </Box>
    </Box>
  );
};
