import { Box } from '@palmetto/palmetto-components';
import { ReactNode } from 'react';

interface PageHeaderProps {
  title: string;
  description?: string;
  children?: ReactNode;
  className?: string;
  eyebrow?: ReactNode;
}

const PageHeader = ({ title, description, children, className, eyebrow, ...rest }: PageHeaderProps) => {
  return (
    <Box
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={className}
      color="contrast"
      padding={{ base: '0 lg', tablet: '0' }}
      flex="auto"
      {...rest}
    >
      <Box display="block" childGap="2xs">
        {eyebrow && <Box display="block">{eyebrow}</Box>}

        {title && (
          <Box direction="row" justifyContent="flex-start" alignItems="baseline" childGap="sm">
            <Box as="h1" fontSize={{ base: 'lg', tablet: 'xl', desktop: '2xl' }} fontWeight="medium">
              {title}
            </Box>
          </Box>
        )}
        {description && (
          <Box
            fontSize={{
              base: 'sm',
              tablet: 'md',
              desktop: 'lg',
            }}
            color="body-secondary"
          >
            {description}
          </Box>
        )}
      </Box>
      {children && <Box display="block">{children}</Box>}
    </Box>
  );
};

export default PageHeader;
